<template>
  <v-list-item class="py-2 px-4" :to="route">
    <v-list-item-avatar :color="item.active ? 'primary' : 'grey'">
      <v-icon dark="dark">mdi-account-outline</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="my-1">
        <v-row>
          <v-col
            ><span class="font-weight-medium">{{ item.firstName }}</span
            ><span class="font-weight-bold mx-2">{{ item.lastName }}</span
            ><span class="text--secondary ml-8">{{ item.email }}</span></v-col
          >
          <v-col v-if="!item.active" cols="auto"><span class="text--secondary">(Nieaktywny)</span></v-col>
          <v-col cols="auto"
            ><span class="font-weight-bold">{{ item.roles | pickHighestRole | mapRole }}</span></v-col
          >
        </v-row>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapRole } from '@/helpers/mapRoles';
import pickHighestRole from '@/helpers/pickHighestRole';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    route() {
      return {
        name: 'user-edit',
        params: { id: this.item.id },
      };
    },
  },
  filters: {
    pickHighestRole,
    mapRole,
  },
};
</script>
