export default (roles) => {
  let hasAdmin = false;
  let hasManager = false;

  for (const role of roles) {
    if (role === 'ROLE_ADMIN') {
      hasAdmin = true;
    }

    if (role === 'ROLE_MANAGER') {
      hasManager = true;
    }
  }

  if (hasAdmin) {
    return 'ROLE_ADMIN';
  }

  if (hasManager) {
    return 'ROLE_MANAGER';
  }

  return 'ROLE_USER';
};
