<template>
  <v-form ref="form">
    <v-row align="center">
      <v-col>
        <div class="text-subtitle-1 px-0 text--secondary">Nazwa dłużnika</div>
      </v-col>
      <v-col v-if="!debtor" cols="auto">
        <v-radio-group row="row" v-model="form.input.isPerson">
          <v-radio :value="true" label="Osoba fizyczna"></v-radio>
          <v-radio :value="false" label="Osoba prawna"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="form.input.isPerson">
      <v-col cols="6">
        <v-text-field
          outlined="outlined"
          label="Imię"
          :rules="form.rules.person.name"
          v-model="form.input.person.name"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined="outlined"
          label="Odmiana imienia"
          :rules="form.rules.person.nameDeclined"
          v-model="form.input.person.nameDeclined"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined="outlined"
          label="Nazwisko"
          :rules="form.rules.person.surname"
          v-model="form.input.person.surname"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined="outlined"
          label="Odmiana nazwiska"
          :rules="form.rules.person.surnameDeclined"
          v-model="form.input.person.surnameDeclined"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-text-field
          outlined="outlined"
          label="Nazwa"
          :rules="form.rules.legal.name"
          v-model="form.input.legal.name"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          outlined="outlined"
          label="Odmiana nazwy"
          :rules="form.rules.legal.nameDeclined"
          v-model="form.input.legal.nameDeclined"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="text-subtitle-1 px-0 text--secondary">Adres do korespondencji</div>
      </v-col>
      <v-col cols="12">
        <v-text-field
          outlined="outlined"
          label="Ulica"
          :rules="form.rules.address.street"
          v-model="form.input.address.street"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined="outlined"
          label="Kod pocztowy"
          :rules="form.rules.address.postCode"
          v-model="form.input.address.postCode"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined="outlined"
          label="Miejscowość"
          :rules="form.rules.address.city"
          v-model="form.input.address.city"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <div class="text-subtitle-1 px-0 text--secondary">Dane kontaktowe</div>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined="outlined"
          label="Email"
          :rules="form.rules.contact.email"
          v-model="form.input.contact.email"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined="outlined"
          label="Telefon"
          :rules="form.rules.contact.phone"
          v-model="form.input.contact.phone"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    debtor: { type: Object },
  },
  data() {
    return {
      form: {
        input: {
          isPerson: true,
          person: {
            name: '',
            nameDeclined: '',
            surname: '',
            surnameDeclined: '',
          },
          legal: {
            name: '',
            nameDeclined: '',
          },
          contact: {
            email: '',
            phone: '',
          },
          address: {
            street: '',
            postCode: '',
            city: '',
          },
        },
        rules: {
          person: {
            name: [(v) => !!v || 'Podaj imię'],
            nameDeclined: [(v) => !!v || 'Odmień imię'],
            surname: [(v) => !!v || 'Podaj nazwisko'],
            surnameDeclined: [(v) => !!v || 'Odmień nazwisko'],
          },
          contact: {
            email: [(v) => !!v || 'Podaj email'],
            phone: [(v) => !!v || 'Podaj nr telefonu'],
          },
          legal: {
            name: [(v) => !!v || 'Podaj nazwę'],
            nameDeclined: [(v) => !!v || 'Odmień nazwę'],
          },
          address: {
            street: [(v) => !!v || 'Podaj ulicę'],
            postCode: [(v) => !!v || 'Podaj kod'],
            city: [(v) => !!v || 'Podaj miejscowość'],
          },
        },
      },
    };
  },
  created() {
    if (this.debtor) {
      this.form.input.isPerson = this.debtor.__typename === 'PrivatePersonDebtor';
      this.form.input.person.name = this.debtor.name || '';
      this.form.input.person.nameDeclined = this.debtor.nameDeclined || '';
      this.form.input.person.surname = this.debtor.surname || '';
      this.form.input.person.surnameDeclined = this.debtor.surnameDeclined || '';
      this.form.input.legal.name = this.debtor.name || '';
      this.form.input.legal.nameDeclined = this.debtor.nameDeclined || '';
      this.form.input.contact.email = this.debtor.email || '';
      this.form.input.contact.phone = this.debtor.phone || '';
      this.form.input.address.street = this.debtor.address.street || '';
      this.form.input.address.postCode = this.debtor.address.postCode || '';
      this.form.input.address.city = this.debtor.address.city || '';
    }
  },
  watch: {
    'form.input': {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>
