import PDFMerger from '@/helpers/pdf/PdfMerger';
import { formatFileName } from '@/helpers/pdf/WriteTextHelper';
import { saveAs } from 'file-saver';

export const serveSingleFile = (file, signature, name) => {
  saveAs(file.downloadUrl, `${signature} ${name}.pdf`, { type: 'application/pdf' });
};

// @param array<string>
export const mergeFiles = async (files) => {
  const merger = new PDFMerger();

  if (files.length < 1) {
    return Promise.reject(Error('No files to merge!'));
  }

  try {
    await Promise.all(files.map(async (file) => merger.add(file)));
    return merger.save(formatFileName('merged_documents'));
  } catch (e) {
    return Promise.reject(e);
  }
};
// @param array<string>
export const mergeFilesAndPrint = async (files) => {
  const merger = new PDFMerger();

  if (files.length < 1) {
    return Promise.reject(Error('No files to merge!'));
  }

  try {
    await Promise.all(files.map(async (file) => merger.add(file)));
    const pdfBuffer = await merger.saveAsBlob();
    const urlCreator = window.URL || window.webkitURL;
    const fileUrl = urlCreator.createObjectURL(pdfBuffer);
    window.open(fileUrl, '_blank').print();

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};
