import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import pl from 'vuetify/es5/locale/pl';

Vue.use(Vuetify);

Vue.component({
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = 'pl';
    },
  },
});

export default new Vuetify({
  lang: {
    locales: { pl },
    current: 'pl',
  },
  theme: {
    themes: {
      // light: {
      //   primary: '#2776EA',
      //   secondary: '#024A6D',
      //   accent: '#53B6E7',
      //   error: '#E82100',
      //   info: '#95D7FF',
      //   success: '#20C311',
      //   warning: '#F78F1E',
      // },
      light: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
    },
  },
});
