// eslint-disable-next-line import/no-cycle
import { isLoggedIn } from '@/plugins/apollo';
import Vue from 'vue';
import VueRouter from 'vue-router';

import v2routes from '@/v2/router/routes'; // eslint-disable-line import/no-cycle

Vue.use(VueRouter);

export function requireAuth(to, from, next) {
  if (!isLoggedIn()) {
    next('login');
    return;
  }

  next();
}
const routes = [
  ...v2routes,
  {
    path: '/time-table',
    name: 'timeTable',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "general" */ '@/pages/TimeTablePage.vue'),
    meta: {
      layout: 'Main',
    },
  },
  {
    path: '/schedules',
    name: 'schedules',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "general" */ '@/pages/task/ScheduleListPage.vue'),
    meta: {
      layout: 'Main',
    },
  },
  {
    path: '/schedules/:id',
    name: 'schedules/details',
    beforeEnter: requireAuth,
    props: (router) => ({
      scheduleId: router.params.id,
    }),
    component: () => import(/* webpackChunkName: "general" */ '@/pages/task/ScheduleListPage.vue'),
    meta: {
      layout: 'Main',
    },
  },
  {
    path: '/documents',
    name: 'documents',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "general" */ '@/pages/document/DocumentsListPage.vue'),
    meta: {
      layout: 'Main',
    },
  },
  {
    path: '/writings',
    name: 'writings',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "general" */ '@/pages/WritingsPage.vue'),
    meta: {
      layout: 'Main',
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "general" */ '@/pages/404.vue'),
  },
];

const routerInstance = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default routerInstance;
