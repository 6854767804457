import Vue from 'vue';
import VueApollo from 'vue-apollo';
// import pl from 'moment/locale/pl';
import '@/plugins/ckeditor';
import '@/plugins/vuetify-money';
import '@/plugins/vuetify-dialog';
import { createProvider } from '@/plugins/apollo';
import App from '@/App.vue';
import VueMoment from 'vue-moment';
import moment from 'moment';
import router from '@/router';
import vuetify from '@/plugins/vuetify';
import '@/styles/main.sass';

import '@/plugins/filters';
import { DocumentEditorPlugin } from '@syncfusion/ej2-vue-documenteditor';

moment.locale('pl');

Vue.use(DocumentEditorPlugin);
Vue.use(VueApollo);
Vue.use(VueMoment, { moment });
Vue.config.productionTip = false;
// const apolloProvider = new VueApollo({
//   defaultClient: apolloClient,
// })
new Vue({
  router,
  apolloProvider: createProvider(),
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
