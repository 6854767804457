<template>
  <v-card>
    <v-card-subtitle>
      <div class="text-subtitle-1 font-weight-medium">Dłużnik</div>
    </v-card-subtitle>
    <div class="px-4 pb-4">
      <h3>{{ debtor.name }} {{ debtor.surname }}</h3>
      <div class="text-small pt-6 text--secondary">Adres:</div>
      <div class="text-subtitle-1 font-weight-medium">{{ debtor.address.street }}</div>
      <div class="text-subtitle-1 font-weight-medium">{{ debtor.address.postCode }} {{ debtor.address.city }}</div>
      <div class="text-small pt-6 text--secondary">Dane kontaktowe:</div>
      <div class="text-subtitle-1 font-weight-medium">{{ debtor.email }}</div>
      <div class="text-subtitle-1 font-weight-medium">{{ debtor.phone }}</div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
  },
  computed: {
    debtor() {
      return this.proceeding.debtors[0];
    },
  },
};
</script>
