/* eslint-disable guard-for-in,new-cap,import/prefer-default-export */
import { jsPDF } from 'jspdf';
import './roboto-normal';
import 'jspdf-autotable';

import { formatFileName, writeText } from '@/helpers/pdf/WriteTextHelper';
import { ConfirmationsLabelPrinter } from '@/helpers/pdf/ConfirmationsLabelPrinter';

export class ConfirmationsPrinter {
  constructor(envelopeGroups, additionalDescriptions) {
    this.additionalDescriptions = additionalDescriptions;
    this.envelopeGroups = envelopeGroups;
  }

  // @see https://spinbits.atlassian.net/jira/software/projects/NGAP/boards/15/backlog?assignee=5f7456eb837bb80068157c44&label=QUESTION&selectedIssue=NGAP-201

  printConfirmations(groups) {
    const printer = new ConfirmationsLabelPrinter(this.document);
    printer.printConfirmations(groups);
  }

  printAll(outputType) {
    this.document = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'A4',
    });
    this.document.deletePage(1);
    for (const group of this.envelopeGroups.filter((groupItem) => groupItem.type === 'book')) {
      this.printBook(group);
    }

    this.printConfirmations(this.envelopeGroups.filter((groupItem) => groupItem.type === 'separate'));

    if (outputType) {
      return this.outputPdf(outputType);
    }
    return this.savePdf();
  }

  outputPdf(outputType) {
    return this.document.output(outputType);
  }

  savePdf() {
    this.document.save(formatFileName('confirmations'));
  }

  printBook(group) {
    let row = 0;

    function fillMissing(rows) {
      const min = 10;
      if (rows.length < min) {
        for (let i = rows.length; i <= min; i += 1) {
          rows.push([i, '\n\n']);
        }
      }
      return rows;
    }

    function prepareSenderAddress(sender) {
      return (
        `Imię i nazwisko (nazwa) oraz adres nadawcy:\n\n` +
        `${sender.name || ''}\n` +
        `${sender.address.street || ''}\n` +
        `${sender.address.postCode || ''} ${sender.address.city || ''}\n` +
        `${sender.address.country || ''}\n`
      );
    }

    this.document.addPage();
    this.document.setFont('roboto', 'normal');
    writeText(this.document, prepareSenderAddress(group.envelopes[0].sender), 10, 5, 150, 5, 8);
    this.document.autoTable({
      startY: 30,
      theme: 'grid',
      styles: {
        overflow: 'linebreak',
        lineColor: [1],
        lineWidth: 0.2,
        fontSize: 8,
      },
      tableLineColor: 0,
      tableLineWidth: 0.51,
      headStyles: { fillColor: 255, textColor: 0 },
      rowPageBreak: 'auto',
      didParseCell(data) {
        // eslint-disable-next-line no-param-reassign
        data.cell.styles.font = 'roboto';
      },
      head: [
        [
          'Lp',
          'Adresat',
          'Dokładne miejsce doręczenia',
          'Kwota zadekl. wartości',
          'Masa',
          'Nr nadawczy',
          'Uwagi',
          'Opłata',
          'Kwota pobrania',
        ],
      ],
      body: fillMissing(
        group.envelopes.map((envelope) => {
          row += 1;
          return [
            row,
            envelope.recipient.name,
            `${envelope.recipient.address.street || ''}\n ${envelope.recipient.address.postCode || ''} ${
              envelope.recipient.address.city || ''
            }\n${envelope.recipient.address.country || ''}`,
            '',
            '',
            envelope.trackingNumber,
            group.additionalDescription || '-',
            '',
            '',
          ];
        }),
      ),
    });
  }
}
