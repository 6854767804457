<template>
  <div>
    <v-card
      tile
      class="grey lighten-5 d-flex flex-column flex-nowrap"
      tile-
      style="position: absolute; right: 0; top: 78px; bottom: 39px; width: 262px; z-index: 2"
    >
      <v-toolbar flat dense color="grey lighten-5">
        <v-toolbar-title>Postępowanie</v-toolbar-title>
        <v-spacer></v-spacer>
        <div>
          <v-btn x-small depressed fab color="grey lighten-3" @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list style="flex-grow: 9999; max-height: 100%; overflow: auto">
        <template v-for="(replacement, key) in documentReplacements.general">
          <v-list-item :key="key">
            <v-list-item-content class="my-2">
              <div class="text-subtitle-2 line-dense mb-1">
                <strong>{{ generalReplacements[key] }}</strong>
              </div>
              <div class="text-subtitle-2 text--disabled fong-weight-normal line-dense text-word-break">
                {{ replacement }}
              </div>
            </v-list-item-content>
            <v-list-item-action class="align-self-start">
              <v-btn fab depressed x-small dark color="grey" @click="$emit('insert', replacement)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="`${key}-divider`"></v-divider>
        </template>
        <template v-for="(replacement, key) in documentReplacements.other">
          <v-list-item :key="key">
            <v-list-item-content class="my-2">
              <div class="text-subtitle-2 line-dense mb-1">
                <strong>{{ key }}</strong>
              </div>
              <div class="text-subtitle-2 text--disabled fong-weight-normal line-dense text-word-break">
                {{ replacement }}
              </div>
            </v-list-item-content>
            <v-list-item-action class="align-self-start">
              <v-btn fab depressed x-small dark color="grey" @click="$emit('insert', replacement)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="`${key}-divider`"></v-divider>
        </template>
      </v-list>
      <!-- <v-card-actions>
        <v-btn color="primary" @click="$emit('replace')">Wypełnij wszystkie luki</v-btn>
      </v-card-actions> -->
      -->
    </v-card>
  </div>
</template>

<script>
import generalReplacements, { createDocumentReplacements } from '@/helpers/replace/replacements';

export default {
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      generalReplacements,
      documentReplacements: createDocumentReplacements(this.document),
      dialog: false,
    };
  },
};
</script>
