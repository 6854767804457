<template>
  <v-card class="overflow-hidden">
    <v-card-actions class="pa-4">
      <v-row>
        <v-col>
          <v-btn
            class="px-2"
            :to="{ name: 'proceeding-documents', params: { id: proceeding.id } }"
            outlined="outlined"
            rounded="rounded"
            color="secondary"
          >
            <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Anuluj</span>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="px-2"
            depressed="depressed"
            rounded="rounded"
            color="secondary"
            :loading="loading"
            @click="save"
          >
            <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <div class="px-4 pt-12">
      <edit-writing
        class="max-width-md"
        ref="form"
        :proceeding="proceeding"
        :new-document-name="template.name"
        :reply-to="replyTo"
        @input="input"
      ></edit-writing>
    </div>
  </v-card>
</template>

<script>
import { documentRoute } from '@/v2/components/Document/Mixin';
import EditWriting from '@/v2/components/Document/EditWriting.vue';
import ADD_INTERNAL_DOCUMENT from '@/graphql/document/mutation/AddInternalDocumentMutation.gql';
import SET_ENTITY_RELATED_DOCUMENTS from '@/graphql/entity/mutation/SetEntityRelatedDocuments.gql';

export default {
  components: {
    EditWriting,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
    replyTo: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        input: {},
      },
    };
  },
  methods: {
    input(input) {
      Object.assign(this.form.input, input);
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.form.input.add.proceedingEntityIds.length === 0) {
          const document = await this.createDocument(
            this.form.input.edit.cardNumbers.from,
            this.form.input.edit.cardNumbers.to,
          );
          this.$router.push(documentRoute(this.proceeding, document));
        } else {
          let cardNumberFrom = this.form.input.edit.cardNumbers.from;
          let cardNumberTo = this.form.input.edit.cardNumbers.to;
          /* eslint-disable no-await-in-loop */
          for (const entityId of this.form.input.add.proceedingEntityIds) {
            const document = await this.createEntityDocument(cardNumberFrom, cardNumberTo, entityId);
            if (this.form.input.add.proceedingEntityIds.length === 1) {
              this.$router.push(documentRoute(this.proceeding, document.id));
              this.loading = false;
              return;
            }
            if (!Number.isNaN(cardNumberFrom) && !Number.isNaN(cardNumberTo)) {
              const diff = cardNumberTo - cardNumberFrom;
              cardNumberFrom = cardNumberTo + 1;
              cardNumberTo = cardNumberFrom + diff;
            }
          }
          this.$router.push({
            name: 'proceeding-documents',
            params: { id: this.proceeding.id },
          });
        }
        this.loading = false;
      }
    },
    async createEntityDocument(cardNumberFrom, cardNumberTo, entityId) {
      const document = await this.createDocument(cardNumberFrom, cardNumberTo, [entityId]);
      await this.setEntityRelatedDocument(entityId, document);
      return document;
    },
    async createDocument(cardNumberFrom = null, cardNumberTo = null, proceedingEntityIds = []) {
      this.form.input.edit.cardNumbers.from = cardNumberFrom;
      this.form.input.edit.cardNumbers.to = cardNumberTo;
      const {
        data: {
          document: { documents },
        },
      } = await this.$apollo.mutate({
        mutation: ADD_INTERNAL_DOCUMENT,
        variables: {
          input: {
            proceedingId: this.proceeding.id,
            templateId: this.template.id,
            withRelatedTemplates: this.form.input.add.withRelatedTemplates,
            proceedingEntityIds,
            ...this.form.input.edit,
          },
        },
      });
      const document = documents[0];
      this.$emit('document-add', document);
      return document;
    },
    async setEntityRelatedDocument(entityId, document) {
      const entity = this.proceeding.entities.find((e) => e.id === entityId);
      const entityRelatedDocumentIds = entity.relatedDocuments.map((d) => d.id);
      const documentIds = [...entityRelatedDocumentIds, document.id];
      await this.$apollo.mutate({
        mutation: SET_ENTITY_RELATED_DOCUMENTS,
        variables: {
          proceedingId: this.proceeding.id,
          input: {
            proceedingId: this.proceeding.id,
            entityId,
            documentIds,
          },
        },
      });
      this.$emit('entity-update-related-documents', {
        entity,
        relatedDocuments: [...entity.relatedDocuments, document],
      });
    },
  },
};
</script>
