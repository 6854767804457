<template>
  <layout-main :title="template ? template.name : 'Nowy szablon pisma'">
    <v-container fluid="fluid">
      <v-card>
        <v-row>
          <v-col>
            <v-card-actions class="px-8">
              <v-btn outlined="outlined" rounded="rounded" color="secondary" @click="cancel">
                <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Szablony pism</span>
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="auto">
            <v-card-actions class="px-8">
              <v-btn
                class="px-4"
                depressed="depressed"
                rounded="rounded"
                large="large"
                color="secondary"
                :loading="loading"
                @click="submit"
              >
                <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <v-form class="px-8 pt-6" ref="form">
          <v-text-field
            outlined="outlined"
            label="Nazwa szablonu"
            :rules="form.rules.name"
            v-model="form.input.name"
          ></v-text-field>
          <v-combobox
            class="mt-4"
            outlined="outlined"
            multiple="multiple"
            chips="chips"
            small-chips="small-chips"
            deletable-chips="deletable-chips"
            :delimiters="[',', ';']"
            label="Słowa kluczowe"
            hint="Każde nowe słowo kluczowe zatwierdź enterem"
            v-model="form.input.tags"
          ></v-combobox>
        </v-form>
        <hr class="grey lighten-3" />
        <document-editor-base
          ref="editor"
          :initialData="form.input.data"
          @change="form.input.data = $event"
        ></document-editor-base>
      </v-card>
    </v-container>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import DocumentEditorBase from '@/components/Document/Editor/DocumentEditorBase.vue';
import GET_ONE_DOCUMENT_TEMPLATE_QUERY from '@/graphql/documentTemplate/query/GetOneDocumentTemplateQuery.gql';
import CREATE_DOCUMENT_TEMPLATE_MUTATION from '@/graphql/documentTemplate/mutation/CreateInternalDocumentTemplateMutation.gql';
import UPDATE_DOCUMENT_TEMPLATE_MUTATION from '@/graphql/documentTemplate/mutation/UpdateInternalDocumentTemplateMutation.gql';

export default {
  components: {
    LayoutMain,
    DocumentEditorBase,
  },
  props: {
    id: String,
  },
  data() {
    return {
      loading: false,
      form: {
        input: {
          name: '',
          tags: [],
          data: '',
        },
        rules: {
          name: [(v) => !!v || 'Podaj nazwę'],
        },
      },
    };
  },
  apollo: {
    template: {
      query: GET_ONE_DOCUMENT_TEMPLATE_QUERY,
      variables() {
        return { templateId: this.id };
      },
      update(data) {
        const item = data.list.items[0];
        this.form.input.name = item.name || '';
        this.form.input.tags = item.tags || '';
        this.form.input.data = item.data || '';
        if (this.$refs.editor) {
          this.$refs.editor.open(this.form.input.data);
        }
        return item;
      },
      error() {
        this.cancel();
      },
      skip() {
        return !this.id;
      },
    },
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.template) {
          await this.templateSave();
        } else {
          await this.templateCreate();
        }
        this.loading = false;
      }
    },
    async templateCreate() {
      const { data } = await this.$apollo.mutate({
        mutation: CREATE_DOCUMENT_TEMPLATE_MUTATION,
        variables: {
          input: this.form.input,
        },
      });
      this.$router.push({
        name: 'template-edit',
        params: { id: data.template.id },
      });
    },
    async templateSave() {
      await this.$apollo.mutate({
        mutation: UPDATE_DOCUMENT_TEMPLATE_MUTATION,
        variables: {
          input: {
            templateId: this.template.id,
            ...this.form.input,
          },
        },
      });
    },
    cancel() {
      this.$router.push({ name: 'template-list' });
    },
  },
};
</script>
