<template>
  <layout-main :title="fragment ? fragment.name : 'Nowy fragment'">
    <v-container fluid="fluid">
      <v-card>
        <v-row>
          <v-col>
            <v-card-actions class="px-8">
              <v-btn outlined="outlined" rounded="rounded" color="secondary" @click="cancel">
                <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Fragmenty</span>
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="auto">
            <v-card-actions class="px-8">
              <v-btn
                class="px-4"
                depressed="depressed"
                rounded="rounded"
                large="large"
                color="secondary"
                :loading="loading"
                @click="submit"
              >
                <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <v-form class="px-8 pt-6" ref="form">
          <v-text-field
            outlined="outlined"
            label="Nazwa fragmentu"
            :rules="form.rules.name"
            v-model="form.input.name"
          ></v-text-field>
          <v-combobox
            class="mt-4"
            outlined="outlined"
            multiple="multiple"
            chips="chips"
            small-chips="small-chips"
            deletable-chips="deletable-chips"
            :delimiters="[',', ';']"
            label="Słowa kluczowe"
            hint="Każde nowe słowo kluczowe zatwierdź enterem"
            v-model="form.input.tags"
          ></v-combobox>
        </v-form>
        <hr class="grey lighten-3" />
        <document-editor-base
          ref="editor"
          :initialData="form.input.content"
          @change="form.input.content = $event"
        ></document-editor-base>
      </v-card>
    </v-container>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import DocumentEditorBase from '@/components/Document/Editor/DocumentEditorBase.vue';
import GET_QUICK_PART_QUERY from '@/graphql/quickPart/query/GetQuickPart.gql';
import CREATE_FRAGMENT_MUTATION from '@/graphql/quickPart/mutation/AddQuickPartMutation.gql';
import UPDATE_FRAGMENT_MUTATION from '@/graphql/quickPart/mutation/EditQuickPartMutation.gql';

export default {
  components: {
    LayoutMain,
    DocumentEditorBase,
  },
  props: {
    id: String,
  },
  data() {
    return {
      loading: false,
      form: {
        input: {
          name: '',
          tags: [],
          content: '',
        },
        rules: {
          name: [(v) => !!v || 'Podaj nazwę'],
        },
      },
    };
  },
  apollo: {
    fragment: {
      query: GET_QUICK_PART_QUERY,
      variables() {
        return { quickPartId: this.id };
      },
      update(data) {
        const item = data.quickPart.items[0];
        this.form.input.name = item.name || '';
        this.form.input.tags = item.tags || [];
        this.form.input.content = item.content || '';
        if (this.$refs.editor) {
          this.$refs.editor.open(this.form.input.content);
        }
        return item;
      },
      error() {
        this.cancel();
      },
      skip() {
        return !this.id;
      },
    },
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.fragment) {
          await this.fragmentSave();
        } else {
          await this.fragmentCreate();
        }
        this.loading = false;
      }
    },
    async fragmentCreate() {
      const { data } = await this.$apollo.mutate({
        mutation: CREATE_FRAGMENT_MUTATION,
        variables: {
          input: this.form.input,
        },
      });
      this.$router.push({
        name: 'fragment-edit',
        params: { id: data.quickPart.id },
      });
    },
    async fragmentSave() {
      await this.$apollo.mutate({
        mutation: UPDATE_FRAGMENT_MUTATION,
        variables: {
          input: {
            quickPartId: this.fragment.id,
            ...this.form.input,
          },
        },
      });
    },
    cancel() {
      this.$router.push({ name: 'fragment-list' });
    },
  },
};
</script>
