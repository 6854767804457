<template>
  <v-card class="overflow-hidden">
    <v-card-actions class="px-3">
      <v-avatar class="mr-2" size="34" color="error">
        <v-icon class="white--text">mdi-email-check</v-icon>
      </v-avatar>
      <h3 class="grey--text">Doręczenie</h3>
    </v-card-actions>
    <v-divider></v-divider>
    <v-list dense="dense">
      <template v-if="envelope">
        <v-list-item>
          <v-list-item-content>
            <div class="text-body1 text--secondary">Sposób doręczenia</div>
          </v-list-item-content>
          <v-list-item-action class="font-weight-bold">{{ deliveryMethods[deliveryMethod] }}</v-list-item-action>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item class="py-4">
          <v-list-item-content>
            <v-select
              outlined="outlined"
              hide-details="hide-details"
              :loading="loading"
              :menu-props="{ offsetY: true }"
              label="Sposób doręczenia"
              :items="deliveryMethodsSelectItems"
              :value="document.deliveryIntent"
              @change="setDeliveryIntent"
            ></v-select>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <div class="text-body1 text--secondary">Potwierdzenie doręczenia</div>
        </v-list-item-content>
        <v-list-item-action class="my-1">
          <v-btn
            class="px-2"
            v-if="envelope && envelope.deliveryConfirmation"
            text="text"
            icon="icon"
            small-="small-"
            color="secondary"
            @click="downloadPdf"
          >
            <v-icon size="28">mdi-cloud-download-outline</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-form class="py-2" ref="form">
            <date-input
              outlined="outlined"
              label="Data"
              :rules="form.rules.actionDate"
              v-model="form.input.actionDate"
            ></date-input>
            <v-text-field
              v-if="deliveryMethod === 'DELIVER_BY_MAIL'"
              outlined="outlined"
              label="Numer nadania"
              :rules="form.rules.trackingNumber"
              v-model="form.input.trackingNumber"
            ></v-text-field>
            <v-file-input
              v-if="deliveryMethod === 'DELIVER_BY_HAND'"
              outlined="outlined"
              prepend-icon="prepend-icon"
              label="Skan potwierdzenia (PDF)"
              accept="application/pdf,.pdf"
              :value="form.input.deliveryConfirmation"
              @change="form.input.deliveryConfirmation = $event"
            ></v-file-input>
            <v-row no-gutters="no-gutters">
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn
                  depressed="depressed"
                  rounded="rounded"
                  color="secondary"
                  :loading="form.loading"
                  @click="confirmDelivery"
                  >Zapisz</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import DateInput from '@/v2/components/Form/Date.vue';
import DELIVERY_INTENT_MUTATION from '@/graphql/document/mutation/SetDocumentDeliveryIntentMutation.gql';
import CONFIRM_DELIVERY_BY_MAIL from '@/graphql/envelope/mutation/DocumentDeliveredByMailMutation.gql';
import CONFIRM_DELIVERY_BY_HAND from '@/graphql/envelope/mutation/DocumentDeliveredByHandMutation.gql';

export default {
  components: {
    DateInput,
  },
  props: {
    document: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      deliveryMethods: {
        DELIVER_BY_MAIL: 'Pocztą',
        DELIVER_BY_HAND: 'Osobiście',
      },
      deliveryMethodsSelectItems: [
        { value: 'DELIVER_BY_MAIL', text: 'Pocztą' },
        { value: 'DELIVER_BY_HAND', text: 'Osobiście' },
      ],
      form: {
        loading: false,
        input: {
          actionDate: '',
          trackingNumber: '',
          deliveryConfirmation: null,
        },
        rules: {
          trackingNumber: [(v) => !!v || 'Podaj numer'],
          actionDate: [(v) => !!v || 'Podaj datę'],
        },
      },
    };
  },
  computed: {
    envelope() {
      return this.document.envelope;
    },
    deliveryMethod() {
      return this.envelope ? this.envelope.deliveryMethod : this.document.deliveryIntent;
    },
  },
  watch: {
    envelope: {
      deep: true,
      immediate: true,
      handler(envelope) {
        if (envelope) {
          this.form.input.trackingNumber = envelope.trackingNumber || '';
          this.form.input.actionDate = envelope.actionDate || '';
        }
      },
    },
  },
  methods: {
    async setDeliveryIntent(deliveryIntent) {
      this.loading = true;
      const { data } = await this.$apollo.mutate({
        mutation: DELIVERY_INTENT_MUTATION,
        variables: {
          documentId: this.document.id,
          deliveryIntent,
        },
      });
      this.$emit('update', data.setDocumentDeliveryIntent);
      this.loading = false;
    },
    async confirmDelivery() {
      if (this.$refs.form.validate()) {
        this.form.loading = true;
        switch (this.deliveryMethod) {
          case 'DELIVER_BY_MAIL':
            await this.confirmDeliveryByMail();
            break;
          case 'DELIVER_BY_HAND':
            await this.confirmDeliveryByHand();
            break;
          default:
            break;
        }
        this.form.loading = false;
      }
    },
    async confirmDeliveryByMail() {
      const { data } = await this.$apollo.mutate({
        mutation: CONFIRM_DELIVERY_BY_MAIL,
        variables: {
          input: {
            documentId: this.document.id,
            sentAt: this.form.input.actionDate,
            trackingNumber: this.form.input.trackingNumber,
          },
        },
      });
      this.$emit('update', data.document);
    },
    async confirmDeliveryByHand() {
      const { data } = await this.$apollo.mutate({
        mutation: CONFIRM_DELIVERY_BY_HAND,
        variables: {
          input: {
            documentId: this.document.id,
            deliveredAt: this.form.input.actionDate,
            file: this.form.input.deliveryConfirmation,
          },
        },
      });
      this.form.input.deliveryConfirmation = null;
      this.$emit('update', data.document);
    },
    async downloadPdf() {
      const response = await window.fetch(this.envelope.deliveryConfirmation.downloadUrl, { credentials: 'include' });
      const buffer = await response.arrayBuffer();
      const blob = new window.Blob([buffer], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Potwierdzenie.pdf`;
      link.click();
    },
  },
};
</script>
