<template>
  <v-list-item v-on="listeners">
    <v-list-item-content>
      <v-list-item-title class="my-4"
        ><span class="text-h5 font-weight-bold">{{ prefix }} {{ section.number }}</span>
        <v-chip
          class="font-weight-medium ml-1 mb-1"
          v-for="(tag, index) in section.tags"
          :key="index"
          x-small="x-small"
          dark="dark"
          color="grey"
          >{{ tag }}</v-chip
        >
      </v-list-item-title>
      <div class="my-4" v-html="markdown2html(section.rawContent)"></div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import markdown2html from '@ckeditor/ckeditor5-markdown-gfm/src/markdown2html/markdown2html';

export default {
  props: {
    section: { type: Object, required: true },
    prefix: { type: String, default: '' },
    editable: { type: Boolean, default: false },
  },
  computed: {
    listeners() {
      if (this.editable) {
        const vm = this;
        return {
          click() {
            vm.$emit('edit');
          },
        };
      }
      return {};
    },
  },
  methods: {
    markdown2html,
  },
};
</script>
