<template>
  <div>
    <v-btn
      class="mr-2"
      :to="{ name: 'content-list', params: { active: 'ACTS' } }"
      rounded="rounded"
      color="primary"
      :depressed="active === 'ACTS'"
      :outlined="active !== 'ACTS'"
    >
      <v-icon class="hidden-sm-and-down">mdi-format-section</v-icon><span class="mx-2">Ustawy</span>
    </v-btn>
    <v-btn
      class="mr-2"
      :to="{ name: 'content-list', params: { active: 'COMMENTS' } }"
      rounded="rounded"
      color="primary"
      :depressed="active === 'COMMENTS'"
      :outlined="active !== 'COMMENTS'"
    >
      <v-icon class="hidden-sm-and-down">mdi-format-quote-close-outline</v-icon><span class="mx-2">Dodatki</span>
    </v-btn>
    <v-btn
      class="mr-2"
      :to="{ name: 'content-list', params: { active: 'CUSTOM' } }"
      rounded="rounded"
      color="primary"
      :depressed="active === 'CUSTOM'"
      :outlined="active !== 'CUSTOM'"
    >
      <v-icon class="hidden-sm-and-down">mdi-pencil-box-outline</v-icon><span class="mx-2">Własne</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: String,
      required: true,
    },
  },
};
</script>
