<template>
  <div class="relative">
    <div class="absolute z-1" @click.stop.prevent="open"></div>
    <v-text-field
      readonly="readonly"
      validate-on-blur="validate-on-blur"
      v-bind="$attrs"
      :value="value.substr(0, 10)"
    ></v-text-field>
    <v-dialog ref="dialog" persistent="persistent" width="290px" v-model="dialog">
      <v-date-picker v-model="date">
        <v-btn v-if="date" text="text" color="info" @click="clear">Wyczyść</v-btn>
        <v-spacer></v-spacer>
        <v-btn text="text" color="grey" @click="close">Anuluj</v-btn>
        <v-btn text="text" color="primary" @click="input">OK</v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      date: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value.trim() === '') {
          this.date = null;
        } else {
          this.date = moment(new Date(value)).format().substr(0, 10);
        }
      },
    },
  },
  methods: {
    clear() {
      this.date = null;
      this.input();
    },
    input() {
      this.close();
      if (this.date === null) {
        this.$emit('input', '');
      } else {
        this.$emit('input', moment(this.date).format().substr(0, 10));
      }
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
