<template>
  <v-card>
    <v-card-actions class="pa-4">
      <v-row align="center">
        <v-col>
          <v-btn
            class="px-2"
            :to="{ name: 'proceeding-entities' }"
            outlined="outlined"
            rounded="rounded"
            color="secondary"
          >
            <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Anuluj</span>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="px-2"
            v-if="step === 'editor'"
            depressed="depressed"
            rounded="rounded"
            color="secondary"
            :loading="loading"
            @click="save"
          >
            <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <div class="max-width-sm" v-if="step === 'select'">
      <v-form>
        <v-row>
          <v-col cols="12">
            <v-card-subtitle class="px-0 text--secondary">Wybierz istniejący podmiot z listy</v-card-subtitle>
            <v-autocomplete
              outlined="outlined"
              clearable="clearable"
              hide-details="hide-details"
              return-object="return-object"
              label="Lista podmiotów"
              :items="notProceedingEntities"
              item-text="name"
              v-model="entity"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="entity">
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              depressed="depressed"
              large="large"
              rounded="rounded"
              color="secondary"
              :loading="loading"
              @click="assign"
            >
              <v-icon>mdi-check-circle</v-icon><span class="px-2">Dodaj</span>
            </v-btn>
          </v-col>
        </v-row>
        <template v-else>
          <v-row class="mt-12">
            <v-col cols="12">
              <v-card-subtitle class="px-0 text--secondary">Pobierz dane z GUS</v-card-subtitle>
              <v-row align="center">
                <v-col cols="auto">
                  <v-select
                    :style="{ width: '120px' }"
                    outlined="outlined"
                    hide-details="hide-details"
                    :items="gusQueryTypes"
                    v-model="gusQueryType"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined="outlined"
                    hide-details="hide-details"
                    placeholder="Szukana wartość"
                    v-model="gusQueryValue"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    outlined="outlined"
                    x-large="x-large"
                    color="secondary"
                    :disabled="!gusQueryValue"
                    :loading="loading"
                    @click="queryGus"
                  >
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-12">
            <v-col cols="12">
              <v-card-subtitle class="px-0 text--secondary">Dodaj nowy podmiot</v-card-subtitle>
              <v-btn
                class="full-width"
                outlined="outlined"
                rounded-="rounded-"
                x-large="x-large"
                color="secondary"
                @click="step = 'editor'"
                >Utwórz nowy</v-btn
              >
            </v-col>
          </v-row>
        </template>
      </v-form>
    </div>
    <div class="max-width-md" v-else-if="step === 'editor'">
      <edit-entity ref="editor" :entity="entity" @input="input"></edit-entity>
    </div>
    <div class="py-8"></div>
  </v-card>
</template>

<script>
import stringCompare from '@/v2/helpers/stringCompare';
import EditEntity from '@/v2/components/Entity/EditEntity.vue';
import ENTITY_LIST_QUERY from '@/graphql/entity/query/ListEntitiesForFormQuery.gql';
import ASSIGN_ENTITY_TO_PROCEEDING_MUTATION from '@/graphql/proceeding/mutation/AssignEntityToProceedingMutation.gql';
import QUERY_GUS from '@/graphql/common/query/FetchDataFromGusQuery.gql';
import ADD_ENTITY_MUTATION from '@/graphql/proceeding/mutation/AddProceedingEntityMutation.gql';

export default {
  components: {
    EditEntity,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      step: 'select',
      gusQueryTypes: [
        {
          text: 'NIP',
          value: 'nip',
        },
        {
          text: 'KRS',
          value: 'krs',
        },
        {
          text: 'REGON',
          value: 'regon',
        },
      ],
      gusQueryType: 'nip',
      gusQueryValue: '',
      entity: null,
      loading: false,
      form: {},
    };
  },
  apollo: {
    entities: {
      query: ENTITY_LIST_QUERY,
      update(data) {
        return data.list.items;
      },
    },
  },
  computed: {
    notProceedingEntities() {
      const entities = this.entities || [];
      return entities
        .filter((entity) => {
          return this.proceeding.entities.find((e) => e.id === entity.id) === undefined;
        })
        .sort((a, b) => stringCompare(a.name, b.name));
    },
  },
  methods: {
    input(input) {
      Object.assign(this.form, input);
    },
    async queryGus() {
      this.loading = true;
      const input = {};
      input[this.gusQueryType] = this.gusQueryValue;
      const {
        data: { data },
      } = await this.$apollo.query({
        query: QUERY_GUS,
        variables: { input },
      });
      this.loading = false;
      if (data.__typename === 'GusDataType') {
        this.entity = {
          name: data.name,
          personData: {
            peselNumber: data.pesel,
          },
          companyData: {
            nipNumber: data.nip,
            regonNumber: data.regon,
            krsNumber: data.krs,
          },
          address: {
            street: `${data.street} ${data.propertyNumber} ${data.apartmentNumber}`,
            postCode: data.postCode,
            city: data.city,
          },
        };
        this.step = 'editor';
      } else {
        this.$dialog.notify.error('Nie znaleziono w bazie GUS podmiotu o podanym numerze', { timeout: 3000 });
      }
    },
    async assign() {
      this.loading = true;
      const { data } = await this.$apollo.mutate({
        mutation: ASSIGN_ENTITY_TO_PROCEEDING_MUTATION,
        variables: {
          proceedingId: this.proceeding.id,
          entityId: this.entity.id,
        },
      });
      this.loading = false;
      this.$emit('entity-assign', data.proceeding.entities);
      this.$router.push({
        name: 'proceeding-entity-edit',
        params: { entityId: this.entity.id },
      });
    },
    async save() {
      if (this.$refs.editor.validate()) {
        this.loading = true;
        const { data } = await this.$apollo.mutate({
          mutation: ADD_ENTITY_MUTATION,
          variables: {
            input: { ...this.form },
            proceedingId: this.proceeding.id,
          },
        });
        this.entity = data.entity;
        await this.assign();
        this.loading = false;
      }
    },
  },
};
</script>
