<template>
  <v-list-item class="py-2 px-4" :id="item.id" :to="route">
    <v-list-item-avatar class="align-self-start" :color="statusColor">
      <v-icon dark="dark">{{ statusIcon }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content
      class="align-self-start"
      :style="{ paddingTop: '13px', marginLeft: '-12px', paddingLeft: '12px' }"
    >
      <v-list-item-title class="my-1 font-weight-bold">
        <v-icon class="pr-2 info--text" v-if="item.milestone">mdi-circle</v-icon
        ><span class="text-middle">{{ item.name }}</span>
      </v-list-item-title>
      <slot name="subtitle">
        <v-list-item-subtitle>
          <v-row>
            <v-col cols="auto">{{ itemProceeding.signature }}</v-col>
            <v-col class="text-truncate"
              ><span class="mr-8" v-if="debtor">{{ debtor.name }} {{ debtor.surname }}</span
              ><span>{{ itemProceeding.court.name }}</span></v-col
            >
          </v-row>
        </v-list-item-subtitle>
      </slot>
    </v-list-item-content>
    <v-list-item-action class="align-self-start">
      <v-list-item-title class="align-self-end my-1 font-weight-bold text--secondary" v-if="item.finishedAt">{{
        item.finishedAt | date
      }}</v-list-item-title>
      <v-list-item-title
        class="align-self-end my-1 font-weight-bold"
        v-else-if="item.due"
        :class="{ 'error--text': isItemDelayed }"
        >{{ item.due | date }}</v-list-item-title
      >
      <v-list-item-subtitle class="align-self-start" v-if="item.user"
        >{{ item.user.firstName }} {{ item.user.lastName }}</v-list-item-subtitle
      >
      <v-list-item-subtitle class="align-self-start" v-else
        >{{ itemProceeding.advisor.firstName }} {{ itemProceeding.advisor.lastName }}</v-list-item-subtitle
      >
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import Mixin from '@/v2/components/Task/Mixin';

export default {
  mixins: [Mixin],
};
</script>
