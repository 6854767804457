<template>
  <v-card class="overflow-hidden">
    <v-card-actions class="pa-4">
      <v-row align="center">
        <v-col>
          <h2 class="secondary--text">Finanse</h2>
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="px-2"
            :to="{ name: 'proceeding-finance-account-edit' }"
            depressed="depressed"
            rounded="rounded"
            color="secondary"
          >
            <v-icon>mdi-plus-circle</v-icon><span class="px-2">Nowe konto</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <div class="px-4 pb-8 pt-2">
      <v-row>
        <v-spacer></v-spacer>
        <v-col class="text-right" cols="auto">
          <h1 class="success white--text px-4 rounded">{{ total | money }}</h1>
        </v-col>
      </v-row>
    </div>
    <v-list>
      <template v-for="account in accounts">
        <account-list-item class="py-2" :key="account.id" :account="account"></account-list-item>
        <v-divider :key="account.id" class="last-child--hidden"></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import stringCompare from '@/v2/helpers/stringCompare';
import AccountListItem from '@/v2/components/BankAccount/ListItem.vue';

export default {
  components: {
    AccountListItem,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
  },
  computed: {
    accounts() {
      const accounts = [...this.proceeding.accounts];
      accounts.sort((a, b) => stringCompare(a.name, b.name));
      return accounts;
    },
    total() {
      return {
        amount: this.accounts.reduce((total, account) => {
          return total + account.operations.reduce((balance, operation) => balance + operation.sum.amount, 0);
        }, 0),
        currency: {
          code: 'PLN',
        },
      };
    },
  },
};
</script>
