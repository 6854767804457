<template>
  <v-text-field
    dense="dense"
    flat="flat"
    outlined="outlined"
    hide-details="hide-details"
    background-color="grey lighten-4"
    prepend-inner-icon="mdi-magnify"
    placeholder="Szukaj"
    :value="value"
    @input="input"
  ></v-text-field>
</template>

<script>
import { debounce } from 'debounce';

export default {
  props: {
    value: { type: String, default: '' },
    debounce: { type: Number, default: 400 },
  },
  created() {
    this.input = debounce(this.input, this.debounce);
  },
  methods: {
    input(value) {
      this.$emit('input', value || '');
    },
  },
};
</script>
