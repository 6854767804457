<template>
  <v-list-item class="py-2" :to="{ name: 'proceeding-entity-info', params: { entityId: entity.id } }" exact="exact">
    <v-list-item-avatar color="primary">
      <v-icon class="white--text">mdi-bank</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-row align="center">
        <v-col>
          <v-list-item-title class="my-1 font-weight-bold">{{ entity.name }}</v-list-item-title>
          <v-list-item-subtitle
            >{{ entity.address.street }}; {{ entity.address.postCode }} {{ entity.address.city }}</v-list-item-subtitle
          >
        </v-col>
        <v-col v-if="claim" cols="auto">
          <v-alert class="mb-0 font-weight-medium" outlined="outlined" dense="dense" color="success">{{
            claim.sum | money
          }}</v-alert>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
  },
  computed: {
    claim() {
      return this.proceeding.claims.find((x) => x.creditor.id === this.entity.id);
    },
  },
};
</script>
