<template>
  <v-list-item class="py-2 px-4" :to="route">
    <v-list-item-avatar color="primary">
      <v-icon dark="dark">{{ iconName }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-title class="font-weight-bold">{{ item.title }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: {
    active: { type: String, required: true },
    group: { type: Object, required: true },
    item: {
      type: Object,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
  },
  computed: {
    route() {
      return {
        name: 'content-document',
        params: {
          active: this.active,
          groupId: this.group.id,
          id: this.item.id,
        },
      };
    },
  },
};
</script>
