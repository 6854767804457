<template>
  <layout-main title="Wysyłka pism">
    <v-container fluid="fluid">
      <v-card class="pb-4">
        <v-row class="px-5 py-2" align="center">
          <v-col>
            <v-btn class="px-2" outlined="outlined" rounded="rounded" color="secondary" @click="$emit('cancel')">
              <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Wstecz</span>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              depressed="depressed"
              rounded="rounded"
              large="large"
              color="secondary"
              :disabled="selected.size === 0"
              :loading="loading"
              @click="print"
            >
              <v-icon>mdi-printer</v-icon><span class="px-2">Drukuj</span>
            </v-btn>
          </v-col>
        </v-row>
        <div class="mx-4 mt-4 rounded overflow-hidden">
          <v-row class="px-4 pb-4 grey lighten-5" no-gutters="no-gutters">
            <v-col>
              <v-checkbox hide-details="hide-details" label="Drukuj pisma" v-model="settings.documents"></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="px-4 pb-4 grey lighten-4" no-gutters="no-gutters">
            <v-col cols="12" md="3" lg="2">
              <v-checkbox hide-details="hide-details" label="Drukuj koperty" v-model="settings.envelopes"></v-checkbox>
            </v-col>
            <v-col class="pl-8" cols="12" md="9" lg="10">
              <v-row no-gutters="no-gutters">
                <v-col cols="12" md="6" lg="5">
                  <v-radio-group v-model="settings.envelopesSize" row="row" hide-details="hide-details">
                    <v-radio label="C4" value="C4"></v-radio>
                    <v-radio label="C5" value="C5"></v-radio>
                    <v-radio label="C6" value="C6"></v-radio>
                    <v-radio label="DL" value="DL"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="6" lg="7">
                  <v-radio-group v-model="settings.envelopesContent" row="row" hide-details="hide-details">
                    <v-radio label="Nadawca i odbiorca" value="both"></v-radio>
                    <v-radio label="Tylko nadawca" value="sender"></v-radio>
                    <v-radio label="Tylko obiorca" value="recipient"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="px-4 pb-4 grey lighten-5" no-gutters="no-gutters">
            <v-col cols="12" md="3" lg="2">
              <v-checkbox hide-details="hide-details" label="Drukuj etykiety" v-model="settings.labels"></v-checkbox>
            </v-col>
            <v-col class="pl-8" cols="12" md="9" lg="10">
              <v-row no-gutters="no-gutters">
                <v-col cols="12" md="6" lg="5">
                  <v-radio-group v-model="settings.labelsCount" row="row" hide-details="hide-details">
                    <v-radio label="12 na arkusz" :value="12"></v-radio>
                    <v-radio label="15 na arkusz" :value="15"></v-radio>
                    <v-radio label="18 na arkusz" :value="18"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="6" lg="7">
                  <v-radio-group v-model="settings.labelsContent" row="row" hide-details="hide-details">
                    <v-radio label="Nadawca i odbiorca" value="both"></v-radio>
                    <v-radio label="Tylko nadawca" value="sender"></v-radio>
                    <v-radio label="Tylko obiorca" value="recipient"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="px-4 pb-4 grey lighten-4" no-gutters="no-gutters">
            <v-col cols="12" md="3" lg="2">
              <v-checkbox
                hide-details="hide-details"
                label="Drukuj potwierdzenia"
                v-model="settings.confirmations"
              ></v-checkbox>
            </v-col>
            <v-col class="pl-8" cols="12" md="9" lg="10">
              <v-radio-group v-model="settings.confirmationsType" row="row" hide-details="hide-details">
                <v-radio label="Osobne potwierdzenia" value="separate"></v-radio>
                <v-radio label="Książka nadawcza" value="book"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-container>
    <shipment-view-envelope
      class="my-4"
      v-for="envelope in envelopes"
      :key="envelope.id"
      :envelope="envelope"
      :is-selected="selected.has(envelope)"
      @select="select(envelope)"
    ></shipment-view-envelope>
  </layout-main>
</template>

<script>
import PDFMerger from '@/helpers/pdf/PdfMerger';
import { ConfirmationsPrinter } from '@/helpers/pdf/ConfirmationsPrinter';
import { EnvelopePrinter } from '@/helpers/pdf/EnvelopePrinter';
import { LabelPrinter } from '@/helpers/pdf/LabelPrinter';
import { formatFileName } from '@/helpers/pdf/WriteTextHelper';
import LayoutMain from '@/v2/layouts/Main.vue';
import ShipmentViewEnvelope from '@/v2/components/Envelope/ShipmentViewEnvelope.vue';

export default {
  components: {
    LayoutMain,
    ShipmentViewEnvelope,
  },
  props: {
    envelopes: { type: Array, required: true },
  },
  methods: {
    select(envelope) {
      if (this.selected.has(envelope)) {
        this.selected.delete(envelope);
      } else {
        this.selected.add(envelope);
      }
      this.selected = new Set(this.selected);
    },
    async print() {
      this.loading = true;
      try {
        const envelopes = this.envelopes.filter((envelope) => this.selected.has(envelope));
        const merger = new PDFMerger();

        if (this.settings.documents) {
          const files = [];
          for (const envelope of envelopes) {
            files.push(...envelope.documents.map((doc) => doc.file.downloadUrl));
          }
          await Promise.all(files.map(async (file) => merger.add(file)));
        }

        if (this.settings.confirmations) {
          const groups = this.groupEnvelopesBySender(envelopes);
          const printer = new ConfirmationsPrinter(groups, []);
          const file = printer.printAll('arraybuffer');
          await merger.add(file);
        }

        if (this.settings.labels) {
          const printer = new LabelPrinter(envelopes, this.settings.labelsCount, this.settings.labelsContent);
          const file = printer.printLabels('arraybuffer');
          await merger.add(file);
        }

        if (this.settings.envelopes) {
          const printer = new EnvelopePrinter(envelopes, this.settings.envelopesSize, this.settings.envelopesContent);
          const file = printer.printEnvelopes('arraybuffer');
          await merger.add(file);
        }

        await merger.save(formatFileName('wysylkapism'));
      } catch (e) {
        // this.error = `Wystąpił błąd ładowania dokumentu. Proszę spróbować później. \n (${e.message})`;
        console.error(e);
      }
      this.loading = false;
    },
    groupEnvelopesBySender(envelopes) {
      const groupsMap = new Map();
      for (const envelope of envelopes) {
        const senderKey = JSON.stringify(envelope.sender);
        const group = groupsMap.get(senderKey) || {
          type: this.settings.confirmationsType,
          envelopes: [],
          additionalDescription: ' ',
        };
        group.envelopes.push(envelope);
        groupsMap.set(senderKey, group);
      }
      return Array.from(groupsMap.values());
    },
  },
  data() {
    return {
      loading: false,
      settings: {
        documents: true,
        envelopes: true,
        envelopesSize: 'C4',
        envelopesContent: 'both',
        labels: true,
        labelsCount: 12,
        labelsContent: 'both',
        confirmations: true,
        confirmationsType: 'separate',
      },
      selected: new Set(),
    };
  },
};
</script>
