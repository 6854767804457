<template>
  <v-navigation-drawer v-model="drawer" app>
    <v-list dense>
      <v-list-item-group color="light-green">
        <NavigationItem :key="i" :item="item" v-for="(item, i) in items" />
      </v-list-item-group>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <system-info-scope v-slot="{ information: { currentVersion }, frontendInformation }">
          <span class="grey--text small-text"
            >Wersja: {{ currentVersion }} / {{ frontendInformation.buildVersion }}</span
          >
        </system-info-scope>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<style>
.small-text {
  font-size: 0.9rem;
}
</style>
<script>
import CURRENT_USER from '@/graphql/security/query/CurrentUserQuery.gql';
import NavigationItem from '@/layouts/components/NavigationItem.vue';
import SystemInfoScope from '@/components/Common/SystemInfo/SystemInfoScope.vue';

export default {
  components: { SystemInfoScope, NavigationItem },
  props: {
    value: Boolean,
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set() {},
    },
    items() {
      const baseMenu = {
        home: {
          icon: 'mdi-home-outline',
          text: 'Stona główna',
          route: { name: 'home' },
        },
        proceedings: {
          icon: 'mdi-apps',
          text: 'Postępowania',
          route: { name: 'proceedings' },
        },
        tasks: {
          icon: 'mdi-text-box-check-outline',
          text: 'Zadania',
          children: [
            {
              icon: 'mdi-text-box-check-outline',
              text: 'Lista zadań',
              route: { name: 'task-list' },
            },
            {
              icon: 'mdi-calendar',
              text: 'Kalendarz',
              route: { name: 'calendar' },
            },
            {
              icon: 'mdi-calendar',
              text: 'Harmonogramy',
              route: { name: 'schedules' },
            },
          ],
        },

        documents: {
          icon: 'mdi-file-document-multiple-outline',
          text: 'Dokumenty',
          route: { name: 'documents' },
        },

        writings: {
          icon: 'mdi-message-bulleted',
          text: 'Pisma',
          children: [
            {
              icon: 'mdi-message-bulleted',
              text: 'Wszystkie pisma',
              route: { name: 'writings' },
            },
            {
              icon: 'mdi-text-box-search-outline',
              text: 'Szablony pism',
              route: { name: 'document-templates' },
            },
            {
              icon: 'mdi-cube-send',
              text: 'Przygotuj wysyłkę',
              route: { name: 'prepare-shipment' },
            },
          ],
        },
      };

      Object.keys(baseMenu).forEach((key) => {
        if (baseMenu[key].children && baseMenu[key].children.length === 0) {
          delete baseMenu[key];
        }
      });

      return baseMenu;
    },
    currentRoute() {
      return this.$route.name;
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER,
      update: (data) => data.me,
    },
  },
  data() {
    return {
      user: null,
    };
  },
};
</script>
