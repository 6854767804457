import stringCompare from '@/v2/helpers/stringCompare';

function isTemplateMatchingSearch(template, search) {
  const value = search.toLowerCase();
  return template.name.toLowerCase().includes(value) || template.tags.find((tag) => tag.toLowerCase().includes(value));
}

export function filterTemplates(templates, search) {
  const filtered = templates.filter((template) => isTemplateMatchingSearch(template, search));
  return filtered.sort((a, b) => stringCompare(a.name, b.name));
}

export default {};
