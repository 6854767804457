<template>
  <v-card outlined="outlined">
    <div class="px-4 pt-4">
      <v-row align="center">
        <v-col cols="6" lg="4">
          <v-text-field
            hide-details="hide-details"
            label="Numer nadania"
            v-model="form.input.trackingNumber"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn
            class="px-2"
            outlined="outlined"
            rounded="rounded"
            small-="small-"
            color="secondary"
            @click="$emit('remove')"
          >
            <v-icon>mdi-minus-circle</v-icon><span class="px-2">Usuń z wysyłki</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="pa-4">
      <v-row align="stretch">
        <v-col cols="6" lg="4">
          <v-card class="info lighten-5 full-height" flat="flat">
            <edit-envelope-address
              class="px-2 pt-2"
              label="Nadawca"
              v-model="form.input.sender"
            ></edit-envelope-address>
          </v-card>
        </v-col>
        <v-col cols="6" lg="4">
          <v-card class="success lighten-5 full-height" flat="flat">
            <edit-envelope-address
              class="px-2 pt-2"
              label="Odbiorca"
              v-model="form.input.recipient"
            ></edit-envelope-address>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card class="yellow lighten-4 full-height overflow-hidden" flat="flat">
            <v-card-actions class="yellow lighten-4 px-4 pt-3">
              <div class="text--secondary">Pisma</div>
              <v-spacer></v-spacer>
              <select-list-item v-if="leftDocuments.length" :items="leftDocuments" @select="documentAdd">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined="outlined" icon="icon" small="small" color="secondary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </select-list-item>
            </v-card-actions>
            <v-list class="transparent">
              <v-list-item class="hoverable" v-for="document in form.input.documents" :key="document.id">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">{{ document.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ document.proceeding.signature }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu left="left" bottom="bottom" offset-y="offset-y" :nudge-bottom="6" :nudge-right="8">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn outlined="outlined" icon="icon" small="small" color="secondary" v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense="dense">
                      <v-list-item @click="documentRemove(document)">
                        <v-list-item-icon>
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Usuń pismo z koperty</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="useDocumentAdvisor(document)">
                        <v-list-item-icon>
                          <v-icon>mdi-email-send</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Użyj jako adresu nadawcy</v-list-item-title>
                          <v-list-item-subtitle
                            >{{ document.proceeding.advisor.firstName }}
                            {{ document.proceeding.advisor.lastName }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            >{{ document.proceeding.advisor.address.street }};
                            {{ document.proceeding.advisor.address.postCode }}
                            {{ document.proceeding.advisor.address.city }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="useDocumentRecipient(document)">
                        <v-list-item-icon>
                          <v-icon>mdi-email-receive</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Użyj jako adresu odbiorcy</v-list-item-title>
                          <v-list-item-subtitle>{{ document.recipient.name }}</v-list-item-subtitle>
                          <v-list-item-subtitle
                            >{{ document.recipient.address.street }}; {{ document.recipient.address.postCode }}
                            {{ document.recipient.address.city }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import EditEnvelopeAddress from '@/v2/components/Envelope/EditEnvelopeAddress.vue';
import SelectListItem from '@/v2/components/SelectListItem.vue';

export default {
  components: {
    EditEnvelopeAddress,
    SelectListItem,
  },
  props: {
    value: { type: Object, required: true },
    leftDocuments: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      form: {
        input: {
          trackingNumber: '',
          sender: {
            name: '',
            address: {
              street: '',
              postCode: '',
              city: '',
            },
          },
          recipient: {
            name: '',
            address: {
              street: '',
              postCode: '',
              city: '',
            },
          },
          documents: [],
        },
      },
    };
  },
  watch: {
    'form.input': {
      deep: true,
      handler(input) {
        this.$emit('input', input);
      },
    },
    'value': {
      immediate: true,
      deep: true,
      handler(envelope) {
        this.form.input.trackingNumber = envelope.trackingNumber;
        this.form.input.documents = envelope.documents;
        this.form.input.sender.name = envelope.sender.name;
        this.form.input.sender.address.street = envelope.sender.address.street;
        this.form.input.sender.address.postCode = envelope.sender.address.postCode;
        this.form.input.sender.address.city = envelope.sender.address.city;
        this.form.input.recipient.name = envelope.recipient.name;
        this.form.input.recipient.address.street = envelope.recipient.address.street;
        this.form.input.recipient.address.postCode = envelope.recipient.address.postCode;
        this.form.input.recipient.address.city = envelope.recipient.address.city;
      },
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    documentAdd(document) {
      const documents = [...this.form.input.documents, document];
      this.form.input.documents = documents;
      if (!this.form.input.sender.name) {
        this.useDocumentAdvisor(document);
      }
      if (!this.form.input.recipient.name) {
        this.useDocumentRecipient(document);
      }
    },
    documentRemove(document) {
      const index = this.form.input.documents.indexOf(document);
      const documents = [...this.form.input.documents];
      documents.splice(index, 1);
      this.form.input.documents = documents;
    },
    useDocumentAdvisor(document) {
      this.form.input.sender.name = `${document.proceeding.advisor.firstName || ''} ${
        document.proceeding.advisor.lastName || ''
      }`;
      this.form.input.sender.address.street = document.proceeding.advisor.address.street || '';
      this.form.input.sender.address.postCode = document.proceeding.advisor.address.postCode || '';
      this.form.input.sender.address.city = document.proceeding.advisor.address.city || '';
    },
    useDocumentRecipient(document) {
      this.form.input.recipient.name = document.recipient.name || '';
      this.form.input.recipient.address.street = document.recipient.address.street || '';
      this.form.input.recipient.address.postCode = document.recipient.address.postCode || '';
      this.form.input.recipient.address.city = document.recipient.address.city || '';
    },
  },
};
</script>
