// eslint-disable-next-line max-classes-per-file
import { jsPDF } from 'jspdf';
import './roboto-normal';
import { formatFileName, writeText } from '@/helpers/pdf/WriteTextHelper';

class BasePrinter {
  constructor(
    document,
    type,
    envelopes,
    fontSize,
    slotWidth,
    slotHeight,
    maxSlots,
    columnsCount,
    marginTop,
    marginLeft = 0,
  ) {
    this.document = document;
    this.slotWidth = slotWidth;
    this.slotHeight = slotHeight;
    this.maxSlots = maxSlots;
    this.type = type;
    this.cursor = 1;
    this.envelopes = envelopes;
    this.fontSize = fontSize;
    this.beginMap = this.buildMap(columnsCount, marginTop, marginLeft);
  }

  buildMap(columnsCount, marginTop, marginLeft) {
    const data = {};
    for (let cursor = 0; cursor <= this.maxSlots; cursor += 1) {
      const column = (cursor - 1) % columnsCount;
      const row = Math.ceil(cursor / columnsCount);
      data[cursor] = [column * this.slotWidth + marginLeft, (row - 1) * this.slotHeight + marginTop];
    }
    return data;
  }

  hasBeginMap(slot) {
    return typeof this.beginMap[slot] !== 'undefined';
  }

  debugSlot(slot) {
    this.document.setDrawColor(240, 240, 240);
    this.document.setTextColor(240, 240, 240);
    const beginX = this.beginMap[slot][0];
    const beginY = this.beginMap[slot][1];
    this.document.rect(beginX, beginY, this.slotWidth, this.slotHeight);
    this.document.text(beginX + 2, beginY + 10, `Slot: ${slot}`);
  }

  // eslint-disable-next-line class-methods-use-this
  printLabels() {
    // for (let slot = 1; slot <= this.maxSlots; slot += 1) {
    //   this.debugSlot(slot);
    // }

    const data = this.prepareSlots();

    for (const label of data) {
      this.writeLabel(label);
    }
  }

  outputPdf(outputType) {
    return this.document.output(outputType);
  }

  savePdf() {
    this.document.save(formatFileName('labels'));
  }

  prepareSlots() {
    const data = [];
    for (const envelope of this.envelopes) {
      if (this.type === 'recipient' || this.type === 'both') {
        data.push(envelope.recipient);
      }

      if (this.type === 'sender' || this.type === 'both') {
        data.push(envelope.sender);
      }
    }

    return data;
  }

  next() {
    this.cursor += 1;
    if (this.cursor > this.maxSlots) {
      this.document.addPage();
      this.cursor = 1;
    }
  }

  writeLabel(label) {
    this.document.setTextColor(0, 0, 0);
    const beginX = this.beginMap[this.cursor][0];
    const beginY = this.beginMap[this.cursor][1];
    this.document.setFont('roboto', 'normal');

    const text =
      `${label.name || ''}\n` +
      `${label.address.street || ''}\n` +
      `${label.address.postCode || ''} ${label.address.city || ''}\n` +
      `${label.address.country || ''}`;

    if (text.trim().length === 0) {
      return; // dont write -> empty label
    }
    writeText(this.document, text, beginX, beginY, this.slotWidth, 5, this.fontSize);
    this.next();
  }
}

class Printer12PerPage extends BasePrinter {
  constructor(document, type, envelopes) {
    super(document, type, envelopes, 10, 105, 49.5, 12, 2, 0);
  }
}

class Printer15PerPage extends BasePrinter {
  constructor(document, type, envelopes) {
    super(document, type, envelopes, 10, 70, 50.8, 15, 3, 21.5);
  }
}

class Printer18PerPage extends BasePrinter {
  constructor(document, type, envelopes) {
    super(document, type, envelopes, 8, 63.5, 46.6, 18, 3, 8.7, 9.75);
  }
}

// eslint-disable-next-line import/prefer-default-export
export class LabelPrinter {
  constructor(envelopes, perPage, type) {
    this.envelopes = envelopes;
    this.perPage = perPage;
    this.type = type;
  }

  // @see https://spinbits.atlassian.net/jira/software/projects/NGAP/boards/15/backlog?assignee=5f7456eb837bb80068157c44&label=QUESTION&selectedIssue=NGAP-201
  printLabels(outputType) {
    // eslint-disable-next-line new-cap
    const document = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'A4',
    });
    let printer = null;
    switch (this.perPage) {
      default:
      case 12:
        // eslint-disable-next-line new-cap

        printer = new Printer12PerPage(document, this.type, this.envelopes);

        break;
      case 15:
        printer = new Printer15PerPage(document, this.type, this.envelopes);

        break;
      case 18:
        printer = new Printer18PerPage(document, this.type, this.envelopes);

        break;
    }

    printer.printLabels();
    if (outputType) {
      return printer.outputPdf(outputType);
    }
    return printer.savePdf();
  }
}
