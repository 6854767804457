// eslint-disable-next-line max-classes-per-file
import './roboto-normal';
import { writeText } from '@/helpers/pdf/WriteTextHelper';

// eslint-disable-next-line import/prefer-default-export
export class ConfirmationsLabelPrinter {
  constructor(document, fontSize = 10, columnsCount = 2, marginTop = 0, marginLeft = 0) {
    this.document = document;
    this.slotWidth = 105;
    this.slotHeight = 74.25;
    this.maxSlots = 8;
    this.cursor = 1;
    this.fontSize = fontSize;
    this.beginMap = this.buildMap(columnsCount, marginTop, marginLeft);
  }

  buildMap(columnsCount, marginTop, marginLeft) {
    const data = {};
    for (let cursor = 0; cursor <= this.maxSlots; cursor += 1) {
      const column = (cursor - 1) % columnsCount;
      const row = Math.ceil(cursor / columnsCount);
      data[cursor] = [column * this.slotWidth + marginLeft, (row - 1) * this.slotHeight + marginTop];
    }
    return data;
  }

  hasBeginMap(slot) {
    return typeof this.beginMap[slot] !== 'undefined';
  }

  debugSlot(slot) {
    this.document.setDrawColor(10, 240, 240);
    this.document.setTextColor(240, 240, 240);
    const beginX = this.beginMap[slot][0];
    const beginY = this.beginMap[slot][1];
    this.document.rect(beginX, beginY, this.slotWidth, this.slotHeight);
    // this.document.text(beginX + 2, beginY + 10, `Slot: ${slot}`);

    this.document.setDrawColor(240, 240, 240);
    for (let cols = 0; cols < this.document.internal.pageSize.getWidth() / 10; cols += 1) {
      for (let rows = 0; rows < this.document.internal.pageSize.getHeight() / 10; rows += 1) {
        this.document.line(cols * 10, 0, cols * 10, this.document.internal.pageSize.getHeight());
        this.document.line(0, rows * 10, this.document.internal.pageSize.getWidth(), rows * 10);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  printConfirmations(groups) {
    this.document.addPage('A4', 'portrait');
    this.drawCutLines(this.document);
    // for (let slot = 1; slot <= this.maxSlots; slot += 1) {
    //   this.debugSlot(slot);
    // }

    for (const group of groups) {
      for (const label of group.envelopes) {
        this.writeLabel(label);
      }
    }
  }

  next() {
    this.cursor += 1;
    if (this.cursor > this.maxSlots) {
      this.document.addPage('A4', 'portrait');
      this.cursor = 1;
      this.drawCutLines(this.document);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  prepareAddress(person) {
    return (
      `${person.name || ''}\n` +
      `${person.address.street || ''}\n` +
      `${person.address.postCode || ''} ${person.address.city || ''}\n` +
      `${person.address.country || ''}`
    );
  }

  writeLabel(envelope) {
    this.document.setTextColor(0, 0, 0);
    const beginX = this.beginMap[this.cursor][0];
    const beginY = this.beginMap[this.cursor][1];
    this.document.setFont('roboto', 'normal');
    // const label = envelope.recipient;
    const recipient = this.prepareAddress(envelope.recipient);
    const sender = this.prepareAddress(envelope.sender);

    if (recipient.trim().length === 0 && sender.trim().length === 0) {
      return; // dont write -> empty label
    }

    this.drawTemplate(this.document, beginX, beginY);

    writeText(this.document, sender, beginX, beginY + 20, 65, 4, this.fontSize);
    writeText(this.document, recipient, beginX, beginY + 50, 65, 4, this.fontSize);
    this.next();
  }

  drawTemplate(document, beginX, beginY) {
    document.setLineDashPattern([0]);
    this.document.setFontSize(10);
    this.document.text('POTWIERDZENIE NADANIA', beginX + 5, beginY + 5);
    this.document.text('przesyłki poleconej nr', beginX + 5, beginY + 10);
    this.document.setDrawColor(0, 0, 0);
    this.document.line(beginX, beginY + 15, beginX + 68, beginY + 15);
    this.document.line(beginX + 68, beginY + 15, beginX + 68, beginY + this.slotHeight);

    this.document.setFontSize(7);
    const rightColBeginingX = beginX + 70;
    const rightColBeginingY = beginY + 20;
    const lineSpacing = 2;
    this.document.text('Opłata ........ zł ........ gr', rightColBeginingX, rightColBeginingY);
    this.document.text('Masa kg ........ g ........ ', rightColBeginingX, rightColBeginingY + 5 + lineSpacing * 1);
    this.document.text('Gabaryt A          B', rightColBeginingX, rightColBeginingY + 10 + lineSpacing * 2);
    this.document.rect(rightColBeginingX + 11.5, rightColBeginingY + 7 + lineSpacing * 2, 5, 5);
    this.document.rect(rightColBeginingX + 11.5 + 8, rightColBeginingY + 7 + lineSpacing * 2, 5, 5);
    this.document.text('Priorytetowa', rightColBeginingX, rightColBeginingY + 15 + lineSpacing * 3);
    this.document.rect(rightColBeginingX + 15, rightColBeginingY + 12 + lineSpacing * 3, 5, 5);
    this.document.text('Potwierdzenie nadania', rightColBeginingX, rightColBeginingY + 20 + lineSpacing * 4);
    this.document.rect(rightColBeginingX + 26, rightColBeginingY + 17 + lineSpacing * 4, 5, 5);
    this.document.text('Podpis przyjmującego', rightColBeginingX, rightColBeginingY + 50);
    this.document.circle(rightColBeginingX + 15, rightColBeginingY + 38, 8);
    this.document.setFontSize(8);
    this.document.text('NADAWCA:', beginX + 10, beginY + 20);
    this.document.line(beginX + 10, beginY + 21, beginX + 25, beginY + 21);
    this.document.text('ODBIORCA:', beginX + 10, beginY + 50);
    this.document.line(beginX + 10, beginY + 51, beginX + 25, beginY + 51);
  }

  // eslint-disable-next-line class-methods-use-this
  drawCutLines(document) {
    document.setLineDashPattern([2, 1]);
    const verticalLineX = document.internal.pageSize.width / 2;
    document.line(verticalLineX, 0, verticalLineX, document.internal.pageSize.height);
    document.line(0, this.slotHeight, document.internal.pageSize.width, this.slotHeight);
    document.line(0, this.slotHeight * 2, document.internal.pageSize.width, this.slotHeight * 2);
    document.line(0, this.slotHeight * 3, document.internal.pageSize.width, this.slotHeight * 3);
  }
}
