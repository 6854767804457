<template>
  <layout-main title="Nowa wysyłka">
    <v-container fluid="fluid">
      <v-card>
        <v-row class="px-5 py-2" align="center">
          <v-col>
            <h2 class="secondary--text">Wysyłka pism</h2>
          </v-col>
          <v-col v-if="items.toBeSent.length" cols="auto">
            <v-menu left="left" bottom="bottom" offset-y="offset-y" :nudge-bottom="6" :nudge-right="10">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined="outlined" fab="fab" x-small="x-small" color="secondary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list dense="dense">
                <v-list-item @click="merge">
                  <v-list-item-content>
                    <v-list-item-title>Złącz koperty wg danych adresowych</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-plus-box-multiple</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="unmerge">
                  <v-list-item-content>
                    <v-list-item-title>Rozdziel pisma w kopertach</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-minus-box-multiple-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col v-if="items.ignored.length" cols="auto">
            <select-list-item ref="selectDocumentDialog" :items="leftDocuments" @select="addEnvelope($event)">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="px-2" outlined="outlined" rounded="rounded" color="secondary" v-bind="attrs" v-on="on"
                  ><span class="px-2">Kopertuj pisma</span>
                  <v-avatar color="secondary" size="24">
                    <h3 class="white--text">{{ items.ignored.length }}</h3>
                  </v-avatar>
                </v-btn>
              </template>
              <template v-slot:title>
                <div class="px-4"></div>
                <v-btn depressed="depressed" rounded="rounded" color="secondary" @click="moveAllToBeSend"
                  >Wyślij wszystkie</v-btn
                >
              </template>
            </select-list-item>
          </v-col>
          <v-col v-if="items.ignored.length || items.toBeSent.length" cols="auto">
            <v-btn
              depressed="depressed"
              rounded="rounded"
              large="large"
              color="secondary"
              :disabled="!canBeSent"
              @click="send"
            >
              <v-icon>mdi-check-circle</v-icon><span class="px-2">Zatwierdź wysyłkę</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <edit-envelope
        class="my-12"
        v-for="(envelope, index) in items.toBeSent"
        :key="index"
        :left-documents="leftDocuments"
        :value="envelope"
        @input="envelopeInput(envelope, $event)"
        @remove="envelopeRemove(envelope)"
      ></edit-envelope>
    </v-container>
  </layout-main>
</template>

<script>
import stringCompare from '@/v2/helpers/stringCompare';
import LayoutMain from '@/v2/layouts/Main.vue';
import SelectListItem from '@/v2/components/SelectListItem.vue';
import EditEnvelope from '@/v2/components/Envelope/EditEnvelope.vue';
import DOCUMENT_LIST_QUERY from '@/graphql/document/query/DocumentListQueryForShipment.gql';
import MUTATION from '@/graphql/envelope/mutation/PrepareShipment.gql';

export default {
  components: {
    LayoutMain,
    SelectListItem,
    EditEnvelope,
  },
  data() {
    return {
      limit: 500,
      items: {
        toBeSent: [], // note: contains envelopes
        ignored: [], // note: contains documents
      },
    };
  },
  apollo: {
    documentList: {
      query: DOCUMENT_LIST_QUERY,
      variables() {
        return { limit: this.limit };
      },
      update(data) {
        this.items.ignored = [...data.list.items];
        return [...data.list.items];
      },
    },
  },
  computed: {
    leftDocuments() {
      const documents = [...this.items.ignored];
      return documents.sort((a, b) => stringCompare(a.proceeding.signature, b.proceeding.signature));
    },
    canBeSent() {
      if (this.items.toBeSent.length === 0) {
        return false;
      }
      for (const envelope of this.items.toBeSent) {
        if (
          !envelope.recipient.name ||
          !envelope.recipient.address.street ||
          !envelope.recipient.address.postCode ||
          !envelope.recipient.address.city ||
          !envelope.sender.name ||
          !envelope.sender.address.street ||
          !envelope.sender.address.postCode ||
          !envelope.sender.address.city ||
          !envelope.documents.length
        ) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    moveAllToBeSend() {
      const leftDocuments = [...this.items.ignored].reverse();
      for (const document of leftDocuments) {
        this.addEnvelope(document);
      }
    },
    addEnvelope(document) {
      const index = this.items.ignored.indexOf(document);
      this.items.ignored.splice(index, 1);
      const documents = [document];
      this.items.toBeSent.unshift({
        recipient: {
          name: document.recipient.name,
          address: {
            street: document.recipient.address.street,
            postCode: document.recipient.address.postCode,
            city: document.recipient.address.city,
          },
        },
        sender: this.getDefaultSender(documents),
        trackingNumber: null,
        documents,
        errors: [],
      });
    },
    envelopeInput(envelope, input) {
      const envelopeIgnoredDocuments = this.items.ignored.filter((x) => !input.documents.includes(x));
      const envelopeRemovedDocuments = envelope.documents.filter((x) => !input.documents.includes(x));
      this.items.ignored = [...envelopeIgnoredDocuments, ...envelopeRemovedDocuments];
      Object.assign(envelope, input);
    },
    envelopeRemove(envelope) {
      if (envelope.documents.length > 0) {
        this.items.ignored = [...envelope.documents, ...this.items.ignored];
      }
      const index = this.items.toBeSent.indexOf(envelope);
      this.items.toBeSent.splice(index, 1);
    },
    getDefaultSender(documents) {
      if (documents.length === 0) {
        return {
          name: '',
          address: {
            street: '',
            postCode: '',
            city: '',
          },
        };
      }
      const firstDocument = documents[0];
      return {
        name: `${firstDocument.proceeding.advisor.firstName} ${firstDocument.proceeding.advisor.lastName}`,
        address: {
          street: firstDocument.proceeding.advisor.address.street,
          postCode: firstDocument.proceeding.advisor.address.postCode,
          city: firstDocument.proceeding.advisor.address.city,
        },
      };
    },
    unmerge() {
      const data = [];
      for (const envelope of this.items.toBeSent) {
        for (const document of envelope.documents) {
          data.push({
            recipient: envelope.recipient,
            sender: envelope.sender,
            trackingNumber: null,
            documents: [document],
            errors: [],
          });
        }
      }
      this.items.toBeSent = data;
    },
    merge() {
      const mergedEnvelopes = new Map();
      for (const envelope of this.items.toBeSent) {
        const key = JSON.stringify(envelope.sender) + JSON.stringify(envelope.recipient);
        if (mergedEnvelopes.has(key)) {
          const mergedEnvelope = mergedEnvelopes.get(key);
          mergedEnvelope.documents = [...mergedEnvelope.documents, ...envelope.documents];
        } else {
          envelope.trackingNumber = null;
          mergedEnvelopes.set(key, envelope);
        }
      }
      this.items.toBeSent = [...mergedEnvelopes.values()];
    },
    async send() {
      const agree = await this.$dialog.confirm({
        text: 'Uwaga: zatwierdzonej wysyłki nie można już zmienić!',
        title: 'Potwierdź wysyłkę',
      });
      if (agree) {
        this.loading = true;
        const envelopes = [];
        for (const envelope of this.items.toBeSent) {
          envelopes.push({
            trackingNumber: envelope.trackingNumber,
            sender: envelope.sender,
            recipient: envelope.recipient,
            documentIds: envelope.documents.map((doc) => doc.id),
          });
        }
        const {
          data: {
            prepareShipment: { preparedEnvelopes },
          },
        } = await this.$apollo.mutate({
          mutation: MUTATION,
          variables: {
            input: { envelopes },
          },
        });
        this.loading = false;
        this.$emit('create', preparedEnvelopes);
      }
    },
  },
};
</script>
