<template>
  <layout-main :title="schedule ? schedule.name : 'Nowy harmonogram'">
    <v-container fluid="fluid">
      <v-card>
        <v-row>
          <v-col>
            <v-card-actions class="px-8">
              <v-btn outlined="outlined" rounded="rounded" color="secondary" @click="cancel">
                <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Harmonogramy</span>
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="auto">
            <v-card-actions class="px-8">
              <v-btn
                class="px-4"
                depressed="depressed"
                rounded="rounded"
                large="large"
                color="secondary"
                :loading="loading"
                @click="submit"
              >
                <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <v-form class="px-8 pt-6" ref="form">
          <v-text-field
            outlined="outlined"
            label="Nazwa szablonu"
            :rules="form.rules.name"
            v-model="form.input.name"
          ></v-text-field>
        </v-form>
      </v-card>
      <v-card class="mt-8" v-if="schedule && schedule.taskTemplates.length">
        <v-card-title class="px-8">
          <h4 class="secondary--text">Lista zadań</h4>
        </v-card-title>
        <div class="pa-4 pt-0">
          <task-list :schedule="schedule" @task-remove="taskRemove"></task-list>
        </div>
      </v-card>
      <div class="mt-8" v-if="schedule">
        <v-card>
          <div class="pa-4">
            <task-list-item-edit
              class="grey lighten-5 rounded overflow-hidden"
              v-if="addTask"
              :schedule="schedule"
              @add="schedule.taskTemplates.push($event)"
              @cancel="addTask = false"
            ></task-list-item-edit>
            <div class="pa-4 text-right" v-else>
              <v-btn class="px-2" depressed="depressed" rounded="rounded" color="secondary" @click="addTask = true">
                <v-icon>mdi-plus-circle</v-icon><span class="px-2">Nowe zadanie</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>
    </v-container>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import TaskList from '@/v2/components/Schedule/TaskList.vue';
import TaskListItemEdit from '@/v2/components/Schedule/TaskListItemEdit.vue';
import GET_SCHEDULE_TEMPLATE_QUERY from '@/graphql/scheduleTemplate/query/GetScheduleTemplateQuery.gql';
import CREATE_SCHEDULE_TEMPLATE_MUTATION from '@/graphql/scheduleTemplate/mutation/AddScheduleTemplateMutation.gql';
import UPDATE_SCHEDULE_TEMPLATE_MUTATION from '@/graphql/scheduleTemplate/mutation/EditScheduleTemplateMutation.gql';

export default {
  components: {
    LayoutMain,
    TaskList,
    TaskListItemEdit,
  },
  props: {
    id: { type: String },
  },
  data() {
    return {
      addTask: false,
      loading: false,
      form: {
        input: {
          name: '',
        },
        rules: {
          name: [(v) => !!v || 'Podaj nazwę'],
        },
      },
    };
  },
  apollo: {
    schedule: {
      query: GET_SCHEDULE_TEMPLATE_QUERY,
      variables() {
        return { templateId: this.id };
      },
      update(data) {
        const item = data.list.items[0];
        this.form.input.name = item.name || '';
        return item;
      },
      error() {
        this.cancel();
      },
      skip() {
        return !this.id;
      },
    },
  },
  methods: {
    taskRemove(task) {
      const index = this.schedule.taskTemplates.indexOf(task);
      this.schedule.taskTemplates.splice(index, 1);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.schedule) {
          await this.scheduleSave();
        } else {
          await this.scheduleCreate();
        }
        this.loading = false;
      }
    },
    async scheduleCreate() {
      const { data } = await this.$apollo.mutate({
        mutation: CREATE_SCHEDULE_TEMPLATE_MUTATION,
        variables: {
          input: this.form.input,
        },
      });
      this.$router.push({
        name: 'schedule-edit',
        params: { id: data.scheduleTemplate.id },
      });
    },
    async scheduleSave() {
      await this.$apollo.mutate({
        mutation: UPDATE_SCHEDULE_TEMPLATE_MUTATION,
        variables: {
          input: {
            scheduleTemplateId: this.schedule.id,
            ...this.form.input,
          },
        },
      });
    },
    cancel() {
      this.$router.push({ name: 'schedule-list' });
    },
  },
};
</script>
