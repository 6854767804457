<template>
  <v-card>
    <v-card-subtitle>
      <div class="text-subtitle-1 font-weight-medium">Doradca</div>
    </v-card-subtitle>
    <div class="px-4 pb-4">
      <h3>{{ proceeding.advisor.firstName }} {{ proceeding.advisor.lastName }}</h3>
      <div class="text-small pt-6 text--secondary">Adres:</div>
      <div class="text-subtitle-1 font-weight-medium">{{ proceeding.advisor.address.street }}</div>
      <div class="text-subtitle-1 font-weight-medium">
        {{ proceeding.advisor.address.postCode }} {{ proceeding.advisor.address.city }}
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
  },
};
</script>
