export function isTaskDelayed(task) {
  return task.due && new Date(task.due) < new Date();
}

export function taskStatusColor(task) {
  if (task.status === 'FINISHED') return 'grey';
  if (isTaskDelayed(task)) return 'warning';
  return 'primary';
}

export function taskStatusIcon(taskStatus) {
  switch (taskStatus) {
    case 'NEW':
      return 'mdi-bell-outline';
    case 'IN_PROGRESS':
      return 'mdi-progress-check';
    case 'FINISHED':
      return 'mdi-check-all';
    default:
      return 'mdi-exclamation';
  }
}

export function taskStatusName(taskStatus) {
  switch (taskStatus) {
    case 'NEW':
      return 'Nowe';
    case 'IN_PROGRESS':
      return 'W trakcie';
    case 'FINISHED':
      return 'Zakończone';
    default:
      return '';
  }
}

export function taskRoute(proceeding, task) {
  return {
    name: 'proceeding-tasks',
    params: {
      id: proceeding.id,
    },
    hash: `#${task.id}`,
  };
}

export default {
  props: {
    proceeding: {
      type: Object,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isItemDelayed() {
      return isTaskDelayed(this.item);
    },
    statusIcon() {
      return taskStatusIcon(this.item.status);
    },
    statusColor() {
      return taskStatusColor(this.item);
    },
    itemProceeding() {
      return this.proceeding || this.item.schedule.proceeding;
    },
    debtor() {
      return this.itemProceeding.debtors.length ? this.itemProceeding.debtors[0] : null;
    },
    route() {
      return taskRoute(this.itemProceeding, this.item);
    },
  },
};
