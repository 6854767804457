<template>
  <shipment-view v-if="envelopes" :envelopes="envelopes" @cancel="envelopes = null"></shipment-view>
  <shipment-create v-else-if="create" @cancel="create = false" @create="envelopes = $event"></shipment-create>
  <shipment-list v-else @add="create = true" @view="envelopes = $event"></shipment-list>
</template>

<script>
import ShipmentList from '@/v2/components/Envelope/ShipmentList.vue';
import ShipmentCreate from '@/v2/components/Envelope/ShipmentCreate.vue';
import ShipmentView from '@/v2/components/Envelope/ShipmentView.vue';

export default {
  components: {
    ShipmentList,
    ShipmentCreate,
    ShipmentView,
  },
  data() {
    return {
      create: false,
      envelopes: null,
    };
  },
  created() {
    this.create = true;
  },
};
</script>
