<template>
  <div>
    <v-card class="overflow-hidden">
      <div class="pa-4">
        <h2 class="secondary--text">Harmonogram zadań</h2>
      </div>
      <v-expansion-panels flat="flat" accordion="accordion" tile="tile" v-model="activeTaskPanel">
        <template v-for="(item, index) in tasks">
          <edit-task-list-item
            :key="item.id"
            :id="item.id"
            :proceeding="proceeding"
            :tasks="tasks"
            :item="item"
            :is-expanded="index === activeTaskPanel"
            @cancel="activeTaskPanel = undefined"
            @update="$emit('task-update', $event)"
            @update-due="$emit('task-update-due')"
            @update-status="$emit('update-status', $event)"
            @note-update="$emit('note-update', $event)"
          ></edit-task-list-item>
        </template>
      </v-expansion-panels>
    </v-card>
    <v-card class="mt-8 overflow-hidden">
      <edit-task
        v-if="editNewTask"
        :proceeding="proceeding"
        :tasks="tasks"
        @cancel="editNewTask = false"
        @add="$emit('task-add', $event)"
      ></edit-task>
      <v-card-actions class="pa-4" v-else>
        <v-row align="center">
          <v-col></v-col>
          <v-col cols="auto">
            <v-btn class="px-2" depressed="depressed" rounded="rounded" color="secondary" @click="createTask">
              <v-icon>mdi-plus-circle</v-icon><span class="px-2">Nowe zadanie</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import EditTaskListItem from '@/v2/components/Task/EditListItem.vue';
import EditTask from '@/v2/components/Task/EditTask.vue';

export default {
  components: {
    EditTaskListItem,
    EditTask,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTaskPanel: undefined,
      editNewTask: false,
    };
  },
  computed: {
    tasks() {
      const tasks = [...this.proceeding.schedules[0].tasks];
      return tasks.sort((a, b) => new Date(a.finishedAt || a.due || 0) - new Date(b.finishedAt || b.due || 0));
    },
  },
  watch: {
    activeTaskPanel(value) {
      if (value !== undefined) {
        this.editNewTask = false;
      }
    },
  },
  methods: {
    createTask() {
      this.activeTaskPanel = undefined;
      this.editNewTask = true;
    },
  },
};
</script>
