<template>
  <v-card>
    <v-card-actions class="pa-4">
      <v-row align="center">
        <v-col>
          <v-btn
            class="px-2"
            :to="{ name: 'proceeding', params: { id: proceeding.id } }"
            outlined="outlined"
            rounded="rounded"
            color="secondary"
          >
            <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Info</span>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="px-2"
            depressed="depressed"
            rounded="rounded"
            color="secondary"
            :loading="loading"
            @click="save"
          >
            <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <div class="pa-4">
      <v-tabs ref="tabs" grow="grow" hide-slider="hide-slider" :value="step">
        <v-tab
          class="rounded--top overflow-hidden"
          :class="step === 0 ? 'info--text' : 'grey--text text--darken-1'"
          @click="goToStep(0)"
          >Dłużnik</v-tab
        >
        <v-tab
          class="rounded--top overflow-hidden"
          :class="step === 1 ? 'info--text' : 'grey--text text--darken-1'"
          @click="goToStep(1)"
          >Sąd</v-tab
        >
        <v-tab
          class="rounded--top overflow-hidden"
          :class="step === 2 ? 'info--text' : 'grey--text text--darken-1'"
          @click="goToStep(2)"
          >Postępowanie</v-tab
        >
      </v-tabs>
      <hr class="grey lighten-3" />
      <v-tabs-items class="pt-8" :value="step">
        <v-tab-item>
          <edit-debtor class="max-width-md" ref="debtor" :debtor="debtor" @input="input"></edit-debtor>
        </v-tab-item>
        <v-tab-item>
          <edit-court class="max-width-md" ref="court" :court="court" :judge="judge" @input="input"></edit-court>
        </v-tab-item>
        <v-tab-item>
          <edit-case class="max-width-md" ref="case" :proceeding="proceeding" @input="input"></edit-case>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-card>
</template>

<script>
import EditCourt from '@/v2/components/Proceeding/EditCourt.vue';
import EditCase from '@/v2/components/Proceeding/EditCase.vue';
import EditDebtor from '@/v2/components/Proceeding/EditDebtor.vue';
import EDIT_PROCEEDING_MUTATION from '@/graphql/proceeding/mutation/EditProceedingMutation.gql';
import EDIT_PRIVATE_DEBTOR_MUTATION from '@/graphql/debtor/mutation/EditPrivatePersonDebtorMutation.gql';
import EDIT_LEGAL_DEBTOR_MUTATION from '@/graphql/debtor/mutation/EditLegalPersonDebtorMutation.gql';

export default {
  components: {
    EditCourt,
    EditCase,
    EditDebtor,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      step: 0,
      loading: false,
      form: {},
    };
  },
  computed: {
    debtor() {
      return this.proceeding && this.proceeding.debtors && this.proceeding.debtors.length
        ? this.proceeding.debtors[0]
        : null;
    },
    court() {
      return this.proceeding.court;
    },
    judge() {
      return this.proceeding.judge;
    },
  },
  methods: {
    input(input) {
      Object.assign(this.form, input);
    },
    goToStep(step) {
      if (this.stepValidate(this.step)) {
        this.step = step;
      }
    },
    stepValidate(step) {
      switch (step) {
        case 0:
          return this.$refs.debtor.validate();
        case 1:
          return this.$refs.court.validate();
        case 2:
          return this.$refs.case.validate();
        default:
          return undefined;
      }
    },
    async save() {
      if (this.stepValidate(this.step)) {
        this.loading = true;
        await this.updateDebtor();
        const { data } = await this.updateProceeding();
        this.$emit('update', data.editProceeding);
        this.loading = false;
      }
    },
    updateDebtor() {
      if (this.form.isPerson) {
        return this.updateDebtorPerson();
      }
      return this.updateDebtorLegal();
    },
    updateDebtorPerson() {
      return this.$apollo.mutate({
        mutation: EDIT_PRIVATE_DEBTOR_MUTATION,
        variables: {
          input: {
            entityId: this.debtor.id,
            ...this.form.person,
            ...this.form.contact,
            address: this.form.address,
          },
        },
      });
    },
    updateDebtorLegal() {
      return this.$apollo.mutate({
        mutation: EDIT_LEGAL_DEBTOR_MUTATION,
        variables: {
          input: {
            entityId: this.debtor.id,
            ...this.form.legal,
            ...this.form.contact,
            address: this.form.address,
          },
        },
      });
    },
    updateProceeding() {
      return this.$apollo.mutate({
        mutation: EDIT_PROCEEDING_MUTATION,
        variables: {
          input: {
            proceedingId: this.proceeding.id,
            ...this.form.proceeding,
            ...this.form.court,
          },
        },
      });
    },
  },
};
</script>
