<template>
  <div>
    <v-card
      tile
      class="grey lighten-5 d-flex flex-column flex-nowrap"
      tile-
      style="position: absolute; right: 0; top: 78px; bottom: 39px; width: 262px; z-index: 2; overflow: auto"
    >
      <v-toolbar flat dense color="grey lighten-5">
        <v-toolbar-title>Fragmenty</v-toolbar-title>
        <v-spacer></v-spacer>
        <div>
          <v-btn x-small depressed fab color="grey lighten-3" @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-toolbar>
      <search-input v-if="quickParts && quickParts.length > 1" class="pt-2 pb-4 px-4" v-model="search"></search-input>
      <v-divider></v-divider>
      <v-list style="flex-grow: 9999; max-height: 100%; overflow: auto">
        <template v-for="(piece, key) in parts">
          <v-list-item :key="key">
            <v-list-item-content class="my-2">
              <div class="text-subtitle-2 line-dense mb-1">
                <strong>{{ piece.name }}</strong>
              </div>
              <div>
                <v-chip v-for="(tag, index) in piece.tags" :key="index" class="mr-1 mt-1" x-small>{{ tag }}</v-chip>
              </div>
            </v-list-item-content>
            <v-list-item-action class="align-self-start">
              <v-btn fab depressed x-small dark color="grey" @click="$emit('paste', piece.content)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="`${key}-divider`"></v-divider>
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import SearchInput from '@/v2/components/Form/SearchInput.vue';
import QUERY from '@/graphql/quickPart/query/ListQuickPartsQuery.gql';

export default {
  name: 'QuickPartsPluginModal',
  components: {
    SearchInput,
  },
  data() {
    return {
      search: '',
    };
  },
  apollo: {
    quickParts: {
      query: QUERY,
      update: (data) => {
        return data.list.items;
      },
    },
  },
  computed: {
    parts() {
      return (this.quickParts || []).filter((part) => this.isPartMatchingSearch(part, this.search));
    },
  },
  methods: {
    isPartMatchingSearch(part, search) {
      return `${part.name}`.includes(search) || part.tags.find((tag) => tag.includes(search));
    },
  },
};
</script>
