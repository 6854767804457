<template>
  <v-card class="max-width-sm">
    <v-card-title>
      <h3 class="full-width text-center pt-4">Logowanie</h3>
    </v-card-title>
    <v-card-text>
      <AbstractForm
        ref="form"
        class="pt-8"
        :mutation="loginMutation"
        :mutation-variables="(data) => data"
        :mutation-error-mapper="(data) => (data.data.login.errors ? data.data.login.errors : [])"
        :form-specification="formSpecification"
        @updateStore="onDone"
      />
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col class="pb-10 px-10">
          <v-btn class="full-width" x-large depressed color="primary" @click="dologin" :loading="loading"
            >Zaloguj</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import { VTextField } from 'vuetify/lib';
import { emailValidator, isRequiredValidator } from '@/helpers/validators';
import { onLogin } from '@/plugins/apollo'; // eslint-disable-line import/no-cycle
import AbstractForm from '@/components/Form/AbstractForm.vue';
import CURRENT_USER from '@/graphql/security/query/CurrentUserQuery.gql';
import LOGIN_MUTATION from '@/graphql/security/mutation/LoginMutation.gql';

export default {
  components: { AbstractForm },
  data() {
    return {
      loginMutation: LOGIN_MUTATION,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'username' },
                props: { label: 'Adres email' },
                rules: [isRequiredValidator, emailValidator],
              },
              {
                cols: { md: 12 },
                type: VTextField,
                rules: [isRequiredValidator],
                model: { out: 'password' },
                onEnterPressed: () => {
                  this.dologin();
                },
                props: {
                  label: 'Hasło',
                  type: 'password',
                },
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    async onDone({ serverData: { data, errors } }) {
      if (typeof errors !== 'undefined') {
        // TODO handle server error here ? Or in AbstractForm ?
        return;
      }
      if (typeof data.login.errors !== 'undefined') {
        console.info('Not handling form, form validation error occurred');
        // form had some errors, ignore. AbstractForm is handling those errors
        return;
      }
      const apolloClient = this.$apollo.provider.defaultClient;
      // store token in local storage for later use
      await onLogin(apolloClient, data.login.token.value, data.login.refreshToken.value);
      apolloClient.writeQuery({
        query: CURRENT_USER,
        data: {
          user: data.login.user,
        },
      });
      this.$router.push({ name: 'home' });
    },
    async dologin() {
      this.$refs.form.mutate();
    },
  },
  computed: {
    loading() {
      return this.$apollo.loading;
    },
  },
  apollo: {
    user: {
      query: CURRENT_USER,
      update: (data) => data.me,
    },
  },
};
</script>
