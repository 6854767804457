<template>
  <layout-main>
    <template v-slot:title>
      <v-btn class="px-2" outlined="outlined" rounded="rounded" color="secondary" @click="cancel">
        <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Użytkownicy</span>
      </v-btn>
    </template>
    <div class="max-width-sm">
      <v-card class="mb-8">
        <v-form ref="form" v-model="form.valid">
          <v-card-subtitle class="text--secondary"
            ><span v-if="user">Identyfikacja użytkownika</span
            ><span v-else>Dodawanie nowego użytkownika</span></v-card-subtitle
          >
          <v-text-field
            class="mx-4 mt-6"
            outlined="outlined"
            label="Imię"
            :rules="form.rules.firstName"
            v-model="form.input.firstName"
          ></v-text-field>
          <v-text-field
            class="mx-4 mt-6"
            outlined="outlined"
            label="Nazwisko"
            :rules="form.rules.lastName"
            v-model="form.input.lastName"
          ></v-text-field>
          <v-text-field
            class="mx-4 mt-6"
            outlined="outlined"
            label="Adres email"
            :rules="form.rules.email"
            v-model="form.input.email"
          ></v-text-field>
          <template v-if="user">
            <v-card-subtitle class="text--secondary">Adres</v-card-subtitle>
            <div class="mx-4 mt-4">
              <v-row>
                <v-col sm="12">
                  <v-text-field outlined="outlined" label="Ulica" v-model="form.address.street"></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field outlined="outlined" label="Kod pocztowy" v-model="form.address.postCode"></v-text-field>
                </v-col>
                <v-col sm="8">
                  <v-text-field outlined="outlined" label="Miejscowość" v-model="form.address.city"></v-text-field>
                </v-col>
              </v-row>
            </div>
          </template>
          <div class="text-right pa-4">
            <v-btn
              depressed="depressed"
              large="large"
              color="secondary"
              :disabled="!form.valid"
              :loading="loading"
              @click="submit"
              >Zapisz</v-btn
            >
          </div>
        </v-form>
      </v-card>
      <template v-if="user">
        <div class="mt-8"></div>
        <set-role class="mb-8" :user="user"></set-role>
        <v-card class="mb-8">
          <v-card-subtitle>Dostęp do aplikacji</v-card-subtitle>
          <div class="pa-4">
            <v-row>
              <v-col cols="auto">
                <set-active-button :user="user"></set-active-button>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <password-reset-button :user="user"></password-reset-button>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </template>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import SetRole from '@/v2/components/User/Admin/SetRole.vue';
import SetActiveButton from '@/v2/components/User/Admin/SetActiveButton.vue';
import PasswordResetButton from '@/v2/components/User/Admin/PasswordResetButton.vue';
import GET_USER_QUERY from '@/graphql/user/query/GetOneUserQuery.gql';
import ADD_USER from '@/graphql/user/mutation/CreateUserMutation.gql';
import EDIT_USER from '@/graphql/user/mutation/EditUserMutation.gql';

/* https://vuetifyjs.com/en/components/inputs/#rules */
const patternEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  components: {
    LayoutMain,
    SetRole,
    SetActiveButton,
    PasswordResetButton,
  },
  props: {
    id: { type: String },
  },
  data() {
    return {
      loading: false,
      form: {
        valid: false,
        input: {
          firstName: '',
          lastName: '',
          email: '',
        },
        address: {
          street: '',
          postCode: '',
          city: '',
        },
        rules: {
          firstName: [(v) => !!v || 'Podaj imię'],
          lastName: [(v) => !!v || 'Podaj nazwisko'],
          email: [(v) => (!!v && patternEmail.test(v)) || 'Nieprawidłowy email'],
        },
      },
    };
  },
  apollo: {
    user: {
      query: GET_USER_QUERY,
      variables() {
        return { id: this.id };
      },
      update(data) {
        const item = data.list.items[0];
        this.form.input.firstName = item.firstName || '';
        this.form.input.lastName = item.lastName || '';
        this.form.input.email = item.email || '';
        this.form.address.street = item.address.street || '';
        this.form.address.postCode = item.address.postCode || '';
        this.form.address.city = item.address.city || '';
        return item;
      },
      error() {
        this.cancel();
      },
      skip() {
        return !this.id;
      },
    },
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.user) {
          await this.userSave();
        } else {
          await this.userCreate();
        }
        this.loading = false;
      }
    },
    async userCreate() {
      const { data } = await this.$apollo.mutate({
        mutation: ADD_USER,
        variables: {
          input: {
            ...this.form.input,
          },
        },
      });
      this.$router.push({
        name: 'user-edit',
        params: {
          id: data.user.id,
        },
      });
    },
    async userSave() {
      await this.$apollo.mutate({
        mutation: EDIT_USER,
        variables: {
          input: {
            userId: this.user.id,
            ...this.form.input,
            address: this.form.address,
          },
        },
      });
    },
    cancel() {
      this.$router.push({ name: 'users' });
    },
  },
};
</script>
