<template>
  <div style="position: relative">
    <LoaderModal v-if="loadingComputed" label="Zapisywanie" />
    <template v-if="dialogs.replace">
      <DocumentReplacePluginModal
        v-if="document"
        ref="replacePlugin"
        :document="document"
        @close="dialogs.replace = false"
        @insert="insertText"
        @replace="replace"
      />
      <ReplacePluginModal v-else ref="replacePlugin" @close="dialogs.replace = false" @insert="insertText" />
    </template>
    <QuickPartsPluginModal
      v-if="dialogs.pieces"
      :getSelectedContent="getSelectedContent"
      @paste="paste"
      @close="dialogs.pieces = false"
    />
    <ContentPluginModal v-if="dialogs.content" @insert="insertText" @close="dialogs.content = false" />
    <ejs-documenteditorcontainer
      :height="containerHeight"
      ref="container"
      :enablePrint="true"
      :toolbarItems="toolbarItems"
      :toolbarClick="onToolbarClick"
      :enableToolbar="true"
      :enableSpellCheck="false"
      :enableSelection="true"
      :enableLocalPaste="true"
      locale="pl-PL"
      @contentChange="change"
    />
  </div>
</template>

<script>
import { DocumentEditorContainerPlugin, Toolbar } from '@syncfusion/ej2-vue-documenteditor';
import Vue from 'vue';
// eslint-disable-next-line
import { L10n } from '@syncfusion/ej2-base';
import { createDocumentReplacements } from '@/helpers/replace/replacements';
import LoaderModal from '@/components/Common/LoaderModal.vue';
import PL_DATA from '@/assets/e2jTranslation-pl.json';
import QuickPartsPluginModal from '@/components/Document/Editor/Plugin/QuickPartsPluginModal.vue';
import ReplacePluginModal from '@/components/Document/Editor/Plugin/ReplacePluginModal.vue';
import DocumentReplacePluginModal from '@/components/Document/Editor/Plugin/DocumentReplacePluginModal.vue';
import ContentPluginModal from '@/components/Document/Editor/Plugin/ContentPluginModal.vue';

Vue.use(DocumentEditorContainerPlugin);
L10n.load({ 'pl-PL': PL_DATA.pl });

export default {
  components: {
    ContentPluginModal,
    QuickPartsPluginModal,
    ReplacePluginModal,
    DocumentReplacePluginModal,
    LoaderModal,
  },
  props: {
    document: {
      type: Object,
      required: false,
    },
    initialData: {
      type: String,
      required: false,
      default: () => null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    loadingComputed() {
      return this.loading || this.pdfSaveLoading;
    },
    documentReplacements() {
      return createDocumentReplacements(this.document);
    },
  },
  data() {
    const toolbarItems = [
      'Separator',
      {
        prefixIcon: 'quick-part-icon',
        tooltipText: 'Fragmenty',
        text: 'Fragmenty',
        id: 'fast-pieces',
      },
      {
        prefixIcon: 'proceeding-icon',
        tooltipText: 'Dane postępowania',
        text: 'Postępowanie',
        id: 'open-replace',
      },
      {
        prefixIcon: 'lex-icon',
        tooltipText: 'Ustawy',
        text: 'Ustawy',
        id: 'open-content',
      },
      'Separator',
      'Undo',
      'Redo',
      'Separator',
      'Table',
      'Separator',
      'Header',
      'Footer',
      'PageSetup',
      'PageNumber',
      'Break',
    ];

    return {
      pdfSaveLoading: false,
      copied: null,
      dialogs: {
        replace: false,
        pieces: false,
        content: false,
      },
      containerHeight: 300,
      serialized: null,
      toolbarItems,
    };
  },
  filters: {
    pretty(value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
  provide: {
    DocumentEditorContainer: [Toolbar],
  },
  methods: {
    closeAllPluginModals() {
      for (const key in this.dialogs) {
        if (!Object.prototype.hasOwnProperty.call(this.dialogs, key)) {
          continue;
        }
        this.dialogs[key] = false;
      }
    },
    insertText(text) {
      this.$refs.container.ej2Instances.documentEditor.editor.insertText(text);
    },
    open(data) {
      if (data) {
        this.$refs.container.ej2Instances.documentEditor.open(data);
      } else {
        this.$refs.container.ej2Instances.documentEditor.openBlank();
      }
    },
    change() {
      this.$emit('change', this.$refs.container.ej2Instances.documentEditor.serialize());
    },
    save() {
      this.$emit('save', this.$refs.container.ej2Instances.documentEditor.serialize());
    },
    getSelectedContent() {
      const startPosition = this.$refs.container.ej2Instances.documentEditor.editor.selection.start;
      const endPosition = this.$refs.container.ej2Instances.documentEditor.editor.selection.end;
      const documentContent = this.$refs.container.ej2Instances.documentEditor.editor.owner.sfdtExportModule.write(
        startPosition.currentWidget,
        startPosition.offset,
        endPosition.currentWidget,
        endPosition.offset,
        true,
      );
      return documentContent;
    },
    paste(sfdt) {
      this.$refs.container.ej2Instances.documentEditor.editor.paste(sfdt);
      if (this.document) {
        this.replace();
      }
    },
    resize() {
      this.$refs.container.ej2Instances.documentEditor.resize();
    },
    replace() {
      for (const key of Object.keys(this.documentReplacements.general)) {
        const editorSearchModul = this.$refs.container.ej2Instances.documentEditor.searchModule;
        editorSearchModul.findAll(`{{${key}}}`);
        editorSearchModul.searchResults.replaceAll(this.documentReplacements.general[key]);
      }
    },
    onToolbarClick(args) {
      switch (args.item.id) {
        case 'fast-pieces':
          // eslint-disable-next-line
          this.closeAllPluginModals();
          this.dialogs.pieces = !this.dialogs.pieces;
          break;
        // case 'do-replace':
        //   this.replace(replacements(this.document));
        //   break;
        case 'open-replace':
          this.closeAllPluginModals();
          // eslint-disable-next-line
          this.dialogs.replace = !this.dialogs.replace;
          break;
        case 'open-content':
          this.closeAllPluginModals();
          // eslint-disable-next-line
          this.dialogs.content = !this.dialogs.content;
          break;
        case 'save':
          this.save();
          break;
        default:
          break;
      }
    },
  },
  beforeMount() {
    this.containerHeight = window.innerHeight - 150;
  },
  mounted() {
    this.$nextTick(() => {
      const defaultCharacterFormat = {
        bold: false,
        italic: false,
        baselineAlignment: 'Normal',
        underline: 'None',
        fontColor: '#000000',
        fontFamily: 'Arial',
        fontSize: 12,
      };

      window.editor = this.$refs.container.ej2Instances.documentEditor;

      this.$refs.container.ej2Instances.documentEditor.setDefaultCharacterFormat(defaultCharacterFormat);
      this.$refs.container.ej2Instances.documentEditor.setDefaultSectionFormat({
        pageWidth: 595.3,
        pageHeight: 841.9,
      });
      this.open(this.initialData);
      this.replace();
    });
  },
};
</script>
<style>
@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-vue-documenteditor/styles/material.css';

.save-icon::before {
  content: '\e735';
}

.quick-part-icon::before {
  font: normal normal normal 24px/1 'Material Design Icons';
  font-size: 1.5em;
  content: '\F09AA';
}

.proceeding-icon::before {
  font: normal normal normal 24px/1 'Material Design Icons';
  font-size: 1.5em;
  content: '\F029B';
}

.lex-icon::before {
  font: normal normal normal 24px/1 'Material Design Icons';
  font-size: 1.5em;
  content: '\F069F';
}

#de_element1_editor_font_properties_rightDiv2 {
  visibility: hidden;
}

.e-de-ctn {
  border: none;
}

.e-de-ctnr-properties-pane-btn {
  display: none;
}

.e-de-tlbr-wrapper {
  width: 100%;
}

.e-toolbar .e-toolbar-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  border-radius: 12px;
}

.e-de-ctnr-prop-label {
  width: 100% !important;
}
</style>
