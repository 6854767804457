<template>
  <v-form class="px-4 pt-6" ref="form">
    <v-row>
      <v-col cols="12">
        <div class="text-subtitle-1 px-0 text--secondary">Identyfikacja podmiotu</div>
      </v-col>
      <v-col cols="12">
        <v-text-field
          outlined="outlined"
          label="Nazwa / Imię i nazwisko"
          :rules="form.rules.name"
          v-model="form.input.name"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field outlined="outlined" label="NIP" v-model="form.input.companyData.nipNumber"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field outlined="outlined" label="KRS" v-model="form.input.companyData.krsNumber"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field outlined="outlined" label="REGON" v-model="form.input.companyData.regonNumber"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field outlined="outlined" label="PESEL" v-model="form.input.personData.peselNumber"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="text-subtitle-1 px-0 text--secondary">Adres do korespondencji</div>
      </v-col>
      <v-col cols="12">
        <v-text-field outlined="outlined" label="Ulica" v-model="form.input.address.street"></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field outlined="outlined" label="Kod pocztowy" v-model="form.input.address.postCode"></v-text-field>
      </v-col>
      <v-col cols="8">
        <v-text-field outlined="outlined" label="Miejscowość" v-model="form.input.address.city"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="text-subtitle-1 px-0 text--secondary">Dane kontaktowe</div>
      </v-col>
      <v-col cols="6">
        <v-text-field outlined="outlined" label="Email" v-model="form.input.email"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field outlined="outlined" label="Telefon" v-model="form.input.phone"></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="entity && entity.id">
      <v-col cols="12">
        <simple-wysiwyg-field
          placeholder="Dodatkowe informacje"
          v-model="form.input.contactDetails"
        ></simple-wysiwyg-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import SimpleWysiwygField from '@/components/FormFields/SimpleWysiwygField.vue';

export default {
  components: {
    SimpleWysiwygField,
  },
  props: {
    entity: { type: Object },
  },
  data() {
    return {
      form: {
        input: {
          name: '',
          phone: '',
          email: '',
          personData: {
            peselNumber: '',
          },
          companyData: {
            nipNumber: '',
            regonNumber: '',
            krsNumber: '',
          },
          address: {
            street: '',
            postCode: '',
            city: '',
          },
          contactDetails: '',
        },
        rules: {
          name: [(v) => !!v || 'Podaj nazwę podmiotu'],
        },
      },
    };
  },
  created() {
    if (this.entity) {
      this.form.input.name = this.entity.name || '';
      this.form.input.phone = this.entity.phone || '';
      this.form.input.email = this.entity.email || '';
      this.form.input.companyData.nipNumber = this.entity.companyData.nipNumber || '';
      this.form.input.companyData.regonNumber = this.entity.companyData.regonNumber || '';
      this.form.input.companyData.krsNumber = this.entity.companyData.krsNumber || '';
      this.form.input.personData.peselNumber = this.entity.personData.peselNumber || '';
      this.form.input.address.street = this.entity.address.street || '';
      this.form.input.address.postCode = this.entity.address.postCode || '';
      this.form.input.address.city = this.entity.address.city || '';
      this.form.input.contactDetails = this.entity.contactDetails || '';
    }
  },
  watch: {
    'form.input': {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>
