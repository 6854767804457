<template>
  <layout-main title="Wysłka pism">
    <v-container fluid="fluid">
      <v-card>
        <v-row class="px-5 py-2" align="center">
          <v-col>
            <h2 class="secondary--text">Utworzone wysyłki</h2>
          </v-col>
          <v-col cols="auto">
            <v-btn depressed="depressed" rounded="rounded" large="large" color="secondary" @click="$emit('add')">
              <v-icon>mdi-plus-circle</v-icon><span class="px-2">Nowa wysyłka</span>
            </v-btn>
          </v-col>
        </v-row>
        <div class="pa-4">
          <v-list class="rounded overflow-hidden">
            <v-list-item @click="$emit('view', envelopes)">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">Brak numeru nadania</v-list-item-title>
                <v-list-item-subtitle>21-08-2089</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div class="font-weight-bold">Kopert: 8</div>
                <div class="text--secondary">Dokumentów: 23</div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
      </v-card>
    </v-container>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';

export default {
  components: {
    LayoutMain,
  },
  data() {
    return {
      envelopes: [
        {
          id: 'f4092e26-21f8-11ec-8396-0242ac1400050',
          trackingNumber: null,
          actionDate: null,
          delivered: true,
          deliveryMethod: 'DELIVER_BY_MAIL',
          recipient: {
            address: {
              postCode: '12-123',
              city: 'warszawa',
              street: 'Jeżowa 120',
              country: null,
              __typename: 'Address',
            },
            name: 'Jan Kowalski0',
            __typename: 'MailRecipient',
          },
          sender: {
            address: {
              postCode: '80-736',
              city: 'Kłobuck',
              street: 'Grota-Roweckiego Stefana 70/900',
              country: 'Nikaragua',
              __typename: 'Address',
            },
            name: 'Aniela Makowska',
            __typename: 'MailRecipient',
          },
          documents: [
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005070',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/6097/63/74',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/4d9ac196-2ce1-11ec-9699-0242ac140004',
                fileName: 'blob',
                __typename: 'File',
              },
            },
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005029',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/7757/91/41',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/4d9ac196-2ce1-11ec-9699-0242ac140004',
                fileName: 'blob',
                __typename: 'File',
              },
            },
          ],
          __typename: 'Envelope',
        },
        {
          id: 'f4092e26-21f8-11ec-8396-0242ac1400051',
          trackingNumber: null,
          actionDate: null,
          delivered: true,
          deliveryMethod: 'DELIVER_BY_MAIL',
          recipient: {
            address: {
              postCode: '12-123',
              city: 'warszawa',
              street: 'Jeżowa 121',
              country: null,
              __typename: 'Address',
            },
            name: 'Jan Kowalski1',
            __typename: 'MailRecipient',
          },
          sender: {
            address: {
              postCode: '80-736',
              city: 'Kłobuck',
              street: 'Grota-Roweckiego Stefana 71/90',
              country: 'Nikaragua',
              __typename: 'Address',
            },
            name: 'Doradca restrukturyzacyjny Aniela Makowska',
            __typename: 'MailRecipient',
          },
          documents: [
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005114',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/8247/74/64',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/4d9ac196-2ce1-11ec-9699-0242ac140004',
                fileName: 'blob',
                __typename: 'File',
              },
            },
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac14000517',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/4268/80/37',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/4d9ac196-2ce1-11ec-9699-0242ac140004',
                fileName: 'blob',
                __typename: 'File',
              },
            },
          ],
          __typename: 'Envelope',
        },
        {
          id: 'f4092e26-21f8-11ec-8396-0242ac1400052',
          trackingNumber: null,
          actionDate: null,
          delivered: true,
          deliveryMethod: 'DELIVER_BY_MAIL',
          recipient: {
            address: {
              postCode: '12-123',
              city: 'warszawa',
              street: 'Jeżowa 122',
              country: null,
              __typename: 'Address',
            },
            name: 'Jan Kowalski2',
            __typename: 'MailRecipient',
          },
          sender: {
            address: {
              postCode: '80-736',
              city: 'Kłobuck',
              street: 'Grota-Roweckiego Stefana 71/90',
              country: 'Nikaragua',
              __typename: 'Address',
            },
            name: 'Doradca restrukturyzacyjny Aniela Makowska',
            __typename: 'MailRecipient',
          },
          documents: [
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac1400052441',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/1080/71/99',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/4d9ac196-2ce1-11ec-9699-0242ac140004',
                fileName: 'blob',
                __typename: 'File',
              },
            },
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005244',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/2142/69/25',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/4d9ac196-2ce1-11ec-9699-0242ac140004',
                fileName: 'blob',
                __typename: 'File',
              },
            },
          ],
          __typename: 'Envelope',
        },
        {
          id: 'f4092e26-21f8-11ec-8396-0242ac1400053',
          trackingNumber: null,
          actionDate: null,
          delivered: true,
          deliveryMethod: 'DELIVER_BY_MAIL',
          recipient: {
            address: {
              postCode: '12-123',
              city: 'warszawa',
              street: 'Jeżowa 123',
              country: null,
              __typename: 'Address',
            },
            name: 'Jan Kowalski3',
            __typename: 'MailRecipient',
          },
          sender: {
            address: {
              postCode: '80-736',
              city: 'Kłobuck',
              street: 'Grota-Roweckiego Stefana 71/90',
              country: 'Nikaragua',
              __typename: 'Address',
            },
            name: 'Doradca restrukturyzacyjny Aniela Makowska',
            __typename: 'MailRecipient',
          },
          documents: [
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005314',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/4231/88/60',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/4d9ac196-2ce1-11ec-9699-0242ac140004',
                fileName: 'blob',
                __typename: 'File',
              },
            },
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005376',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/1857/51/36',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/4d9ac196-2ce1-11ec-9699-0242ac140004',
                fileName: 'blob',
                __typename: 'File',
              },
            },
          ],
          __typename: 'Envelope',
        },
        {
          id: 'f4092e26-21f8-11ec-8396-0242ac1400054',
          trackingNumber: null,
          actionDate: null,
          delivered: true,
          deliveryMethod: 'DELIVER_BY_MAIL',
          recipient: {
            address: {
              postCode: '12-123',
              city: 'warszawa',
              street: 'Jeżowa 124',
              country: null,
              __typename: 'Address',
            },
            name: 'Jan Kowalski4',
            __typename: 'MailRecipient',
          },
          sender: {
            address: {
              postCode: '80-736',
              city: 'Kłobuck',
              street: 'Grota-Roweckiego Stefana 71/90',
              country: 'Nikaragua',
              __typename: 'Address',
            },
            name: 'Doradca restrukturyzacyjny Aniela Makowska',
            __typename: 'MailRecipient',
          },
          documents: [
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005415',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/1317/59/29',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/4d9ac196-2ce1-11ec-9699-0242ac140004',
                fileName: 'blob',
                __typename: 'File',
              },
            },
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005481',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/6795/12/55',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/dc494c10-21f0-11ec-9dd2-0242ac140005',
                fileName: 'blob',
                __typename: 'File',
              },
            },
          ],
          __typename: 'Envelope',
        },
        {
          id: 'f4092e26-21f8-11ec-8396-0242ac1400055',
          trackingNumber: null,
          actionDate: null,
          delivered: true,
          deliveryMethod: 'DELIVER_BY_MAIL',
          recipient: {
            address: {
              postCode: '12-123',
              city: 'warszawa',
              street: 'Jeżowa 125',
              country: null,
              __typename: 'Address',
            },
            name: 'Jan Kowalski5',
            __typename: 'MailRecipient',
          },
          sender: {
            address: {
              postCode: '80-736',
              city: 'Kłobuck',
              street: 'Grota-Roweckiego Stefana 71/905',
              country: 'Nikaragua',
              __typename: 'Address',
            },
            name: 'Doradca restrukturyzacyjny Aniela Makowska',
            __typename: 'MailRecipient',
          },
          documents: [
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005570',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/3451/27/90',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/dc494c10-21f0-11ec-9dd2-0242ac140005',
                fileName: 'blob',
                __typename: 'File',
              },
            },
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005558',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/3248/44/52',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/dc494c10-21f0-11ec-9dd2-0242ac140005',
                fileName: 'blob',
                __typename: 'File',
              },
            },
          ],
          __typename: 'Envelope',
        },
        {
          id: 'f4092e26-21f8-11ec-8396-0242ac1400056',
          trackingNumber: null,
          actionDate: null,
          delivered: true,
          deliveryMethod: 'DELIVER_BY_MAIL',
          recipient: {
            address: {
              postCode: '12-123',
              city: 'warszawa',
              street: 'Jeżowa 126',
              country: null,
              __typename: 'Address',
            },
            name: 'Jan Kowalski6',
            __typename: 'MailRecipient',
          },
          sender: {
            address: {
              postCode: '80-736',
              city: 'Kłobuck',
              street: 'Grota-Roweckiego Stefana 71/905',
              country: 'Nikaragua',
              __typename: 'Address',
            },
            name: 'Doradca restrukturyzacyjny Aniela Makowska',
            __typename: 'MailRecipient',
          },
          documents: [
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac14000567',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/8215/82/70',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/dc494c10-21f0-11ec-9dd2-0242ac140005',
                fileName: 'blob',
                __typename: 'File',
              },
            },
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005694',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/1610/89/68',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/dc494c10-21f0-11ec-9dd2-0242ac140005',
                fileName: 'blob',
                __typename: 'File',
              },
            },
          ],
          __typename: 'Envelope',
        },
        {
          id: 'f4092e26-21f8-11ec-8396-0242ac1400057',
          trackingNumber: null,
          actionDate: null,
          delivered: true,
          deliveryMethod: 'DELIVER_BY_MAIL',
          recipient: {
            address: {
              postCode: '12-123',
              city: 'warszawa',
              street: 'Jeżowa 127',
              country: null,
              __typename: 'Address',
            },
            name: 'Jan Kowalski7',
            __typename: 'MailRecipient',
          },
          sender: {
            address: {
              postCode: '80-736',
              city: 'Kłobuck',
              street: 'Grota-Roweckiego Stefana 71/907',
              country: 'Nikaragua',
              __typename: 'Address',
            },
            name: 'Doradca restrukturyzacyjny Aniela Makowska',
            __typename: 'MailRecipient',
          },
          documents: [
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005727',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/1153/89/78',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/dc494c10-21f0-11ec-9dd2-0242ac140005',
                fileName: 'blob',
                __typename: 'File',
              },
            },
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005713',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/5823/43/41',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/dc494c10-21f0-11ec-9dd2-0242ac140005',
                fileName: 'blob',
                __typename: 'File',
              },
            },
          ],
          __typename: 'Envelope',
        },
        {
          id: 'f4092e26-21f8-11ec-8396-0242ac1400058',
          trackingNumber: null,
          actionDate: null,
          delivered: true,
          deliveryMethod: 'DELIVER_BY_MAIL',
          recipient: {
            address: {
              postCode: '12-123',
              city: 'warszawa',
              street: 'Jeżowa 128',
              country: null,
              __typename: 'Address',
            },
            name: 'Jan Kowalski8',
            __typename: 'MailRecipient',
          },
          sender: {
            address: {
              postCode: '80-736',
              city: 'Kłobuck',
              street: 'Grota-Roweckiego Stefana 71/908',
              country: 'Nikaragua',
              __typename: 'Address',
            },
            name: 'Doradca restrukturyzacyjny Aniela Makowska',
            __typename: 'MailRecipient',
          },
          documents: [
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005812',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/3377/13/76',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/dc494c10-21f0-11ec-9dd2-0242ac140005',
                fileName: 'blob',
                __typename: 'File',
              },
            },
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005850',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/2376/87/97',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/dc494c10-21f0-11ec-9dd2-0242ac140005',
                fileName: 'blob',
                __typename: 'File',
              },
            },
          ],
          __typename: 'Envelope',
        },
        {
          id: 'f4092e26-21f8-11ec-8396-0242ac1400059',
          trackingNumber: null,
          actionDate: null,
          delivered: true,
          deliveryMethod: 'DELIVER_BY_MAIL',
          recipient: {
            address: {
              postCode: '12-123',
              city: 'warszawa',
              street: 'Jeżowa 129',
              country: null,
              __typename: 'Address',
            },
            name: 'Jan Kowalski9',
            __typename: 'MailRecipient',
          },
          sender: {
            address: {
              postCode: '80-736',
              city: 'Kłobuck',
              street: 'Grota-Roweckiego Stefana 71/909',
              country: 'Nikaragua',
              __typename: 'Address',
            },
            name: 'Doradca restrukturyzacyjny Aniela Makowska',
            __typename: 'MailRecipient',
          },
          documents: [
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac140005942',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/6674/11/71',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/dc494c10-21f0-11ec-9dd2-0242ac140005',
                fileName: 'blob',
                __typename: 'File',
              },
            },
            {
              id: 'ca938d00-2088-11ec-be1a-0242ac14000595',
              name: 'Plan likwidacyjny',
              acl_edit: false,
              acl_edit_content: false,
              __typename: 'InternalDocument',
              status: 'FINISHED_SENT_BY_MAIL',
              proceeding: {
                id: 'ca932388-2088-11ec-aa5e-0242ac140005',
                signature: 'SPIN/5981/33/52',
                debtors: [
                  {
                    id: 'ca932996-2088-11ec-9911-0242ac140005',
                    name: 'Dorota',
                    nameDeclined: 'Dorota',
                    phone: null,
                    surname: 'Przybylska',
                    email: 'blanka91@gmail.com',
                    surnameDeclined: 'Przybylska',
                    address: { city: 'Ustka', postCode: '99-840', street: 'Mazurska 92', __typename: 'Address' },
                    __typename: 'PrivatePersonDebtor',
                  },
                ],
                __typename: 'Proceeding',
              },
              file: {
                id: 'dc494c10-21f0-11ec-9dd2-0242ac140005',
                downloadUrl: 'http://localhost/download/dc494c10-21f0-11ec-9dd2-0242ac140005',
                fileName: 'blob',
                __typename: 'File',
              },
            },
          ],
          __typename: 'Envelope',
        },
      ],
    };
  },
};
</script>
