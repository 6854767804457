<template>
  <v-form class="px-4 pt-4" ref="form">
    <v-row class="mb-4" align="center">
      <v-col>
        <div class="secondary--text font-weight-medium">{{ version ? 'Ustawienia wersji' : 'Nowa wersja' }}</div>
      </v-col>
      <v-col v-if="version" cols="auto">
        <v-btn class="px-2" outlined="outlined" rounded="rounded" color="secondary" @click="$emit('create')">
          <v-icon>mdi-plus-circle</v-icon><span class="px-2">Nowa wersja</span>
        </v-btn>
      </v-col>
      <v-col v-else-if="document.versions.length" cols="auto">
        <v-btn
          class="px-2"
          outlined="outlined"
          rounded="rounded"
          color="secondary"
          :loading="loading"
          @click="$emit('cancel')"
          ><span class="px-2">Anuluj</span></v-btn
        >
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="px-2"
          depressed="depressed"
          rounded="rounded"
          color="secondary"
          :loading="loading"
          @click="submit"
        >
          <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-text-field outlined="outlined" label="Nazwa" :rules="form.rules.title" v-model="form.input.title"></v-text-field>
    <v-row>
      <v-col sm="8" md="9" lg="10">
        <v-text-field
          outlined="outlined"
          label="Pierwsze wystąpienie"
          :rules="form.rules.firstOccurrence"
          v-model="form.input.firstOccurrence"
        ></v-text-field>
      </v-col>
      <v-col sm="4" md="3" lg="2">
        <v-text-field
          outlined="outlined"
          label="Następne"
          :rules="form.rules.nextOccurrences"
          v-model="form.input.nextOccurrences"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import CREATE from '@/graphql/content/mutation/AddContentDocumentVersionMutation.gql';
import UPDATE from '@/graphql/content/mutation/EditContentDocumentVersionMutation.gql';

export default {
  props: {
    document: { type: Object, required: true },
    version: { type: Object },
  },
  data() {
    return {
      loading: false,
      form: {
        input: {
          title: '',
          firstOccurrence: '',
          nextOccurrences: '',
        },
        rules: {
          title: [(v) => !!v || 'Podaj nazwę'],
          firstOccurrence: [(v) => !!v || 'Uzupełnij'],
          nextOccurrences: [(v) => !!v || 'Uzupełnij'],
        },
      },
    };
  },
  watch: {
    version: {
      immediate: true,
      handler() {
        if (this.version) {
          this.form.input.title = this.version.title;
          this.form.input.firstOccurrence = this.version.firstOccurrence;
          this.form.input.nextOccurrences = this.version.nextOccurrences;
        } else {
          this.form.input.title = '';
          this.form.input.firstOccurrence = '';
          this.form.input.nextOccurrences = '';
        }
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      },
    },
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.version) {
          await this.update();
        } else {
          await this.create();
        }
        this.loading = false;
      }
    },
    async create() {
      const { data } = await this.$apollo.mutate({
        mutation: CREATE,
        variables: {
          input: {
            parentDocumentId: this.document.id,
            ...this.form.input,
          },
        },
      });
      this.$emit('add', Object.assign(data.entity, { rows: [] }));
    },
    async update() {
      await this.$apollo.mutate({
        mutation: UPDATE,
        variables: {
          input: {
            entityId: this.version.id,
            ...this.form.input,
            htmlDocumentContent: ' ',
          },
        },
      });
      this.$emit('update', { ...this.form.input });
    },
  },
};
</script>
