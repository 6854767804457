<template>
  <v-navigation-drawer
    app="app"
    permanent="permanent"
    floating="floating"
    :expand-on-hover="$vuetify.breakpoint.mdAndDown"
  >
    <template v-slot:prepend>
      <div class="mt-10 mb-5" style="height: 100px">
        <router-link :to="{ name: 'home' }"><img src="/assets/logo.svg" width="100%" /></router-link>
      </div>
    </template>
    <v-container class="flex-nowrap pa-0" flex-column="flex-column" justify-space-around="justify-space-around">
      <v-list class="align-self-stretch" nav="nav" :dense="$vuetify.breakpoint.mdAndDown">
        <v-list-item-group class="my-16" color="primary">
          <v-list-item v-for="(item, index) in main" :key="index" :to="item.route" :exact="item.routeExact">
            <v-list-item-icon>
              <v-icon class="primary--text">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="primary--text">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group class="my-16" color="secondary">
          <v-list-item v-for="(item, index) in proceeding" :key="index" :to="item.route" :exact="item.routeExact">
            <v-list-item-icon>
              <v-icon class="secondary--text">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="secondary--text">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group class="my-16" color="success">
          <v-list-item v-for="(item, index) in settings" :key="index" :to="item.route" :exact="item.routeExact">
            <v-list-item-icon>
              <v-icon class="success--text">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="success--text">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </v-container>
    <template v-slot:append>
      <div class="pa-2">
        <system-info-scope v-slot:default="{ information: { currentVersion }, frontendInformation }"
          ><span class="grey--text small-text"
            >Wersja {{ currentVersion }} / {{ frontendInformation.buildVersion }}</span
          ></system-info-scope
        >
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import SystemInfoScope from '@/components/Common/SystemInfo/SystemInfoScope.vue';

const menu = {
  main: [
    {
      route: { name: 'home' },
      routeExact: true,
      icon: 'mdi-view-dashboard',
      title: 'Start',
    },
    {
      route: { name: 'proceedings' },
      routeExact: true,
      icon: 'mdi-gavel',
      title: 'Postępowania',
    },
    {
      route: { name: 'shipment' },
      icon: 'mdi-email-outline',
      title: 'Wysyłka pism',
    },
  ],
  settings: [
    {
      route: { name: 'court-list' },
      icon: 'mdi-scale-balance',
      title: 'Sądy',
    },
    {
      route: { name: 'content-list' },
      icon: 'mdi-format-section',
      title: 'Ustawy',
    },
    {
      route: { name: 'template-list' },
      icon: 'mdi-text-box-outline',
      title: 'Szablony',
    },
  ],
  proceeding: [
    {
      route: { name: 'proceeding' },
      icon: 'mdi-view-dashboard-outline',
      title: 'Info',
    },
    {
      route: { name: 'proceeding-tasks' },
      routeExact: true,
      icon: 'mdi-checkbox-marked-circle-outline',
      title: 'Zadania',
    },
    {
      route: { name: 'proceeding-documents' },
      routeExact: true,
      icon: 'mdi-file-document-outline',
      title: 'Dokumenty',
    },
    {
      route: { name: 'proceeding-entities' },
      routeExact: true,
      icon: 'mdi-bank',
      title: 'Podmioty',
    },
    {
      route: { name: 'proceeding-finance' },
      routeExact: true,
      icon: 'mdi-swap-horizontal-bold',
      title: 'Finanse',
    },
  ],
};

export default {
  components: {
    SystemInfoScope,
  },
  data() {
    return {
      main: menu.main,
      settings: menu.settings,
    };
  },
  computed: {
    proceeding() {
      if (this.$route.name.startsWith('proceeding') && this.$route.params.id) {
        for (const item of menu.proceeding) {
          item.route.params = { id: this.$route.params.id };
        }
        return menu.proceeding;
      }
      return null;
    },
  },
};
</script>
