<template>
  <layout-main title="Sądy">
    <v-container fluid="fluid">
      <v-card>
        <v-row align="center">
          <v-col>
            <v-card-actions class="px-8">
              <v-responsive max-width="260">
                <search-input v-model="search"></search-input>
              </v-responsive>
            </v-card-actions>
          </v-col>
          <v-col cols="auto">
            <v-card-actions class="px-8">
              <v-btn class="px-4" depressed="depressed" rounded="rounded" large="large" color="secondary" @click="add">
                <v-icon>mdi-plus-circle</v-icon><span class="px-2">Dodaj sąd</span>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <infinite-loading class="pa-4" ref="infinite" :load="load">
          <template v-slot:default="{ list }">
            <v-list class="rounded overflow-hidden">
              <template v-for="item in list.items">
                <court-list-item :key="item.id" :item="item"></court-list-item>
                <v-divider :key="item.id" class="last-child--hidden"></v-divider>
              </template>
            </v-list>
          </template>
        </infinite-loading>
      </v-card>
    </v-container>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import SearchInput from '@/v2/components/Form/SearchInput.vue';
import InfiniteLoading from '@/v2/components/InfiniteLoading.vue';
import CourtListItem from '@/v2/components/Court/ListItem.vue';
import COURTS_LIST_QUERY from '@/graphql/court/query/ListCourtsQuery.gql';

export default {
  components: {
    LayoutMain,
    SearchInput,
    InfiniteLoading,
    CourtListItem,
  },
  data() {
    return {
      search: '',
    };
  },
  watch: {
    search() {
      this.$refs.infinite.reset();
    },
  },
  methods: {
    async load(offset) {
      const { data } = await this.$apollo.query({
        query: COURTS_LIST_QUERY,
        variables: {
          query: {
            filters: [{ field: 'search', type: 'EQ', value: this.search }],
            orders: [{ field: 'name', direction: 'ASC' }],
          },
          offset,
          limit: 20,
        },
        fetchPolicy: 'network-only',
      });
      return data.list;
    },
    add() {
      this.$router.push({ name: 'court-edit' });
    },
  },
};
</script>
