// eslint-disable-next-line import/prefer-default-export
import moment from 'moment';

export function writeText(document, text, x, y, maxLineWidth, margin, fontSize = 24, debug = false) {
  const textLines = document.setFontSize(fontSize).splitTextToSize(text, maxLineWidth - margin * 2);
  document.text(textLines, x + margin, y + fontSize / 2);
  if (debug) {
    document.rect(x, y, x + maxLineWidth, y + 30);
  }
}

export function formatFileName(suffix) {
  const date = moment().format('YYYY-MM-DD_HHmmss');
  return `${date}_${suffix}`;
}
