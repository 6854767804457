<template>
  <v-form ref="form">
    <div class="px-4 pt-4">
      <v-row class="mb-8" align="center" no-gutters="no-gutters">
        <v-col v-if="item">
          <v-menu right="right" bottom="bottom" offset-y="offset-y" :nudge-bottom="6">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="px-2"
                depressed="depressed"
                rounded="rounded"
                color="secondary"
                :loading="loading"
                v-bind="attrs"
                v-on="on"
                ><span class="mx-2">{{ taskStatusName(item.status) }}</span>
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense="dense">
              <v-list-item @click="setStatus('NEW')">
                <v-list-item-icon>
                  <v-icon>{{ taskStatusIcon('NEW') }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ taskStatusName('NEW') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="setStatus('IN_PROGRESS')">
                <v-list-item-icon>
                  <v-icon>{{ taskStatusIcon('IN_PROGRESS') }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ taskStatusName('IN_PROGRESS') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="setStatus('FINISHED')">
                <v-list-item-icon>
                  <v-icon>{{ taskStatusIcon('FINISHED') }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ taskStatusName('FINISHED') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col v-else>
          <h2 class="secondary--text">Nowe zadanie</h2>
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="ml-4"
            v-if="!loading"
            outlined="outlined"
            rounded="rounded"
            color="secondary"
            @click="$emit('cancel')"
            >Anuluj</v-btn
          >
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="ml-4 px-2"
            depressed="depressed"
            rounded="rounded"
            color="secondary"
            :loading="loading"
            @click="save"
          >
            <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-text-field
        outlined="outlined"
        label="Nazwa zadania"
        :rules="form.rules.task.name"
        v-model="form.input.task.name"
      ></v-text-field>
      <v-textarea
        outlined="outlined"
        auto-grow="auto-grow"
        no-resize="no-resize"
        rows="1"
        label="Dodatkowy opis"
        v-model="form.input.task.description"
      ></v-textarea>
      <v-row align="center">
        <v-col cols="4" md="3" lg="2">
          <date-input outlined="outlined" label="Termin" v-model="form.input.due"></date-input>
        </v-col>
        <v-col>
          <v-autocomplete
            outlined="outlined"
            clearable="clearable"
            :items="users"
            item-value="id"
            label="Osoba odpowiedzialna"
            placeholder="Doradca"
            :filter="userFilter"
            v-model="form.input.task.userId"
          >
            <template v-slot:selection="{ item }"
              ><span class="font-weight-medium">{{ item.firstName }}</span
              ><span class="font-weight-bold ml-2">{{ item.lastName }}</span></template
            >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title
                  ><span class="font-weight-medium">{{ item.firstName }}</span
                  ><span class="font-weight-bold ml-2">{{ item.lastName }}</span></v-list-item-title
                >
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col class="pb-11" cols="auto">
          <select-list-item :items="documents || []" @select="documentAdd">
            <template v-slot:activator="{ attrs, on }">
              <v-btn class="px-2" outlined="outlined" x-large="x-large" color="grey" v-on="on"
                ><span class="mx-2 grey--text text--darken-1">Dokumenty</span>
                <v-icon class="grey--text text--darken-1" large="large">mdi-plus-circle</v-icon>
              </v-btn>
            </template>
          </select-list-item>
        </v-col>
      </v-row>
    </div>
    <v-list class="mt-2 grey lighten-4" v-if="form.input.relatedDocuments.length">
      <template v-for="document in form.input.relatedDocuments">
        <v-divider :key="document.id"></v-divider>
        <v-list-item :key="document.id">
          <v-list-item-avatar>
            <v-icon>mdi-text-box-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ document.name }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="pr-2">
            <v-btn outlined="outlined" small="small" icon="icon" color="grey" @click="documentRemove(document)">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </v-form>
</template>

<script>
import { taskStatusIcon, taskStatusName } from '@/v2/components/Task/Mixin';
import stringCompare from '@/v2/helpers/stringCompare';
import DateInput from '@/v2/components/Form/Date.vue';
import SelectListItem from '@/v2/components/SelectListItem.vue';
import LIST_USERS from '@/graphql/user/query/ListUsersForFormQuery.gql';
import ADD_NEW_TASK from '@/graphql/task/mutation/AddTaskMutation.gql';
import CHANGE_DUE_DATE_MUTATION from '@/graphql/task/mutation/ChangeTaskDueMutation.gql';
import EDIT_TASK_MUTATION from '@/graphql/task/mutation/EditTaskMutation.gql';
import SET_RELATED_TASKS_MUTATION from '@/graphql/task/mutation/SetTaskRelatedDocumentsMutation.gql';
import CHANGE_TASK_STATUS_MUTATION from '@/graphql/task/mutation/ChangeTaskStatusMutation.gql';

export default {
  components: {
    DateInput,
    SelectListItem,
  },
  props: {
    proceeding: { type: Object, required: true },
    tasks: {
      type: Array,
      default() {
        return [];
      },
    },
    item: { type: Object },
  },
  data() {
    return {
      loading: false,
      form: {
        input: {
          task: {
            name: '',
            description: '',
            // milestone: false,
            userId: null,
          },
          due: '',
          relatedDocuments: [],
        },
        rules: {
          task: {
            name: [(v) => !!v || 'Podaj nazwę'],
          },
        },
      },
    };
  },
  apollo: {
    users: {
      query: LIST_USERS,
      update(data) {
        const users = data.listUsers.items;
        return users.sort((a, b) => stringCompare(`${a.lastName} ${a.firstName}`, `${b.lastName} ${b.firstName}`));
      },
    },
  },
  computed: {
    documents() {
      return this.proceeding.documents;
    },
  },
  created() {
    if (this.item) {
      this.form.input.task.name = this.item.name || '';
      this.form.input.task.description = this.item.description || '';
      // this.form.input.task.milestone = this.item.milestone || false;
      this.form.input.task.userId = this.item.user ? this.item.user.id : null;
      this.form.input.due = this.item.due || '';
      this.form.input.relatedDocuments = [...this.item.relatedDocuments];
    }
  },
  methods: {
    documentAdd(document) {
      const index = this.form.input.relatedDocuments.indexOf(document);
      if (index === -1) {
        this.form.input.relatedDocuments.push(document);
      }
    },
    documentRemove(document) {
      const index = this.form.input.relatedDocuments.indexOf(document);
      this.form.input.relatedDocuments.splice(index, 1);
    },
    userFilter(item, queryText) {
      const itemText = `${item.firstName} ${item.lastName}`.toLowerCase();
      const searchText = queryText.toLowerCase();
      return itemText.indexOf(searchText) > -1;
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.item) {
          const task = await this.updateTask();
          const taskWithDocuments = await this.setTaskRelatedDocuments(task);
          this.$emit('update', taskWithDocuments);
          if (new Date(this.form.input.due || 0).getTime() !== new Date(this.item.due || 0).getTime()) {
            this.updateTaskDue(task);
            this.$emit('update-due');
          }
        } else {
          const task = await this.createTask();
          const taskWithDocuments = await this.setTaskRelatedDocuments(task);
          this.$emit('add', taskWithDocuments);
        }
        this.loading = false;
        this.$emit('cancel');
      }
    },
    async createTask() {
      const { data } = await this.$apollo.mutate({
        mutation: ADD_NEW_TASK,
        variables: {
          input: {
            proceedingId: this.proceeding.id,
            due: this.form.input.due,
            ...this.form.input.task,
          },
        },
      });
      return data.task;
    },
    async updateTask() {
      const { data } = await this.$apollo.mutate({
        mutation: EDIT_TASK_MUTATION,
        variables: {
          input: {
            taskId: this.item.id,
            ...this.form.input.task,
          },
        },
      });
      return data.editTask;
    },
    async updateTaskDue() {
      const { data } = await this.$apollo.mutate({
        mutation: CHANGE_DUE_DATE_MUTATION,
        variables: {
          taskId: this.item.id,
          date: this.form.input.due,
          fullDay: this.item.fullDay || false,
        },
      });
      return data.task;
    },
    async setTaskRelatedDocuments(task) {
      const { data } = await this.$apollo.mutate({
        mutation: SET_RELATED_TASKS_MUTATION,
        variables: {
          input: {
            taskId: task.id,
            documentIds: this.form.input.relatedDocuments.map((x) => x.id),
          },
        },
      });
      return data.task;
    },
    taskStatusIcon,
    taskStatusName,
    async setStatus(status) {
      this.loading = true;
      const { data } = await this.$apollo.mutate({
        mutation: CHANGE_TASK_STATUS_MUTATION,
        variables: {
          taskId: this.item.id,
          newStatus: status,
        },
      });
      this.$emit('update-status', data.task);
      this.loading = false;
    },
  },
};
</script>
