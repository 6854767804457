<template>
  <v-list-item :to="route" exact="exact" v-on="$listeners">
    <v-list-item-avatar :color="iconColor">
      <v-icon class="white--text">{{ iconName }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="font-weight-medium">{{ document.name }}</v-list-item-title>
      <slot name="subtitle">
        <v-list-item-subtitle
          ><small v-if="document.recipient">{{ document.recipient.name }}</small
          ><small v-if="document.recipientSender">{{ document.recipientSender.name }}</small></v-list-item-subtitle
        >
      </slot>
    </v-list-item-content>
    <v-list-item-action class="text-right"
      ><strong>{{ document.receivedAt | date }}</strong>
      <v-list-item-subtitle v-if="document.status"
        ><small>{{ statusName }}</small></v-list-item-subtitle
      >
    </v-list-item-action>
    <slot name="action"></slot>
  </v-list-item>
</template>

<script>
import { documentRoute, documentColor, documentIcon, documentStatusName } from '@/v2/components/Document/Mixin';

export default {
  props: {
    document: { type: Object, required: true },
    onClick: { type: Function },
  },
  computed: {
    route() {
      if (this.$listeners.click) {
        return '';
      }
      return documentRoute(this.document.proceeding, this.document);
    },
    iconColor() {
      return documentColor(this.document);
    },
    iconName() {
      return documentIcon(this.document);
    },
    statusName() {
      return documentStatusName(this.document.status);
    },
  },
};
</script>
