<template>
  <v-list-item class="grey lighten-5">
    <v-list-item-content>
      <v-row no-gutters="no-gutters">
        <v-col>
          <v-list-item-title class="my-4"
            ><span class="text-h5 font-weight-bold"
              >{{ prefix }} {{ section ? section.number : '' }}</span
            ></v-list-item-title
          >
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="ml-4 mt-2"
            v-if="!loading"
            outlined="outlined"
            rounded="rounded"
            color="secondary"
            @click="$emit('cancel')"
            >Anuluj</v-btn
          >
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="ml-4 mt-2 px-2"
            depressed="depressed"
            rounded="rounded"
            color="secondary"
            :loading="loading"
            @click="submit"
          >
            <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-form class="pt-4" ref="form">
        <v-row>
          <v-col cols="2">
            <v-text-field
              outlined="outlined"
              label="Numer"
              :rules="form.rules.number"
              v-model="form.input.number"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-combobox
              outlined="outlined"
              multiple="multiple"
              chips="chips"
              small-chips="small-chips"
              deletable-chips="deletable-chips"
              :delimiters="[',', ';']"
              hint="Każde nowe słowo kluczowe zatwierdź enterem"
              label="Słowa kluczowe"
              v-model="form.input.tags"
            ></v-combobox>
          </v-col>
        </v-row>
        <simple-wysiwyg-field placeholder="Treść" v-model="form.input.rawContent"></simple-wysiwyg-field>
      </v-form>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import SimpleWysiwygField from '@/components/FormFields/SimpleWysiwygField.vue';
import CREATE from '@/graphql/content/mutation/AddContentDocumentRowMutation.gql';
import UPDATE from '@/graphql/content/mutation/EditContentDocumentRowMutation.gql';

export default {
  components: {
    SimpleWysiwygField,
  },
  props: {
    version: { type: Object, required: true },
    section: { type: Object },
    prefix: { type: String, default: '' },
  },
  data() {
    return {
      loading: false,
      form: {
        input: {
          number: '',
          tags: [],
          rawContent: '',
          content: '',
        },
        rules: {
          number: [(v) => !!v || 'Uzupełnij'],
        },
      },
    };
  },
  created() {
    if (this.section) {
      this.form.input.number = this.section.number;
      this.form.input.tags = this.section.tags;
      this.form.input.rawContent = this.section.rawContent;
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.section) {
          await this.update();
        } else {
          await this.create();
        }
        this.loading = false;
        this.$emit('cancel');
      }
    },
    async create() {
      const { data } = await this.$apollo.mutate({
        mutation: CREATE,
        variables: {
          input: {
            documentVersionId: this.version.id,
            ...this.form.input,
          },
        },
      });
      this.$emit('add', data.addContentDocumentRow);
    },
    async update() {
      const { data } = await this.$apollo.mutate({
        mutation: UPDATE,
        variables: {
          input: {
            entityId: this.section.id,
            ...this.form.input,
          },
        },
      });
      this.$emit('update', data.entity);
    },
  },
};
</script>
