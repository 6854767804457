<template>
  <task-list-item-edit
    class="grey lighten-5"
    v-if="editor"
    :key="`${item.id}-editor`"
    :schedule="schedule"
    :item="item"
    @cancel="editor = false"
  ></task-list-item-edit>
  <v-list-item class="py-2 px-4" v-else :key="item.id" @click="edit">
    <v-list-item-avatar color="primary">
      <v-icon v-if="item.milestone" dark="dark">mdi-circle</v-icon>
      <v-icon v-else dark="dark" x-large="x-large">mdi-circle-small</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="font-weight-bold">{{ item.name }}</v-list-item-title>
      <v-list-item-subtitle class="mt-1" v-if="item.parentTaskTemplate">{{
        item.parentTaskTemplate.name
      }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="ml-4" v-if="item.documentTemplates.length">
      <div class="text-nowrap">
        <strong class="text--secondary">Pisma:&nbsp;</strong
        ><strong class="primary--text">{{ item.documentTemplates.length }}</strong>
      </div>
    </v-list-item-action>
    <v-list-item-action class="ml-4" v-if="item.interval">
      <div class="text-right text--secondary font-weight-bold" :style="{ width: '90px' }">
        {{ item.interval | humanizeInterval }}
      </div>
    </v-list-item-action>
    <v-list-item-action class="ml-4">
      <v-btn icon="icon" color="error" :loading="loading" @click.stop="del">
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import TaskListItemEdit from '@/v2/components/Schedule/TaskListItemEdit.vue';
import MUTATION from '@/graphql/scheduleTemplate/mutation/RemoveTaskTemplateMutation.gql';

export default {
  components: {
    TaskListItemEdit,
  },
  props: {
    schedule: { type: Object, required: true },
    item: { type: Object, required: true },
  },
  data() {
    return {
      editor: false,
      loading: false,
    };
  },
  methods: {
    edit() {
      this.editor = true;
    },
    async del() {
      const agree = await this.$dialog.confirm({
        text: 'Usunąć szablon zadania z tego harmonogramu?',
        title: 'Potwierdź operację',
      });
      if (agree) {
        this.loading = true;
        await this.$apollo.mutate({
          mutation: MUTATION,
          variables: { taskTemplateId: this.item.id },
        });
        this.loading = false;
        this.$emit('remove');
      }
    },
  },
};
</script>
