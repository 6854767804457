<template>
  <layout-main>
    <v-container fluid="fluid">
      <v-card>
        <v-row class="fill-height">
          <v-col>
            <v-sheet height="64">
              <v-toolbar flat="flat">
                <v-btn class="mr-4" outlined="outlined" color="grey darken-2" @click="setToday">Dzisiaj</v-btn>
                <v-btn fab="fab" text="text" small="small" color="grey darken-2" @click="prev">
                  <v-icon small="small">mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn fab="fab" text="text" small="small" color="grey darken-2" @click="next">
                  <v-icon small="small">mdi-chevron-right</v-icon>
                </v-btn>
                <v-toolbar-title class="px-4" v-if="$refs.calendar">{{ $refs.calendar.title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu bottom="bottom" right="right">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined="outlined" color="grey darken-2" v-bind="attrs" v-on="on"
                      ><span>{{ typeToLabel[type] }}</span>
                      <v-icon right="right">mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Dzień</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Tydzień</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Miesiąc</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                      <v-list-item-title>4 Dni</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>
            <v-sheet class="pa-4" height="800">
              <v-skeleton-loader v-if="!tasks" type="table-tbody"></v-skeleton-loader>
              <v-calendar
                v-if="tasks"
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                event-overlap-mode="stack"
                :event-overlap-threshold="3"
                :type="type"
                :weekdays="weekdays"
                @change="updateRange"
                @click:event="openDialog"
                @click:more="viewDay"
                @click:date="viewDay"
              ></v-calendar>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <calendar-details-dialog
      v-if="selectedTaskId"
      ref="dialog"
      force-open-dialog="force-open-dialog"
      :task-id="selectedTaskId"
      @close="closeDialog"
    ></calendar-details-dialog>
  </layout-main>
</template>

<script>
import { taskStatusColor } from '@/v2/components/Task/Mixin';
import sameDay from '@/helpers/sameDay';
import LayoutMain from '@/v2/layouts/Main.vue';
import CalendarDetailsDialog from '@/v2/components/Task/CalendarDetailsDialog.vue';
import TASK_LIST_QUERY from '@/graphql/task/query/TaskListQuery.gql';

export default {
  components: {
    LayoutMain,
    CalendarDetailsDialog,
  },
  data: () => ({
    focus: '',
    type: 'month',
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    typeToLabel: {
      'month': 'Miesiąc',
      'week': 'Tydzień',
      'day': 'Dzień',
      '4day': '4 Dni',
    },
    selectedTaskId: null,
    selectedElement: null,
    selectedOpen: false,
    month: new Date(),
  }),
  apollo: {
    tasks: {
      skip: false,
      query: TASK_LIST_QUERY,
      variables() {
        return this.calculateVariables();
      },
      update: (data) => {
        return data.list.items;
      },
    },
  },
  computed: {
    events() {
      return this.tasks.map((task) => {
        const { due, beginning } = task;
        const dueDate = new Date(Date.parse(due));
        let beginningDate = dueDate;
        if (beginning) {
          beginningDate = new Date(Date.parse(beginning));
        }

        let timed = sameDay(beginningDate, dueDate) && !task.wholeDay;

        if (beginning === due) {
          timed = false;
        }
        return {
          start: beginningDate,
          end: dueDate,
          color: taskStatusColor(task),
          timed,
          ...task,
        };
      });
    },
  },
  methods: {
    calculateVariables() {
      const firstDay = new Date(this.month.getFullYear(), this.month.getMonth(), 1);
      const lastDay = new Date(this.month.getFullYear(), this.month.getMonth() + 1, 0);
      return {
        query: {
          filters: [
            { field: 'due', type: 'GTE', value: firstDay },
            { field: 'due', type: 'LTE', value: lastDay },
          ],
        },
      };
    },
    openDialog({ event }) {
      this.selectedTaskId = event.id;
    },
    closeDialog() {
      this.selectedTaskId = null;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    updateRange(event) {
      const newDate = new Date(event.start.year, event.start.month, 0);
      if (this.month.getFullYear() === newDate.getFullYear() && this.month.getMonth() === newDate.getMonth()) {
        return; // same year and month, omit
      }
      this.month = newDate;
    },
  },
};
</script>
