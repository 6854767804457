import { documentKind } from '@/helpers/documents';
import Vue from 'vue';
import moment from 'moment';

export const calendar = (value) => moment(value).calendar();

export const date = (value) => {
  if (!value) {
    return null;
  }
  return moment(value).format('DD-MM-YYYY');
};

export const formatDate = (value) => date(value);

export const time = (value) => {
  if (!value) {
    return null;
  }
  return moment(value).format('HH:mm');
};

export const formatDateTime = (value) => moment(value).format('DD MMMM YYYY, HH:mm');
export const formatDateTimeReversed = (value) => moment(value).format('HH:mm, DD MMMM YYYY');

export const dateTime = (value) => {
  if (!value) {
    return null;
  }
  return formatDateTime(value);
};

Vue.filter('date', date);
Vue.filter('dateFrom', (value) => {
  if (!value) {
    return null;
  }
  return moment(value).from();
});
Vue.filter('calendar', calendar);
Vue.filter('dateTime', dateTime);
Vue.filter('humanizeInterval', (value) => moment.duration(value).humanize());
Vue.filter('orDash', (value) => value || '-');
Vue.filter('orEmpty', (value) => value || 'brak');
Vue.filter('money', (value) => {
  return `${(value.amount / 100).toFixed(2)} ${value.currency.code}`;
});
Vue.filter('orNoInfo', (value) => value || 'Brak informacji');

Vue.filter('mapStatus', (value) => {
  switch (value) {
    case 'NEW':
      return 'Nowy';
    case 'ACTIVE':
    case 'IN_PROGRESS':
      return 'W toku';
    case 'FINISHED_ARCHIVED':
      return 'Ukończony, zarchiwizowany';
    case 'FINISHED_NON_ARCHIVED':
      return 'Ukończony, nie zarchiwizowany';
    default:
      return 'Nieznany';
  }
});
Vue.filter('mapDeliveryIntent', (value) => {
  switch (value) {
    case 'DELIVER_BY_HAND':
      return 'do złożenia ręcznie';
    case 'DELIVER_BY_MAIL':
      return 'do wysłania';
    default:
      return 'Nieznany';
  }
});

Vue.filter('mapProceedingType', (value) => {
  switch (value) {
    case 'PERSONAL_BANKRUPTCY_SIMPLIFIED':
      return 'upadłość konsumencka uproszczona';
    case 'PERSONAL_BANKRUPTCY_STANDARD':
      return 'upadłość konsumencka';
    case 'COMPANY_BANKRUPTCY':
      return 'upadłość firmowa';
    case 'COMPANY_RESTRUCTURING':
      return 'restrukturyzacja firmy';
    default:
      return 'nieznany';
  }
});

Vue.filter('documentKindName', (value) => documentKind(value).name);
Vue.filter('documentKindClass', (value) => documentKind(value).class);
