<template>
  <v-app>
    <main-top-bar :title="title" :search="search" @search="$emit('search', $event)">
      <template v-slot:title>
        <slot name="title"></slot>
      </template>
    </main-top-bar>
    <main-side-navigation></main-side-navigation>
    <v-main>
      <slot></slot>
    </v-main>
  </v-app>
</template>

<script>
import MainTopBar from '@/v2/layouts/components/MainTopBar.vue';
import MainSideNavigation from '@/v2/layouts/components/MainSideNavigation.vue';

export default {
  components: {
    MainTopBar,
    MainSideNavigation,
  },
  props: {
    title: {
      type: String,
    },
    search: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
