<template>
  <v-card>
    <v-card-subtitle class="text--secondary">Uprawnienia</v-card-subtitle>
    <div class="px-4">
      <v-radio-group v-model="role">
        <v-radio value="ROLE_USER">
          <template v-slot:label>
            <h3 class="px-2">{{ 'ROLE_USER' | mapRole }}</h3>
          </template>
        </v-radio>
        <v-radio class="mt-8" value="ROLE_MANAGER">
          <template v-slot:label>
            <h3 class="px-2">{{ 'ROLE_MANAGER' | mapRole }}</h3>
          </template>
        </v-radio>
        <v-radio class="mt-8" value="ROLE_ADMIN">
          <template v-slot:label>
            <h3 class="px-2">{{ 'ROLE_ADMIN' | mapRole }}</h3>
          </template>
        </v-radio>
      </v-radio-group>
    </div>
    <div class="text-right px-4 pb-4">
      <v-btn depressed="depressed" large="large" color="secondary" :loading="loading" @click="save">Zapisz</v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapRole } from '@/helpers/mapRoles';
import pickHighestRole from '@/helpers/pickHighestRole';
import EDIT_USER_ROLE from '@/graphql/user/mutation/EditUserRoleMutation.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      role: 'ROLE_USER',
    };
  },
  watch: {
    'user.roles': {
      immediate: true,
      handler(roles) {
        this.role = pickHighestRole(roles);
      },
    },
  },
  filters: {
    mapRole,
  },
  methods: {
    async save() {
      this.loading = true;
      const { data } = await this.$apollo.mutate({
        mutation: EDIT_USER_ROLE,
        variables: {
          input: {
            userId: this.user.id,
            role: this.role,
          },
        },
      });
      this.role = pickHighestRole(data.user.roles);
      this.loading = false;
    },
  },
};
</script>
