<template>
  <v-card class="overflow-hidden">
    <v-row class="pa-4" align="center" no-gutters="no-gutters">
      <v-col cols="auto">
        <v-menu right="right" bottom="bottom" offset-y="offset-y" :nudge-bottom="6" :nudge-left="8">
          <template v-slot:activator="{ on, attrs }">
            <div class="grey lighten-4 rounded-circle">
              <v-btn fab="fab" outlined="outlined" small="small" color="grey" v-bind="attrs" v-on="on">
                <v-icon>{{ filterIcon }}</v-icon>
              </v-btn>
            </div>
          </template>
          <v-list dense="dense">
            <v-list-item @click="setFilter('outcome')">
              <v-list-item-icon>
                <v-icon>mdi-arrow-left-bold</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Wydatki</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="setFilter('income')">
              <v-list-item-icon>
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Wpływy</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="setFilter('all')">
              <v-list-item-icon>
                <v-icon>mdi-menu</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Wszystkie</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col class="pl-3" cols="auto">
        <div class="grey lighten-4 rounded">
          <v-btn class="px-0" :style="{ height: '40px' }" outlined="outlined" color="grey">
            <v-row no-gutters="no-gutters" align="center">
              <v-col>
                <date-input
                  :style="{ maxWidth: '107px' }"
                  dense="dense"
                  solo="solo"
                  flat="flat"
                  hide-details="hide-details"
                  background-color="transparent"
                  placeholder="Od dnia"
                  :value="start"
                  @input="setStart"
                ></date-input>
              </v-col>
              <v-col cols="auto">
                <h2 class="text--secondary">~</h2>
              </v-col>
              <v-col>
                <date-input
                  :style="{ maxWidth: '107px' }"
                  dense="dense"
                  solo="solo"
                  flat="flat"
                  hide-details="hide-details"
                  background-color="transparent"
                  placeholder="Do dnia"
                  :value="end"
                  @input="setEnd"
                ></date-input>
              </v-col>
            </v-row>
          </v-btn>
        </div>
      </v-col>
      <v-col class="pl-3">
        <v-responsive max-width="260">
          <search-input :value="search" @input="setSearch"></search-input>
        </v-responsive>
      </v-col>
      <v-col class="pl-3" cols="auto">
        <operation-upload-button :account="account" @update="operationUpdateAll"></operation-upload-button>
      </v-col>
      <v-col class="pl-3" cols="auto">
        <operation-edit :account="account" @add="operationAdd">
          <template v-slot:activator="{ attrs, on }">
            <v-btn class="px-4" depressed="depressed" fab="fab" small="small" color="secondary" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </operation-edit>
      </v-col>
    </v-row>
    <infinite-loading class="pt-4" ref="infinite" :load="load">
      <template v-slot:default="{ list }">
        <v-list>
          <template v-for="item in list.items">
            <operation-list-item
              :key="item.id"
              :account="account"
              :item="item"
              @update="operationUpdate"
              @delete="operationDelete"
            ></operation-list-item>
            <v-divider :key="item.id" class="last-child--hidden"></v-divider>
          </template>
        </v-list>
      </template>
    </infinite-loading>
  </v-card>
</template>

<script>
import DateInput from '@/v2/components/Form/Date.vue';
import SearchInput from '@/v2/components/Form/SearchInput.vue';
import InfiniteLoading from '@/v2/components/InfiniteLoading.vue';
import OperationListItem from '@/v2/components/BankAccount/OperationListItem.vue';
import OperationEdit from '@/v2/components/BankAccount/OperationEdit.vue';
import OperationUploadButton from '@/v2/components/BankAccount/OperationUploadButton.vue';

export default {
  components: {
    DateInput,
    SearchInput,
    InfiniteLoading,
    OperationListItem,
    OperationEdit,
    OperationUploadButton,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      filter: 'all',
      start: '',
      end: '',
    };
  },
  computed: {
    filterIcon() {
      switch (this.filter) {
        case 'income':
          return 'mdi-arrow-right-bold';
        case 'outcome':
          return 'mdi-arrow-left-bold';
        default:
          return 'mdi-filter-variant';
      }
    },
    operations() {
      const search = this.search.toLowerCase();
      const operations = this.account.operations.filter((item) => {
        if (this.filter === 'income' && item.sum.amount < 0) return false;
        if (this.filter === 'outcome' && item.sum.amount > 0) return false;
        if (this.start !== '' && item.operationDate.substring(0, 10) < this.start) return false;
        if (this.end !== '' && item.operationDate.substring(0, 10) > this.end) return false;
        if (search !== '') {
          if (item.receiverSender.toLowerCase().includes(search)) return true;
          if (item.operationIdentifier.name.toLowerCase().includes(search)) return true;
          if (item.description.toLowerCase().includes(search)) return true;
          if (
            Math.abs(item.sum.amount / 100)
              .toFixed(2)
              .includes(search)
          )
            return true;
          return false;
        }
        return true;
      });
      return operations.sort((a, b) => new Date(a.operationDate) - new Date(b.operationDate));
    },
  },
  methods: {
    setFilter(filter) {
      this.filter = filter;
      this.$refs.infinite.reset();
    },
    setStart(start) {
      this.start = start;
      this.$refs.infinite.reset();
    },
    setEnd(end) {
      this.end = end;
      this.$refs.infinite.reset();
    },
    setSearch(search) {
      this.search = search;
      this.$refs.infinite.reset();
    },
    operationAdd(operation) {
      this.account.operations.push(operation);
      this.$refs.infinite.reset();
    },
    operationUpdate(operation) {
      const index = this.account.operations.findIndex((x) => x.id === operation.id);
      this.account.operations.splice(index, 1, operation);
      this.$refs.infinite.reset();
    },
    operationUpdateAll(operations) {
      this.account.operations = operations;
      this.$refs.infinite.reset();
    },
    operationDelete(operation) {
      const index = this.account.operations.findIndex((x) => x.id === operation.id);
      this.account.operations.splice(index, 1);
      this.$refs.infinite.reset();
    },
    load(offset) {
      const items = this.operations.slice(offset, offset + 20);
      return {
        count: this.operations.length,
        items: items.map((item) => ({
          id: item.id,
          subject: item.receiverSender,
          identifier: item.operationIdentifier
            ? {
                id: item.operationIdentifier.id,
                name: item.operationIdentifier.name,
                nameId: item.operationIdentifier.id.replaceAll('_', '.'),
              }
            : null,
          description: item.description,
          value: item.sum,
          balance: item.sumAfter,
          date: item.operationDate,
        })),
      };
    },
  },
};
</script>
