<template>
  <layout-main title="Nowe postępowanie">
    <v-card>
      <v-card-actions>
        <v-row class="pt-2 px-4" align="center">
          <v-col>
            <v-btn to="/" outlined="outlined" rounded="rounded" color="secondary">Anuluj</v-btn>
          </v-col>
          <v-col v-if="step > 1 && !loading" cols="auto">
            <v-btn class="px-1" outlined="outlined" rounded="rounded" small="small" color="secondary" @click="prev">
              <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Wstecz</span>
            </v-btn>
          </v-col>
          <v-col v-if="step &lt; 3" cols="auto">
            <v-btn class="px-4" depressed="depressed" rounded="rounded" color="secondary" @click="next"
              ><span class="px-2">Dalej</span>
              <v-icon>mdi-arrow-right-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col v-else cols="auto">
            <v-btn
              class="px-4"
              depressed="depressed"
              rounded="rounded"
              color="secondary"
              :loading="loading"
              @click="next"
            >
              <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-text>
        <v-stepper flat="flat" v-model="step">
          <v-stepper-header>
            <v-stepper-step :step="1" :complete="step > 1">Dłużnik</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :step="2" :complete="step > 2">Sąd</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :step="3" :complete="step > 3">Postępowanie</v-stepper-step>
          </v-stepper-header>
          <v-stepper-items class="max-width-md">
            <v-stepper-content :step="1">
              <edit-debtor ref="debtor" @input="input"></edit-debtor>
            </v-stepper-content>
            <v-stepper-content :step="2">
              <edit-court ref="court" @input="input"></edit-court>
            </v-stepper-content>
            <v-stepper-content :step="3">
              <edit-case ref="case" @input="input"></edit-case>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import EditCourt from '@/v2/components/Proceeding/EditCourt.vue';
import EditCase from '@/v2/components/Proceeding/EditCase.vue';
import EditDebtor from '@/v2/components/Proceeding/EditDebtor.vue';
import ADD_LEGAL_DEBTOR_MUTATION from '@/graphql/debtor/mutation/AddLegalPersonDebtorMutation.gql';
import ADD_PERSON_DEBTOR_MUTATION from '@/graphql/debtor/mutation/AddPersonalDebtorMutation.gql';
import ADD_PROCEEDING_MUTATION from '@/graphql/proceeding/mutation/AddProceedingMutation.gql';

export default {
  components: {
    LayoutMain,
    EditCourt,
    EditCase,
    EditDebtor,
  },
  data() {
    return {
      step: 1,
      loading: false,
      form: {},
    };
  },
  methods: {
    next() {
      switch (this.step) {
        case 1:
          if (this.$refs.debtor.validate()) {
            this.step += 1;
          }
          break;
        case 2:
          if (this.$refs.court.validate()) {
            this.step += 1;
          }
          break;
        default:
          if (this.$refs.case.validate()) {
            this.save();
          }
          break;
      }
    },
    prev() {
      this.step -= 1;
    },
    input(input) {
      Object.assign(this.form, input);
    },
    async save() {
      this.loading = true;
      const debtor = await this.createDebtor();
      const proceeding = await this.createProceeding(debtor);
      this.loading = false;
      this.$router.push({
        name: 'proceeding',
        params: { id: proceeding.id },
      });
    },
    async createDebtor() {
      if (this.form.isPerson) {
        return this.createDebtorPerson();
      }
      return this.createDebtorLegal();
    },
    async createDebtorPerson() {
      const { data } = await this.$apollo.mutate({
        mutation: ADD_PERSON_DEBTOR_MUTATION,
        variables: {
          personalDebtorInput: {
            ...this.form.person,
            ...this.form.contact,
            address: this.form.address,
          },
        },
      });
      return data.addDebtor;
    },
    async createDebtorLegal() {
      const { data } = await this.$apollo.mutate({
        mutation: ADD_LEGAL_DEBTOR_MUTATION,
        variables: {
          legalPersonDebtorInput: {
            ...this.form.legal,
            ...this.form.contact,
            address: this.form.address,
          },
        },
      });
      return data.addDebtor;
    },
    async createProceeding(debtor) {
      const { data } = await this.$apollo.mutate({
        mutation: ADD_PROCEEDING_MUTATION,
        variables: {
          input: {
            ...this.form.proceeding,
            ...this.form.schedule,
            ...this.form.court,
            debtors: [debtor.id],
          },
        },
      });
      return data.addProceeding;
    },
  },
};
</script>
