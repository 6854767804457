<template>
  <layout-main :title="court ? court.name : 'Dodawanie sądu'">
    <v-container fluid="fluid">
      <v-card>
        <v-row>
          <v-col>
            <v-card-actions class="px-8">
              <v-btn outlined="outlined" rounded="rounded" color="secondary" @click="cancel">
                <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Sądy</span>
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="auto">
            <v-card-actions class="px-8">
              <v-btn
                class="px-4"
                depressed="depressed"
                rounded="rounded"
                large="large"
                color="secondary"
                :loading="loading"
                @click="submit"
              >
                <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <v-form class="px-8 pt-6" ref="form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined="outlined"
                label="Nazwa sądu"
                :rules="form.rules.name"
                v-model="form.input.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined="outlined"
                label="Ulica"
                :rules="form.rules.address.street"
                v-model="form.input.address.street"
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
              <v-text-field
                outlined="outlined"
                label="Kod pocztowy"
                :rules="form.rules.address.postCode"
                v-model="form.input.address.postCode"
              ></v-text-field>
            </v-col>
            <v-col cols="8" md="4">
              <v-text-field
                outlined="outlined"
                label="Miejscowość"
                :rules="form.rules.address.city"
                v-model="form.input.address.city"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <div class="pa-4 pb-2" v-if="id">
          <simple-wysiwyg-field
            class="px-4"
            placeholder="Informacje dodatkowe"
            v-model="form.input.contactDetails"
          ></simple-wysiwyg-field>
        </div>
      </v-card>
      <v-card class="mt-8" v-if="court">
        <judge-list class="pa-4" :court-id="court.id" :judges="judges"></judge-list>
      </v-card>
    </v-container>
  </layout-main>
</template>

<script>
import stringCompare from '@/v2/helpers/stringCompare';
import LayoutMain from '@/v2/layouts/Main.vue';
import SimpleWysiwygField from '@/components/FormFields/SimpleWysiwygField.vue';
import JudgeList from '@/v2/components/Judge/List.vue';
import GET_COURT_QUERY from '@/graphql/court/query/GetCourtQuery.gql';
import CREATE_COURT_MUTATION from '@/graphql/court/mutation/AddCourtMutation.gql';
import UPDATE_COURT_MUTATION from '@/graphql/court/mutation/EditCourtMutation.gql';

export default {
  components: {
    LayoutMain,
    SimpleWysiwygField,
    JudgeList,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        input: {
          name: '',
          address: {
            street: '',
            postCode: '',
            city: '',
          },
          contactDetails: '',
        },
        rules: {
          name: [(v) => !!v || 'Podaj nazwę'],
          address: {
            street: [(v) => !!v || 'Podaj ulicę'],
            postCode: [(v) => !!v || 'Podaj kod'],
            city: [(v) => !!v || 'Podaj miejscowość'],
          },
        },
      },
    };
  },
  apollo: {
    court: {
      query: GET_COURT_QUERY,
      variables() {
        return { courtId: this.id };
      },
      update(data) {
        const item = data.list.items[0];
        this.form.input.name = item.name || '';
        this.form.input.address.street = item.address.street || '';
        this.form.input.address.postCode = item.address.postCode || '';
        this.form.input.address.city = item.address.city || '';
        this.form.input.contactDetails = item.contactDetails || '';
        return item;
      },
      error() {
        this.cancel();
      },
      skip() {
        return !this.id;
      },
    },
  },
  computed: {
    judges() {
      const judges = [...this.court.judges];
      return judges.sort((a, b) => stringCompare(`${a.lastName} ${a.firstName}`, `${b.lastName} ${b.firstName}`));
    },
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.court) {
          await this.courtSave();
        } else {
          await this.courtCreate();
        }
        this.loading = false;
      }
    },
    async courtCreate() {
      const input = { ...this.form.input, contactDetails: this.form.input.contactDetails || null };
      const { data } = await this.$apollo.mutate({
        mutation: CREATE_COURT_MUTATION,
        variables: { input },
      });
      this.$router.push({
        name: 'court-edit',
        params: { id: data.court.id },
      });
    },
    async courtSave() {
      const input = { ...this.form.input, contactDetails: this.form.input.contactDetails || null };
      await this.$apollo.mutate({
        mutation: UPDATE_COURT_MUTATION,
        variables: {
          input: {
            courtId: this.court.id,
            ...input,
          },
        },
      });
    },
    cancel() {
      this.$router.push({ name: 'court-list' });
    },
  },
};
</script>
