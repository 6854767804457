<template>
  <div>
    <div class="px-4">
      <h2>{{ entity.name }}</h2>
      <div class="pt-2 font-weight-medium text--secondary">
        <span class="mr-4" v-if="entity.personData.peselNumber">PESEL: {{ entity.personData.peselNumber }}</span
        ><span class="mr-4" v-if="entity.companyData.krsNumber">KRS: {{ entity.companyData.krsNumber }}</span
        ><span class="mr-4" v-if="entity.companyData.nipNumber">NIP: {{ entity.companyData.nipNumber }}</span
        ><span class="mr-4" v-if="entity.companyData.regonNumber">REGON: {{ entity.companyData.regonNumber }}</span>
      </div>
    </div>
    <div class="mt-8 px-4"><small class="text--secondary">Dane kontaktowe</small></div>
    <v-divider class="my-2"></v-divider>
    <div class="px-4 font-weight-medium">
      <div class="pt-2" v-if="entity.address.street">
        <v-icon class="icon-fw mr-2">mdi-email-outline</v-icon
        ><span class="text-middle"
          >{{ entity.address.street }}; {{ entity.address.postCode }} {{ entity.address.city }}</span
        >
      </div>
      <div class="pt-2" v-if="entity.email">
        <v-icon class="icon-fw mr-2">mdi-at</v-icon><span class="text-middle">{{ entity.email }}</span>
      </div>
      <div class="pt-2" v-if="entity.phone">
        <v-icon class="icon-fw mr-2">mdi-cellphone</v-icon><span class="text-middle">{{ entity.phone }}</span>
      </div>
    </div>
    <div class="mt-12 px-4" v-if="entity.contactDetails">
      <small class="text--secondary">Informacje dodatkowe</small>
    </div>
    <v-divider class="my-2"></v-divider>
    <div class="px-4 text-pre-line">{{ entity.contactDetails }}</div>
    <v-card-actions class="mt-12 px-4"
      ><small class="text--secondary">Powiązane dokumenty</small>
      <v-spacer></v-spacer>
      <select-list-item :items="documents" @select="add">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="secondary"
            outlined="outlined"
            small="small"
            icon="icon"
            color="white"
            :loading="adding"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </select-list-item>
    </v-card-actions>
    <v-divider v-if="entityRelatedDocuments.length"></v-divider>
    <v-list>
      <template v-for="document in entityRelatedDocuments">
        <document-list-item :key="document.id" :document="document">
          <template v-slot:subtitle><span></span></template>
          <template v-slot:action>
            <v-btn
              class="ml-4"
              outlined="outlined"
              small="small"
              icon="icon"
              color="grey"
              :loading="removing === document.id"
              @click.stop.prevent="remove(document)"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </template>
        </document-list-item>
        <v-divider :key="document.id" class="last-child--hidden"></v-divider>
      </template>
    </v-list>
  </div>
</template>

<script>
import stringCompare from '@/v2/helpers/stringCompare';
import SelectListItem from '@/v2/components/SelectListItem.vue';
import DocumentListItem from '@/v2/components/Document/ListItem.vue';
import SET_RELATED_DOCUMENTS_MUTATION from '@/graphql/entity/mutation/SetEntityRelatedDocuments.gql';

export default {
  components: {
    SelectListItem,
    DocumentListItem,
  },
  props: {
    proceeding: { type: Object, required: true },
    entity: { type: Object, required: true },
  },
  data() {
    return {
      adding: false,
      removing: false,
    };
  },
  computed: {
    documents() {
      return this.proceeding.documents || [];
    },
    entityRelatedDocuments() {
      const documents = [...(this.entity.relatedDocuments || [])];
      documents.sort((a, b) => stringCompare(a.receivedAt, b.receivedAt));
      return documents;
    },
  },
  methods: {
    async add(document) {
      this.adding = true;
      const relatedDocumentIds = (this.entity.relatedDocuments || []).map((x) => x.id);
      relatedDocumentIds.push(document.id);
      const documentsForProceeding = await this.setRelatedDocuments(this.entity, relatedDocumentIds);
      this.$emit('update', documentsForProceeding);
      this.adding = false;
    },
    async remove(document) {
      this.removing = document.id;
      const relatedDocumentIds = (this.entity.relatedDocuments || [])
        .map((x) => x.id)
        .filter((id) => id !== document.id);
      const documentsForProceeding = await this.setRelatedDocuments(this.entity, relatedDocumentIds);
      this.$emit('update', documentsForProceeding);
      this.removing = '';
    },
    async setRelatedDocuments(entity, relatedDocumentIds) {
      const { data } = await this.$apollo.mutate({
        mutation: SET_RELATED_DOCUMENTS_MUTATION,
        variables: {
          input: {
            proceedingId: this.proceeding.id,
            entityId: entity.id,
            documentIds: relatedDocumentIds,
          },
          proceedingId: this.proceeding.id,
        },
      });
      return data.entity.documentsForProceeding.map((x) => x.id);
    },
  },
};
</script>
