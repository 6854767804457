<template>
  <layout-main>
    <template v-slot:title>
      <div v-if="proceeding">
        <h3>
          <span>{{ proceeding.signature }}</span
          ><span class="ml-4" v-if="debtor">{{ debtor.name }} {{ debtor.surname }}</span>
        </h3>
        <h5 class="text--secondary">
          <span class="mr-4" v-if="proceeding.signatureAdditional">{{ proceeding.signatureAdditional }}</span
          ><span>({{ proceeding.type | mapProceedingType }})</span>
        </h5>
      </div>
    </template>
    <v-container fluid="fluid">
      <template v-if="proceeding">
        <v-alert
          v-if="proceeding.status === 'FINISHED_ARCHIVED'"
          prominent="prominent"
          type="success"
          color="secondary"
          icon="mdi-archive-outline"
        >
          <div class="text-subtitle-1 text-center mr-16">Postępowanie zarchiwizowane.</div>
        </v-alert>
        <v-alert
          v-else-if="proceeding.status === 'FINISHED_NON_ARCHIVED'"
          prominent="prominent"
          type="success"
          icon="mdi-check-all"
        >
          <div class="text-subtitle-1 text-center mr-16">Postępowanie zamknięte.</div>
        </v-alert>
        <v-fade-transition mode="out-in">
          <router-view
            :proceeding="proceeding"
            :template="template"
            :reply-to="replyTo"
            :file="file"
            @update="Object.assign(proceeding, $event)"
            @note-update="noteUpdate"
            @entity-assign="entityAssign"
            @entity-update="entityUpdate"
            @entity-update-related-documents="entityUpdateRelatedDocuments"
            @claim-add="claimAdd"
            @claim-update="claimUpdate"
            @task-add="taskAdd"
            @task-update="taskUpdate"
            @task-update-due="$apollo.queries.proceeding.refetch()"
            @document-add="documentAdd"
            @document-update="documentUpdate"
            @document-update-related-documents="documentUpdateRelatedDocuments"
            @document-update-related-tasks="documentUpdateRelatedTasks"
            @document-write-template="template = $event"
            @document-write-replyto="replyTo = $event"
            @document-attach-file="file = $event"
            @update-status="taskUpdateStatus"
            @account-add="accountAdd"
            @account-update="accountUpdate"
          ></router-view>
        </v-fade-transition>
        <note-edit-dialog :proceeding="proceeding" @add="noteAdd"></note-edit-dialog>
        <div class="py-12"></div>
      </template>
    </v-container>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import NoteEditDialog from '@/v2/components/Note/EditDialog.vue';
import PROCEEDING_DETAILS_QUERY from '@/graphql/proceeding/query/ProceedingDetailsQuery.gql';

export default {
  components: {
    LayoutMain,
    NoteEditDialog,
  },
  props: {
    id: { type: String, required: true },
    accountId: { type: String },
  },
  data() {
    return {
      template: null,
      replyTo: null,
      file: null,
    };
  },
  computed: {
    debtor() {
      return this.proceeding && this.proceeding.debtors && this.proceeding.debtors.length
        ? this.proceeding.debtors[0]
        : null;
    },
  },
  apollo: {
    proceeding: {
      query: PROCEEDING_DETAILS_QUERY,
      variables() {
        return {
          proceedingId: this.id,
        };
      },
      update: (data) => {
        return data.list.items[0];
      },
    },
  },
  methods: {
    noteAdd(note) {
      this.proceeding.notes.push(note);
    },
    noteUpdate(note) {
      const index = this.proceeding.notes.findIndex((x) => x.id === note.id);
      this.proceeding.notes.splice(index, 1, note);
    },
    entityAssign(entities) {
      this.proceeding.entities = entities;
    },
    entityUpdate(entity) {
      const ent = this.proceeding.entities.find((x) => x.id === entity.id);
      Object.assign(ent, entity);
    },
    entityUpdateRelatedDocuments({ entity, documentsForProceeding }) {
      const ent = this.proceeding.entities.find((x) => x.id === entity.id);
      const relatedDocuments = (this.proceeding.documents || []).filter((x) => documentsForProceeding.includes(x.id));
      Object.assign(ent, { relatedDocuments });
    },
    claimAdd(claim) {
      this.proceeding.claims.push(claim);
    },
    claimUpdate(claim) {
      const index = this.proceeding.claims.findIndex((x) => x.id === claim.id);
      this.proceeding.claims.splice(index, 1, claim);
    },
    taskAdd(task) {
      this.proceeding.schedules[0].tasks.push(task);
    },
    taskUpdate(task) {
      const index = this.proceeding.schedules[0].tasks.findIndex((x) => x.id === task.id);
      this.proceeding.schedules[0].tasks.splice(index, 1, task);
    },
    taskUpdateStatus(task) {
      const index = this.proceeding.schedules[0].tasks.findIndex((x) => x.id === task.id);
      const instance = this.proceeding.schedules[0].tasks[index];
      this.proceeding.schedules[0].tasks.splice(index, 1, { ...instance, ...task });
    },
    documentAdd(document) {
      this.proceeding.documents.push(Object.assign(document, { proceeding: { id: this.proceeding.id } }));
    },
    documentUpdate(document) {
      const doc = this.proceeding.documents.find((d) => d.id === document.id);
      Object.assign(doc, document);
    },
    documentUpdateRelatedDocuments({ document, relatedDocuments }) {
      const doc = this.proceeding.documents.find((d) => d === document);
      doc.relatedDocuments = relatedDocuments;
    },
    documentUpdateRelatedTasks({ document, relatedTasks }) {
      const doc = this.proceeding.documents.find((d) => d === document);
      doc.relatedTasks = relatedTasks;
    },
    accountAdd(account) {
      this.proceeding.accounts.push(account);
    },
    accountUpdate(account) {
      const index = this.proceeding.accounts.findIndex((x) => x.id === account.id);
      this.proceeding.accounts.splice(index, 1, account);
    },
  },
};
</script>
