<template>
  <div>
    <v-card>
      <v-card-actions class="pa-4">
        <v-row align="center">
          <v-col>
            <v-btn
              class="px-2"
              :to="{ name: 'proceeding-finance' }"
              outlined="outlined"
              rounded="rounded"
              color="secondary"
            >
              <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Finanse</span>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              class="px-2"
              :to="{ name: 'proceeding-finance-account-edit', params: { accountId: accountId } }"
              depressed="depressed"
              rounded="rounded"
              color="secondary"
            >
              <v-icon>mdi-pencil-circle</v-icon><span class="px-2">Edytuj</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <div class="px-4 pb-4 pt-2">
        <v-row>
          <v-col>
            <h2>{{ account.name }}</h2>
            <h3 class="text--secondary font-weight-medium">{{ account.number }}</h3>
          </v-col>
          <v-col cols="auto">
            <h1 class="success white--text px-4 rounded">{{ balance | money }}</h1>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <operation-list class="mt-8" :account="account"></operation-list>
  </div>
</template>

<script>
import OperationList from '@/v2/components/BankAccount/OperationList.vue';

export default {
  components: {
    OperationList,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
    accountId: {
      type: String,
      required: true,
    },
  },
  computed: {
    account() {
      return this.proceeding.accounts.find((account) => account.id === this.accountId);
    },
    balance() {
      return {
        amount: this.account.operations.reduce((balance, operation) => balance + operation.sum.amount, 0),
        currency: {
          code: 'PLN',
        },
      };
    },
  },
};
</script>
