<template>
  <layout-main :title="judge ? judgeName : 'Nowy sędzia'">
    <v-container fluid="fluid">
      <v-card>
        <v-row>
          <v-col>
            <v-card-actions class="px-8">
              <v-btn outlined="outlined" rounded="rounded" color="secondary" @click="cancel">
                <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Edycja sądu</span>
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="auto">
            <v-card-actions class="px-8">
              <v-btn
                class="px-4"
                depressed="depressed"
                rounded="rounded"
                large="large"
                color="secondary"
                :loading="loading"
                @click="submit"
              >
                <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <v-form class="px-8 pt-6" ref="form">
          <v-row>
            <v-col sm="2">
              <v-text-field outlined="outlined" label="Tytuł" v-model="form.input.title"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                outlined="outlined"
                label="Imię"
                :rules="form.rules.firstName"
                v-model="form.input.firstName"
              ></v-text-field>
            </v-col>
            <v-col sm="6">
              <v-text-field
                outlined="outlined"
                label="Nazwisko"
                :rules="form.rules.lastName"
                v-model="form.input.lastName"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <div class="pa-4 pb-2" v-if="id">
          <simple-wysiwyg-field
            class="px-4"
            placeholder="Informacje dodatkowe"
            v-model="form.input.contactDetails"
          ></simple-wysiwyg-field>
        </div>
      </v-card>
    </v-container>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import SimpleWysiwygField from '@/components/FormFields/SimpleWysiwygField.vue';
import GET_JUDGE_QUERY from '@/graphql/judge/query/GetJudgeQuery.gql';
import CREATE_JUDGE_MUTATION from '@/graphql/judge/mutation/AddJudgeMutation.gql';
import UPDATE_JUDGE_MUTATION from '@/graphql/judge/mutation/EditJudgeMutation.gql';

export default {
  components: {
    LayoutMain,
    SimpleWysiwygField,
  },
  props: {
    courtId: {
      type: String,
      required: true,
    },
    id: String,
  },
  data() {
    return {
      loading: false,
      form: {
        input: {
          title: '',
          firstName: '',
          lastName: '',
          contactDetails: '',
        },
        rules: {
          firstName: [(v) => !!v || 'Podaj imię'],
          lastName: [(v) => !!v || 'Podaj nazwisko'],
        },
      },
    };
  },
  computed: {
    judgeName() {
      return this.judge ? `${this.judge.firstName} ${this.judge.lastName}` : '';
    },
  },
  apollo: {
    judge: {
      query: GET_JUDGE_QUERY,
      variables() {
        return { judgeId: this.id };
      },
      update(data) {
        const item = data.list.items[0];
        this.form.input.title = item.title || '';
        this.form.input.firstName = item.firstName || '';
        this.form.input.lastName = item.lastName || '';
        this.form.input.contactDetails = item.contactDetails || '';
        if (this.$refs.editor) {
          this.$refs.editor.open(this.form.input.contactDetails);
        }
        return item;
      },
      error() {
        this.cancel();
      },
      skip() {
        return !this.id;
      },
    },
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.form.input.title = this.form.input.title || null;
        this.form.input.contactDetails = this.form.input.contactDetails || null;
        if (this.judge) {
          await this.judgeSave();
        } else {
          await this.judgeCreate();
        }
        this.loading = false;
      }
    },
    async judgeCreate() {
      const { data } = await this.$apollo.mutate({
        mutation: CREATE_JUDGE_MUTATION,
        variables: {
          input: {
            courtId: this.courtId,
            ...this.form.input,
          },
        },
      });
      this.$router.push({
        name: 'judge-edit',
        params: {
          courtId: this.courtId,
          id: data.judge.id,
        },
      });
    },
    async judgeSave() {
      await this.$apollo.mutate({
        mutation: UPDATE_JUDGE_MUTATION,
        variables: {
          input: {
            courtId: this.courtId,
            judgeId: this.judge.id,
            ...this.form.input,
          },
        },
      });
    },
    cancel() {
      this.$router.push({
        name: 'court-edit',
        params: { id: this.courtId },
      });
    },
  },
};
</script>
