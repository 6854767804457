<template>
  <layout-main :title="document ? document.title : 'Nowa treść'">
    <v-card>
      <v-row>
        <v-col>
          <v-card-actions class="pl-8">
            <slot name="back"></slot>
          </v-card-actions>
        </v-col>
        <v-col cols="auto">
          <v-card-actions class="px-8">
            <v-btn
              class="px-4"
              depressed="depressed"
              rounded="rounded"
              large="large"
              color="secondary"
              :loading="loading"
              @click="submit"
            >
              <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
      <v-form class="px-8 pt-6" ref="form">
        <v-row>
          <v-col cols="9">
            <v-text-field
              outlined="outlined"
              label="Nazwa"
              :rules="form.rules.title"
              v-model="form.input.title"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined="outlined"
              label="Prefix"
              :rules="form.rules.pointPrefix"
              v-model="form.input.pointPrefix"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-card class="mt-12" v-if="document">
      <template v-if="versions.length">
        <div class="px-8 py-4 secondary--text font-weight-medium">Dostępne wersje</div>
        <div class="px-8">
          <document-version-list
            :versions="versions"
            :version="version"
            @select="version = $event"
          ></document-version-list>
        </div>
      </template>
      <div class="pa-4">
        <document-version-edit
          class="grey lighten-5 rounded overflow-hidden"
          :document="document"
          :version="version"
          @cancel="setDefaultVersion"
          @create="version = null"
          @add="addNewVersion"
          @update="updateVersion"
        ></document-version-edit>
      </div>
    </v-card>
    <v-card class="mt-8" v-if="version && version.rows.length">
      <div class="pa-4 pt-8">
        <document-version-section-list
          :version="version"
          :prefix="document.pointPrefix"
          :editable="true"
          @update="updateSection"
        ></document-version-section-list>
      </div>
    </v-card>
    <v-card class="mt-8" v-if="document && version">
      <div class="pa-4">
        <document-version-section-list-item-edit
          class="grey lighten-5 rounded overflow-hidden"
          v-if="addSection"
          :version="version"
          :prefix="document.pointPrefix"
          @cancel="addSection = false"
          @add="addNewSection"
        ></document-version-section-list-item-edit>
        <div class="pa-4 text-right" v-else>
          <v-btn class="px-2" depressed="depressed" rounded="rounded" color="secondary" @click="addSection = true">
            <v-icon>mdi-plus-circle</v-icon><span class="px-2">Nowa sekcja</span>
          </v-btn>
        </div>
      </div>
    </v-card>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import DocumentVersionList from '@/v2/components/Content/DocumentVersionList.vue';
import DocumentVersionEdit from '@/v2/components/Content/DocumentVersionEdit.vue';
import DocumentVersionSectionList from '@/v2/components/Content/DocumentVersionSectionList.vue';
import DocumentVersionSectionListItemEdit from '@/v2/components/Content/DocumentVersionSectionListItemEdit.vue';
import SELECT from '@/graphql/content/query/GetOneContentDocumentQuery.gql';
import CREATE from '@/graphql/content/mutation/AddContentDocumentMutation.gql';
import UPDATE from '@/graphql/content/mutation/EditContentDocumentMutation.gql';

export default {
  name: 'DocumentEdit',
  components: {
    LayoutMain,
    DocumentVersionList,
    DocumentVersionEdit,
    DocumentVersionSectionList,
    DocumentVersionSectionListItemEdit,
  },
  props: {
    active: { type: String, required: true },
    groupId: { type: String, required: true },
    id: { type: String },
  },
  data() {
    return {
      addSection: false,
      versions: [],
      version: null,
      loading: false,
      form: {
        input: {
          title: '',
          pointPrefix: 'Art.',
        },
        rules: {
          title: [(v) => !!v || 'Podaj nazwę'],
          pointPrefix: [(v) => !!v || 'Podaj prefix'],
        },
      },
    };
  },
  apollo: {
    document: {
      query: SELECT,
      variables() {
        return { id: this.id };
      },
      update(data) {
        const item = data.list.items[0];
        this.form.input.title = item.title || '';
        this.form.input.pointPrefix = item.pointPrefix || '';
        if (!this.version) {
          this.versions = [...item.versions].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
          this.version = this.versions.length ? this.versions[this.versions.length - 1] : null;
        }
        return item;
      },
      error() {
        this.$emit('cancel');
      },
      skip() {
        return !this.id;
      },
    },
  },
  methods: {
    setDefaultVersion() {
      this.version = this.versions.length ? this.versions[this.versions.length - 1] : null;
    },
    addNewVersion(version) {
      this.versions.push(version);
      this.version = version;
    },
    updateVersion(data) {
      Object.assign(this.version, data);
    },
    addNewSection(section) {
      this.version.rows.push(section);
    },
    updateSection(data) {
      const section = this.version.rows.find((x) => x.id === data.id);
      Object.assign(section, data);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.document) {
          await this.update();
        } else {
          await this.create();
        }
        this.loading = false;
      }
    },
    async create() {
      const { data } = await this.$apollo.mutate({
        mutation: CREATE,
        variables: {
          input: {
            groupId: this.groupId,
            ...this.form.input,
          },
        },
      });
      this.$router.push({
        name: 'content-document',
        params: {
          active: this.active,
          groupId: this.groupId,
          id: data.entity.id,
        },
      });
    },
    async update() {
      await this.$apollo.mutate({
        mutation: UPDATE,
        variables: {
          input: {
            entityId: this.document.id,
            ...this.form.input,
          },
        },
      });
    },
  },
};
</script>
