<template>
  <v-row align="stretch">
    <v-col v-for="note in notesSorted" :key="note.id" xs="12" sm="6" md="4" lg="4" xl="3">
      <view-card
        class="full-height"
        :proceeding="proceeding"
        :note="note"
        @update="$emit('update', $event)"
      ></view-card>
    </v-col>
  </v-row>
</template>

<script>
import ViewCard from '@/v2/components/Note/ViewCard.vue';

export default {
  components: {
    ViewCard,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
    notes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    notesSorted() {
      const notes = [...this.notes];
      notes.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
      notes.reverse();
      return notes;
    },
  },
};
</script>
