<template>
  <document-edit v-if="group && group.acl_edit" :active="active" :group-id="groupId" :id="id" @cancel="cancel">
    <template v-slot:back>
      <v-btn outlined="outlined" rounded="rounded" color="secondary" @click="cancel">
        <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">{{ activeGroupName }}</span>
      </v-btn>
    </template>
  </document-edit>
  <document-view v-else-if="group" :id="id">
    <template v-slot:back>
      <v-btn outlined="outlined" rounded="rounded" color="secondary" @click="cancel">
        <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">{{ activeGroupName }}</span>
      </v-btn>
    </template>
  </document-view>
</template>

<script>
import DocumentEdit from '@/v2/components/Content/DocumentEdit.vue';
import DocumentView from '@/v2/components/Content/DocumentView.vue';
import QUERY_GROUP from '@/graphql/content/query/GetOneContentGroupQuery.gql';

export default {
  components: {
    DocumentEdit,
    DocumentView,
  },
  props: {
    active: { type: String, required: true },
    groupId: { type: String, required: true },
    id: { type: String },
  },
  apollo: {
    group: {
      query: QUERY_GROUP,
      variables() {
        return { id: this.groupId };
      },
      update(data) {
        const item = data.list.items[0];
        return item;
      },
      error() {
        this.cancel();
      },
    },
  },
  computed: {
    activeGroupName() {
      switch (this.active) {
        case 'ACTS':
          return 'Ustawy';
        case 'COMMENTS':
          return 'Dodatki';
        case 'CUSTOM':
          return 'Własne treści';
        default:
          return '';
      }
    },
  },
  methods: {
    cancel() {
      this.$router.push({
        name: 'content-list',
        params: { active: this.active },
      });
    },
  },
};
</script>
