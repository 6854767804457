<template>
  <v-card
    class="grey lighten-5 d-flex flex-column flex-nowrap"
    style="position: absolute; right: 0; top: 78px; bottom: 39px; width: 262px; z-index: 2; overflow: auto"
    tile="tile"
  >
    <v-toolbar flat="flat" dense="dense" color="grey lighten-5">
      <v-toolbar-title class="full-width mr-4">
        <v-btn
          class="px-1 grey lighten-3"
          text="text"
          rounded="rounded"
          small="small"
          color="black"
          @click="$emit('back')"
        >
          <v-icon>mdi-arrow-left</v-icon><span class="pl-2 pr-4 black--text">{{ active }}</span>
        </v-btn>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div>
        <v-btn x-small="x-small" depressed="depressed" fab="fab" color="grey lighten-3" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-toolbar>
    <template v-if="document">
      <h5 class="pa-2 text-center line-dense">{{ document.title }}</h5>
      <div class="text-subtitle-2 text--secondary line-dense text-center mb-2">{{ version.title }}</div>
      <search-input class="pt-2 pb-4 px-4" v-model="search"></search-input>
      <v-divider></v-divider>
      <v-list class="pb-4" style="flex-grow: 9999; max-height: 100%; overflow: auto">
        <template v-for="section in sections">
          <v-list-item :key="section.id">
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters="no-gutters" align="center">
                  <v-col
                    ><span class="text-h5 font-weight-bold"
                      >{{ document.pointPrefix }} {{ section.number }}</span
                    ></v-col
                  >
                  <v-col cols="auto">
                    <v-btn
                      class="ml-2 text-body-1 font-weight-medium white--text"
                      depressed="depressed"
                      fab="fab"
                      x-small="x-small"
                      color="grey"
                      @click="insertFirstOccurance(section)"
                      >1+</v-btn
                    >
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      class="ml-2 text-body-1 font-weight-medium white--text"
                      depressed="depressed"
                      fab="fab"
                      x-small="x-small"
                      color="grey darken-1"
                      @click="insertNextOccurances(section)"
                      >N+</v-btn
                    >
                  </v-col>
                </v-row>
              </v-list-item-title>
              <div>
                <v-chip
                  class="font-weight-medium mr-1 mt-1"
                  v-for="(tag, index) in section.tags"
                  :key="index"
                  x-small="x-small"
                  dark="dark"
                  color="grey"
                  >{{ tag }}</v-chip
                >
              </div>
              <div class="my-4" v-html="markdown2html(section.rawContent)"></div>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="section.id" class="last-child--hidden"></v-divider>
        </template>
      </v-list>
    </template>
  </v-card>
</template>

<script>
import stringCompare from '@/v2/helpers/stringCompare';
import markdown2html from '@ckeditor/ckeditor5-markdown-gfm/src/markdown2html/markdown2html';
import htmlToText from '@/v2/helpers/htmlToText';
import SearchInput from '@/v2/components/Form/SearchInput.vue';
import QUERY from '@/graphql/content/query/GetOneContentDocumentQuery.gql';

export default {
  components: {
    SearchInput,
  },
  props: {
    active: {
      type: String,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
    versionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: '',
    };
  },
  apollo: {
    document: {
      query: QUERY,
      variables() {
        return { id: this.documentId };
      },
      update(data) {
        return data.list.items[0];
      },
    },
  },
  computed: {
    version() {
      return this.document.versions.find((v) => v.id === this.versionId);
    },
    sections() {
      const sections = this.version.rows.filter((section) => this.isSectionMatchingSearch(section, this.search));
      return sections.sort((a, b) => stringCompare(a.number, b.number));
    },
  },
  methods: {
    markdown2html,
    isSectionMatchingSearch(section, search) {
      return `${section.number}`.startsWith(search) || section.tags.find((tag) => tag.startsWith(search));
    },
    insertFirstOccurance(section) {
      const content = htmlToText(markdown2html(section.rawContent));
      const text = `${this.document.pointPrefix} ${section.number} ${this.version.firstOccurrence}: ${content}`;
      this.$emit('insert', text);
    },
    insertNextOccurances(section) {
      const content = htmlToText(markdown2html(section.rawContent));
      const text = `${this.document.pointPrefix} ${section.number} ${this.version.nextOccurrences}: ${content}`;
      this.$emit('insert', text);
    },
  },
};
</script>
