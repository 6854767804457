<template>
  <operation-edit
    :account="account"
    :operation="item"
    @update="$emit('update', $event)"
    @delete="$emit('delete', $event)"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-list-item :key="item.id" v-on="on">
        <v-list-item-avatar v-if="item.value.amount > 0" color="primary">
          <v-icon class="white--text">mdi-arrow-right-bold</v-icon>
        </v-list-item-avatar>
        <v-list-item-avatar v-else color="error">
          <v-icon class="white--text">mdi-arrow-left-bold</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{ item.subject }}</v-list-item-title>
          <v-list-item-subtitle
            ><small class="font-weight-bold mr-4">{{ item.date | date }}</small>
            <v-tooltip v-if="item.identifier" top="top">
              <template v-slot:activator="{ on, attrs }"
                ><small class="mr-4 font-weight-bold success--text" v-bind="attrs" v-on="on">{{
                  item.identifier.nameId
                }}</small></template
              ><span>{{ item.identifier.name }}</span> </v-tooltip
            ><small>{{ item.description }}</small>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="text-right">
          <h4 :class="item.value.amount &lt; 0 ? 'error--text' : 'primary--text'">{{ item.value | money }}</h4>
          <small class="text--secondary">Saldo {{ item.balance | money }}</small>
        </v-list-item-action>
      </v-list-item>
    </template>
  </operation-edit>
</template>

<script>
import OperationEdit from '@/v2/components/BankAccount/OperationEdit.vue';

export default {
  components: {
    OperationEdit,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
