<template>
  <FinalizeRegistrationForm :token="token" />
</template>
<script>
import CURRENT_USER from '@/graphql/security/query/CurrentUserQuery.gql';
import FinalizeRegistrationForm from '@/v2/components/User/FinalizeRegistrationForm.vue';

export default {
  components: { FinalizeRegistrationForm },
  computed: {
    loading() {
      return this.$apollo.loading;
    },
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  apollo: {
    user: {
      query: CURRENT_USER,
      update: (data) => data.me,
    },
  },
};
</script>
