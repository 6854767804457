import { date } from '@/plugins/filters';
import { flatten } from '@/helpers/flattenObject';

const generalReplacements = {
  'name': '(Pismo) Nazwa',
  'createdAt': '(Pismo) Data utworzenia',
  'proceeding.signature': '(Postępowanie) Sygnatura',
  'proceeding.signatureAdditional': '(Postępowanie) Sygnatura dodatkowa',
  'proceeding.openedAt': '(Postępowanie) Data otwarcia',
  'proceeding.court.name': '(Sąd) Nazwa',
  'proceeding.court.address.postCode': '(Sąd) Kod pocztowy',
  'proceeding.court.address.city': '(Sąd) Miasto',
  'proceeding.court.address.street': '(Sąd) Ulica i numer',
  'proceeding.judge.title': '(Sędzia) Tytuł',
  'proceeding.judge.firstName': '(Sędzia) Imię',
  'proceeding.judge.lastName': '(Sędzia) Nazwisko',
  'proceeding.advisor.firstName': '(Doradca) Imię',
  'proceeding.advisor.lastName': '(Doradca) Nazwisko',
  'proceeding.advisor.email': '(Doradca) Adres email',
  'debtor.name': '(Dłużnik) Imię',
  'debtor.nameDeclined': '(Dłużnik) Imię - odmienione',
  'debtor.surname': '(Dłużnik) Nazwisko',
  'debtor.surnameDeclined': '(Dłużnik) Nazwisko - odmienione',
  'debtor.email': '(Dłużnik) Adres email',
  'debtor.address.city': '(Dłużnik) Miasto',
  'debtor.address.postCode': '(Dłużnik) Kod pocztowy',
  'debtor.address.street': '(Dłużnik) Ulica i numer domu',
  'recipient.name': '(Odbiorca) Imię i Nazwisko',
  'recipient.address.postCode': '(Odbiorca) Kod pocztowy',
  'recipient.address.city': '(Odbiorca) Miasto',
  'recipient.address.street': '(Odbiorca) Ulica i numer',
  'recipient.address.country': '(Odbiorca) Kraj',
};
export default generalReplacements;

/* eslint-disable no-use-before-define */

export function createDocumentReplacements(document) {
  const flattenDocument = flatten(document);
  const general = createDocumentGeneralReplacements(flattenDocument);
  const attachment = createDocumentAttachmentReplacements(document);
  const entity = createDocumentEntityReplacements(document);
  const task = createDocumentTaskReplacements(document);
  return {
    general,
    other: {
      ...attachment,
      ...entity,
      ...task,
    },
  };
}

function createDocumentGeneralReplacements(flattenDocument) {
  const replacements = {};
  for (const key of Object.keys(generalReplacements)) {
    const value = flattenDocument[key];
    if (value) {
      replacements[key] = value;
    }
  }
  replacements.createdAt = date(replacements.createdAt);
  replacements['proceeding.openedAt'] = date(replacements['proceeding.openedAt']);
  return replacements;
}

function createDocumentAttachmentReplacements(document) {
  const replacements = {};
  for (const i of Object.keys(document?.proceeding?.documents || {})) {
    replacements[`(Dokument #${+i + 1})`] = document.proceeding.documents[i].name;
  }
  return replacements;
}

function createDocumentEntityReplacements(document) {
  const replacements = {};
  for (const i of Object.keys(document?.proceeding?.entities || {})) {
    replacements[`(Strona #${+i + 1})`] = document.proceeding.entities[i].name;
  }
  return replacements;
}

function createDocumentTaskReplacements(document) {
  const replacements = {};
  for (const schedule of document?.proceeding?.schedules || []) {
    const tasks = schedule.tasks.filter((task) => task.due !== null);
    for (const task of tasks) {
      replacements[`(Data) ${task.name}`] = date(task.due);
    }
  }
  return replacements;
}
