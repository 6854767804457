<template>
  <div>
    <slot :list="list"></slot>
    <infinite-loading :identifier="infiniteId" @infinite="loadMoreItems">
      <template v-slot:no-results><span></span></template>
      <template v-slot:no-more><span></span></template>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
  components: {
    InfiniteLoading,
  },
  props: {
    load: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      list: {
        count: 0,
        items: [],
      },
      infiniteId: 0,
    };
  },
  methods: {
    async loadMoreItems(state) {
      const loadInfiniteId = this.infiniteId;
      const list = await this.load(this.list.items.length);
      if (loadInfiniteId === this.infiniteId) {
        if (list.items.length) {
          this.list.count = list.count;
          this.list.items.push(...list.items);
        }
        if (this.list.items.length < this.list.count) {
          state.loaded();
        } else {
          state.complete();
        }
      }
    },
    reset() {
      this.list.count = 0;
      this.list.items = [];
      this.infiniteId += 1;
    },
  },
};
</script>
