<template>
  <layout-main title="Ustawienia konta">
    <div class="max-width-sm" v-if="me">
      <v-card class="mb-8">
        <v-card-subtitle>Nazwa użytkownika</v-card-subtitle>
        <h3 class="pb-4 px-4">{{ me.firstName }} {{ me.lastName }}</h3>
      </v-card>
      <v-card class="mb-8">
        <v-card-subtitle>Adres email</v-card-subtitle>
        <h3 class="pb-4 px-4">{{ me.email }}</h3>
      </v-card>
      <v-card class="mb-8">
        <v-card-subtitle>Zmiana hasła</v-card-subtitle>
        <v-card-text>
          <change-password></change-password>
        </v-card-text>
      </v-card>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import ChangePassword from '@/v2/components/User/Me/ChangePassword.vue';
import ME from '@/graphql/security/query/CurrentUserQuery.gql';

export default {
  components: {
    LayoutMain,
    ChangePassword,
  },
  apollo: {
    me: {
      query: ME,
      update: (data) => data.me,
    },
  },
};
</script>
