<template>
  <layout-main>
    <template v-slot:title>
      <templates-top-navigation></templates-top-navigation>
    </template>
    <v-container fluid="fluid">
      <slot></slot>
    </v-container>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import TemplatesTopNavigation from '@/v2/layouts/components/TemplatesTopNavigation.vue';

export default {
  components: {
    LayoutMain,
    TemplatesTopNavigation,
  },
};
</script>
