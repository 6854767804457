<template>
  <v-app>
    <v-main>
      <v-container class="fill-height flex-column flex-nowrap justify-start" fluid="fluid"
        ><img src="/assets/logo.svg" width="300px" />
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
img
  margin-top: 5%
  margin-bottom: 5%
</style>
