<template>
  <v-list-item class="py-2 px-4" :to="route">
    <v-list-item-avatar color="primary">
      <v-icon dark="dark">mdi-text-long</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <div class="my-1">
        <span class="font-weight-bold mr-2">{{ item.name }}</span>
        <v-chip
          class="font-weight-medium mr-1 mb-1"
          v-for="(tag, index) in item.tags"
          :key="index"
          x-small="x-small"
          dark="dark"
          color="grey"
          >{{ tag }}</v-chip
        >
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    route() {
      return {
        name: 'fragment-edit',
        params: { id: this.item.id },
      };
    },
  },
};
</script>
