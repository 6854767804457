<template>
  <v-expansion-panel>
    <v-expansion-panel-header hide-actions="hide-actions" :ripple="false">
      <div>
        <task-list-item :proceeding="proceeding" :item="item">
          <template v-slot:subtitle v-if="isExpanded"><span></span></template>
          <template v-slot:subtitle v-else>
            <v-list :style="{ marginLeft: '-12px' }" dense="dense">
              <template v-for="document in item.relatedDocuments">
                <v-list-item class="rounded overflow-hidden px-1" :to="documentRoute(document)" :key="document.id">
                  <v-list-item-avatar class="ma-0">
                    <v-icon>mdi-file-document-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>{{ document.name }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
            <div class="pt-2 text-pre-line text--secondary" v-if="item.description">{{ item.description }}</div>
            <info-notes
              class="pt-2"
              v-if="notes.length"
              :proceeding="proceeding"
              :notes="notes"
              @update="$emit('note-update', $event)"
            ></info-notes>
          </template>
        </task-list-item>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="primary lighten-5">
      <edit-task
        v-if="isExpanded"
        :proceeding="proceeding"
        :tasks="tasks"
        :item="item"
        @cancel="$emit('cancel')"
        @update="$emit('update', $event)"
        @update-due="$emit('update-due')"
        @update-status="$emit('update-status', $event)"
      ></edit-task>
    </v-expansion-panel-content>
    <v-divider></v-divider>
  </v-expansion-panel>
</template>

<script>
import TaskListItem from '@/v2/components/Task/ListItem.vue';
import EditTask from '@/v2/components/Task/EditTask.vue';
import InfoNotes from '@/v2/components/Proceeding/InfoNotes.vue';

export default {
  components: {
    TaskListItem,
    EditTask,
    InfoNotes,
  },
  props: {
    proceeding: { type: Object, required: true },
    tasks: {
      type: Array,
      default() {
        return [];
      },
    },
    item: { type: Object, required: true },
    isExpanded: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    notes() {
      return this.proceeding.notes.filter((x) => x.relatedTasks.find((t) => t.id === this.item.id));
    },
  },
  watch: {
    '$route.hash': {
      handler(hash) {
        if (hash === `#${this.item.id}`) {
          setTimeout(() => {
            this.$vuetify.goTo(this.$el);
          }, 300);
        }
      },
    },
  },
  mounted() {
    if (this.$route.hash === `#${this.item.id}`) {
      this.$vuetify.goTo(this.$el);
    }
  },
  methods: {
    documentRoute(document) {
      return {
        name: 'proceeding-document-info',
        params: {
          id: this.proceeding.id,
          docid: document.id,
        },
      };
    },
  },
};
</script>
