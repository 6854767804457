<template>
  <div>
    <v-card class="overflow-hidden">
      <v-card-actions class="pa-4">
        <v-row align="center">
          <v-col>
            <v-btn
              class="px-2"
              :to="{ name: 'proceeding-entities' }"
              outlined="outlined"
              rounded="rounded"
              color="secondary"
            >
              <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Podmioty</span>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              class="px-2"
              :to="{ name: 'proceeding-entity-edit', params: { entityId: entity.id } }"
              depressed="depressed"
              rounded="rounded"
              color="secondary"
            >
              <v-icon>mdi-pencil-circle</v-icon><span class="px-2">Edytuj</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <info-entity
        :proceeding="proceeding"
        :entity="entity"
        @update="$emit('entity-update-related-documents', { entity, documentsForProceeding: $event })"
      ></info-entity>
    </v-card>
    <v-card class="pa-4 mt-12" v-if="claim && claim.sum.amount > 0">
      <info-claim :claim="claim"></info-claim>
      <div class="py-2"></div>
    </v-card>
  </div>
</template>

<script>
import InfoEntity from '@/v2/components/Entity/InfoEntity.vue';
import InfoClaim from '@/v2/components/Entity/InfoClaim.vue';

export default {
  components: {
    InfoEntity,
    InfoClaim,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
    entityId: {
      type: String,
      required: true,
    },
  },
  computed: {
    entity() {
      return this.proceeding.entities.find((x) => x.id === this.entityId);
    },
    claim() {
      return this.proceeding.claims.find((x) => x.creditor.id === this.entity.id);
    },
  },
};
</script>
