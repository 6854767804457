<template>
  <layout-templates>
    <v-card>
      <v-row>
        <v-col>
          <v-card-actions class="px-8">
            <v-responsive max-width="260">
              <search-input v-model="search"></search-input>
            </v-responsive>
          </v-card-actions>
        </v-col>
        <v-col cols="auto">
          <v-card-actions class="px-8">
            <v-btn
              class="px-4"
              :to="{ name: 'template-edit' }"
              rounded="rounded"
              depressed="depressed"
              large="large"
              color="secondary"
            >
              <v-icon>mdi-plus-circle</v-icon><span class="px-2">Utwórz szablon</span>
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
      <div class="pa-4">
        <v-list class="rounded overflow-hidden" v-if="templates">
          <template v-for="item in templatesFiltered">
            <template-list-item :key="item.id" :item="item"></template-list-item>
            <v-divider :key="item.id + 'divider'" class="last-child--hidden"></v-divider>
          </template>
        </v-list>
      </div>
    </v-card>
  </layout-templates>
</template>

<script>
import { filterTemplates } from '@/v2/components/Template/Mixin';
import SearchInput from '@/v2/components/Form/SearchInput.vue';
import LayoutTemplates from '@/v2/layouts/Templates.vue';
import TemplateListItem from '@/v2/components/Template/ListItem.vue';
import TEMPLATES_LIST_QUERY from '@/graphql/documentTemplate/query/DocumentTemplatesListQuery.gql';

export default {
  components: {
    SearchInput,
    LayoutTemplates,
    TemplateListItem,
  },
  data() {
    return {
      search: '',
    };
  },
  apollo: {
    templates: {
      query: TEMPLATES_LIST_QUERY,
      update: (data) => {
        return data.list.items;
      },
    },
  },
  computed: {
    templatesFiltered() {
      const templates = this.templates || [];
      return filterTemplates(templates, this.search);
    },
  },
};
</script>
