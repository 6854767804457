<template>
  <v-row class="pb-4">
    <v-col v-for="ver in versions" :key="ver.id" sm="6" md="4" lg="3">
      <v-btn
        rounded="rounded"
        block="block"
        x-large="x-large"
        color="primary"
        :outlined="ver !== version"
        :depressed="ver === version"
        @click="$emit('select', ver)"
        >{{ ver.title }}</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    versions: { type: Array, required: true },
    version: { type: Object, default: null },
  },
};
</script>
