// eslint-disable-next-line max-classes-per-file
import { jsPDF } from 'jspdf';
import './roboto-normal';
import { formatFileName, writeText } from '@/helpers/pdf/WriteTextHelper';

class BasePrinter {
  constructor(type, envelopes) {
    this.type = type;
    this.envelopes = envelopes;
    this.isFirstPage = true;
  }

  prepareEnvelopes() {
    const data = [];

    for (const envelope of this.envelopes) {
      const row = { recipient: null, sender: null };
      if (this.type === 'recipient' || this.type === 'both') {
        row.recipient = envelope.recipient;
      }
      if (this.type === 'sender' || this.type === 'both') {
        row.sender = envelope.sender;
      }
      data.push(row);
    }

    return data;
  }

  writeEnvelope(envelope) {
    if (!this.isFirstPage) {
      this.document.addPage(this.document.internal.pageSize);
    }
    this.isFirstPage = false;
    this.document.setTextColor(0, 0, 0);
    this.document.setFont('roboto', 'normal');

    function extractAddress(label) {
      return (
        `${label.name || ''}\n` +
        `${label.address.street || ''}\n` +
        `${label.address.postCode || ''} ${label.address.city || ''}\n` +
        `${label.address.country || ''}`
      );
    }

    if (envelope.sender) {
      const senderText = extractAddress(envelope.sender);
      writeText(
        this.document,
        senderText,
        this.senderBeginX,
        this.senderBeginY,
        this.slotWidthSender,
        5,
        this.fontSizeSender,
      );
    }

    if (envelope.recipient) {
      const recipientText = extractAddress(envelope.recipient);

      writeText(
        this.document,
        recipientText,
        this.recipientBeginX,
        this.recipientBeginY,
        this.slotWidthRecipient,
        5,
        this.fontSizeRecipient,
      );
    }
  }

  printEnvelopes() {
    // this.debugSlots();
    const data = this.prepareEnvelopes();
    for (const envelope of data) {
      this.writeEnvelope(envelope);
    }
  }

  outputPdf(outputType) {
    return this.document.output(outputType);
  }

  savePdf() {
    this.document.save(formatFileName('envelopes'));
  }

  debugSlots() {
    this.document.setDrawColor(240, 240, 240);
    for (let cols = 0; cols < this.document.internal.pageSize.getWidth() / 10; cols += 1) {
      for (let rows = 0; rows < this.document.internal.pageSize.getHeight() / 10; rows += 1) {
        this.document.line(cols * 10, 0, cols * 10, this.document.internal.pageSize.getHeight());
        this.document.line(0, rows * 10, this.document.internal.pageSize.getWidth(), rows * 10);
      }
    }

    this.document.setDrawColor(0, 240, 0);
    this.document.setTextColor(240, 240, 240);

    this.document.rect(this.senderBeginX, this.senderBeginY, this.slotWidthSender, this.slotHeightSender);
    this.document.rect(this.recipientBeginX, this.recipientBeginY, this.slotWidthRecipient, this.slotHeightRecipient);
  }
}

class PrinterC4 extends BasePrinter {
  constructor(type, envelopes) {
    super(type, envelopes);
    // eslint-disable-next-line new-cap
    this.document = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'C4',
    });

    this.senderBeginX = 10;
    this.senderBeginY = 10;
    this.slotWidthSender = 100;
    this.slotHeightSender = 50;

    this.recipientBeginX = 180;
    this.recipientBeginY = 140;
    this.slotWidthRecipient = 130;
    this.slotHeightRecipient = 80;
    this.fontSizeRecipient = 15;
    this.fontSizeSender = 10;
  }
}

class PrinterC5 extends BasePrinter {
  constructor(type, envelopes) {
    super(type, envelopes);
    // eslint-disable-next-line new-cap
    this.document = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'C5',
    });

    this.senderBeginX = 10;
    this.senderBeginY = 10;
    this.slotWidthSender = 90;
    this.slotHeightSender = 40;

    this.recipientBeginX = 120;
    this.recipientBeginY = 80;
    this.slotWidthRecipient = 100;
    this.slotHeightRecipient = 50;
    this.fontSizeRecipient = 15;
    this.fontSizeSender = 8;
  }
}

class PrinterC6 extends BasePrinter {
  constructor(type, envelopes) {
    super(type, envelopes);
    // eslint-disable-next-line new-cap
    this.document = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'C6',
    });

    this.fontSizeSender = 7;
    this.senderBeginX = 10;
    this.senderBeginY = 10;
    this.slotWidthSender = 60;
    this.slotHeightSender = 40;

    this.fontSizeRecipient = 10;
    this.recipientBeginX = 90;
    this.recipientBeginY = 60;
    this.slotWidthRecipient = 60;
    this.slotHeightRecipient = 50;
  }
}

class PrinterDL extends BasePrinter {
  constructor(type, envelopes) {
    super(type, envelopes);
    // eslint-disable-next-line new-cap
    this.document = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'DL',
    });

    this.senderBeginX = 10;
    this.senderBeginY = 10;
    this.slotWidthSender = 80;
    this.slotHeightSender = 30;
    this.fontSizeSender = 8;

    this.recipientBeginX = 130;
    this.recipientBeginY = 60;
    this.slotWidthRecipient = 80;
    this.slotHeightRecipient = 40;
    this.fontSizeRecipient = 10;
  }
}

// eslint-disable-next-line import/prefer-default-export
export class EnvelopePrinter {
  constructor(envelopes, size, type) {
    this.envelopes = envelopes;
    this.size = size;
    this.type = type;
  }

  // @see https://spinbits.atlassian.net/jira/software/projects/NGAP/boards/15/backlog?assignee=5f7456eb837bb80068157c44&label=QUESTION&selectedIssue=NGAP-201
  printEnvelopes(outputType) {
    // eslint-disable-next-line new-cap
    let printer = null;
    switch (this.size) {
      default:
      case 'C4':
        // eslint-disable-next-line new-cap

        printer = new PrinterC4(this.type, this.envelopes);

        break;
      case 'C5':
        printer = new PrinterC5(this.type, this.envelopes);

        break;
      case 'C6':
        printer = new PrinterC6(this.type, this.envelopes);

        break;
      case 'DL':
        printer = new PrinterDL(this.type, this.envelopes);

        break;
    }

    printer.printEnvelopes();
    if (outputType) {
      return printer.outputPdf(outputType);
    }
    return printer.savePdf();
  }
}
