<template>
  <file-input
    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
    @input="input"
  >
    <template v-slot:default="{ open }">
      <v-tooltip bottom="bottom" content-class="tooltip--card max-width-sm">
        <template v-slot:activator="{ on }">
          <v-btn
            outlined="outlined"
            fab="fab"
            small="small"
            color="secondary"
            :loading="loading"
            v-on="on"
            @click="open"
          >
            <v-icon>mdi-tray-arrow-up</v-icon>
          </v-btn>
        </template>
        <v-card class="pa-4 grey lighten-4">
          <h2>Import transakcji z pliku Excel (.xlsx)</h2>
          <div class="mt-4 mb-2 text-subtitle-1 text--secondary">Prawidłowy format danych:</div>
          <v-simple-table>
            <thead>
              <tr>
                <th>Data</th>
                <th>Odbiorca/Nadawca</th>
                <th class="text-right">Kwota</th>
                <th>Opis</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2021-12-14</td>
                <td>Jan Kowalski</td>
                <td class="text-right">123,45</td>
                <td>Płatność za znaczki</td>
              </tr>
              <tr>
                <td>2021-12-27</td>
                <td>Tomasz Nowak</td>
                <td class="text-right">-67,89</td>
                <td>Zwrot zaliczki</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-tooltip>
    </template>
  </file-input>
</template>

<script>
import FileInput from '@/v2/components/Form/FileInput.vue';
import ADD_OPERATION_MUTATION from '@/graphql/account/mutation/ImportOperationsStatementMutation.gql';

export default {
  components: {
    FileInput,
  },
  props: {
    account: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async input(files) {
      this.loading = true;
      const { data } = await this.$apollo.mutate({
        mutation: ADD_OPERATION_MUTATION,
        variables: {
          input: {
            accountId: this.account.id,
            statement: files[0],
          },
        },
      });
      this.$emit('update', data.account.operations);
      this.loading = false;
    },
  },
};
</script>
