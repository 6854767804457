<template>
  <v-btn
    class="full-width"
    v-if="document.basicProceedingDocument"
    depressed="depressed"
    rounded="rounded"
    color="secondary"
    :loading="loading"
    @click="change"
    >Usuń z głównych</v-btn
  >
  <v-btn
    class="full-width"
    v-else
    outlined="outlined"
    rounded="rounded"
    color="secondary"
    :loading="loading"
    @click="change"
    >Dodaj do głównych</v-btn
  >
</template>

<script>
import MUTATION from '@/graphql/document/mutation/SetBasicProceedingDocumentMutation.gql';

export default {
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async change() {
      this.loading = true;
      const { data } = await this.$apollo.mutate({
        mutation: MUTATION,
        variables: {
          documentId: this.document.id,
          basicProceedingDocument: !this.document.basicProceedingDocument,
        },
      });
      this.$emit('document-update', data.setBasicProceedingDocument);
      this.loading = false;
    },
  },
};
</script>
