<template>
  <v-card>
    <v-card-subtitle>
      <div class="text-subtitle-1 font-weight-medium">Sąd</div>
    </v-card-subtitle>
    <div class="px-4 pb-4">
      <v-row>
        <v-col>
          <h3>{{ proceeding.court.name }}</h3>
        </v-col>
        <v-col v-if="proceeding.court.contactDetails" cols="auto">
          <v-tooltip bottom="bottom" content-class="tooltip--card max-width-sm">
            <template v-slot:activator="{ on }">
              <v-icon class="secondary--text" v-on="on">mdi-information</v-icon>
            </template>
            <v-card class="pa-4 grey lighten-4" v-html="markdown2html(proceeding.court.contactDetails)"></v-card>
          </v-tooltip>
        </v-col>
      </v-row>
      <div class="text-small pt-6 text--secondary">Adres:</div>
      <div class="text-subtitle-1 font-weight-medium">{{ proceeding.court.address.street }}</div>
      <div class="text-subtitle-1 font-weight-medium">
        {{ proceeding.court.address.postCode }} {{ proceeding.court.address.city }}
      </div>
      <div class="text-small pt-6 text--secondary">Sędzia:</div>
      <v-row>
        <v-col>
          <div class="text-subtitle-1 font-weight-medium">
            {{ proceeding.judge.title }} {{ proceeding.judge.firstName }} {{ proceeding.judge.lastName }}
          </div>
        </v-col>
        <v-col v-if="proceeding.judge.contactDetails" cols="auto">
          <v-tooltip bottom="bottom" content-class="tooltip--card max-width-sm">
            <template v-slot:activator="{ on }">
              <v-icon class="secondary--text" v-on="on">mdi-information</v-icon>
            </template>
            <v-card class="pa-4 grey lighten-4" v-html="markdown2html(proceeding.judge.contactDetails)"></v-card>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import markdown2html from '@ckeditor/ckeditor5-markdown-gfm/src/markdown2html/markdown2html';

export default {
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
  },
  methods: {
    markdown2html,
  },
};
</script>
