<template>
  <div>
    <content-version
      v-if="version"
      :active="activeName"
      :document-id="document.id"
      :version-id="version.id"
      @insert="$emit('insert', $event)"
      @close="$emit('close')"
      @back="setDocumentVersion(null, null)"
    ></content-version>
    <v-card
      v-else
      tile
      class="grey lighten-5 d-flex flex-column flex-nowrap"
      tile-
      style="position: absolute; right: 0; top: 78px; bottom: 39px; width: 262px; z-index: 2; overflow: auto"
    >
      <v-toolbar flat dense color="grey lighten-5">
        <v-toolbar-title class="full-width mr-4">
          <v-menu class="full-width mr-4" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="px-0" plain block color="black" v-bind="attrs" v-on="on">
                <span style="color: black !important; font-size: 1.25rem; font-weight: normal">{{ activeName }}</span>
                <v-icon>mdi-menu-down</v-icon>
                <v-spacer></v-spacer>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="setActive('ACTS')">
                <v-list-item-title>Ustawy</v-list-item-title>
              </v-list-item>
              <v-list-item @click="setActive('COMMENTS')">
                <v-list-item-title>Dodatki</v-list-item-title>
              </v-list-item>
              <v-list-item @click="setActive('CUSTOM')">
                <v-list-item-title>Własne</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div>
          <v-btn x-small depressed fab color="grey lighten-3" @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-toolbar>
      <search-input class="pt-2 pb-4 px-4" v-model="search"></search-input>
      <v-divider></v-divider>
      <v-list class="pb-4" style="flex-grow: 9999; max-height: 100%; overflow: auto">
        <template v-for="(item, key) in items">
          <v-list-item :key="key">
            <v-list-item-content>
              <div class="text-subtitle-2 line-dense mb-1">
                <strong>{{ item.title }}</strong>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="mx-2 px-2 py-1 grey--text text--darken-3 rounded overflow-hidden"
            style="min-height: auto"
            v-for="ver in item.versions"
            :key="ver.id"
            @click="setDocumentVersion(item, ver)"
          >
            <v-list-item-content class="py-0">
              <v-list-item-title>{{ ver.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon class="my-0 ml-2">
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider class="pt-4 last-child--hidden" :key="`${key}-divider`"></v-divider>
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import SearchInput from '@/v2/components/Form/SearchInput.vue';
import ContentVersion from '@/v2/components/Editor/ContentVersion.vue';
import stringCompare from '@/v2/helpers/stringCompare';
import QUERY from '@/graphql/content/query/ListContentGroupsQuery.gql';

export default {
  components: {
    SearchInput,
    ContentVersion,
  },
  data() {
    return {
      active: 'ACTS',
      document: null,
      version: null,
      search: '',
    };
  },
  computed: {
    acts() {
      const acts = this.groups && this.groups.find((group) => group.locked && group.name === 'Ustawy');
      return acts;
    },
    comments() {
      const comments = this.groups && this.groups.find((group) => group.locked && group.name === 'Komentarze');
      return comments;
    },
    custom() {
      const groups = (this.groups && this.groups.filter((group) => !group.locked)) || [];
      return groups.length ? groups[0] : null;
    },
    group() {
      switch (this.active) {
        case 'ACTS':
          return this.acts;
        case 'COMMENTS':
          return this.comments;
        case 'CUSTOM':
          return this.custom;
        default:
          return null;
      }
    },
    documents() {
      return this.group ? this.group.documents : [];
    },
    items() {
      const items = this.documents.filter((item) => item.title.toLowerCase().includes(this.search.toLowerCase()));
      return items.sort((a, b) => stringCompare(a.title, b.title));
    },
    activeName() {
      switch (this.active) {
        case 'ACTS':
          return 'Ustawy';
        case 'COMMENTS':
          return 'Dodatki';
        case 'CUSTOM':
          return 'Własne';
        default:
          return '';
      }
    },
  },
  apollo: {
    groups: {
      query: QUERY,
      update: (data) => {
        return data.list.items;
      },
    },
  },
  methods: {
    setActive(active) {
      this.active = active;
      this.setDocumentVersion(null, null);
      this.search = '';
    },
    setDocumentVersion(document, version) {
      this.document = document;
      this.version = version;
    },
  },
};
</script>
