export const isRequiredValidator = (value) => {
  if (value) {
    return true;
  }
  return 'To Pole jest wymagane';
};
export const isNumber = (value) => {
  if (Number.isNaN(value) || typeof value !== 'number') {
    return 'To pole musi być liczbą';
  }
  return true;
};
export const isBoolean = (value) => {
  if (typeof value !== 'boolean') {
    return 'To Pole jest wymagane';
  }
  return true;
};
export const emailValidator = (value) => /.+@.+\..+/.test(value) || 'Podaj prawidłowy adres e-mail';
export const emptyOrEmailValidator = (value) => {
  if (!value) {
    return true;
  }
  // eslint-disable-next-line
  return emailValidator(value);
};
export const noteTitleLengthValidator = (value) =>
  /^.{1,50}$/.test(value) || 'Wystąpił błąd: dozwolona ilość znaków to: 50 lub mniej znaków';
export const noteContentLengthValidator = (value) =>
  /^.{1,500}$/.test(value) || 'Wystąpił błąd: dozwolona ilość znaków to: 500 lub mniej znaków';
export const testValidator = () => 'To jest testowy walidator który zawsze zwraca błąd.';

export const translateServerSideCode = (code) => {
  const SERVER_SIDE_ERROR_CODES = {
    SECURITY_INVALID_USERNAME: 'Nieprawidłowy login',
    SECURITY_INVALID_PASSWORD: 'Nieprawidłowe hasło',
    CONSTRAINT_NOT_EMAIL: 'Nieprawidłowy email',
    // GENERIC: 'Nieprawidłowa wartość',
  };

  return SERVER_SIDE_ERROR_CODES[code] || null;
};
