<template>
  <v-list-item class="py-2 px-4" @click="edit">
    <v-list-item-avatar color="primary">
      <v-icon dark="dark">mdi-school-outline</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-row class="align-center">
        <v-col>
          <v-list-item-title class="my-1"
            ><span class="text--secondary mr-2" v-if="item.title">{{ item.title }}</span
            ><span class="font-weight-medium">{{ item.firstName }}</span
            ><span class="font-weight-bold mx-2">{{ item.lastName }}</span></v-list-item-title
          >
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    courtId: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    edit() {
      this.$router.push({
        name: 'judge-edit',
        params: {
          courtId: this.courtId,
          id: this.item.id,
        },
      });
    },
  },
};
</script>
