<template>
  <span>
    <v-row>
      <v-col>
        <VTextField outlined v-model="myInternalValue" type="password" :rules="[passwordRequired]" label="Nowe hasło" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <VTextField outlined type="password" :rules="[passwordsMatch]" label="Powtórz hasło" v-model="passwordRepeat" />
      </v-col>
    </v-row>
  </span>
</template>

<script>
import { VInput, VTextField } from 'vuetify/lib';

export default {
  name: 'PasswordRepeatField',
  extends: VInput,
  props: {
    value: String,
    rules: Array,
  },
  components: {
    VTextField,
  },
  data() {
    return {
      passwordRepeat: null,
    };
  },
  computed: {
    passwordRequired() {
      if (!this.myInternalValue) {
        return 'To pole jest wymagane';
      }
      return true;
    },
    passwordsMatch() {
      if (!this.myInternalValue || this.myInternalValue !== this.passwordRepeat) {
        return 'Wprowadzone hasła się różnią';
      }
      return true;
    },
    myInternalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (this.passwordsMatch !== true) {
          this.$emit('input', null);
        }
        this.$emit('input', newValue);
      },
    },
  },
};
</script>
