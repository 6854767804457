<template>
  <v-card class="overflow-hidden">
    <v-card-actions class="pa-4">
      <v-row align="center">
        <v-col>
          <h2 class="secondary--text">Dokumenty</h2>
        </v-col>
        <v-col cols="auto">
          <file-input accept="application/pdf,.pdf" @input="attach">
            <template v-slot:default="{ open }">
              <v-btn class="px-2" outlined="outlined" rounded="rounded" color="secondary" @click="open">
                <v-icon>mdi-attachment</v-icon><span class="px-2">Dokument</span>
              </v-btn>
            </template>
          </file-input>
        </v-col>
        <v-col cols="auto">
          <select-list-item :items="templates || []" :items-search-fn="templateSearch" @select="write">
            <template v-slot:activator="{ attrs, on }">
              <v-btn class="px-2" depressed="depressed" rounded="rounded" color="secondary" v-bind="attrs" v-on="on">
                <v-icon>mdi-pencil-circle</v-icon><span class="px-2">Pismo</span>
              </v-btn>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <div class="my-1">
                  <span class="font-weight-bold mr-2">{{ item.name }}</span>
                  <v-chip
                    class="font-weight-medium mr-1 mb-1"
                    v-for="(tag, index) in item.tags"
                    :key="index"
                    x-small="x-small"
                    dark="dark"
                    color="grey"
                    >{{ tag }}</v-chip
                  >
                </div>
              </v-list-item-content>
            </template>
          </select-list-item>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-list>
      <template v-for="document in documents">
        <document-list-item :key="document.id" :document="document"></document-list-item>
        <v-divider :key="document.id" class="last-child--hidden"></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import stringCompare from '@/v2/helpers/stringCompare';
import { filterTemplates } from '@/v2/components/Template/Mixin';
import SelectListItem from '@/v2/components/SelectListItem.vue';
import FileInput from '@/v2/components/Form/FileInput.vue';
import DocumentListItem from '@/v2/components/Document/ListItem.vue';
import TEMPLATES_LIST_QUERY from '@/graphql/documentTemplate/query/DocumentTemplatesListQuery.gql';

export default {
  components: {
    SelectListItem,
    FileInput,
    DocumentListItem,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    templates: {
      query: TEMPLATES_LIST_QUERY,
      update: (data) => {
        return data.list.items;
      },
    },
  },
  computed: {
    documents() {
      const documents = [...this.proceeding.documents];
      documents.sort((a, b) => stringCompare(a.receivedAt, b.receivedAt));
      return documents;
    },
  },
  methods: {
    templateSearch(search) {
      const templates = this.templates || [];
      return filterTemplates(templates, search);
    },
    write(template) {
      this.$emit('document-write-template', template);
      this.$router.push({
        name: 'proceeding-document-write',
        params: { id: this.proceeding.id },
      });
    },
    attach(files) {
      this.$emit('document-attach-file', files[0]);
      this.$router.push({
        name: 'proceeding-document-attach',
        params: { id: this.proceeding.id },
      });
    },
  },
};
</script>
