<template>
  <v-form ref="form">
    <div class="pa-4">
      <v-row class="mb-8" no-gutters="no-gutters">
        <v-col cols="auto">
          <v-btn
            class="px-0"
            :style="{ marginLeft: '-2px' }"
            text="text"
            icon="icon"
            large="large"
            color="grey"
            @click="form.input.task.milestone = !form.input.task.milestone"
          >
            <v-avatar color="grey" size="40">
              <v-icon v-if="form.input.task.milestone" dark="dark" color="white">mdi-circle</v-icon>
              <v-icon v-else dark="dark" x-large="x-large" color="white">mdi-circle-small</v-icon>
            </v-avatar>
          </v-btn>
        </v-col>
        <v-col cols="auto" align-self="center">
          <div class="ml-3 text--secondary">Krok milowy</div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn
            class="ml-4"
            v-if="!loading"
            outlined="outlined"
            rounded="rounded"
            color="secondary"
            @click="$emit('cancel')"
            >Anuluj</v-btn
          >
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="ml-4 px-2"
            depressed="depressed"
            rounded="rounded"
            color="secondary"
            :loading="loading"
            @click="submit"
          >
            <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-text-field
        outlined="outlined"
        label="Nazwa zadania"
        :rules="form.rules.task.name"
        v-model="form.input.task.name"
      ></v-text-field>
      <v-select
        v-if="prevItems.length"
        outlined="outlined"
        clearable="clearable"
        :menu-props="{ offsetY: true }"
        label="Zadanie nadrzędne"
        item-text="name"
        item-value="id"
        :items="prevItems"
        v-model="form.input.parent.parentTaskTemplateId"
      ></v-select>
      <v-row align="center" no-gutters="no-gutters">
        <v-col cols="7" md="6" lg="5">
          <date-interval v-model="form.input.task.interval"></date-interval>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <select-list-item :items="documentTemplates || []" @select="documentTemplateAdd">
            <template v-slot:activator="{ attrs, on }">
              <v-btn class="px-1" outlined="outlined" x-large="x-large" color="grey" v-on="on"
                ><span class="mx-2 grey--text text--darken-1">Szablony pism</span>
                <v-icon class="grey--text text--darken-1" large="large">mdi-plus-circle</v-icon>
              </v-btn>
            </template>
          </select-list-item>
        </v-col>
      </v-row>
    </div>
    <v-list class="mt-2 grey lighten-4" v-if="form.input.documentTemplates.length">
      <template v-for="template in form.input.documentTemplates">
        <v-divider :key="template.id"></v-divider>
        <v-list-item :key="template.id">
          <v-list-item-avatar>
            <v-icon>mdi-text-box-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ template.name }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="pr-2">
            <v-btn outlined="outlined" small="small" icon="icon" color="grey" @click="documentTemplateRemove(template)">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </v-form>
</template>

<script>
import DateInterval from '@/v2/components/Form/DateInterval.vue';
import SelectListItem from '@/v2/components/SelectListItem.vue';
import QUERY_DOCUMENT_TEMPLATES from '@/graphql/documentTemplate/query/DocumentTemplatesListForFormQuery.gql';
import MUTATION_ADD from '@/graphql/scheduleTemplate/mutation/AddTaskTemplateMutation.gql';
import MUTATION_SAVE from '@/graphql/scheduleTemplate/mutation/EditTaskTemplateMutation.gql';
import MUTATION_SET_PARENT from '@/graphql/scheduleTemplate/mutation/SetTaskTemplateParentMutation.gql';
import MUTATION_SET_DOCUMENT_TEMPLATES from '@/graphql/scheduleTemplate/mutation/SetTaskTemplateRelatedDocumentTemplatesMutation.gql';

export default {
  components: {
    DateInterval,
    SelectListItem,
  },
  props: {
    schedule: { type: Object, required: true },
    item: { type: Object },
  },
  data() {
    return {
      loading: false,
      form: {
        input: {
          task: {
            name: '',
            fullDay: true,
            interval: 'P0D',
            milestone: false,
          },
          parent: {
            parentTaskTemplateId: null,
          },
          documentTemplates: [],
        },
        rules: {
          task: {
            name: [(v) => !!v || 'Podaj nazwę'],
          },
        },
      },
    };
  },
  apollo: {
    documentTemplates: {
      query: QUERY_DOCUMENT_TEMPLATES,
      update(data) {
        return data.list.items;
      },
    },
  },
  computed: {
    prevItems() {
      const tasks = this.schedule.taskTemplates;
      const itemIndex = tasks.indexOf(this.item);
      return itemIndex === -1 ? tasks : tasks.slice(0, itemIndex);
    },
  },
  created() {
    if (this.item) {
      this.form.input.task.name = this.item.name || '';
      this.form.input.task.interval = this.item.interval || 'P0D';
      this.form.input.task.milestone = this.item.milestone || false;
      this.form.input.parent.parentTaskTemplateId =
        (this.item.parentTaskTemplate && this.item.parentTaskTemplate.id) || null;
      this.form.input.documentTemplates = [...this.item.documentTemplates];
    }
  },
  methods: {
    documentTemplateAdd(template) {
      const index = this.form.input.documentTemplates.indexOf(template);
      if (index === -1) {
        this.form.input.documentTemplates.push(template);
      }
    },
    documentTemplateRemove(template) {
      const index = this.form.input.documentTemplates.indexOf(template);
      this.form.input.documentTemplates.splice(index, 1);
    },
    async submit() {
      this.loading = true;
      if (this.item) {
        await this.save();
        await this.setParentTask(this.item);
        await this.setDocumentTemplates(this.item);
      } else {
        const item = await this.add();
        await this.setParentTask(item);
        await this.setDocumentTemplates(item);
        this.$emit('add', item);
      }
      this.loading = false;
      this.$emit('cancel');
    },
    async add() {
      const { data } = await this.$apollo.mutate({
        mutation: MUTATION_ADD,
        variables: {
          input: {
            scheduleTemplateId: this.schedule.id,
            ...this.form.input.task,
          },
        },
      });
      return data.taskTemplate;
    },
    async save() {
      await this.$apollo.mutate({
        mutation: MUTATION_SAVE,
        variables: {
          input: {
            taskTemplateId: this.item.id,
            ...this.form.input.task,
          },
        },
      });
    },
    async setParentTask(item) {
      await this.$apollo.mutate({
        mutation: MUTATION_SET_PARENT,
        variables: {
          input: {
            taskTemplateId: item.id,
            ...this.form.input.parent,
          },
        },
      });
    },
    async setDocumentTemplates(item) {
      await this.$apollo.mutate({
        mutation: MUTATION_SET_DOCUMENT_TEMPLATES,
        variables: {
          input: {
            taskTemplateId: item.id,
            documentTemplateIds: [...this.form.input.documentTemplates.map((x) => x.id)],
          },
        },
      });
    },
  },
};
</script>
