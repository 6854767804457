<template>
  <v-card class="overflow-hidden">
    <v-card-actions class="pa-4">
      <v-row align="center">
        <v-col cols="auto">
          <v-btn class="px-2" outlined="outlined" rounded="rounded" color="secondary" @click="cancel">
            <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Powrót</span>
          </v-btn>
        </v-col>
        <v-col>
          <h4 class="text-center">{{ document.name }}</h4>
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="px-2"
            depressed="depressed"
            rounded="rounded"
            color="secondary"
            :loading="loading"
            @click="save"
          >
            <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <div class="px-4 pt-12">
      <edit-attachment
        class="max-width-md"
        v-if="document.__typename === 'ExternalDocument'"
        ref="form"
        :proceeding="proceeding"
        :document="document"
        @input="input"
      ></edit-attachment>
      <edit-writing
        class="max-width-md"
        v-else
        ref="form"
        :proceeding="proceeding"
        :document="document"
        @input="input"
      ></edit-writing>
    </div>
  </v-card>
</template>

<script>
import { documentRoute } from '@/v2/components/Document/Mixin';
import EditAttachment from '@/v2/components/Document/EditAttachment.vue';
import EditWriting from '@/v2/components/Document/EditWriting.vue';
import EDIT_EXTERNAL_DOCUMENT from '@/graphql/document/mutation/EditExternalDocumentMutation.gql';
import EDIT_INTERNAL_DOCUMENT from '@/graphql/document/mutation/EditInternalDocumentMutation.gql';

export default {
  components: {
    EditAttachment,
    EditWriting,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
    docid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        input: {},
      },
    };
  },
  computed: {
    document() {
      return this.proceeding.documents.find((x) => x.id === this.docid);
    },
  },
  methods: {
    input(input) {
      Object.assign(this.form.input, input);
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.document.__typename === 'ExternalDocument') {
          const { data } = await this.$apollo.mutate({
            mutation: EDIT_EXTERNAL_DOCUMENT,
            variables: {
              input: {
                externalDocumentId: this.document.id,
                ...this.form.input.edit,
              },
            },
          });
          this.$emit('document-update', data.document);
        } else {
          const { data } = await this.$apollo.mutate({
            mutation: EDIT_INTERNAL_DOCUMENT,
            variables: {
              input: {
                documentId: this.document.id,
                ...this.form.input.edit,
              },
            },
          });
          this.$emit('document-update', data.document);
        }
        this.loading = false;
      }
    },
    cancel() {
      this.$router.push(documentRoute(this.proceeding, this.document));
    },
  },
};
</script>
