<template>
  <v-card class="max-width-sm">
    <v-card-title>
      <h3 class="full-width text-center pt-4">Ustaw hasło logowania</h3>
    </v-card-title>
    <v-card-text>
      <AbstractForm
        ref="form"
        class="pt-8"
        :form-specification="formSpecification"
        :mutation="MUTATION"
        :mutation-error-mapper="(data) => (data.data.auth.errors ? data.data.auth.errors : [])"
        :mutation-variables="
          (data) => {
            data.token = token;
            return { input: data };
          }
        "
        @updateStore="onDone"
      />
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col class="pb-10 px-10">
          <v-btn class="full-width" x-large depressed color="primary" @click="send()" :loading="loading">Zapisz</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { isRequiredValidator } from '@/helpers/validators';
import { onLogin } from '@/plugins/apollo';
import AbstractForm from '@/components/Form/AbstractForm.vue';
import CURRENT_USER from '@/graphql/security/query/CurrentUserQuery.gql';
import MUTATION from '@/graphql/security/mutation/FinalizeRegistrationMutation.gql';
import PasswordRepeatField from '@/components/FormFields/Security/PasswordRepeatField.vue';

export default {
  name: 'FinalizeRegistrationForm',
  components: { AbstractForm },
  data() {
    return {
      loading: false,
      MUTATION,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: PasswordRepeatField,
                model: { out: 'password' },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
    };
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  methods: {
    async onDone({ serverData: { data, errors } }) {
      if (typeof errors !== 'undefined') {
        // TODO handle server error here ? Or in AbstractForm ?
        return;
      }
      if (typeof data.auth.errors !== 'undefined') {
        console.info('Not handling form, form validation error occurred');
        // form had some errors, ignore. AbstractForm is handling those errors
        return;
      }
      const apolloClient = this.$apollo.provider.defaultClient;
      // store token in local storage for later use
      await onLogin(apolloClient, data.auth.token.value, data.auth.refreshToken.value);
      apolloClient.writeQuery({
        query: CURRENT_USER,
        data: {
          user: data.auth.user,
        },
      });
      this.$router.push({ name: 'home' });
    },
    send() {
      this.$refs.form.mutate();
    },
  },
};
</script>
