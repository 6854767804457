<template>
  <dialog-fullscreen ref="dialog">
    <template v-slot:activator="{ attrs, on }">
      <slot name="activator" v-bind="{ attrs, on }"></slot>
    </template>
    <template v-slot:title>
      <h3><span v-if="operation">Edycja transakcji</span><span v-else>Nowa transakcja</span></h3>
    </template>
    <div class="pa-8 max-width-sm">
      <v-form ref="form">
        <v-row>
          <v-col cols="12">
            <v-select
              outlined="outlined"
              :menu-props="{ offsetY: true }"
              :items="form.types"
              item-value="value"
              item-text="text"
              label="Rodzaj płatności"
              :rules="form.rules.type"
              v-model="form.input.type"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined="outlined"
              :label="form.input.type === -1 ? 'Odbiorca płatności' : 'Nadawca płatności'"
              :rules="form.rules.subject"
              v-model="form.input.subject"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              outlined="outlined"
              clearable="clearable"
              :items="identifiers"
              item-value="id"
              item-text="name"
              label="Identyfikator płatności"
              v-model="form.input.identifier"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined="outlined"
              label="Opis"
              :rules="form.rules.description"
              v-model="form.input.description"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <date-input
              outlined="outlined"
              label="Data"
              :rules="form.rules.date"
              v-model="form.input.date"
            ></date-input>
          </v-col>
          <v-col cols="6">
            <vuetify-money
              outlined="outlined"
              persistent-placeholder="persistent-placeholder"
              label="Kwota"
              placeholder="0.00"
              :options="{ locale: 'pl-PL', suffix: 'PLN', precision: 2 }"
              :rules="form.rules.amount"
              v-model="form.input.amount"
            ></vuetify-money>
          </v-col>
          <v-col class="align-self-center">
            <v-btn
              class="px-2-"
              v-if="operation"
              outlined="outlined"
              large="large"
              rounded="rounded"
              color="error"
              :disabled="saving"
              :loading="deleting"
              @click="remove"
            >
              <v-icon>mdi-trash-can-outline</v-icon><span class="px-2">Usuń</span>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              class="pl-2"
              depressed="depressed"
              rounded="rounded"
              large="large"
              color="secondary"
              :disabled="deleting"
              :loading="saving"
              @click="save"
            >
              <v-icon>mdi-check-circle</v-icon><span class="pl-4 pr-2">Zapisz</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </dialog-fullscreen>
</template>

<script>
import DialogFullscreen from '@/v2/components/DialogFullscreen.vue';
import DateInput from '@/v2/components/Form/Date.vue';
import LIST_ACCOUNT_OPERATION_IDENTIFIERS from '@/graphql/proceeding/query/AccountOperationIdentifiersQuery.gql';
import ADD_OPERATION_MUTATION from '@/graphql/account/mutation/AddBankOperationMutation.gql';
import EDIT_OPERATION_MUTATION from '@/graphql/account/mutation/EditBankOperationMutation.gql';
import DELETE_OPERATION_MUTATION from '@/graphql/account/mutation/DeleteBankOperatioMutation.gql';

export default {
  components: {
    DialogFullscreen,
    DateInput,
  },
  props: {
    account: { type: Object, required: true },
    operation: { type: Object },
  },
  data() {
    return {
      saving: false,
      deleting: false,
      form: {
        types: [
          { value: -1, text: 'Płatność wychodząca' },
          { value: 1, text: 'Płatność przychodząca' },
        ],
        input: {
          type: '',
          date: '',
          amount: '',
          identifier: null,
          subject: '',
          description: '',
        },
        rules: {
          date: [(v) => !!v || 'Podaj datę'],
          amount: [(v) => !!v || 'Podaj kwotę'],
          subject: [(v) => !!v || 'Podaj kwotę'],
          description: [(v) => !!v || 'Wprowadź opis'],
        },
      },
    };
  },
  watch: {
    operation: {
      immediate: true,
      deep: true,
      handler(operation) {
        if (operation) {
          this.form.input.type = operation.value.amount < 0 ? -1 : 1;
          this.form.input.date = operation.date || '';
          this.form.input.amount = Math.abs(operation.value.amount || 0) / 100 || '';
          this.form.input.identifier = operation.identifier ? operation.identifier.id || null : null;
          this.form.input.subject = operation.subject || '';
          this.form.input.description = operation.description || '';
        } else {
          this.form.input.type = -1;
        }
      },
    },
  },
  apollo: {
    identifiers: {
      query: LIST_ACCOUNT_OPERATION_IDENTIFIERS,
      update(data) {
        return data.list.items;
      },
    },
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.operation) {
          const operation = await this.update();
          this.$emit('update', operation);
        } else {
          const operation = await this.create();
          this.$emit('add', operation);
        }
        this.loading = false;
        this.$refs.dialog.hide();
      }
    },
    async create() {
      const { data } = await this.$apollo.mutate({
        mutation: ADD_OPERATION_MUTATION,
        variables: {
          input: {
            accountId: this.account.id,
            sum: {
              amount: this.form.input.amount * this.form.input.type * 100,
              currency: 'PLN',
            },
            operationDate: this.form.input.date,
            receiverSender: this.form.input.subject,
            description: this.form.input.description,
            operationIdentifier: this.form.input.identifier || null,
          },
        },
      });
      return data.operation;
    },
    async update() {
      const { data } = await this.$apollo.mutate({
        mutation: EDIT_OPERATION_MUTATION,
        variables: {
          input: {
            bankOperationId: this.operation.id,
            sum: {
              amount: this.form.input.amount * this.form.input.type * 100,
              currency: 'PLN',
            },
            operationDate: this.form.input.date,
            receiverSender: this.form.input.subject,
            description: this.form.input.description,
            operationIdentifier: this.form.input.identifier || null,
          },
        },
      });
      return data.operation;
    },
    async remove() {
      const agree = await this.$dialog.confirm({
        text: 'Usunąć transakcję?',
        title: 'Potwierdź operację',
      });
      if (agree) {
        this.deleting = true;
        await this.$apollo.mutate({
          mutation: DELETE_OPERATION_MUTATION,
          variables: { bankOperationId: this.operation.id },
        });
        this.deleting = false;
        this.$emit('delete', this.operation);
        this.$refs.dialog.hide();
      }
    },
  },
};
</script>
