export function documentRoute(proceeding, document) {
  return {
    name: 'proceeding-document-info',
    params: {
      id: proceeding.id,
      docid: document.id,
    },
  };
}

export function documentColor(document) {
  switch (document.kind) {
    case 'INCOMING_COURT':
    case 'INCOMING_CREDITOR':
    case 'INCOMING_OTHER':
      return 'success';
    case 'OUTGOING_COURT':
    case 'OUTGOING_CREDITOR':
    case 'OUTGOING_OTHER':
      return 'primary';
    default:
      return undefined;
  }
}

export function documentIcon(document) {
  switch (document.kind) {
    case 'INCOMING_COURT':
    case 'INCOMING_CREDITOR':
    case 'INCOMING_OTHER':
      return 'mdi-arrow-right-bold';
    case 'OUTGOING_COURT':
    case 'OUTGOING_CREDITOR':
    case 'OUTGOING_OTHER':
      return 'mdi-arrow-left-bold';
    default:
      return undefined;
  }
}

export function documentRecipientSenderType(document) {
  switch (document.kind) {
    case 'INCOMING_COURT':
    case 'OUTGOING_COURT':
      return 'Sąd';
    case 'INCOMING_OTHER':
    case 'OUTGOING_OTHER':
      return 'Podmiot';
    case 'INCOMING_CREDITOR':
    case 'OUTGOING_CREDITOR':
      return 'Dłużnik';
    default:
      return undefined;
  }
}

export function documentReplyType(document) {
  switch (document.kind) {
    case 'INCOMING_COURT':
      return 'OUTGOING_COURT';
    case 'INCOMING_OTHER':
      return 'OUTGOING_OTHER';
    case 'INCOMING_CREDITOR':
      return 'OUTGOING_CREDITOR';
    default:
      return undefined;
  }
}

export function documentStatusName(status) {
  switch (status) {
    case 'EDITION_IN_PROGRESS':
      return 'W opracowaniu';
    case 'FINISHED':
      return 'Zakończone';
    case 'FINISHED_SENT_BY_MAIL':
      return 'Wysłane';
    // case 'FINISHED_SENT':
    //   return 'zakończone, wysłane';
    case 'FINISHED_DELIVERED_BY_HAND_UNCONFIRMED':
      return 'Doręczone (brak potwierdzenia)';
    case 'FINISHED_DELIVERED_BY_HAND_CONFIRMED':
      return 'Doręczone';
    default:
      return status;
  }
}

export default {};
