export const mapRole = (role) => {
  switch (role) {
    case 'ROLE_ADMIN':
      return 'Administrator';
    case 'ROLE_MANAGER':
      return 'Menadżer';
    case 'ROLE_USER':
      return 'Doradca';
    default:
      return role;
  }
};

export const mapRoles = (roles) => {
  const list = [];
  for (const role of roles) {
    list.push(mapRole(role));
  }
  return list;
};
