<template>
  <div>
    <div class="px-4">
      <v-row>
        <v-col sm="6" md="4" lg="3">
          <search-input v-model="search"></search-input>
        </v-col>
      </v-row>
    </div>
    <v-list class="mt-4 rounded overflow-hidden">
      <template v-for="section in sections">
        <section-list-item-edit
          v-if="editor === section"
          :key="section.id"
          :version="version"
          :section="section"
          :prefix="prefix"
          @cancel="editor = null"
          @add="$emit('add', $event)"
          @update="$emit('update', $event)"
        ></section-list-item-edit>
        <section-list-item
          v-else
          :key="section.id"
          :section="section"
          :prefix="prefix"
          :editable="editable"
          @edit="editor = section"
        ></section-list-item>
        <v-divider :key="section.id" class="last-child--hidden"></v-divider>
      </template>
    </v-list>
  </div>
</template>

<script>
import stringCompare from '@/v2/helpers/stringCompare';
import SearchInput from '@/v2/components/Form/SearchInput.vue';
import SectionListItem from '@/v2/components/Content/DocumentVersionSectionListItem.vue';
import SectionListItemEdit from '@/v2/components/Content/DocumentVersionSectionListItemEdit.vue';

export default {
  components: {
    SearchInput,
    SectionListItem,
    SectionListItemEdit,
  },
  props: {
    version: { type: Object, required: true },
    prefix: { type: String, default: '' },
    editable: { type: Boolean, default: false },
  },
  data() {
    return {
      editor: null,
      search: '',
    };
  },
  computed: {
    sections() {
      const sections = this.version.rows.filter((section) => this.isSectionMatchingSearch(section, this.search));
      return sections.sort((a, b) => stringCompare(a.number, b.number));
    },
  },
  methods: {
    isSectionMatchingSearch(section, search) {
      return `${section.number}`.startsWith(search) || section.tags.find((tag) => tag.startsWith(search));
    },
  },
};
</script>
