<template>
  <dialog-fullscreen ref="dialog">
    <template v-slot:activator="{ attrs, on }">
      <slot name="activator" v-bind="{ attrs, on }">
        <v-btn outlined="outlined" small="small" icon="icon" color="grey" v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </slot>
    </template>
    <template v-slot:title>
      <v-responsive max-width="260">
        <search-input v-model="search"></search-input>
      </v-responsive>
      <slot name="title"></slot>
    </template>
    <div class="pa-8 max-width-md">
      <v-list class="rounded overflow-hidden">
        <template v-for="(item, index) in list">
          <v-list-item class="hoverable" :key="index">
            <slot name="item" v-bind="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  <h4>{{ item[itemName] }}</h4>
                </v-list-item-title>
              </v-list-item-content>
            </slot>
            <v-list-item-action>
              <v-btn outlined="outlined" rounded="rounded" icon="icon" color="grey" @click="select(item)">
                <v-icon>mdi-forward</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index + '-divider'" class="last-child--hidden"></v-divider>
        </template>
      </v-list>
    </div>
  </dialog-fullscreen>
</template>

<script>
import stringCompare from '@/v2/helpers/stringCompare';
import DialogFullscreen from '@/v2/components/DialogFullscreen.vue';
import SearchInput from '@/v2/components/Form/SearchInput.vue';

export default {
  components: {
    DialogFullscreen,
    SearchInput,
  },
  props: {
    items: { type: Array, required: true },
    itemName: { type: String, default: 'name' },
    itemSearchFn: { type: Function },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    list() {
      if (this.itemSearchFn) {
        return this.itemSearchFn(this.search);
      }
      const { items } = this;
      const list = items.filter((item) => item.name.toLowerCase().includes(this.search.toLowerCase()));
      return list.sort((a, b) => stringCompare(a.name, b.name));
    },
  },
  methods: {
    select(item) {
      this.$refs.dialog.hide();
      this.$emit('select', item);
    },
  },
};
</script>
