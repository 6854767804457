<template>
  <layout-templates>
    <v-card>
      <v-row>
        <v-col>
          <v-card-actions class="px-8">
            <v-responsive max-width="260">
              <search-input v-model="search"></search-input>
            </v-responsive>
          </v-card-actions>
        </v-col>
        <v-col cols="auto">
          <v-card-actions class="px-8">
            <v-btn
              class="px-4"
              :to="{ name: 'schedule-edit' }"
              depressed="depressed"
              rounded="rounded"
              large="large"
              color="secondary"
            >
              <v-icon>mdi-plus-circle</v-icon><span class="px-2">Utwórz szablon</span>
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
      <div class="pa-4">
        <v-list class="rounded overflow-hidden">
          <template v-for="item in schedulesFiltered">
            <schedule-list-item :key="item.id" :item="item"></schedule-list-item>
            <v-divider :key="item.id + 'divider'" class="last-child--hidden"></v-divider>
          </template>
        </v-list>
      </div>
    </v-card>
  </layout-templates>
</template>

<script>
import stringCompare from '@/v2/helpers/stringCompare';
import SearchInput from '@/v2/components/Form/SearchInput.vue';
import LayoutTemplates from '@/v2/layouts/Templates.vue';
import ScheduleListItem from '@/v2/components/Schedule/ListItem.vue';
import SCHEDULE_LIST_QUERY from '@/graphql/scheduleTemplate/query/ListScheduleTemplatesQuery.gql';

export default {
  components: {
    SearchInput,
    LayoutTemplates,
    ScheduleListItem,
  },
  data() {
    return {
      search: '',
    };
  },
  apollo: {
    schedules: {
      query: SCHEDULE_LIST_QUERY,
      variables: {
        query: {
          orders: [{ field: 'name', direction: 'ASC' }],
        },
      },
      update: (data) => data.list.items,
    },
  },
  computed: {
    schedulesFiltered() {
      const schedules = this.schedules || [];
      const filtered = schedules.filter((schedule) => schedule.name.toLowerCase().includes(this.search.toLowerCase()));
      return filtered.sort((a, b) => stringCompare(a.name, b.name));
    },
  },
};
</script>
