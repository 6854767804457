<template>
  <layout-main>
    <template v-slot:title>
      <content-top-navigation :active="active"></content-top-navigation>
    </template>
    <v-container fluid="fluid">
      <v-card v-if="group">
        <v-row>
          <v-col>
            <v-card-actions class="px-8">
              <v-responsive max-width="260">
                <search-input v-model="search"></search-input>
              </v-responsive>
            </v-card-actions>
          </v-col>
          <v-col v-if="group.acl_edit" cols="auto">
            <v-card-actions class="px-8">
              <v-btn
                class="px-4"
                :to="{ name: 'content-document', params: { active: active, groupId: group.id } }"
                rounded="rounded"
                depressed="depressed"
                large="large"
                color="secondary"
              >
                <v-icon>mdi-plus-circle</v-icon><span class="px-2">Dodaj</span>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <div class="pa-4" v-if="items.length">
          <v-list class="rounded overflow-hidden">
            <template v-for="item in items">
              <content-list-item
                :key="item.id"
                :active="active"
                :group="group"
                :item="item"
                :icon-name="iconName"
              ></content-list-item>
              <v-divider :key="item.id" class="last-child--hidden"></v-divider>
            </template>
          </v-list>
        </div>
      </v-card>
    </v-container>
  </layout-main>
</template>

<script>
import stringCompare from '@/v2/helpers/stringCompare';
import LayoutMain from '@/v2/layouts/Main.vue';
import SearchInput from '@/v2/components/Form/SearchInput.vue';
import ContentTopNavigation from '@/v2/layouts/components/ContentTopNavigation.vue';
import QUERY from '@/graphql/content/query/ListContentGroupsQuery.gql';

import ContentListItem from '@/v2/components/Content/ListItem.vue';

export default {
  components: {
    LayoutMain,
    SearchInput,
    ContentTopNavigation,
    ContentListItem,
  },
  props: {
    active: { type: String, default: 'ACTS' },
  },
  data() {
    return {
      search: '',
    };
  },
  apollo: {
    groups: {
      query: QUERY,
      update: (data) => data.list.items,
    },
  },
  computed: {
    acts() {
      const acts = this.groups && this.groups.find((group) => group.locked && group.name === 'Ustawy');
      return acts;
    },
    comments() {
      const comments = this.groups && this.groups.find((group) => group.locked && group.name === 'Komentarze');
      return comments;
    },
    custom() {
      const groups = (this.groups && this.groups.filter((group) => !group.locked)) || [];
      return groups.length ? groups[0] : null;
    },
    group() {
      switch (this.active) {
        case 'ACTS':
          return this.acts;
        case 'COMMENTS':
          return this.comments;
        case 'CUSTOM':
          return this.custom;
        default:
          return null;
      }
    },
    documents() {
      return this.group ? this.group.documents : [];
    },
    items() {
      const items = this.documents.filter((item) => item.title.toLowerCase().includes(this.search.toLowerCase()));
      return items.sort((a, b) => stringCompare(a.title, b.title));
    },
    iconName() {
      switch (this.active) {
        case 'ACTS':
          return 'mdi-format-section';
        case 'COMMENTS':
          return 'mdi-format-quote-close-outline';
        case 'CUSTOM':
          return 'mdi-pencil-box-outline';
        default:
          return '';
      }
    },
  },
};
</script>
