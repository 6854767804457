<template>
  <v-row align="stretch">
    <v-col cols="12">
      <v-card>
        <v-card-actions class="pa-4">
          <v-row align="center">
            <v-col>
              <v-btn class="px-2" :to="{ name: 'proceedings' }" outlined="outlined" rounded="rounded" color="secondary">
                <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Postępowania</span>
              </v-btn>
            </v-col>
            <template v-if="proceeding.status === 'FINISHED_ARCHIVED'">
              <v-col cols="auto">
                <v-btn
                  outlined="outlined"
                  rounded="rounded"
                  color="secondary"
                  :loading="loading.status"
                  @click="setStatus('FINISHED_NON_ARCHIVED')"
                  >Przywróć z archiwum</v-btn
                >
              </v-col>
            </template>
            <template v-else-if="proceeding.status === 'FINISHED_NON_ARCHIVED'">
              <v-col cols="auto">
                <v-btn
                  outlined="outlined"
                  rounded="rounded"
                  color="secondary"
                  :loading="loading.status"
                  @click="setStatus('ACTIVE')"
                  >Otwórz ponownie</v-btn
                >
              </v-col>
              <v-col cols="auto">
                <v-btn
                  depressed="depressed"
                  rounded="rounded"
                  color="secondary"
                  :loading="loading.status"
                  @click="setStatus('FINISHED_ARCHIVED')"
                >
                  <v-icon>mdi-archive-outline</v-icon><span class="px-2">Archiwizuj</span>
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="auto">
                <v-btn
                  outlined="outlined"
                  rounded="rounded"
                  color="secondary"
                  :loading="loading.status"
                  @click="setStatus('FINISHED_NON_ARCHIVED')"
                >
                  <v-icon>mdi-check-all</v-icon><span class="px-2">Zamknij</span>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  class="px-2"
                  :to="{ name: 'proceeding-edit' }"
                  depressed="depressed"
                  rounded="rounded"
                  color="secondary"
                >
                  <v-icon>mdi-pencil-circle</v-icon><span class="px-2">Edytuj dane</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="4">
      <info-card-debtor class="full-height" :proceeding="proceeding"></info-card-debtor>
    </v-col>
    <v-col cols="4">
      <info-card-court class="full-height" :proceeding="proceeding"></info-card-court>
    </v-col>
    <v-col cols="4">
      <info-card-advisor class="full-height" :proceeding="proceeding"></info-card-advisor>
    </v-col>
    <v-col cols="8">
      <info-card-milestones class="full-height" :proceeding="proceeding"></info-card-milestones>
    </v-col>
    <v-col cols="4">
      <info-card-documents class="full-height" :proceeding="proceeding"></info-card-documents>
    </v-col>
    <v-col cols="12">
      <info-notes
        :proceeding="proceeding"
        :notes="proceeding.notes"
        @update="$emit('note-update', $event)"
      ></info-notes>
    </v-col>
  </v-row>
</template>

<script>
import InfoCardDebtor from '@/v2/components/Proceeding/InfoCardDebtor.vue';
import InfoCardCourt from '@/v2/components/Proceeding/InfoCardCourt.vue';
import InfoCardAdvisor from '@/v2/components/Proceeding/InfoCardAdvisor.vue';
import InfoCardMilestones from '@/v2/components/Proceeding/InfoCardMilestones.vue';
import InfoCardDocuments from '@/v2/components/Proceeding/InfoCardDocuments.vue';
import InfoNotes from '@/v2/components/Proceeding/InfoNotes.vue';
import CAHNGE_PROCEEDING_STATUS_MUTATION from '@/graphql/proceeding/mutation/ChangeProceedingStatusMutation.gql';

export default {
  components: {
    InfoCardDebtor,
    InfoCardCourt,
    InfoCardAdvisor,
    InfoCardMilestones,
    InfoCardDocuments,
    InfoNotes,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: {
        status: false,
      },
    };
  },
  methods: {
    async setStatus(status) {
      this.loading.status = true;
      await this.$apollo.mutate({
        mutation: CAHNGE_PROCEEDING_STATUS_MUTATION,
        variables: {
          proceedingId: this.proceeding.id,
          newStatus: status,
        },
      });
      this.$emit('update', { status });
      this.loading.status = false;
    },
  },
};
</script>
