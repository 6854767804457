<template>
  <layout-main>
    <v-container fluid="fluid">
      <proceeding-dashboard-widget class="mb-12"></proceeding-dashboard-widget>
      <task-dashboard-widget class="mb-12"></task-dashboard-widget>
    </v-container>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import TaskDashboardWidget from '@/v2/components/Task/DashboardWidget.vue';
import ProceedingDashboardWidget from '@/v2/components/Proceeding/DashboardWidget.vue';

export default {
  components: {
    LayoutMain,
    TaskDashboardWidget,
    ProceedingDashboardWidget,
  },
};
</script>
