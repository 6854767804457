<template>
  <v-card>
    <v-card-subtitle>
      <div class="text-subtitle-1 font-weight-medium">Harmonogram ({{ proceeding.type | mapProceedingType }})</div>
    </v-card-subtitle>
    <v-timeline class="my-4 pr-4 pb-4" align-top="align-top" dense="dense">
      <v-timeline-item color="grey">
        <v-row class="pt-2" no-gutters="no-gutters">
          <v-col xs="12" sm="12" md="auto">
            <h4>Otwarcie postępowania</h4>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <div class="font-weight-medium text--secondary">{{ proceeding.openedAt | date }}</div>
          </v-col>
        </v-row>
      </v-timeline-item>
      <v-timeline-item
        class="cursor-pointer"
        v-for="item in milestones"
        :key="item.id"
        :color="taskStatusColor(item)"
        @click.native="open(item)"
      >
        <v-row class="pt-2" no-gutters="no-gutters">
          <v-col xs="12" sm="12" md="auto">
            <h4>{{ item.name }}</h4>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <div class="font-weight-medium text--secondary">{{ item.due | date }}</div>
          </v-col>
        </v-row>
      </v-timeline-item>
      <v-timeline-item color="grey">
        <v-row class="pt-2" no-gutters="no-gutters">
          <v-col xs="12" sm="12" md="auto">
            <h4>Zamknięcie postępowania</h4>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <div class="font-weight-medium text--secondary">{{ proceeding.finishedAt | date }}</div>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script>
import { taskStatusColor, taskRoute } from '@/v2/components/Task/Mixin';

export default {
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
  },
  computed: {
    milestones() {
      return this.proceeding.schedules[0].tasks.filter((item) => item.milestone);
    },
  },
  methods: {
    taskStatusColor,
    open(task) {
      this.$router.push(taskRoute(this.proceeding, task));
    },
  },
};
</script>
