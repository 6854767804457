<template>
  <v-form ref="form">
    <v-row class="pt-5">
      <v-col cols="12">
        <div class="text-subtitle-1 px-0 text--secondary">Identyfikacja postępowania</div>
      </v-col>
      <v-col cols="12">
        <v-select
          outlined="outlined"
          :menu-props="{ offsetY: true }"
          :items="types"
          item-value="value"
          item-text="text"
          label="Rodzaj postępowania"
          :rules="form.rules.proceeding.type"
          v-model="form.input.proceeding.type"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined="outlined"
          label="Sygnatura"
          :rules="form.rules.proceeding.signature"
          v-model="form.input.proceeding.signature"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined="outlined"
          label="Sygnatura dodatkowa"
          :rules="form.rules.proceeding.signatureAdditional"
          v-model="form.input.proceeding.signatureAdditional"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="text-subtitle-1 px-0 text--secondary">Osoby</div>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          outlined="outlined"
          :items="advisors"
          item-value="id"
          label="Doradca"
          :filter="advisorFilter"
          :rules="form.rules.proceeding.advisorId"
          v-model="form.input.proceeding.advisorId"
        >
          <template v-slot:selection="{ item }"
            ><span class="font-weight-medium">{{ item.firstName }}</span
            ><span class="font-weight-bold ml-2">{{ item.lastName }}</span></template
          >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title
                ><span class="font-weight-medium">{{ item.firstName }}</span
                ><span class="font-weight-bold ml-2">{{ item.lastName }}</span></v-list-item-title
              >
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="text-subtitle-1 px-0 text--secondary">Harmonogram postępowania</div>
      </v-col>
      <v-col cols="4">
        <date-input
          outlined="outlined"
          label="Data otwarcia"
          :rules="form.rules.proceeding.openedAt"
          v-model="form.input.proceeding.openedAt"
        ></date-input>
      </v-col>
      <v-col cols="8">
        <v-select
          v-if="!proceeding"
          outlined="outlined"
          :menu-props="{ offsetY: true }"
          :items="schedules"
          item-value="id"
          item-text="name"
          label="Schemat harmonogramu"
          :rules="form.rules.schedule.scheduleTemplateId"
          v-model="form.input.schedule.scheduleTemplateId"
        ></v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import stringCompare from '@/v2/helpers/stringCompare';
import DateInput from '@/v2/components/Form/Date.vue';
import LIST_USERS from '@/graphql/user/query/ListUsersForFormQuery.gql';
import LIST_SCHEDULE_TEMPLATES from '@/graphql/scheduleTemplate/query/ListScheduleTemplatesForFormQuery.gql';

export default {
  components: {
    DateInput,
  },
  props: {
    proceeding: { type: Object },
  },
  data() {
    return {
      form: {
        input: {
          proceeding: {
            type: '',
            signature: '',
            signatureAdditional: '',
            advisorId: null,
            openedAt: '',
          },
          schedule: {
            scheduleTemplateId: null,
          },
        },
        rules: {
          proceeding: {
            type: [(v) => !!v || 'Wybierz rodzaj'],
            signature: [(v) => !!v || 'Podaj sygnaturę'],
            advisorId: [(v) => !!v || 'Wybierz doradcę'],
            openedAt: [(v) => !!v || 'Określ datę'],
          },
          schedule: {
            scheduleTemplateId: [(v) => !!v || 'Określ harmonogram'],
          },
        },
      },
      types: [
        {
          value: 'COMPANY_RESTRUCTURING',
          text: 'Restrukturyzacja',
        },
        {
          value: 'COMPANY_BANKRUPTCY',
          text: 'Upadłość',
        },
        {
          value: 'PERSONAL_BANKRUPTCY_STANDARD',
          text: 'Upadłość konsumencka',
        },
        {
          value: 'PERSONAL_BANKRUPTCY_SIMPLIFIED',
          text: 'Upadłość konsumencka - uproszczona',
        },
      ],
    };
  },
  apollo: {
    advisors: {
      query: LIST_USERS,
      update(data) {
        const advisors = data.listUsers.items;
        return advisors.sort((a, b) => stringCompare(`${a.lastName} ${a.firstName}`, `${b.lastName} ${b.firstName}`));
      },
    },
    schedules: {
      query: LIST_SCHEDULE_TEMPLATES,
      update(data) {
        return data.listScheduleTemplates.items;
      },
    },
  },
  created() {
    if (this.proceeding) {
      this.form.input.proceeding.type = this.proceeding.type;
      this.form.input.proceeding.signature = this.proceeding.signature || '';
      this.form.input.proceeding.signatureAdditional = this.proceeding.signatureAdditional || '';
      this.form.input.proceeding.advisorId = this.proceeding.advisor.id;
      this.form.input.proceeding.openedAt = this.proceeding.openedAt;
    }
  },
  watch: {
    'form.input': {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    advisorFilter(item, queryText) {
      const itemText = `${item.firstName} ${item.lastName}`.toLowerCase();
      const searchText = queryText.toLowerCase();
      return itemText.indexOf(searchText) > -1;
    },
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>
