<template>
  <v-card class="overflow-hidden">
    <v-card-actions class="pa-4">
      <v-row>
        <v-col>
          <v-btn
            class="px-2"
            :to="{ name: 'proceeding-documents', params: { id: proceeding.id } }"
            outlined="outlined"
            rounded="rounded"
            color="secondary"
          >
            <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Anuluj</span>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="px-2"
            depressed="depressed"
            rounded="rounded"
            color="secondary"
            :loading="loading"
            @click="save"
          >
            <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <div class="px-4 pt-12">
      <edit-attachment
        class="max-width-md"
        ref="form"
        :proceeding="proceeding"
        :new-document-name="file.name"
        @input="input"
      ></edit-attachment>
    </div>
    <v-divider></v-divider>
    <pdf-viewer :url="fileUrl"></pdf-viewer>
  </v-card>
</template>

<script>
import { documentRoute } from '@/v2/components/Document/Mixin';
import EditAttachment from '@/v2/components/Document/EditAttachment.vue';
import PdfViewer from '@/v2/components/Document/PdfViewer.vue';
import ADD_EXTERNAL_DOCUMENT from '@/graphql/document/mutation/AddExternalDocumentMutation.gql';
import SET_ENTITY_RELATED_DOCUMENTS from '@/graphql/entity/mutation/SetEntityRelatedDocuments.gql';

export default {
  components: {
    EditAttachment,
    PdfViewer,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
    file: {
      type: File,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        input: {},
      },
    };
  },
  computed: {
    debtor() {
      return this.proceeding.debtors[0];
    },
    fileUrl() {
      return URL.createObjectURL(this.file);
    },
  },
  methods: {
    input(input) {
      Object.assign(this.form.input, input);
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const document = await this.createDocument();
        this.$emit('document-add', document);
        if (this.form.input.add.entity) {
          await this.addEntityRelatedDocument(document);
        }
        this.loading = false;
        this.$router.push(documentRoute(this.proceeding, document));
      }
    },
    async createDocument() {
      const {
        data: { document },
      } = await this.$apollo.mutate({
        mutation: ADD_EXTERNAL_DOCUMENT,
        variables: {
          input: {
            proceedingId: this.proceeding.id,
            debtorId: this.debtor.id,
            file: this.file,
            ...this.form.input.edit,
          },
        },
      });
      return document;
    },
    async addEntityRelatedDocument(document) {
      const entityId = this.form.input.add.entity.id;
      const entity = this.proceeding.entities.find((e) => e.id === entityId);
      const entityRelatedDocumentIds = entity.relatedDocuments.map((d) => d.id);
      const documentIds = [...entityRelatedDocumentIds, document.id];
      await this.$apollo.mutate({
        mutation: SET_ENTITY_RELATED_DOCUMENTS,
        variables: {
          proceedingId: this.proceeding.id,
          input: {
            proceedingId: this.proceeding.id,
            entityId,
            documentIds,
          },
        },
      });
      this.$emit('entity-update-related-documents', {
        entity,
        relatedDocuments: [...entity.relatedDocuments, document],
      });
    },
  },
};
</script>
