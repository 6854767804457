<template>
  <v-btn v-if="active" depressed="depressed" large="large" color="error" :loading="loading" @click="action"
    >Wyłącz konto</v-btn
  >
  <v-btn v-else depressed="depressed" large="large" color="error" :loading="loading" @click="action"
    >Aktywuj konto</v-btn
  >
</template>

<script>
import EDIT_USER_STATE from '@/graphql/user/mutation/SetUserStateMutation.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      active: false,
    };
  },
  watch: {
    'user.active': {
      immediate: true,
      handler(active) {
        this.active = active;
      },
    },
  },
  methods: {
    async action() {
      this.loading = true;
      const { data } = await this.$apollo.mutate({
        mutation: EDIT_USER_STATE,
        variables: {
          id: this.user.id,
          active: !this.active,
        },
      });
      this.active = data.user.active;
      this.loading = false;
    },
  },
};
</script>
