<template>
  <v-card class="yellow lighten-4 d-flex flex-column overflow-hidden">
    <v-card-actions class="py-1 yellow lighten-3"
      ><small class="px-2 font-weight-bold">{{ note.createdAt | date }}</small
      ><small class="px-2 font-weight-medium">{{ note.createdBy.firstName }} {{ note.createdBy.lastName }}</small>
      <v-spacer></v-spacer>
      <edit-dialog :proceeding="proceeding" :note="note" @update="$emit('update', $event)">
        <template v-slot:activator="{ on }">
          <v-btn text="text" fab="fab" x-small="x-small" color="secondary" v-on="on">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </edit-dialog>
    </v-card-actions>
    <v-divider></v-divider>
    <div class="pa-4 pt-2">{{ note.content }}</div>
    <v-spacer></v-spacer>
    <v-list class="yellow lighten-4" dense="dense">
      <template v-for="document in note.relatedDocuments">
        <v-divider :key="document.id"></v-divider>
        <v-list-item class="pl-1" :to="documentRoute(proceeding, document)" :key="document.id" @click.stop="">
          <v-list-item-avatar class="ma-0">
            <v-icon>mdi-file-document-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ document.name }}</v-list-item-title>
        </v-list-item>
      </template>
      <template v-for="task in note.relatedTasks">
        <v-divider :key="task.id"></v-divider>
        <v-list-item class="pl-1" :to="taskRoute(proceeding, task)" :key="task.id" @click.stop="">
          <v-list-item-avatar class="ma-0">
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ task.name }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import { documentRoute } from '@/v2/components/Document/Mixin';
import { taskRoute } from '@/v2/components/Task/Mixin';
import EditDialog from '@/v2/components/Note/EditDialog.vue';

export default {
  components: {
    EditDialog,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
    note: {
      type: Object,
      required: true,
    },
  },
  methods: {
    taskRoute,
    documentRoute,
  },
};
</script>
