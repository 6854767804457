let now = Date.now();

function uid(prefix) {
  const uidVar = `${prefix}${(now += 1).toString(16)}`;
  return uidVar;
}

let graphqlUri = null;
let configCachedValue;

const getConfig = async () => {
  if (typeof configCachedValue !== 'undefined') {
    return configCachedValue;
  }
  const data = await fetch(`/config.json?${uid()}`);
  configCachedValue = await data.json();
  return configCachedValue;
};

export const fetchGraphqlUri = async () => {
  const config = await getConfig();
  return config.graphql;
};

export const fetchCookieDomain = async () => {
  const config = await getConfig();
  return config.domain;
};

const customFetch = async (uri, options) => {
  if (graphqlUri === null) {
    graphqlUri = await fetchGraphqlUri();
  }
  return fetch(graphqlUri, options);
};

export default customFetch;
