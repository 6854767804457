<template>
  <v-list class="rounded overflow-hidden" v-if="schedule.taskTemplates.length">
    <template v-for="item in schedule.taskTemplates">
      <task-list-item
        :key="item.id"
        :schedule="schedule"
        :item="item"
        @remove="$emit('task-remove', item)"
      ></task-list-item>
      <v-divider :key="item.id" class="last-child--hidden"></v-divider>
    </template>
  </v-list>
</template>

<script>
import TaskListItem from '@/v2/components/Schedule/TaskListItem.vue';

export default {
  components: {
    TaskListItem,
  },
  props: {
    schedule: { type: Object, required: true },
  },
};
</script>
