<template>
  <layout-main v-if="document" :title="document.title">
    <v-card>
      <v-row>
        <v-col>
          <v-card-actions class="pl-8">
            <slot name="back"></slot>
          </v-card-actions>
        </v-col>
      </v-row>
      <div class="px-4">
        <document-version-list
          class="px-4 pt-4"
          :versions="versions"
          :version="version"
          @select="version = $event"
        ></document-version-list>
      </div>
    </v-card>
    <v-card class="mt-12 pa-4 pt-4" v-if="version && version.rows.length">
      <document-version-section-list :version="version"></document-version-section-list>
    </v-card>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import DocumentVersionList from '@/v2/components/Content/DocumentVersionList.vue';
import DocumentVersionSectionList from '@/v2/components/Content/DocumentVersionSectionList.vue';
import QUERY_DOCUMENT from '@/graphql/content/query/GetOneContentDocumentQuery.gql';

export default {
  components: {
    LayoutMain,
    DocumentVersionList,
    DocumentVersionSectionList,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      versions: [],
      version: null,
    };
  },
  apollo: {
    document: {
      query: QUERY_DOCUMENT,
      variables() {
        return { id: this.id };
      },
      update(data) {
        const item = data.list.items[0];
        this.versions = [...item.versions].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        this.version = this.versions.length ? this.versions[this.versions.length - 1] : null;
        return item;
      },
      error() {
        this.$emit('cancel');
      },
    },
  },
};
</script>
