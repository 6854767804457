<template>
  <v-card>
    <v-card-actions class="pa-4">
      <v-row align="center">
        <v-col>
          <v-btn class="px-2" :to="backRoute" outlined="outlined" rounded="rounded" color="secondary">
            <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Anuluj</span>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="px-2"
            depressed="depressed"
            rounded="rounded"
            color="secondary"
            :loading="loading"
            @click="save"
          >
            <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-form class="px-4 pt-6" ref="form">
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined="outlined"
            label="Nazwa konta"
            :rules="form.rules.name"
            v-model="form.input.name"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field outlined="outlined" label="Nr konta" v-model="form.input.number"></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import CREATE_MUTATION from '@/graphql/account/mutation/AddBankAccountMutation.gql';
import UPDATE_MUTATION from '@/graphql/account/mutation/EditBankAccountMutation.gql';

export default {
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
    accountId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        input: {
          name: '',
          number: '',
          currency: 'PLN',
        },
        rules: {
          name: [(v) => !!v || 'Podaj nazwę'],
        },
      },
    };
  },
  computed: {
    account() {
      if (this.accountId) {
        return this.proceeding.accounts.find((account) => account.id === this.accountId);
      }
      return null;
    },
    backRoute() {
      if (this.account) {
        return {
          name: 'proceeding-finance-account',
          params: { accountId: this.account.id },
        };
      }
      return {
        name: 'proceeding-finance',
      };
    },
  },
  created() {
    if (this.account) {
      this.form.input.name = this.account.name || '';
      this.form.input.number = this.account.number || '';
    }
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let account = null;
        if (this.account) {
          account = await this.updateAccount();
          this.$emit('account-update', account);
        } else {
          account = await this.createAccount();
          this.$emit('account-add', account);
        }
        this.loading = false;
        this.$router.push({
          name: 'proceeding-finance-account',
          params: { accountId: account.id },
        });
      }
    },
    async createAccount() {
      const { data } = await this.$apollo.mutate({
        mutation: CREATE_MUTATION,
        variables: {
          input: {
            proceedingId: this.proceeding.id,
            ...this.form.input,
          },
        },
      });
      return data.account;
    },
    async updateAccount() {
      const { data } = await this.$apollo.mutate({
        mutation: UPDATE_MUTATION,
        variables: {
          input: {
            id: this.account.id,
            ...this.form.input,
          },
        },
      });
      return data.account;
    },
  },
};
</script>
