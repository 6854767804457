<template>
  <v-form ref="form">
    <v-row class="pt-5">
      <v-col cols="12">
        <div class="text-subtitle-1 px-0 text--secondary">Wybór sądu (przed którym toczy się postępowanie)</div>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          outlined="outlined"
          :items="courts"
          item-value="id"
          item-text="name"
          label="Sąd"
          :rules="form.rules.court.courtId"
          :value="form.input.court.courtId"
          @input="setCourt"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="text-subtitle-1 px-0 text--secondary">Sędzia prowadzący sprawę</div>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          outlined="outlined"
          :disabled="!form.input.court.courtId"
          :items="courtJudges"
          item-value="id"
          label="Sędzia"
          :filter="judgeFilter"
          :rules="form.rules.court.judgeId"
          v-model="form.input.court.judgeId"
        >
          <template v-slot:selection="{ item }"
            ><span class="text--secondary mr-2" v-if="item.title">{{ item.title }}</span
            ><span class="font-weight-medium">{{ item.firstName }}</span
            ><span class="font-weight-bold ml-2">{{ item.lastName }}</span></template
          >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title
                ><span class="text--secondary mr-2" v-if="item.title">{{ item.title }}</span
                ><span class="font-weight-medium">{{ item.firstName }}</span
                ><span class="font-weight-bold ml-2">{{ item.lastName }}</span></v-list-item-title
              >
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import stringCompare from '@/v2/helpers/stringCompare';
import LIST_COURTS from '@/graphql/court/query/ListCourtsForFormQuery.gql';
import LIST_JUDGES from '@/graphql/judge/query/ListJudgesForFormQuery.gql';

export default {
  props: {
    court: { type: Object },
    judge: { type: Object },
  },
  data() {
    return {
      form: {
        input: {
          court: {
            courtId: null,
            judgeId: null,
          },
        },
        rules: {
          court: {
            courtId: [(v) => !!v || 'Wybierz sąd'],
            judgeId: [(v) => !!v || 'Wybierz sędziego'],
          },
        },
      },
    };
  },
  apollo: {
    courts: {
      query: LIST_COURTS,
      update(data) {
        return data.list.items;
      },
    },
    judges: {
      query: LIST_JUDGES,
      update(data) {
        return data.listJudges.items;
      },
    },
  },
  computed: {
    courtJudges() {
      const judges = this.judges || [];
      const filtered = judges.filter((judge) => judge.court.id === this.form.input.court.courtId);
      return filtered.sort((a, b) => stringCompare(`${a.lastName} ${a.firstName}`, `${b.lastName} ${b.firstName}`));
    },
  },
  created() {
    if (this.court) {
      this.form.input.court.courtId = this.court.id;
    }
    if (this.judge) {
      this.form.input.court.judgeId = this.judge.id;
    }
  },
  watch: {
    'form.input': {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    setCourt(courtId) {
      console.log('set court id');
      this.form.input.court.courtId = courtId;
      this.form.input.court.judgeId = null;
    },
    judgeFilter(item, queryText) {
      const itemText = `${item.firstName} ${item.lastName}`.toLowerCase();
      const searchText = queryText.toLowerCase();
      return itemText.indexOf(searchText) > -1;
    },
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>
