<template>
  <v-app-bar app :clipped-left="$vuetify.breakpoint.lgAndUp">
    <v-app-bar-nav-icon @click.stop="$emit('update:drawer', !drawer)"></v-app-bar-nav-icon>
    <v-toolbar-title>
      <span>Synd App</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div class="text-h6 line-dense">Witaj {{ currentUser.firstName }}!</div>
    <v-menu class="" left bottom offset-y :nudge-bottom="6">
      <template #activator="{ on, attrs }">
        <v-btn class="mx-2" icon v-bind="attrs" v-on="on">
          <v-icon large color="primary">mdi-cog</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item-group color="light-green">
          <NavigationItem :key="i" :item="item" v-for="(item, i) in menu" />
        </v-list-item-group>
        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon class="error--text">mdi-power</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="error--text">Wyloguj</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { onLogout } from '@/plugins/apollo';
import CURRENT_USER from '@/graphql/security/query/CurrentUserQuery.gql';
import NavigationItem from '@/layouts/components/NavigationItem.vue';

export default {
  components: { NavigationItem },
  props: {
    drawer: Boolean,
  },
  data() {
    return {
      menu: [
        {
          icon: 'mdi-card-account-details',
          text: 'Konto',
          route: { name: 'user-profile' },
        },
        {
          icon: 'mdi-contacts',
          text: 'Użytkownicy',
          route: { name: 'users' },
        },
        {
          icon: 'mdi-calendar-edit',
          text: 'Szablony harmonogramów',
          route: { name: 'list-schetule-template' },
        },
        {
          icon: 'mdi-home-account',
          text: 'Sądy',
          route: { name: 'courts' },
        },
        {
          icon: 'mdi-account-tie',
          text: 'Sędziowie',
          route: { name: 'judges' },
        },
        {
          icon: 'mdi-marker',
          text: 'Fragmenty',
          route: { name: 'list-quick-parts' },
        },
        {
          icon: 'mdi-contacts',
          text: 'Edytor treści',
          route: { name: 'content-manager' },
        },
      ],
    };
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER,
      update: (data) => data.me,
    },
  },
  methods: {
    logout() {
      onLogout(this.$apollo.getClient());
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style lang="sass" scoped>
.line-dense
  line-height: 1em
</style>
