<template>
  <v-card>
    <v-card-subtitle>
      <div class="text-subtitle-1 font-weight-medium">Dokumenty główne</div>
    </v-card-subtitle>
    <v-list>
      <v-list-item
        v-for="document in documents"
        :key="document.id"
        :to="{ name: 'proceeding-document-info', params: { id: proceeding.id, docid: document.id } }"
      >
        <v-list-item-avatar color="primary">
          <v-icon dark="dark">mdi-attachment</v-icon>
        </v-list-item-avatar>
        <div class="font-weight-medium">{{ document.name }}</div>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
  },
  computed: {
    documents() {
      return this.proceeding.documents.filter((document) => document.basicProceedingDocument);
    },
  },
};
</script>
