<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="px-4">
          <h4 class="secondary--text">Lista sędziów</h4>
        </v-card-title>
      </v-col>
      <v-col cols="auto">
        <v-card-actions class="px-4">
          <v-btn
            :to="{ name: 'judge-edit', params: { courtId } }"
            depressed="depressed"
            rounded="rounded"
            color="secondary"
          >
            <v-icon>mdi-plus-circle</v-icon><span class="px-2">Dodaj sędziego</span>
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <v-list class="rounded overflow-hidden">
      <template v-for="item in judges">
        <judge-list-item :key="item.id" :court-id="courtId" :item="item"></judge-list-item>
        <v-divider :key="item.id" class="last-child--hidden"></v-divider>
      </template>
    </v-list>
  </div>
</template>

<script>
import JudgeListItem from '@/v2/components/Judge/ListItem.vue';

export default {
  components: {
    JudgeListItem,
  },
  props: {
    courtId: {
      type: String,
      required: true,
    },
    judges: {
      type: Array,
      required: true,
    },
  },
};
</script>
