<template>
  <v-app v-if="currentUser">
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <keep-alive>
        <NavigationMenu v-model="drawer" />
      </keep-alive>
    </v-navigation-drawer>
    <top-bar :drawer.sync="drawer" />
    <v-main>
      <v-container fluid>
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import CURRENT_USER from '@/graphql/security/query/CurrentUserQuery.gql';
import TopBar from '@/layouts/components/TopBar.vue';
import NavigationMenu from '@/layouts/components/NavigationMenu.vue';

export default {
  name: 'FullWideLayout',
  components: { TopBar, NavigationMenu },
  // created() {
  //   this.$vuetify.theme.dark = false
  // },
  data() {
    return {
      drawer: true,
    };
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER,
      update: (data) => {
        return data.me;
      },
      error: () => {
        // TODO
        this.$router.push({ name: 'login' });
      },
    },
  },
};
</script>
