<template>
  <v-list-item class="py-2 px-4" :to="route">
    <v-list-item-avatar color="primary">
      <v-icon dark="dark">mdi-timeline-outline</v-icon>
    </v-list-item-avatar>
    <v-list-item-title class="font-weight-bold">{{ item.name }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    route() {
      return {
        name: 'schedule-edit',
        params: { id: this.item.id },
      };
    },
  },
};
</script>
