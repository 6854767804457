<template>
  <v-btn depressed="depressed" large="large" color="secondary" :loading="loading" @click="action">Resetuj hasło</v-btn>
</template>

<script>
import MUTATION from '@/graphql/user/mutation/ForgotPasswordMutation.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async action() {
      const agree = await this.$dialog.confirm({
        text: 'Wysłaś użytkownikowi link do zmiany hasła?',
        title: 'Reset hasła',
      });
      if (agree) {
        this.loading = true;
        await this.$apollo.mutate({
          mutation: MUTATION,
          variables: {
            email: this.user.email,
          },
        });
        this.loading = false;
      }
    },
  },
};
</script>
