<template>
  <v-app-bar app="app" flat="flat" height="112px">
    <slot name="title">
      <v-toolbar-title
        ><strong v-if="title">{{ title }}</strong
        ><strong v-else-if="me">Witaj {{ me.firstName }}!</strong></v-toolbar-title
      >
    </slot>
    <v-spacer></v-spacer>
    <v-btn :to="{ name: 'tasks' }" icon="icon" color="primary">
      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
    </v-btn>
    <v-btn :to="{ name: 'calendar' }" icon="icon" color="primary">
      <v-icon>mdi-calendar-month-outline</v-icon>
    </v-btn>
    <v-menu left="left" bottom="bottom" offset-y="offset-y" :nudge-bottom="6">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-4" icon="icon" outlined="outlined" color="primary" v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list dense="dense">
        <v-list-item :to="{ name: 'user-profile' }">
          <v-list-item-icon>
            <v-icon>mdi-account-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Konto</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="me && me.acl_edit_users" :to="{ name: 'users' }">
          <v-list-item-icon>
            <v-icon>mdi-account-supervisor-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Użytkownicy</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon class="error--text">mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="error--text">Wyloguj</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> </v-menu
    ><span></span>
  </v-app-bar>
</template>

<script>
import { onLogout } from '@/plugins/apollo';
import ME from '@/graphql/security/query/CurrentUserQuery.gql';

export default {
  props: {
    title: {
      type: String,
    },
  },
  apollo: {
    me: {
      query: ME,
      update: (data) => data.me,
      fetchPolicy: 'cache-first',
    },
  },
  methods: {
    logout() {
      onLogout(this.$apollo.getClient());
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
