import { onError } from 'apollo-link-error'; // eslint-disable-line import/no-cycle
import { onLogout } from '@/plugins/apollo'; // eslint-disable-line import/no-cycle
import routerInstance from '@/router'; // eslint-disable-line import/no-cycle

export default onError(({ networkError }) => {
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }

  if (networkError.statusCode === 401) {
    onLogout();
    routerInstance.push({ name: 'login' });
  }
});
