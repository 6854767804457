<template>
  <layout-main title="Administracja użytkownikami">
    <v-container fluid="fluid">
      <v-card>
        <v-row>
          <v-col>
            <v-card-actions class="px-8">
              <v-responsive max-width="260">
                <search-input :value="search" @input="setSearch"></search-input>
              </v-responsive>
            </v-card-actions>
          </v-col>
          <v-col cols="auto">
            <v-card-actions class="px-8">
              <v-btn
                class="px-4"
                :to="{ name: 'user-edit' }"
                depressed="depressed"
                rounded="rounded"
                large="large"
                color="secondary"
              >
                <v-icon>mdi-plus-circle</v-icon><span class="px-2">Utwórz konto</span>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <infinite-loading class="pa-4" ref="infinite" :load="load">
          <template v-slot:default="{ list }">
            <v-list class="rounded overflow-hidden">
              <template v-for="item in list.items">
                <user-list-item :key="item.id" :item="item"></user-list-item>
                <v-divider :key="item.id" class="last-child--hidden"></v-divider>
              </template>
            </v-list>
          </template>
        </infinite-loading>
      </v-card>
    </v-container>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import InfiniteLoading from '@/v2/components/InfiniteLoading.vue';
import SearchInput from '@/v2/components/Form/SearchInput.vue';
import UserListItem from '@/v2/components/User/Admin/ListItem.vue';
import USERS_LIST_QUERY from '@/graphql/user/query/ListUsersQuery.gql';

export default {
  components: {
    LayoutMain,
    InfiniteLoading,
    SearchInput,
    UserListItem,
  },
  data() {
    return {
      search: '',
    };
  },
  methods: {
    setSearch(search) {
      this.search = search || '';
      this.$refs.infinite.reset();
    },
    async load(offset) {
      const { data } = await this.$apollo.query({
        query: USERS_LIST_QUERY,
        variables: {
          query: {
            filters: [{ field: 'search', type: 'EQ', value: this.search }],
            orders: [
              { field: 'lastName', direction: 'ASC' },
              { field: 'firstName', direction: 'ASC' },
            ],
          },
          offset,
          limit: 20,
        },
        fetchPolicy: 'network-only',
      });
      return data.list;
    },
  },
};
</script>
