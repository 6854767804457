<template>
  <form ref="form">
    <v-row no-gutters="no-gutters">
      <v-col cols="12">
        <v-text-field
          solo="solo"
          flat="flat"
          dense="dense"
          background-color="transparent"
          :placeholder="label"
          :rules="form.rules.name"
          v-model="form.input.name"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          solo="solo"
          flat="flat"
          dense="dense"
          background-color="transparent"
          placeholder="Ulica"
          :rules="form.rules.address.street"
          v-model="form.input.address.street"
        ></v-text-field>
      </v-col>
      <v-col class="pr-2" cols="4">
        <v-text-field
          solo="solo"
          flat="flat"
          dense="dense"
          background-color="transparent"
          placeholder="Kod pocztowy"
          :rules="form.rules.address.postCode"
          v-model="form.input.address.postCode"
        ></v-text-field>
      </v-col>
      <v-col class="pl-2" cols="8">
        <v-text-field
          solo="solo"
          flat="flat"
          dense="dense"
          background-color="transparent"
          placeholder="Miejscowość"
          :rules="form.rules.address.city"
          v-model="form.input.address.city"
        ></v-text-field>
      </v-col>
    </v-row>
  </form>
</template>

<script>
export default {
  props: {
    value: { type: Object, required: true },
    label: { type: String, default: 'Nazwa adresata' },
  },
  data() {
    return {
      form: {
        input: {
          name: '',
          address: {
            street: '',
            postCode: '',
            city: '',
          },
        },
        rules: {
          name: [(v) => !!v || 'Uzupełnij dane'],
          address: {
            street: [(v) => !!v || 'Podaj ulicę'],
            postCode: [(v) => !!v || 'Podaj kod'],
            city: [(v) => !!v || 'Podaj miejscowość'],
          },
        },
      },
    };
  },
  watch: {
    'value': {
      immediate: true,
      deep: true,
      handler(value) {
        this.form.input.name = value.name || '';
        this.form.input.address.street = value.address.street || '';
        this.form.input.address.postCode = value.address.postCode || '';
        this.form.input.address.city = value.address.city || '';
      },
    },
    'form.input': {
      deep: true,
      handler(input) {
        this.$emit('input', input);
      },
    },
  },
};
</script>
