<template>
  <v-form ref="form" v-model="form.valid">
    <v-text-field
      class="mt-4"
      outlined="outlined"
      label="Aktualne hasło"
      :type="form.inputHelpers.showPasswords ? 'text' : 'password'"
      :rules="form.rules.oldPassword"
      v-model="form.input.oldPassword"
    ></v-text-field>
    <v-text-field
      class="mt-4"
      outlined="outlined"
      label="Nowe hasło"
      :type="form.inputHelpers.showPasswords ? 'text' : 'password'"
      :rules="form.rules.newPassword"
      v-model="form.input.newPassword"
    ></v-text-field>
    <v-text-field
      class="mt-4"
      outlined="outlined"
      label="Powtórz nowe hasło"
      :type="form.inputHelpers.showPasswords ? 'text' : 'password'"
      :rules="form.rules.newPasswordRepeat"
      v-model="form.inputHelpers.newPasswordRepeat"
    ></v-text-field>
    <v-row align="center">
      <v-col cols="auto">
        <v-checkbox label="Pokaż hasła" v-model="form.inputHelpers.showPasswords"></v-checkbox>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn
          depressed="depressed"
          large="large"
          color="secondary"
          :disabled="!form.valid"
          :loading="loading"
          @click="change"
          >Zmień</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import CHANGE_PASSWORD from '@/graphql/user/mutation/ChangePasswordMutation.gql';

export default {
  data() {
    return {
      form: {
        valid: false,
        input: {
          oldPassword: '',
          newPassword: '',
        },
        inputHelpers: {
          newPasswordRepeat: '',
          showPasswords: false,
        },
        rules: {
          oldPassword: [(v) => !!v || 'Podaj aktualne hasło'],
          newPassword: [
            (v) => !!v || 'Podaj nowe hasło',
            (v) => (!!v && v.length >= 8) || 'Hasło powinno mieć minimum 8 znaków',
          ],
          newPasswordRepeat: [(v) => (!!v && v === this.form.input.newPassword) || 'Błędnie powtórzone hasło'],
        },
      },
      loading: false,
    };
  },
  methods: {
    async change() {
      this.loading = true;
      const response = await this.$apollo.mutate({
        mutation: CHANGE_PASSWORD,
        variables: { input: this.form.input },
      });
      if (response.data.user.errors) {
        await this.$dialog.error({
          title: 'Błąd',
          text: 'Nieprawidłowe hasło',
        });
      } else {
        this.$refs.form.reset();
        await this.$dialog.info({
          title: 'Potwierdzenie',
          text: 'Hasło zostało zmienione',
        });
      }
      this.loading = false;
    },
  },
};
</script>
