<template>
  <v-card>
    <v-card-actions class="pa-4">
      <v-row align="center">
        <v-col>
          <v-btn class="px-2" :to="backRoute" outlined="outlined" rounded="rounded" color="secondary">
            <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Anuluj</span>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="px-2"
            depressed="depressed"
            rounded="rounded"
            color="secondary"
            :loading="loading"
            @click="save"
          >
            <v-icon>mdi-check-circle</v-icon><span class="px-2">Zapisz</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <div class="pa-4">
      <v-tabs ref="tabs" grow="grow" hide-slider="hide-slider" :value="step">
        <v-tab
          class="rounded--top overflow-hidden"
          :class="step === 0 ? 'info--text' : 'grey--text text--darken-1'"
          @click="goToStep(0)"
          >Dane podmiotu</v-tab
        >
        <v-tab
          class="rounded--top overflow-hidden"
          :class="step === 1 ? 'info--text' : 'grey--text text--darken-1'"
          @click="goToStep(1)"
          >Wierzytelności</v-tab
        >
      </v-tabs>
      <hr class="grey lighten-3" />
      <v-tabs-items class="pt-8" :value="step">
        <v-tab-item>
          <edit-entity class="max-width-md" ref="entity" :entity="entity" @input="inputEntity"></edit-entity>
        </v-tab-item>
        <v-tab-item>
          <edit-claim class="max-width-md" ref="claim" :claim="claim" @input="inputClaim"></edit-claim>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-card>
</template>

<script>
import EditEntity from '@/v2/components/Entity/EditEntity.vue';
import EditClaim from '@/v2/components/Entity/EditClaim.vue';
import EDIT_ENTITY_MUTATION from '@/graphql/proceeding/mutation/EditProceedingEntityMutation.gql';
import ADD_CLAIM_MUTATION from '@/graphql/entity/mutation/AddEntityClaimMutation.gql';
import EDIT_CLAIM_MUTATION from '@/graphql/entity/mutation/EditEntityClaimMutation.gql';

export default {
  components: {
    EditEntity,
    EditClaim,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
    entityId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      step: 0,
      loading: false,
      form: {},
    };
  },
  computed: {
    entity() {
      return this.proceeding.entities.find((x) => x.id === this.entityId);
    },
    claim() {
      return this.proceeding.claims.find((x) => x.creditor.id === this.entity.id);
    },
    backRoute() {
      return {
        name: 'proceeding-entity-info',
        params: { entityId: this.entity.id },
      };
    },
  },
  methods: {
    inputEntity(entity) {
      Object.assign(this.form, { entity });
    },
    inputClaim(claim) {
      Object.assign(this.form, { claim });
    },
    goToStep(step) {
      if (this.stepValidate(this.step)) {
        this.step = step;
      }
    },
    stepValidate(step) {
      switch (step) {
        case 0:
          return this.$refs.entity.validate();
        case 1:
          return this.$refs.claim.validate();
        default:
          return undefined;
      }
    },
    async save() {
      if (this.stepValidate(this.step)) {
        this.loading = true;
        if (this.form.entity) {
          await this.saveEntity();
        }
        if (this.form.claim) {
          await this.saveClaim();
        }
        this.loading = false;
      }
    },
    async saveEntity() {
      const { data } = await this.$apollo.mutate({
        mutation: EDIT_ENTITY_MUTATION,
        variables: {
          input: {
            entityId: this.entity.id,
            ...this.form.entity,
          },
        },
      });
      this.$emit('entity-update', data.entity);
    },
    async saveClaim() {
      if (this.claim) {
        await this.claimUpdate();
      } else {
        await this.claimCreate();
      }
    },
    async claimCreate() {
      const { data } = await this.$apollo.mutate({
        mutation: ADD_CLAIM_MUTATION,
        variables: {
          input: {
            proceedingId: this.proceeding.id,
            creditorId: this.entity.id,
            ...this.form.claim,
          },
        },
      });
      this.$emit('claim-add', data.claim);
    },
    async claimUpdate() {
      const { data } = await this.$apollo.mutate({
        mutation: EDIT_CLAIM_MUTATION,
        variables: {
          input: {
            claimId: this.claim.id,
            creditorId: this.claim.creditor.id,
            ...this.form.claim,
          },
        },
      });
      this.$emit('claim-update', data.claim);
    },
  },
};
</script>
