<template>
  <div>
    <v-card-actions class="pl-4 pr-3">
      <div class="text-body1 text--secondary">Powiązane zadania</div>
      <v-spacer></v-spacer>
      <select-list-item :items="tasks" @select="add">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="secondary"
            outlined="outlined"
            small="small"
            icon="icon"
            color="white"
            :loading="adding"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </select-list-item>
    </v-card-actions>
    <v-list dense="dense">
      <template v-for="task in document.relatedTasks">
        <v-divider :key="task.id"></v-divider>
        <v-list-item class="pl-1" :to="taskRoute(proceeding, task)" :key="task.id" @click.stop="">
          <v-list-item-avatar class="ma-0">
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ task.name }}</v-list-item-title>
          <v-list-item-action>
            <v-btn
              outlined="outlined"
              small="small"
              icon="icon"
              color="grey"
              :loading="removing === task.id"
              @click.stop.prevent="remove(task)"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import { taskRoute } from '@/v2/components/Task/Mixin';
import SelectListItem from '@/v2/components/SelectListItem.vue';
import SET_RELATED_TASKS_MUTATION from '@/graphql/document/mutation/SetDocumentRelatedTasks.gql';

export default {
  components: {
    SelectListItem,
  },
  props: {
    proceeding: { type: Object, required: true },
    tasks: { type: Array, required: true },
    document: { type: Object, required: true },
  },
  data() {
    return {
      adding: false,
      removing: '',
    };
  },
  methods: {
    taskRoute,
    async add(task) {
      this.adding = true;
      const relatedTaskIds = this.document.relatedTasks.map((x) => x.id);
      relatedTaskIds.push(task.id);
      const relatedTasks = await this.setRelatedTasks(this.document, relatedTaskIds);
      this.$emit('update', relatedTasks);
      this.adding = false;
    },
    async remove(task) {
      this.removing = task.id;
      const relatedTaskIds = this.document.relatedTasks.map((x) => x.id).filter((x) => x !== task.id);
      const relatedTasks = await this.setRelatedTasks(this.document, relatedTaskIds);
      this.$emit('update', relatedTasks);
      this.removing = '';
    },
    async setRelatedTasks(document, relatedTaskIds) {
      const { data } = await this.$apollo.mutate({
        mutation: SET_RELATED_TASKS_MUTATION,
        variables: {
          input: {
            documentId: document.id,
            taskIds: relatedTaskIds,
          },
        },
      });
      return data.document.relatedTasks;
    },
  },
};
</script>
