<template>
  <v-list-item class="py-2 px-4" :to="route">
    <v-list-item-avatar color="primary">
      <v-icon dark="dark">mdi-scale-balance</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-row class="align-center">
        <v-col>
          <v-list-item-title class="my-1 font-weight-bold">{{ item.name }}</v-list-item-title>
        </v-col>
        <v-col cols="auto">
          <v-list-item-subtitle>
            <div class="text-right">{{ item.address.street }}</div>
            <div class="text-right">{{ item.address.postCode }} {{ item.address.city }}</div>
          </v-list-item-subtitle>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    route() {
      return {
        name: 'court-edit',
        params: { id: this.item.id },
      };
    },
  },
};
</script>
