<template>
  <v-card class="overflow-hidden">
    <v-card-actions class="pa-4">
      <v-row align="center">
        <v-col>
          <h2 class="secondary--text">Podmioty</h2>
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="px-2"
            :to="{ name: 'proceeding-entity-create' }"
            depressed="depressed"
            rounded="rounded"
            color="secondary"
          >
            <v-icon>mdi-plus-circle</v-icon><span class="px-2">Nowy podmiot</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-list>
      <template v-for="entity in proceeding.entities">
        <entity-list-item class="py-2" :key="entity.id" :proceeding="proceeding" :entity="entity"></entity-list-item>
        <v-divider :key="entity.id" class="last-child--hidden"></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import EntityListItem from '@/v2/components/Entity/ListItem.vue';

export default {
  components: {
    EntityListItem,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
  },
};
</script>
