<template>
  <div>
    <slot v-if="information" v-bind:information="information" v-bind:frontendInformation="frontendInformation"></slot>
  </div>
</template>
<script>
import SYSTEM_INFO from '@/graphql/system/SystemInfoQuery.gql';
// eslint-disable-next-line import/extensions
import { version } from '@/helpers/version.js';

export default {
  name: 'SystemInfoScope',
  data() {
    return { frontendInformation: { buildVersion: version } };
  },
  apollo: {
    information: {
      query: SYSTEM_INFO,
      update(data) {
        this.$emit('input', data.information);
        return data.information;
      },
    },
  },
};
</script>
