<template>
  <div>
    <slot />
  </div>
</template>

<script>
// clear layout for login page etc
export default {
  name: 'RawLayout',
};
</script>
