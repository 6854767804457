<template>
  <v-dialog fullscreen="fullscreen" hide-overlay="hide-overlay" transition="dialog-bottom-transition" v-model="dialog">
    <template v-slot:activator="{ attrs, on }">
      <slot name="activator" v-bind="{ attrs, on }"></slot>
    </template>
    <v-card>
      <v-toolbar class="sticky-top z-1" height="80" flat="flat">
        <slot name="header">
          <slot name="title"></slot>
          <v-spacer></v-spacer>
          <div>
            <v-btn outlined="outlined" icon="icon" color="grey" @click.stop="dialog = false">
              <v-icon large="large">mdi-close</v-icon>
            </v-btn>
          </div>
        </slot>
      </v-toolbar>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    show() {
      this.dialog = true;
    },
    hide() {
      this.dialog = false;
    },
  },
};
</script>
