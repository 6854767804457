<template>
  <div>
    <v-row align="center" no-gutters="no-gutters">
      <v-col>
        <h3>Wierzytelność</h3>
        <h3 class="text--secondary">{{ claim.accountNumber }}</h3>
      </v-col>
      <v-col class="text-right" cols="auto">
        <h1 class="success white--text px-4 rounded">{{ claim.sum | money }}</h1>
      </v-col>
    </v-row>
    <v-row class="my-8 pr-4 text--secondary text-right text-no-wrap">
      <v-col>
        <v-row>
          <v-col xs="12" sm="6" lg="auto"
            ><small>Kategoria I</small>
            <h2 class="line-dense">{{ claim.sumCatI | money }}</h2>
          </v-col>
          <v-col xs="12" sm="6" lg="3"
            ><small>Kategoria II</small>
            <h2 class="line-dense">{{ claim.sumCatII | money }}</h2>
          </v-col>
          <v-col xs="12" sm="6" lg="3"
            ><small>Kategoria III</small>
            <h2 class="line-dense">{{ claim.sumCatIII | money }}</h2>
          </v-col>
          <v-col xs="12" sm="6" lg="3"
            ><small>Kategoria IV</small>
            <h2 class="line-dense">{{ claim.sumCatIV | money }}</h2>
          </v-col>
        </v-row>
      </v-col>
      <v-col xs="12" sm="4" lg="3"
        ><small>Udział w głosowaniu</small>
        <h2 class="line-dense">{{ claim.sumForVote | money }}</h2>
      </v-col>
    </v-row>
    <v-card
      class="pa-4 success lighten-5"
      v-if="claim.protectionExists || claim.isConditional || claim.rightToDeduct"
      flat="flat"
    >
      <v-row v-if="claim.protectionExists">
        <v-col cols="auto">
          <v-icon>mdi-checkbox-marked-outline</v-icon>
        </v-col>
        <v-col
          ><span class="mb-1 text-middle font-weight-medium">Zabezpieczenie wierzytelności</span>
          <div class="text-pre-line">{{ claim.protectionExistsDescription }}</div>
        </v-col>
      </v-row>
      <v-row v-if="claim.isConditional">
        <v-col cols="auto">
          <v-icon>mdi-checkbox-marked-outline</v-icon>
        </v-col>
        <v-col
          ><span class="mb-1 text-middle font-weight-medium">Warunek wierzytelności</span>
          <div class="text-pre-line">{{ claim.isConditionalDescription }}</div>
        </v-col>
      </v-row>
      <v-row v-if="claim.rightToDeduct">
        <v-col cols="auto">
          <v-icon>mdi-checkbox-marked-outline</v-icon>
        </v-col>
        <v-col
          ><span class="mb-1 text-middle font-weight-medium">Prawo potrącenia</span>
          <div class="text-pre-line">{{ claim.rightToDeductDescription }}</div>
        </v-col>
      </v-row>
    </v-card>
    <div class="pt-12" v-if="claim.courtCaseStatus">
      <small class="text--secondary">Informacja o stanie postępowania</small>
      <v-divider class="mt-1 mb-2"></v-divider>
      <div class="text-body-1 text-pre-line">{{ claim.courtCaseStatus }}</div>
    </div>
    <div class="pt-12" v-if="claim.justification">
      <small class="text--secondary">Uzasadnienie uznania wierzytelności</small>
      <v-divider class="mt-1 mb-2"></v-divider>
      <div class="text-body-1 text-pre-line">{{ claim.justification }}</div>
    </div>
    <div class="pt-12" v-if="claim.justificationForDismissal">
      <small class="text--secondary">Uzasadnienie zaprzeczenia przez syndyka oświadczeniom wierzyciela</small>
      <v-divider class="mt-1 mb-2"></v-divider>
      <div class="text-body-1 text-pre-line">{{ claim.justificationForDismissal }}</div>
    </div>
    <div class="pt-12" v-if="claim.debtorClaim">
      <small class="text--secondary">Oświadczenie updadłego z uzasadnieniem</small>
      <v-divider class="mt-1 mb-2"></v-divider>
      <div class="text-body-1 text-pre-line">{{ claim.debtorClaim }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    claim: { type: Object, required: true },
  },
};
</script>
