const court = 'Sąd';
const debtor = 'Wierzyciel';
const outgoing = 'Wychodząca';
const incoming = 'Przychodząca';
const others = 'Inne';
const unknown = 'Nieznany';

const incomingCourt = `${incoming}, ${court}`;
const outgoingCourt = `${outgoing}, ${court}`;
const incomintDebtor = `${incoming}, ${debtor}`;
const outgoingDebtor = `${outgoing}, ${debtor}`;

const incomingOthers = `${incoming}, ${others}`;
const outgoingOthers = `${outgoing}, ${others}`;

export const documentKind = (kind) => {
  switch (kind) {
    case 'INCOMING_COURT':
      return { name: `${incomingCourt}`, class: 'success' };
    case 'OUTGOING_COURT':
      return { name: `${outgoingCourt}`, class: 'orange' };
    case 'INCOMING_CREDITOR':
      return { name: `${incomintDebtor}`, class: 'blue' };
    case 'OUTGOING_CREDITOR':
      return { name: `${outgoingDebtor}`, class: 'red' };
    case 'INCOMING_OTHER':
      return { name: `${incomingOthers}`, class: 'purple' };
    case 'OUTGOING_OTHER':
      return { name: `${outgoingOthers}`, class: 'purple' };
    default:
      return { name: `${unknown}`, class: 'gray' };
  }
};

export const getSenderOrReceiverPrefix = (value) => {
  switch (value) {
    case 'INCOMING_COURT':
    case 'INCOMING_CREDITOR':
    case 'INCOMING_OTHER':
      return 'od: ';
    case 'OUTGOING_COURT':
    case 'OUTGOING_CREDITOR':
    case 'OUTGOING_OTHER':
      return 'do: ';
    default:
      return 'Brak';
  }
};
export const getSenderOrReceiver = (value) => {
  switch (value) {
    case 'INCOMING_COURT':
      return 'sąd';
    case 'INCOMING_CREDITOR':
      return 'wierzyciel';
    case 'INCOMING_OTHER':
      return 'inne';
    case 'OUTGOING_COURT':
      return 'sąd';
    case 'OUTGOING_CREDITOR':
      return 'wierzyciel';
    case 'OUTGOING_OTHER':
      return 'inne';
    default:
      return 'Brak';
  }
};

export default {};
