<template>
  <v-card v-if="proceedings">
    <v-row>
      <v-col>
        <v-card-title class="px-8">
          <h4 class="secondary--text">Najnowsze postępowania</h4>
        </v-card-title>
      </v-col>
      <v-col cols="auto">
        <v-card-actions class="px-8">
          <v-btn
            class="px-4"
            :to="{ name: 'proceeding-create' }"
            depressed="depressed"
            rounded="rounded"
            large="large"
            color="secondary"
          >
            <v-icon>mdi-plus-circle</v-icon><span class="px-2">Utwórz postępowanie</span>
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <div class="pa-4">
      <v-list class="rounded overflow-hidden">
        <template v-for="item in proceedings.items">
          <proceeding-list-item :key="item.id" :item="item"></proceeding-list-item>
          <v-divider :key="item.id" class="last-child--hidden"></v-divider>
        </template>
      </v-list>
      <div class="mt-2 text-center">
        <v-btn :to="{ name: 'proceedings' }" rounded="rounded" text="text" color="primary"
          >Zobacz wszystkie ({{ proceedings.count }})</v-btn
        >
      </div>
    </div>
  </v-card>
</template>

<script>
import ProceedingListItem from '@/v2/components/Proceeding/ListItem.vue';
import PROCEEDING_LIST_QUERY from '@/graphql/proceeding/query/ProceedingListQuery.gql';

export default {
  components: {
    ProceedingListItem,
  },
  apollo: {
    proceedings: {
      query: PROCEEDING_LIST_QUERY,
      variables: {
        query: {
          filters: [{ field: 'status', type: 'EQ', value: 'ACTIVE' }],
          orders: [{ field: 'createdAt', direction: 'DESC' }],
        },
        offset: 0,
        limit: 5,
      },
      update: (data) => {
        return data.list;
      },
    },
  },
};
</script>
