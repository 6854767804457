<template>
  <v-dialog
    max-width="650px"
    :fullscreen="false"
    :scrollable="false"
    transition="dialog-bottom-transition"
    :value="dialog"
    @close="close"
    @click:outside="close"
  >
    <template v-slot:activator="{ attrs, on }">
      <slot name="activator" :attrs="attrs" :on="on"></slot>
    </template>
    <v-card v-if="item">
      <v-list>
        <v-list-item>
          <v-list-item-avatar :color="taskStatusColor(item)">
            <v-icon dark="dark">{{ taskStatusIcon(item.status) }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="my-1 font-weight-bold">
              <v-row class="flex-nowrap">
                <v-col class="text-truncate">{{ item.name }}</v-col>
                <v-col cols="auto"
                  ><span class="text--secondary" v-if="item.finishedAt">{{ item.finishedAt | date }}</span
                  ><span v-else-if="item.due" :class="{ 'error--text': isTaskDelayed(item) }">{{
                    item.due | date
                  }}</span></v-col
                >
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-text>
        <div class="mt-4 mb-2"><small class="text--subtitle">Postępowanie</small></div>
        <v-row>
          <v-col cols="auto">
            <h3>{{ proceeding.signature }}</h3>
          </v-col>
          <v-col class="text-truncate">
            <h3 class="mr-8" v-if="debtor">{{ debtor.name }} {{ debtor.surname }}</h3>
          </v-col>
        </v-row>
        <div class="text-subtitle-1 font-weight-medium my-1">{{ proceeding.court.name }}</div>
        <div class="mt-6 mb-2"><small class="text--subtitle">Osoba odpowiedzialna</small></div>
        <h3>
          <span v-if="item.user">{{ item.user.firstName }} {{ item.user.lastName }}</span
          ><span v-else>{{ proceeding.advisor.firstName }} {{ proceeding.advisor.lastName }}</span>
        </h3>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ml-2" text="text" color="grey" @click="close">Zamknij</v-btn>
        <v-btn class="ml-2" :to="taskRoute(proceeding, item)" text="text" color="primary">Otwórz</v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="pa-8 text-center" v-else>
      <v-progress-circular indeterminate="indeterminate" color="blue-grey"></v-progress-circular>
    </v-card>
  </v-dialog>
</template>

<script>
import { taskRoute, taskStatusColor, taskStatusIcon, isTaskDelayed } from '@/v2/components/Task/Mixin';
import TASK_DETAILS_QUERY from '@/graphql/task/query/TaskDetailsQuery.gql';

export default {
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: true,
    };
  },
  apollo: {
    item: {
      query: TASK_DETAILS_QUERY,
      variables() {
        return {
          taskId: this.taskId,
        };
      },
      update: (data) => {
        return data.list.items[0];
      },
    },
  },
  computed: {
    proceeding() {
      return this.item.schedule.proceeding;
    },
    debtor() {
      return this.proceeding.debtors.length ? this.proceeding.debtors[0] : null;
    },
  },
  methods: {
    taskRoute,
    taskStatusColor,
    taskStatusIcon,
    isTaskDelayed,
    show() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit('close');
    },
  },
};
</script>
