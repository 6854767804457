<template>
  <div class="pdf">
    <div class="pdf-pages" v-for="i in numPages" :key="i">
      <pdf-vuer class="d-inline-block full-width" :src="src" :page="i">
        <template v-slot:loading>
          <v-progress-linear></v-progress-linear>
        </template>
      </pdf-vuer>
    </div>
  </div>
</template>

<script>
import PdfVuer from 'pdfvuer';
import { getAuthToken } from '@/plugins/apolloAuthTokenLink';

export default {
  components: {
    PdfVuer,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  watch: {
    url(url) {
      this.load(url);
    },
  },
  data() {
    return {
      src: Promise.resolve(),
      numPages: undefined,
    };
  },
  mounted() {
    this.load(this.url);
  },
  methods: {
    async load(url) {
      const token = await getAuthToken();
      const headers = {
        authorization: token ? `Bearer ${token}` : '',
      };
      this.src = PdfVuer.createLoadingTask({ url, withCredentials: true, httpHeaders: headers });
      this.src.then((pdfResult) => {
        this.numPages = pdfResult.numPages;
      });
    },
  },
};
</script>

<style scoped>
/* .page {
  box-shadow: 1px 1px 20px 1px black;
  width: 95%;
  margin-left: 2.5%;
  margin-top: 2.5%;
}
.pdf-pages {
  padding-right: 50px;
}
.pdf {
  width: 100%;
  min-height: 100%;
  background: grey;
  padding-top: 1%;
} */
</style>
