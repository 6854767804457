<template>
  <v-card outlined="outlined">
    <v-card-actions class="pa-4">
      <v-btn v-if="isSelected" icon="icon" color="primary" @click="$emit('select')">
        <v-icon x-large="x-large">mdi-checkbox-marked</v-icon>
      </v-btn>
      <v-btn v-else icon="icon" color="grey" @click="$emit('select')">
        <v-icon x-large="x-large">mdi-checkbox-blank-outline</v-icon>
      </v-btn>
      <h4 class="px-4">{{ envelope.trackingNumber || 'Brak numeru nadania' }}</h4>
    </v-card-actions>
    <div class="px-4 pb-4">
      <v-row align="stretch">
        <v-col cols="6" lg="4">
          <v-card class="pa-4 info lighten-5 full-height" flat="flat">
            <h4>{{ envelope.sender.name }}</h4>
            <div class="font-weight-medium">{{ envelope.sender.address.street }}</div>
            <div class="font-weight-medium">
              {{ envelope.sender.address.postCode }} {{ envelope.sender.address.city }}
            </div>
          </v-card>
        </v-col>
        <v-col cols="6" lg="4">
          <v-card class="pa-4 success lighten-5 full-height" flat="flat">
            <h4>{{ envelope.recipient.name }}</h4>
            <div class="font-weight-medium">{{ envelope.recipient.address.street }}</div>
            <div class="font-weight-medium">
              {{ envelope.recipient.address.postCode }} {{ envelope.recipient.address.city }}
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card class="yellow lighten-4 full-height" flat="flat">
            <v-list class="transparent">
              <template v-for="document in envelope.documents">
                <v-list-item class="py-0" :key="document.id">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">{{ document.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ document.proceeding.signature }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="document.id" class="last-child--hidden"></v-divider>
              </template>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    envelope: { type: Object, required: true },
    isSelected: { type: Boolean, default: false },
  },
};
</script>
