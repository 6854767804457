<template>
  <layout-main title="Postępowania">
    <v-container fluid="fluid">
      <v-card>
        <v-row align="center">
          <v-col>
            <v-card-actions class="px-8">
              <v-menu right="right" bottom="bottom" offset-y="offset-y" :nudge-bottom="6" :nudge-left="8">
                <template v-slot:activator="{ on, attrs }">
                  <div class="grey lighten-4 rounded-circle">
                    <v-btn fab="fab" outlined="outlined" small="small" color="grey" v-bind="attrs" v-on="on">
                      <v-icon>{{ queryStatusFilterIcon }}</v-icon>
                    </v-btn>
                  </div>
                </template>
                <v-list dense="dense">
                  <v-list-item @click="setFilter('ACTIVE')">
                    <v-list-item-icon>
                      <v-icon>mdi-gavel</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Aktywne</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="setFilter('FINISHED_NON_ARCHIVED')">
                    <v-list-item-icon>
                      <v-icon>mdi-check-all</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Zakończone</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="setFilter('FINISHED_ARCHIVED')">
                    <v-list-item-icon>
                      <v-icon>mdi-archive-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Zarchiwizowane</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-responsive class="mx-4" max-width="260">
                <search-input v-model="search"></search-input>
              </v-responsive>
            </v-card-actions>
          </v-col>
          <v-col cols="auto">
            <v-card-actions class="px-8">
              <v-btn
                class="px-4"
                :to="{ name: 'proceeding-create' }"
                depressed="depressed"
                rounded="rounded"
                large="large"
                color="secondary"
              >
                <v-icon>mdi-plus-circle</v-icon><span class="px-2">Utwórz postępowanie</span>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <infinite-loading class="pa-4" ref="infinite" :load="load">
          <template v-slot:default="{ list }">
            <v-list class="rounded overflow-hidden">
              <template v-for="item in list.items">
                <proceeding-list-item :key="item.id" :item="item"></proceeding-list-item>
                <v-divider :key="item.id" class="last-child--hidden"></v-divider>
              </template>
            </v-list>
          </template>
        </infinite-loading>
      </v-card>
    </v-container>
  </layout-main>
</template>

<script>
import LayoutMain from '@/v2/layouts/Main.vue';
import SearchInput from '@/v2/components/Form/SearchInput.vue';
import InfiniteLoading from '@/v2/components/InfiniteLoading.vue';
import ProceedingListItem from '@/v2/components/Proceeding/ListItem.vue';
import PROCEEDING_LIST_QUERY from '@/graphql/proceeding/query/ProceedingListQuery.gql';

export default {
  components: {
    LayoutMain,
    SearchInput,
    InfiniteLoading,
    ProceedingListItem,
  },
  data() {
    return {
      search: '',
      filter: 'ACTIVE',
    };
  },
  computed: {
    queryStatusFilterIcon() {
      switch (this.filter) {
        case 'FINISHED_NON_ARCHIVED':
          return 'mdi-check-all';
        case 'FINISHED_ARCHIVED':
          return 'mdi-archive-outline';
        default:
          return 'mdi-filter-variant';
      }
    },
  },
  watch: {
    search() {
      this.$refs.infinite.reset();
    },
  },
  methods: {
    setFilter(filter) {
      this.filter = filter;
      this.$refs.infinite.reset();
    },
    async load(offset) {
      const filters = [{ field: 'status', type: 'EQ', value: this.filter }];
      if (this.search !== '') {
        filters.push({ field: 'search', type: 'EQ', value: this.search });
      }
      const { data } = await this.$apollo.query({
        query: PROCEEDING_LIST_QUERY,
        variables: {
          query: {
            filters,
            orders: [{ field: 'createdAt', direction: 'DESC' }],
          },
          offset,
          limit: 20,
        },
        fetchPolicy: 'network-only',
      });
      return data.list;
    },
  },
};
</script>
