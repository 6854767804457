<template>
  <v-dialog persistent="persistent" width="600" v-model="open">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :attrs="attrs" :on="on">
        <v-btn
          fab="fab"
          depressed="depressed"
          fixed="fixed"
          right="right"
          bottom="bottom"
          dark="dark"
          color="info"
          v-on="on"
        >
          <v-icon class="yellow--text" large="large">mdi-sticker-plus-outline</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card class="yellow lighten-4">
      <v-card-text class="pa-2">
        <v-textarea
          :style="{ maxHeight: '33vh', overflow: 'scroll' }"
          flat="flat"
          solo="solo"
          autofocus="autofocus"
          hide-details="hide-details"
          auto-grow="auto-grow"
          no-resize="no-resize"
          background-color="yellow lighten-4"
          placeholder="Napisz notatkę..."
          v-model="form.input.note.content"
        ></v-textarea>
      </v-card-text>
      <v-list class="yellow lighten-4" dense="dense">
        <template v-for="document in form.input.relatedDocuments">
          <v-divider :key="document.id"></v-divider>
          <v-list-item class="pl-3 hoverable" :key="document.id">
            <v-list-item-avatar class="my-0 mr-2">
              <v-icon>mdi-file-document-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ document.name }}</v-list-item-title>
            <v-list-item-action>
              <v-btn outlined="outlined" x-small="x-small" icon="icon" color="grey" @click="formInputDocumentRemove">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
        <template v-for="task in form.input.relatedTasks">
          <v-divider :key="task.id"></v-divider>
          <v-list-item class="pl-3 hoverable" :key="task.id">
            <v-list-item-avatar class="my-0 mr-2">
              <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ task.name }}</v-list-item-title>
            <v-list-item-action>
              <v-btn outlined="outlined" x-small="x-small" icon="icon" color="grey" @click="formInputTaskRemove(task)">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <v-divider></v-divider>
      <v-card-actions>
        <select-list-item :items="documents" @select="formInputDocumentAdd">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-1 mr-4"
              text="text"
              rounded="rounded"
              small="small"
              color="secondary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus-circle-outline</v-icon><span class="px-1">Dokument</span>
            </v-btn>
          </template>
        </select-list-item>
        <select-list-item :items="tasks" @select="formInputTaskAdd">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-1 mr-4"
              text="text"
              rounded="rounded"
              small="small"
              color="secondary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus-circle-outline</v-icon><span class="px-1">Zadanie</span>
            </v-btn>
          </template>
        </select-list-item>
        <v-spacer></v-spacer>
        <v-btn text="text" color="grey" @click="cancel">Anuluj</v-btn>
        <v-btn text="text" color="primary" :loading="loading" @click="save">Zapisz</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectListItem from '@/v2/components/SelectListItem.vue';
import ADD_NOTE_MUTATION from '@/graphql/note/mutation/AddProceedingNoteMutation.gql';
import EDIT_NOTE_MUTATION from '@/graphql/note/mutation/EditProceedingNoteMutation.gql';
import SET_RELATED_TASKS_MUTATION from '@/graphql/note/mutation/SetNoteRelatedTasksMutation.gql';
import SET_RELATED_DOCUMENTS_MUTATION from '@/graphql/note/mutation/SetNoteRelatedDocumentsMutation.gql';

export default {
  components: {
    SelectListItem,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
    note: {
      type: Object,
    },
  },
  data() {
    return {
      open: false,
      loading: false,
      form: {
        input: {
          note: {
            title: ' ',
            content: '',
          },
          relatedDocuments: [],
          relatedTasks: [],
        },
      },
    };
  },
  computed: {
    tasks() {
      return this.proceeding.schedules.length ? this.proceeding.schedules[0].tasks : [];
    },
    documents() {
      return this.proceeding.documents;
    },
  },
  watch: {
    open(open) {
      if (open) {
        if (this.note) {
          this.form.input.note.content = this.note.content;
          this.form.input.relatedDocuments = [...this.note.relatedDocuments];
          this.form.input.relatedTasks = [...this.note.relatedTasks];
        } else {
          this.form.input.note.content = '';
          this.form.input.relatedDocuments = [];
          this.form.input.relatedTasks = [];
        }
      }
    },
  },
  methods: {
    cancel() {
      this.open = false;
    },
    formInputTaskAdd(task) {
      this.form.input.relatedTasks.push(task);
    },
    formInputTaskRemove(task) {
      const index = this.form.input.relatedTasks.indexOf(task);
      this.form.input.relatedTasks.splice(index, 1);
    },
    formInputDocumentAdd(doc) {
      this.form.input.relatedDocuments.push(doc);
    },
    formInputDocumentRemove(doc) {
      const index = this.form.input.relatedDocuments.indexOf(doc);
      this.form.input.relatedDocuments.splice(index, 1);
    },
    async save() {
      if (this.form.input.note.content !== '') {
        this.loading = true;
        if (this.note) {
          await this.update();
        } else {
          await this.create();
        }
        this.loading = false;
      }
      this.open = false;
    },
    async create() {
      const { data } = await this.$apollo.mutate({
        mutation: ADD_NOTE_MUTATION,
        variables: {
          input: {
            proceedingId: this.proceeding.id,
            ...this.form.input.note,
          },
        },
      });
      await this.setRelatedTasks(data.note);
      const note = await this.setRelatedDocuments(data.note);
      this.$emit('add', note);
    },
    async update() {
      const { data } = await this.$apollo.mutate({
        mutation: EDIT_NOTE_MUTATION,
        variables: {
          input: {
            noteId: this.note.id,
            ...this.form.input.note,
          },
        },
      });
      await this.setRelatedTasks(data.note);
      const note = await this.setRelatedDocuments(data.note);
      this.$emit('update', note);
    },
    async setRelatedTasks(note) {
      const { data } = await this.$apollo.mutate({
        mutation: SET_RELATED_TASKS_MUTATION,
        variables: {
          input: {
            noteId: note.id,
            taskIds: this.form.input.relatedTasks.map((task) => task.id),
          },
        },
      });
      return data.note;
    },
    async setRelatedDocuments(note) {
      const { data } = await this.$apollo.mutate({
        mutation: SET_RELATED_DOCUMENTS_MUTATION,
        variables: {
          input: {
            noteId: note.id,
            documentIds: this.form.input.relatedDocuments.map((doc) => doc.id),
          },
        },
      });
      return data.note;
    },
  },
};
</script>
