export default (propertyName, object, defaultValue) => {
  const parts = propertyName.split('.');
  const { length } = parts;
  let i;
  let property = object || this;

  try {
    for (i = 0; i < length; i += 1) {
      property = property[parts[i]];
    }
  } catch {
    return defaultValue;
  }

  return property;
};
