<template>
  <v-form ref="form">
    <v-row>
      <v-col cols="12">
        <v-text-field
          outlined="outlined"
          label="Nazwa dokumentu"
          :rules="form.rules.name"
          v-model="form.input.edit.name"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          outlined="outlined"
          hide-details="hide-details"
          :menu-props="{ offsetY: true }"
          :items="form.helpers.kindItems"
          item-value="value"
          item-text="text"
          label="Adresat pisma"
          v-model="form.input.edit.kind"
        ></v-select>
      </v-col>
      <v-col>
        <date-input
          outlined="outlined"
          label="Data pisma"
          :rules="form.rules.date"
          v-model="form.input.edit.date"
        ></date-input>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>
        <select-list-item
          v-if="!document && form.input.edit.kind === 'OUTGOING_OTHER'"
          :items="entities"
          @select="entityAdd"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-input class="mb-2" :value="form.input.add.proceedingEntityIds">
              <v-btn
                class="full-width px-2"
                outlined="outlined"
                x-large="x-large"
                color="grey"
                v-bind="attrs"
                v-on="on"
              >
                <v-row class="px-2" align="center"
                  ><span class="px-2 black--text font-weight-regular">Wybierz podmiot(y)</span>
                  <v-spacer></v-spacer>
                  <v-icon large="large">mdi-plus-circle</v-icon>
                </v-row>
              </v-btn>
            </v-input>
          </template>
        </select-list-item>
      </v-col>
      <v-col>
        <card-numbers-field
          :proceeding-id="proceeding.id"
          label="Numery kart"
          v-model="form.input.edit.cardNumbers"
        ></card-numbers-field>
      </v-col>
    </v-row>
    <v-row v-if="form.input.add.proceedingEntityIds.length === 0">
      <v-col cols="12">
        <v-text-field
          outlined="outlined"
          label="Nazwa Adresata"
          v-model="form.input.edit.recipient.name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          outlined="outlined"
          label="Ulica"
          v-model="form.input.edit.recipient.address.street"
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="2">
        <v-text-field
          outlined="outlined"
          label="Kod pocztowy"
          v-model="form.input.edit.recipient.address.postCode"
        ></v-text-field>
      </v-col>
      <v-col cols="8" md="4">
        <v-text-field
          outlined="outlined"
          label="Miejscowość"
          v-model="form.input.edit.recipient.address.city"
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="pt-4 pb-12" v-else>
      <v-list class="rounded overflow-hidden" outlined="outlined">
        <template v-for="entity in form.helpers.entities">
          <v-list-item class="py-1 hoverable" :key="entity.id">
            <v-list-item-content>
              <v-list-item-title>
                <h4>{{ entity.name }}</h4>
              </v-list-item-title>
              <v-list-item-subtitle
                ><strong>{{ entity.address.name }}</strong>
                <div class="text--secondary">
                  {{ entity.address.street }}; {{ entity.address.postCode }} {{ entity.address.city }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn outlined="outlined" fab="fab" x-small="x-small" color="grey" @click="entityRemove(entity)">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="entity.id" class="last-child--hidden"></v-divider>
        </template>
      </v-list>
    </div>
  </v-form>
</template>

<script>
import { documentReplyType } from '@/v2/components/Document/Mixin';
import DateInput from '@/v2/components/Form/Date.vue';
import CardNumbersField from '@/components/FormFields/CardNumbersField.vue';
import SelectListItem from '@/v2/components/SelectListItem.vue';

export default {
  components: {
    DateInput,
    CardNumbersField,
    SelectListItem,
  },
  props: {
    proceeding: { type: Object, required: true },
    document: { type: Object },
    newDocumentName: { type: String, default: '' },
    replyTo: { type: Object },
  },
  data() {
    return {
      form: {
        input: {
          edit: {
            name: '',
            date: '',
            cardNumbers: {
              from: null,
              to: null,
            },
            kind: 'OUTGOING_OTHER',
            recipient: {
              name: '',
              address: {
                city: '',
                postCode: '',
                street: '',
              },
            },
          },
          add: {
            proceedingEntityIds: [],
            withRelatedTemplates: false,
          },
        },
        helpers: {
          kindItems: [
            { value: 'OUTGOING_COURT', text: 'Sąd' },
            { value: 'OUTGOING_CREDITOR', text: 'Dłużnik' },
            { value: 'OUTGOING_OTHER', text: 'Podmiot' },
          ],
          entities: [],
        },
        rules: {
          name: [(v) => !!v || 'Podaj nazwę dokumentu'],
          date: [(v) => !!v || 'Podaj datę'],
        },
      },
    };
  },
  computed: {
    entities() {
      return this.proceeding.entities.filter((x) => !this.form.input.add.proceedingEntityIds.includes(x.id));
    },
  },
  created() {
    if (this.replyTo) {
      this.form.input.edit.kind = documentReplyType(this.replyTo.document);
      this.$nextTick(() => {
        this.setFormInputReceipient(this.replyTo.recipient.name, this.replyTo.recipient.address);
      });
    }
  },
  watch: {
    'document': {
      immediate: true,
      handler(document) {
        if (document) {
          this.form.input.edit.name = document.name || '';
          this.form.input.edit.date = document.receivedAt || '';
          this.form.input.edit.cardNumbers.from = this.form.input.edit.cardNumbers.from || null;
          this.form.input.edit.cardNumbers.to = this.form.input.edit.cardNumbers.to || null;
          this.form.input.edit.kind = document.kind || 'OUTGOING_OTHER';
          this.form.input.edit.recipient.name = document.recipient.name || '';
          if (document.recipient.address) {
            this.form.input.edit.recipient.address.street = document.recipient.address.street || '';
            this.form.input.edit.recipient.address.postCode = document.recipient.address.postCode || '';
            this.form.input.edit.recipient.address.city = document.recipient.address.city || '';
          }
        } else {
          this.form.input.edit.name = this.newDocumentName;
        }
      },
    },
    'form.input': {
      deep: true,
      handler(input) {
        this.$emit('input', input);
      },
    },
    'form.input.edit.kind': {
      handler(kind) {
        switch (kind) {
          case 'OUTGOING_COURT':
            this.form.helpers.entities = [];
            this.setFormInputReceipient(this.proceeding.court.name, this.proceeding.court.address);
            break;
          case 'OUTGOING_CREDITOR':
            this.form.helpers.entities = [];
            this.setFormInputReceipient(
              `${this.proceeding.debtors[0].name} ${this.proceeding.debtors[0].surname}`,
              this.proceeding.debtors[0].address,
            );
            break;
          case 'OUTGOING_OTHER':
            this.form.helpers.entities = [];
            this.setFormInputReceipient('', {});
            break;
          default:
            break;
        }
      },
    },
    'form.helpers.entities': {
      handler(entities) {
        this.form.input.add.proceedingEntityIds = entities.map((e) => e.id);
      },
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    setFormInputReceipient(name, address) {
      this.form.input.edit.recipient.name = name || '';
      this.form.input.edit.recipient.address.street = address.street || '';
      this.form.input.edit.recipient.address.postCode = address.postCode || '';
      this.form.input.edit.recipient.address.city = address.city || '';
    },
    entityAdd(entity) {
      this.form.helpers.entities.push(entity);
      this.setFormInputReceipient('', {});
    },
    entityRemove(entity) {
      const index = this.form.helpers.entities.findIndex((x) => x === entity);
      this.form.helpers.entities.splice(index, 1);
    },
  },
};
</script>
