<template>
  <v-list-item class="py-2 px-4" :to="route">
    <v-list-item-avatar :color="statusColor">
      <v-icon dark="dark">{{ statusIcon }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="my-1 font-weight-bold">
        <v-row class="flex-nowrap">
          <v-col cols="auto">{{ item.signature }}</v-col>
          <v-col v-if="debtor">{{ debtor.name }} {{ debtor.surname }}</v-col>
          <v-col cols="auto"
            ><span v-if="tasksToDo.length"
              ><span :class="{ 'error--text': tasksToDoDelayed.length }">Zadania:&nbsp;</span
              ><span class="error--text" v-if="tasksToDoDelayed.length">{{ tasksToDoDelayed.length }}</span
              ><span
                class="font-weight-regular"
                v-if="tasksToDoDelayed.length && tasksToDo.length > tasksToDoDelayed.length"
                >&nbsp;+&nbsp;</span
              ><span class="primary--text" v-if="tasksToDo.length - tasksToDoDelayed.length">{{
                tasksToDo.length - tasksToDoDelayed.length
              }}</span></span
            ><span class="last-child--hidden">,&nbsp;</span
            ><span v-if="documentsInProgress.length + documentsToSend.length"
              ><span :class="{ 'error--text': documentsToSend.length }">Pisma:&nbsp;</span
              ><span class="error--text" v-if="documentsToSend.length">{{ documentsToSend.length }}</span
              ><span class="font-weight-regular" v-if="documentsInProgress.length && documentsToSend.length"
                >&nbsp;+&nbsp;</span
              ><span class="primary--text" v-if="documentsInProgress.length">{{
                documentsInProgress.length
              }}</span></span
            ></v-col
          >
        </v-row>
      </v-list-item-title>
      <v-list-item-subtitle>
        <v-row>
          <v-col class="text-truncate">{{ item.court.name }}</v-col>
          <v-col cols="auto">{{ item.advisor.firstName }} {{ item.advisor.lastName }}</v-col>
          <v-col cols="auto">{{ item.createdAt | date }}</v-col>
        </v-row>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    debtor() {
      return this.item.debtors.length ? this.item.debtors[0] : null;
    },
    tasksToDo() {
      const tasks = this.item.schedules.reduce((output, schedule) => [...output, ...schedule.tasks], []);
      return tasks.filter((task) => task.status !== 'FINISHED');
    },
    tasksToDoDelayed() {
      return this.tasksToDo.filter((task) => task.due && new Date(task.due) < new Date());
    },
    documentsInProgress() {
      return this.item.documents.filter((document) => document.status === 'EDITION_IN_PROGRESS');
    },
    documentsToSend() {
      return this.item.documents.filter((document) => document.status === 'FINISHED');
    },
    route() {
      return {
        name: 'proceeding',
        params: {
          id: this.item.id,
        },
      };
    },
    statusIcon() {
      switch (this.item.status) {
        case 'ACTIVE':
          return 'mdi-gavel';
        case 'FINISHED_NON_ARCHIVED':
          return 'mdi-check-all';
        case 'FINISHED_ARCHIVED':
          return 'mdi-archive-outline';
        default:
          return 'mdi-exclamation';
      }
    },
    statusColor() {
      switch (this.item.status) {
        case 'ACTIVE':
          return 'primary';
        case 'FINISHED_NON_ARCHIVED':
        case 'FINISHED_ARCHIVED':
          return 'grey';
        default:
          return 'warning';
      }
    },
  },
};
</script>
