<template>
  <div>
    <v-card-actions class="pl-4 pr-3">
      <div class="text-body1 text--secondary">Powiązane dokumenty</div>
      <v-spacer></v-spacer>
      <select-list-item :items="documents" @select="add">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="secondary"
            outlined="outlined"
            small="small"
            icon="icon"
            color="white"
            :loading="adding"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </select-list-item>
    </v-card-actions>
    <v-list dense="dense">
      <template v-for="document in document.relatedDocuments">
        <v-divider :key="document.id"></v-divider>
        <v-list-item class="pl-1" :to="documentRoute(proceeding, document)" :key="document.id" @click.stop="">
          <v-list-item-avatar class="ma-0">
            <v-icon>mdi-file-document-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ document.name }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import { documentRoute } from '@/v2/components/Document/Mixin';
import SelectListItem from '@/v2/components/SelectListItem.vue';
import SET_RELATED_DOCUMENTS_MUTATION from '@/graphql/document/mutation/SetDocumentRelatedDocuments.gql';

export default {
  components: {
    SelectListItem,
  },
  props: {
    proceeding: { type: Object, required: true },
    documents: { type: Array, required: true },
    document: { type: Object, required: true },
  },
  data() {
    return {
      adding: false,
      removing: '',
    };
  },
  methods: {
    documentRoute,
    async add(document) {
      this.adding = true;
      const relatedDocumentIds = this.document.relatedDocuments.map((x) => x.id);
      relatedDocumentIds.push(document.id);
      const relatedDocuments = await this.setRelatedDocuments(this.document, relatedDocumentIds);
      this.$emit('update', relatedDocuments);
      this.adding = false;
    },
    async remove(document) {
      this.removing = document.id;
      const relatedDocumentIds = this.document.relatedDocuments.map((x) => x.id).filter((x) => x.id !== document.id);
      const relatedDocuments = await this.setRelatedDocuments(this.document, relatedDocumentIds);
      this.$emit('update', relatedDocuments);
      this.removing = '';
    },
    async setRelatedDocuments(document, relatedDocumentIds) {
      const { data } = await this.$apollo.mutate({
        mutation: SET_RELATED_DOCUMENTS_MUTATION,
        variables: {
          input: {
            documentId: document.id,
            documentIds: relatedDocumentIds,
          },
        },
      });
      return data.document.relatedDocuments;
    },
  },
};
</script>
