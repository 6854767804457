import { render, staticRenderFns } from "./SimpleWysiwygField.vue?vue&type=template&id=5c88c391&"
import script from "./SimpleWysiwygField.vue?vue&type=script&lang=js&"
export * from "./SimpleWysiwygField.vue?vue&type=script&lang=js&"
import style0 from "./SimpleWysiwygField.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VMessages } from 'vuetify/lib/components/VMessages';
installComponents(component, {VLabel,VMessages})
