import { setContext } from 'apollo-link-context';
import { getToken, saveToken, saveRefreshToken } from '@/plugins/apollo'; // eslint-disable-line import/no-cycle
import getNewToken from '@/plugins/apolloGetTokenHelper'; // eslint-disable-line import/no-cycle

function isTokenExpired(JWT) {
  const payload = JSON.parse(window.atob(JWT.split('.')[1]));
  const expireAt = payload.exp;
  const expireAtTolaranceTime = 60;
  return Math.floor(new Date().getTime() / 1000) >= expireAt - expireAtTolaranceTime;
}

async function refreshAuthToken() {
  try {
    const { accessToken, refreshToken } = await getNewToken();
    saveToken(accessToken);
    saveRefreshToken(refreshToken);
    return accessToken;
  } catch (err) {
    return '';
  }
}

let refreshAuthTokenPromise = null;

export async function getAuthToken() {
  const currentToken = getToken();
  if (!currentToken) {
    return '';
  }
  if (!isTokenExpired(currentToken)) {
    return currentToken;
  }
  if (!refreshAuthTokenPromise) {
    refreshAuthTokenPromise = refreshAuthToken();
  }
  const newToken = await refreshAuthTokenPromise;
  refreshAuthTokenPromise = null;
  return newToken;
}

async function setContextFunction(_, { headers }) {
  const token = await getAuthToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
}

const asyncAuthLink = setContext(setContextFunction);
export default asyncAuthLink;
