<template>
  <!-- <span> -->
  <v-row>
    <v-col>
      <v-text-field outlined clearable v-bind="$props" v-model="from" :label="`${label} od`" type="number" />
    </v-col>
    <v-col>
      <v-text-field
        outlined
        v-bind="$props"
        v-model="to"
        label="do"
        :disabled="!from"
        :placeholder="`${from}`"
        type="number"
      />
    </v-col>
    <!--      <v-col>-->
    <!--        <v-btn class="mt-2" outlined @click="clear">Wyczyść</v-btn>-->
    <!--      </v-col>-->
  </v-row>
  <!-- <VMessages v-if="shouldValidate" :value="errorBucket" color="error" />
  </span> -->
</template>

<script>
import { VInput } from 'vuetify/lib';
// import { VInput, VMessages } from 'vuetify/lib';
import PROCEEDING_DETAILS_QUERY from '@/graphql/proceeding/query/ProceedingDetailsQuery.gql';

export default {
  name: 'CardNumbersField',
  extends: VInput,
  props: {
    value: { type: Object },
    proceedingId: { type: String, default: null },
    label: String,
  },
  // components: {
  //   VMessages,
  // },
  data() {
    return {
      first: true,
      from: null,
      to: null,
    };
  },
  computed: {
    highestCardNumber() {
      if (!this.proceeding) {
        return null;
      }
      return this.proceeding.highestCardNumber + 1;
    },
  },
  watch: {
    proceeding() {
      if (this.from === null) {
        this.from = this.proceeding.highestCardNumber + 1;
        this.to = this.from;
      }
    },
    from() {
      this.emitCurrentValues();
    },
    to() {
      this.emitCurrentValues();
    },
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (this.first && this.from === null) {
          this.from = this.highestCardNumber;
          this.to = this.from;
          this.first = false;
        }
        if (newValue === null) {
          return;
        }
        if (newValue.from) {
          this.from = newValue.from;
          this.to = Math.max(this.to || 0, this.from);
        }
        if (newValue.to) {
          this.to = Math.max(newValue.to || 0, this.from);
        }
        if (typeof newValue.__typename !== 'undefined') {
          this.emitCurrentValues();
        }
      },
    },
  },
  methods: {
    clear() {
      this.from = null;
      this.to = null;
    },
    emitCurrentValues() {
      let { from } = this;
      let { to } = this;
      // if (to < from) {
      //   to = from;
      // }
      if (from) {
        from = parseInt(from, 10);
        to = Math.max(to || 0, from);
        this.to = to;
      } else {
        from = null;
        to = null;
        this.to = null;
      }
      this.$emit('input', {
        from,
        to,
      });
    },
  },
  apollo: {
    proceeding: {
      skip() {
        return this.proceedingId === null;
      },
      query: PROCEEDING_DETAILS_QUERY,
      variables() {
        return {
          proceedingId: this.proceedingId,
        };
      },
      update: (data) => {
        return data.list.items[0];
      },
    },
  },
};
</script>
