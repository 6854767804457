<template>
  <v-list-item
    class="py-2"
    :to="{ name: 'proceeding-finance-account', params: { accountId: account.id } }"
    exact="exact"
  >
    <v-list-item-avatar color="primary">
      <v-icon class="white--text">mdi-swap-horizontal-bold</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="font-weight-bold">{{ account.name }}</v-list-item-title>
      <v-list-item-subtitle class="font-weight-medium mt-1">{{ account.number }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <h4>{{ balance | money }}</h4>
      <small class="text--secondary">{{ account.updatedAt | date }}</small>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  computed: {
    balance() {
      return {
        amount: this.account.operations.reduce((balance, operation) => balance + operation.sum.amount, 0),
        currency: {
          code: 'PLN',
        },
      };
    },
  },
};
</script>
