import Vue from 'vue';
import vuetify from '@/plugins/vuetify';

import VuetifyDialog from 'vuetify-dialog';
import 'vuetify-dialog/dist/vuetify-dialog.css';

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
  confirm: {
    actions: {
      false: {
        text: 'Anuluj',
        color: 'grey',
      },
      true: {
        text: 'OK',
        color: 'primary',
      },
    },
    icon: false, // to disable icon just put false
    // width: 500
  },
  warning: {},
  error: {},
  prompt: {},
});

export default VuetifyDialog;
