import Home from '@/v2/pages/Home.vue';
import Tasks from '@/v2/pages/Tasks.vue';
import Calendar from '@/v2/pages/Calendar.vue';
import Proceedings from '@/v2/pages/Proceedings.vue';
import Shipment from '@/v2/pages/Shipment.vue';

import Proceeding from '@/v2/pages/proceeding/Proceeding.vue';
import ProceedingCreate from '@/v2/pages/proceeding/ProceedingCreate.vue';
import ProceedingInfo from '@/v2/pages/proceeding/ProceedingInfo.vue';
import ProceedingInfoEdit from '@/v2/pages/proceeding/ProceedingInfoEdit.vue';
import ProceedingTasks from '@/v2/pages/proceeding/ProceedingTasks.vue';
import ProceedingDocuments from '@/v2/pages/proceeding/ProceedingDocuments.vue';
import ProceedingDocumentInfo from '@/v2/pages/proceeding/ProceedingDocumentInfo.vue';
import ProceedingDocumentEditData from '@/v2/pages/proceeding/ProceedingDocumentEditData.vue';
import ProceedingDocumentEditContent from '@/v2/pages/proceeding/ProceedingDocumentEditContent.vue';
import ProceedingDocumentAttach from '@/v2/pages/proceeding/ProceedingDocumentAttach.vue';
import ProceedingDocumentWrite from '@/v2/pages/proceeding/ProceedingDocumentWrite.vue';
import ProceedingEntities from '@/v2/pages/proceeding/ProceedingEntities.vue';
import ProceedingEntityInfo from '@/v2/pages/proceeding/ProceedingEntityInfo.vue';
import ProceedingEntityCreate from '@/v2/pages/proceeding/ProceedingEntityCreate.vue';
import ProceedingEntityEdit from '@/v2/pages/proceeding/ProceedingEntityEdit.vue';
import ProceedingFinance from '@/v2/pages/proceeding/ProceedingFinance.vue';
import ProceedingFinanceAccount from '@/v2/pages/proceeding/ProceedingFinanceAccount.vue';
import ProceedingFinanceAccountEdit from '@/v2/pages/proceeding/ProceedingFinanceAccountEdit.vue';

import CourtList from '@/v2/pages/courts/CourtList.vue';
import CourtEdit from '@/v2/pages/courts/CourtEdit.vue';
import JudgeEdit from '@/v2/pages/judges/JudgeEdit.vue';
import TemplateList from '@/v2/pages/templates/TemplateList.vue';
import TemplateEdit from '@/v2/pages/templates/TemplateEdit.vue';
import FragmentList from '@/v2/pages/fragments/FragmentList.vue';
import FragmentEdit from '@/v2/pages/fragments/FragmentEdit.vue';
import ScheduleList from '@/v2/pages/schedules/ScheduleList.vue';
import ScheduleEdit from '@/v2/pages/schedules/ScheduleEdit.vue';
import ContentList from '@/v2/pages/content/ContentList.vue';
import ContentDocument from '@/v2/pages/content/ContentDocument.vue';

import Login from '@/v2/pages/auth/Login.vue';
import UserProfile from '@/v2/pages/auth/UserProfile.vue';
import UserAdmin from '@/v2/pages/auth/UserAdmin.vue';
import UserEdit from '@/v2/pages/auth/UserEdit.vue';
import FinalizeRegistrationPage from '@/v2/pages/auth/FinalizeRegistrationPage.vue';
import FinalizePasswordResetPage from '@/v2/pages/auth/FinalizePasswordResetPage.vue';

import { requireAuth } from '@/router'; // eslint-disable-line import/no-cycle

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: requireAuth,
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'Auth' },
    component: Login,
  },
  {
    path: '/finalize-registration/:token',
    name: 'finalize-registration',
    meta: { layout: 'Auth' },
    component: FinalizeRegistrationPage,
    props: true,
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    meta: { layout: 'Auth' },
    component: FinalizePasswordResetPage,
    props: true,
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: UserProfile,
    beforeEnter: requireAuth,
  },
  {
    path: '/admin/users',
    name: 'users',
    component: UserAdmin,
    beforeEnter: requireAuth,
  },
  {
    path: '/admin/user/:id?',
    name: 'user-edit',
    component: UserEdit,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/courts',
    name: 'court-list',
    component: CourtList,
    beforeEnter: requireAuth,
  },
  {
    path: '/courts/:id?',
    name: 'court-edit',
    component: CourtEdit,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/courts/:courtId/judges/:id?',
    name: 'judge-edit',
    component: JudgeEdit,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/templates',
    name: 'template-list',
    component: TemplateList,
    beforeEnter: requireAuth,
  },
  {
    path: '/templates/edit/:id?',
    name: 'template-edit',
    component: TemplateEdit,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/templates/fragments',
    name: 'fragment-list',
    component: FragmentList,
    beforeEnter: requireAuth,
  },
  {
    path: '/templates/fragments/edit/:id?',
    name: 'fragment-edit',
    component: FragmentEdit,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/templates/schedules',
    name: 'schedule-list',
    component: ScheduleList,
    beforeEnter: requireAuth,
  },
  {
    path: '/templates/schedules/edit/:id?',
    name: 'schedule-edit',
    component: ScheduleEdit,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/content/:active?',
    name: 'content-list',
    component: ContentList,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/content/:active/:groupId/document/:id?',
    name: 'content-document',
    component: ContentDocument,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar,
    beforeEnter: requireAuth,
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: Tasks,
    beforeEnter: requireAuth,
  },
  {
    path: '/proceedings',
    name: 'proceedings',
    component: Proceedings,
    beforeEnter: requireAuth,
  },
  {
    path: '/proceedings/add',
    name: 'proceeding-create',
    component: ProceedingCreate,
    beforeEnter: requireAuth,
  },
  {
    path: '/proceedings/:id',
    component: Proceeding,
    beforeEnter: requireAuth,
    props: true,
    children: [
      {
        path: 'info',
        name: 'proceeding',
        component: ProceedingInfo,
        beforeEnter: requireAuth,
      },
      {
        path: 'info/edit',
        name: 'proceeding-edit',
        component: ProceedingInfoEdit,
        beforeEnter: requireAuth,
      },
      {
        path: 'tasks',
        name: 'proceeding-tasks',
        component: ProceedingTasks,
        beforeEnter: requireAuth,
      },
      {
        path: 'documents',
        name: 'proceeding-documents',
        component: ProceedingDocuments,
        beforeEnter: requireAuth,
      },
      {
        path: 'documents/attach',
        name: 'proceeding-document-attach',
        component: ProceedingDocumentAttach,
        beforeEnter: requireAuth,
        props: true,
      },
      {
        path: 'documents/write',
        name: 'proceeding-document-write',
        component: ProceedingDocumentWrite,
        beforeEnter: requireAuth,
        props: true,
      },
      {
        path: 'documents/:docid',
        name: 'proceeding-document-info',
        component: ProceedingDocumentInfo,
        beforeEnter: requireAuth,
        props: true,
      },
      {
        path: 'documents/:docid/edit-data',
        name: 'proceeding-document-edit-data',
        component: ProceedingDocumentEditData,
        beforeEnter: requireAuth,
        props: true,
      },
      {
        path: 'documents/:docid/edit-content',
        name: 'proceeding-document-edit-content',
        component: ProceedingDocumentEditContent,
        beforeEnter: requireAuth,
        props: true,
      },
      {
        path: 'entities',
        name: 'proceeding-entities',
        component: ProceedingEntities,
        beforeEnter: requireAuth,
      },
      {
        path: 'entities/add',
        name: 'proceeding-entity-create',
        component: ProceedingEntityCreate,
        beforeEnter: requireAuth,
        props: true,
      },
      {
        path: 'entities/edit/:entityId?',
        name: 'proceeding-entity-edit',
        component: ProceedingEntityEdit,
        beforeEnter: requireAuth,
        props: true,
      },
      {
        path: 'entities/:entityId',
        name: 'proceeding-entity-info',
        component: ProceedingEntityInfo,
        beforeEnter: requireAuth,
        props: true,
      },
      {
        path: 'finance',
        name: 'proceeding-finance',
        component: ProceedingFinance,
        beforeEnter: requireAuth,
      },
      {
        path: 'finance/edit/:accountId?',
        name: 'proceeding-finance-account-edit',
        component: ProceedingFinanceAccountEdit,
        beforeEnter: requireAuth,
        props: true,
      },
      {
        path: 'finance/:accountId',
        name: 'proceeding-finance-account',
        component: ProceedingFinanceAccount,
        beforeEnter: requireAuth,
        props: true,
      },
    ],
  },
  {
    path: '/shipment',
    name: 'shipment',
    component: Shipment,
    beforeEnter: requireAuth,
  },
];
