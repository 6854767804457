<template>
  <v-row>
    <v-col cols="12" md="8" lg="9" xl="10">
      <v-card class="overflow-hidden">
        <v-card-actions class="pa-4">
          <v-row align="center" dense="dense">
            <v-col cols="auto">
              <v-btn
                class="px-2"
                :to="{ name: 'proceeding-documents', params: { id: proceeding.id } }"
                outlined="outlined"
                rounded="rounded"
                color="secondary"
              >
                <v-icon>mdi-arrow-left-circle</v-icon><span class="px-2">Dokumenty</span>
              </v-btn>
            </v-col>
            <v-col class="text-right">
              <h4 class="pt-1 line-dense">{{ document.name }}</h4>
              <small class="text--secondary" v-if="document.status">{{ statusName }}</small>
            </v-col>
            <v-col v-if="document.__typename === 'ExternalDocument'" cols="auto">
              <select-list-item :items="templates || []" :items-search-fn="templateSearch" @select="reply">
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    class="px-2"
                    outlined="outlined"
                    fab="fab"
                    small="small"
                    color="secondary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-reply</v-icon>
                  </v-btn>
                </template>
              </select-list-item>
            </v-col>
            <template v-if="document.file">
              <v-col cols="auto">
                <v-btn outlined="outlined" fab="fab" small="small" color="secondary" @click="downloadPdf">
                  <v-icon>mdi-cloud-download</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn outlined="outlined" fab="fab" small="small" color="secondary" @click="print">
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-actions>
        <template v-if="document">
          <v-divider></v-divider>
          <v-row class="px-4 pt-4" v-if="document.status === 'EDITION_IN_PROGRESS'">
            <v-spacer></v-spacer>
            <v-col v-if="document.file" cols="auto">
              <v-btn
                outlined="outlined"
                rounded="rounded"
                color="secondary"
                :loading="loading"
                @click="editContentFinish"
                >Zakończ</v-btn
              >
            </v-col>
            <v-col cols="auto">
              <v-btn
                class="px-2"
                depressed="depressed"
                rounded="rounded"
                color="secondary"
                :disabled="loading"
                @click="editContent"
              >
                <v-icon>mdi-pencil-circle</v-icon><span class="px-2">Edytuj</span>
              </v-btn>
            </v-col>
          </v-row>
          <pdf-viewer
            v-if="document.file"
            :url="`${document.file.downloadUrl}?version=${document.file.checksum}`"
          ></pdf-viewer>
          <v-alert class="ma-4 text-center" v-else outlined="outlined" color="error">
            <v-icon class="error--text">mdi-alert</v-icon><span class="px-2 text-middle">Brak pliku PDF</span>
          </v-alert>
        </template>
      </v-card>
    </v-col>
    <v-col v-if="document" cols="12" md="4" lg="3" xl="2">
      <v-card class="overflow-hidden">
        <v-card-actions class="pl-3 pr-3">
          <v-avatar class="mr-2" size="32" :color="color">
            <v-icon class="white--text">{{ icon }}</v-icon>
          </v-avatar>
          <h3 class="grey--text">{{ recipientSenderType }}</h3>
          <v-spacer></v-spacer>
          <v-btn
            class="secondary align-self-start"
            v-if="document.acl_edit"
            outlined="outlined"
            small="small"
            icon="icon"
            color="white"
            @click="editInfo"
          >
            <v-icon small="small">mdi-pencil</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
        <div class="px-4 py-2">
          <h4 v-if="recipientSender.name">{{ recipientSender.name }}</h4>
          <template v-if="recipientSender.address"
            ><span>{{ recipientSender.address.street }} <br /></span
            ><span>{{ recipientSender.address.postCode }} {{ recipientSender.address.city }}</span></template
          >
        </div>
        <v-divider></v-divider>
        <div class="px-4 py-2">
          <v-row>
            <v-col>
              <div class="text-body1 text--secondary">Data</div>
            </v-col>
            <v-col cols="auto">
              <h4>{{ document.receivedAt | date }}</h4>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <div class="px-4 py-2">
          <v-row>
            <v-col>
              <div class="text-body1 text--secondary">Numery kart</div>
            </v-col>
            <v-col cols="auto">
              <h4>
                <span>{{ document.cardNumbers.from || '-' }}</span
                ><span>&nbsp;-&nbsp;</span><span>{{ document.cardNumbers.to || '-' }}</span>
              </h4>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <delivery-info
        class="mt-4"
        v-if="document.__typename === 'InternalDocument' && document.status !== 'EDITION_IN_PROGRESS'"
        :document="document"
        @update="$emit('document-update', $event)"
      ></delivery-info>
      <v-card class="mt-4 overflow-hidden">
        <v-card-actions class="px-3">
          <v-avatar class="mr-2" size="34" color="grey">
            <v-icon class="white--text">mdi-vector-link</v-icon>
          </v-avatar>
          <h3 class="grey--text">Powiązania</h3>
        </v-card-actions>
        <v-divider></v-divider>
        <div class="pa-4">
          <basic-switch :document="document" @document-update="$emit('document-update', $event)"></basic-switch>
        </div>
        <v-divider></v-divider>
        <related-documents
          :proceeding="proceeding"
          :documents="documents"
          :document="document"
          @update="$emit('udpate-document-related-documents', { document, relatedDocuments: $event })"
        ></related-documents>
        <v-divider></v-divider>
        <related-tasks
          :proceeding="proceeding"
          :tasks="tasks"
          :document="document"
          @update="$emit('udpate-document-related-tasks', { document, relatedTasks: $event })"
        ></related-tasks>
      </v-card>
      <note-view-card
        class="mt-4"
        v-for="note in notes"
        :key="note.id"
        :proceeding="proceeding"
        :note="note"
        @update="$emit('update', $event)"
      ></note-view-card>
    </v-col>
  </v-row>
</template>

<script>
import { mergeFiles, mergeFilesAndPrint } from '@/helpers/pdf/DocumentsMerger';
import {
  documentColor,
  documentIcon,
  documentRecipientSenderType,
  documentStatusName,
} from '@/v2/components/Document/Mixin';
import { filterTemplates } from '@/v2/components/Template/Mixin';
import SelectListItem from '@/v2/components/SelectListItem.vue';
import PdfViewer from '@/v2/components/Document/PdfViewer.vue';
import DeliveryInfo from '@/v2/components/Document/DeliveryInfo.vue';
import BasicSwitch from '@/v2/components/Document/BasicSwitch.vue';
import RelatedDocuments from '@/v2/components/Document/RelatedDocuments.vue';
import RelatedTasks from '@/v2/components/Document/RelatedTasks.vue';
import NoteViewCard from '@/v2/components/Note/ViewCard.vue';
import FINISH_DOCUMENT_MUTATION from '@/graphql/document/mutation/FinishDocumentMutation.gql';
import TEMPLATES_LIST_QUERY from '@/graphql/documentTemplate/query/DocumentTemplatesListQuery.gql';

export default {
  components: {
    SelectListItem,
    PdfViewer,
    DeliveryInfo,
    BasicSwitch,
    RelatedDocuments,
    RelatedTasks,
    NoteViewCard,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
    docid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  apollo: {
    templates: {
      query: TEMPLATES_LIST_QUERY,
      update: (data) => {
        return data.list.items;
      },
    },
  },
  computed: {
    statusName() {
      return documentStatusName(this.document.status);
    },
    color() {
      return documentColor(this.document);
    },
    icon() {
      return documentIcon(this.document);
    },
    recipientSenderType() {
      return documentRecipientSenderType(this.document);
    },
    recipientSender() {
      return this.document.recipient || this.document.recipientSender;
    },
    notes() {
      return this.proceeding.notes.filter((x) => x.relatedDocuments.find((d) => d.id === this.document.id));
    },
    tasks() {
      return this.proceeding.schedules.length ? this.proceeding.schedules[0].tasks : [];
    },
    documents() {
      return this.proceeding.documents;
    },
    document() {
      return this.documents.find((x) => x.id === this.docid);
    },
  },
  methods: {
    editInfo() {
      this.$router.push({
        name: 'proceeding-document-edit-data',
        params: {
          id: this.proceeding.id,
          docid: this.document.id,
        },
      });
    },
    editContent() {
      this.$router.push({
        name: 'proceeding-document-edit-content',
        params: {
          id: this.proceeding.id,
          docid: this.document.id,
        },
      });
    },
    async editContentFinish() {
      const agree = await this.$dialog.confirm({
        text: 'Uwaga: zakończonych pism nie można ponownie edytować!',
        title: 'Potwierdź zakończenie edycji pisma',
      });
      if (agree) {
        this.loading = true;
        const { data } = await this.$apollo.mutate({
          mutation: FINISH_DOCUMENT_MUTATION,
          variables: {
            documentId: this.document.id,
          },
        });
        this.$emit('document-update', data.finishDocument);
        this.loading = false;
      }
    },
    templateSearch(search) {
      const templates = this.templates || [];
      return filterTemplates(templates, search);
    },
    reply(template) {
      const replyTo = {
        document: this.document,
        recipient: this.recipientSender,
      };
      this.$emit('document-write-template', template);
      this.$emit('document-write-replyto', replyTo);
      this.$router.push({
        name: 'proceeding-document-write',
        params: { id: this.proceeding.id },
      });
    },
    downloadPdf() {
      mergeFiles([this.document.file.downloadUrl]);
    },
    print() {
      mergeFilesAndPrint([this.document.file.downloadUrl]);
    },
  },
};
</script>
