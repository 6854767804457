<template>
  <v-card v-if="tasks">
    <v-row>
      <v-col>
        <v-card-title class="px-8">
          <h4 class="secondary--text">Zadania do realizacji</h4>
        </v-card-title>
      </v-col>
      <v-col cols="auto">
        <v-card-actions class="px-8">
          <v-btn
            rounded="rounded"
            color="primary"
            :outlined="sort === 'due'"
            :depressed="sort === 'createdAt'"
            @click="sort = 'createdAt'"
          >
            <v-icon dark="dark">mdi-clock-outline</v-icon><span class="px-2">Najnowsze</span>
          </v-btn>
          <v-btn
            rounded="rounded"
            color="primary"
            :outlined="sort === 'createdAt'"
            :depressed="sort === 'due'"
            @click="sort = 'due'"
          >
            <v-icon dark="dark">mdi-camera-timer</v-icon><span class="px-2">Najbliższe</span>
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <div class="pa-4">
      <v-list class="rounded overflow-hidden">
        <template v-for="item in tasks.items">
          <task-list-item :key="item.id" :proceeding="item.schedule.proceeding" :item="item"></task-list-item>
          <v-divider :key="item.id" class="last-child--hidden"></v-divider>
        </template>
      </v-list>
      <div class="mt-2 text-center">
        <v-btn :to="{ name: 'tasks' }" rounded="rounded" text="text" color="primary"
          >Zobacz wszystkie ({{ tasks.count }})</v-btn
        >
      </div>
    </div>
  </v-card>
</template>

<script>
import TaskListItem from '@/v2/components/Task/ListItem.vue';
import TASK_LIST_QUERY from '@/graphql/task/query/TaskListQuery.gql';

export default {
  components: {
    TaskListItem,
  },
  data() {
    return {
      sort: 'due',
    };
  },
  computed: {
    sortDirection() {
      return this.sort === 'due' ? 'ASC_NULL_LAST' : 'DESC';
    },
  },
  apollo: {
    tasks: {
      query: TASK_LIST_QUERY,
      variables() {
        return {
          query: {
            filters: [{ field: 'status', type: 'NEQ', value: 'FINISHED' }],
            orders: [{ field: this.sort, direction: this.sortDirection }],
          },
          offset: 0,
          limit: 5,
        };
      },
      update: (data) => {
        return data.list;
      },
    },
  },
};
</script>
