<template>
  <v-form ref="form">
    <v-row>
      <v-col cols="12">
        <v-text-field
          outlined="outlined"
          label="Nazwa dokumentu"
          :rules="form.rules.name"
          v-model="form.input.edit.name"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          outlined="outlined"
          :menu-props="{ offsetY: true }"
          :items="form.helpers.kindItems"
          item-value="value"
          item-text="text"
          label="Rodzaj dokumentu"
          v-model="form.input.edit.kind"
        ></v-select>
      </v-col>
      <v-col>
        <date-input
          outlined="outlined"
          label="Data doręczenia"
          :rules="form.rules.receivedAt"
          v-model="form.input.edit.receivedAt"
        ></date-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-if="!document && ['INCOMING_OTHER', 'OUTGOING_OTHER'].includes(form.input.edit.kind)"
          outlined="outlined"
          return-object="return-object"
          clearable="clearable"
          :menu-props="{ offsetY: true }"
          :items="proceeding.entities"
          item-text="name"
          label="Powiąż z podmiotem"
          v-model="form.input.add.entity"
        ></v-select>
      </v-col>
      <v-col>
        <card-numbers-field
          :proceeding-id="proceeding.id"
          label="Numery kart"
          v-model="form.input.edit.cardNumbers"
        ></card-numbers-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          outlined="outlined"
          label="Nazwa Adresata"
          v-model="form.input.edit.recipientSender.name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          outlined="outlined"
          label="Ulica"
          v-model="form.input.edit.recipientSender.address.street"
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="2">
        <v-text-field
          outlined="outlined"
          label="Kod pocztowy"
          v-model="form.input.edit.recipientSender.address.postCode"
        ></v-text-field>
      </v-col>
      <v-col cols="8" md="4">
        <v-text-field
          outlined="outlined"
          label="Miejscowość"
          v-model="form.input.edit.recipientSender.address.city"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import DateInput from '@/v2/components/Form/Date.vue';
import CardNumbersField from '@/components/FormFields/CardNumbersField.vue';

export default {
  components: {
    DateInput,
    CardNumbersField,
  },
  props: {
    proceeding: { type: Object, required: true },
    document: { type: Object },
    newDocumentName: { type: String, default: '' },
  },
  data() {
    return {
      form: {
        input: {
          edit: {
            name: '',
            receivedAt: '',
            cardNumbers: {
              from: null,
              to: null,
            },
            kind: 'INCOMING_OTHER',
            recipientSender: {
              name: '',
              address: {
                city: '',
                postCode: '',
                street: '',
              },
            },
          },
          add: {
            entity: null,
          },
        },
        helpers: {
          kindItems: [
            { value: 'INCOMING_COURT', text: '(Sąd) Przychodzący' },
            { value: 'OUTGOING_COURT', text: '(Sąd) Wychodzący' },
            { value: 'INCOMING_CREDITOR', text: '(Dłużnik) Przychodzący' },
            { value: 'OUTGOING_CREDITOR', text: '(Dłużnik) Wychodzący' },
            { value: 'INCOMING_OTHER', text: '(Podmiot) Przychodzący' },
            { value: 'OUTGOING_OTHER', text: '(Podmiot) Wychodzący' },
          ],
        },
        rules: {
          name: [(v) => !!v || 'Podaj nazwę dokumentu'],
          receivedAt: [(v) => !!v || 'Podaj datę'],
        },
      },
    };
  },
  watch: {
    'document': {
      immediate: true,
      handler(document) {
        if (document) {
          this.form.input.edit.name = document.name || '';
          this.form.input.edit.receivedAt = document.receivedAt || '';
          this.form.input.edit.cardNumbers.from = this.form.input.edit.cardNumbers.from || null;
          this.form.input.edit.cardNumbers.to = this.form.input.edit.cardNumbers.to || null;
          this.form.input.edit.kind = document.kind || 'INCOMING_OTHER';
          this.form.input.edit.recipientSender.name = document.recipientSender.name || '';
          if (document.recipientSender.address) {
            this.form.input.edit.recipientSender.address.street = document.recipientSender.address.street || '';
            this.form.input.edit.recipientSender.address.postCode = document.recipientSender.address.postCode || '';
            this.form.input.edit.recipientSender.address.city = document.recipientSender.address.city || '';
          }
        } else {
          this.form.input.edit.name = this.newDocumentName;
        }
      },
    },
    'form.input': {
      deep: true,
      handler(input) {
        this.$emit('input', input);
      },
    },
    'form.input.edit.kind': {
      handler(kind, previously) {
        switch (kind) {
          case 'INCOMING_COURT':
          case 'OUTGOING_COURT':
            this.form.input.add.entity = null;
            this.setFormInputReceipientSender(this.proceeding.court.name, this.proceeding.court.address);
            break;
          case 'INCOMING_CREDITOR':
          case 'OUTGOING_CREDITOR':
            this.form.input.add.entity = null;
            this.setFormInputReceipientSender(this.proceeding.debtors[0].name, this.proceeding.debtors[0].address);
            break;
          case 'INCOMING_OTHER':
          case 'OUTGOING_OTHER':
            if (!['INCOMING_OTHER', 'OUTGOING_OTHER'].includes(previously)) {
              this.form.input.add.entity = null;
              this.setFormInputReceipientSender('', {});
            }
            break;
          default:
            break;
        }
      },
    },
    'form.input.add.entity': {
      handler(entity) {
        if (entity) {
          this.setFormInputReceipientSender(entity.name, entity.address);
        } else {
          this.setFormInputReceipientSender('', {});
        }
      },
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    setFormInputReceipientSender(name, address) {
      this.form.input.edit.recipientSender.name = name || '';
      this.form.input.edit.recipientSender.address.street = address.street || '';
      this.form.input.edit.recipientSender.address.postCode = address.postCode || '';
      this.form.input.edit.recipientSender.address.city = address.city || '';
    },
  },
};
</script>
