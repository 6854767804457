var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"overflow-hidden"},[_c('v-card-actions',{staticClass:"pa-4"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('h2',{staticClass:"secondary--text"},[_vm._v("Dokumenty")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('file-input',{attrs:{"accept":"application/pdf,.pdf"},on:{"input":_vm.attach},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-btn',{staticClass:"px-2",attrs:{"outlined":"outlined","rounded":"rounded","color":"secondary"},on:{"click":open}},[_c('v-icon',[_vm._v("mdi-attachment")]),_c('span',{staticClass:"px-2"},[_vm._v("Dokument")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('select-list-item',{attrs:{"items":_vm.templates || [],"items-search-fn":_vm.templateSearch},on:{"select":_vm.write},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-2",attrs:{"depressed":"depressed","rounded":"rounded","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-circle")]),_c('span',{staticClass:"px-2"},[_vm._v("Pismo")])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('div',{staticClass:"my-1"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v(_vm._s(item.name))]),_vm._l((item.tags),function(tag,index){return _c('v-chip',{key:index,staticClass:"font-weight-medium mr-1 mb-1",attrs:{"x-small":"x-small","dark":"dark","color":"grey"}},[_vm._v(_vm._s(tag))])})],2)])]}}])})],1)],1)],1),_c('v-list',[_vm._l((_vm.documents),function(document){return [_c('document-list-item',{key:document.id,attrs:{"document":document}}),_c('v-divider',{key:document.id,staticClass:"last-child--hidden"})]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }