<template>
  <layout-templates>
    <v-card>
      <v-row>
        <v-col>
          <v-card-actions class="px-8">
            <v-responsive max-width="260">
              <search-input v-model="search"></search-input>
            </v-responsive>
          </v-card-actions>
        </v-col>
        <v-col cols="auto">
          <v-card-actions class="px-8">
            <v-btn
              class="px-4"
              :to="{ name: 'fragment-edit' }"
              depressed="depressed"
              rounded="rounded"
              large="large"
              color="secondary"
            >
              <v-icon>mdi-plus-circle</v-icon><span class="px-2">Utwórz szablon</span>
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
      <div class="pa-4">
        <v-list class="rounded overflow-hidden">
          <template v-for="item in fragmentsFiltered">
            <fragment-list-item :key="item.id" :item="item"></fragment-list-item>
            <v-divider :key="item.id" class="last-child--hidden"></v-divider>
          </template>
        </v-list>
      </div>
    </v-card>
  </layout-templates>
</template>

<script>
import stringCompare from '@/v2/helpers/stringCompare';
import SearchInput from '@/v2/components/Form/SearchInput.vue';
import LayoutTemplates from '@/v2/layouts/Templates.vue';
import FragmentListItem from '@/v2/components/Fragment/ListItem.vue';
import FRAGMENT_LIST_QUERY from '@/graphql/quickPart/query/ListQuickPartsQuery.gql';

export default {
  components: {
    SearchInput,
    LayoutTemplates,
    FragmentListItem,
  },
  data() {
    return {
      search: '',
    };
  },
  apollo: {
    fragments: {
      query: FRAGMENT_LIST_QUERY,
      variables: {
        query: {
          orders: [{ field: 'name', direction: 'ASC' }],
        },
      },
      update: (data) => data.list.items,
    },
  },
  computed: {
    fragmentsFiltered() {
      const fragments = this.fragments || [];
      const filtered = fragments.filter((fragment) => this.isFragmentMatchingSearch(fragment, this.search));
      return filtered.sort((a, b) => stringCompare(a.name, b.name));
    },
  },
  methods: {
    isFragmentMatchingSearch(fragment, search) {
      const value = search.toLowerCase();
      return (
        fragment.name.toLowerCase().includes(value) || fragment.tags.find((tag) => tag.toLowerCase().includes(value))
      );
    },
  },
};
</script>
