<template lang="pug">
component( v-if="layout"
  :is="layout"
  )
  router-view
router-view( v-else )
</template>

<script>
import FullLayout from '@/layouts/FullLayout.vue';
import FullWideLayout from '@/layouts/FullWideLayout.vue';
import RawLayout from '@/layouts/RawLayout.vue';
import SimpleLayout from '@/layouts/SimpleLayout.vue';
import Main from '@/v2/layouts/Main.vue';
import Auth from '@/v2/layouts/Auth.vue';

export default {
  name: 'App',
  components: {
    FullWideLayout,
    FullLayout,
    SimpleLayout,
    RawLayout,
    Main,
    Auth,
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
};
</script>
