import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client';
// eslint-disable-next-line import/no-cycle
import { getRefreshToken } from '@/plugins/apollo';
import EXCHANGE_REFRESH_TOKEN_QUERY from '@/graphql/security/mutation/ExchangeRefreshToken.gql';
import { fetchGraphqlUri } from '@/helpers/dynamicFetch'; // eslint-disable-line import/no-cycle

// Http endpoint
export default async () => {
  const { apolloClient: client } = createApolloClient({
    httpEndpoint: await fetchGraphqlUri(),
    getAuth: () => null, // dont send any token for refresh action
  });
  const result = await client.mutate({
    variables: { refreshToken: getRefreshToken() },
    mutation: EXCHANGE_REFRESH_TOKEN_QUERY,
  });

  return Promise.resolve({
    accessToken: result.data.exchangeRefreshToken.token.value,
    refreshToken: result.data.exchangeRefreshToken.refreshToken.value,
  });
};
