let supported = true;
try {
  'a'.localeCompare('b', 'i');
} catch (e) {
  if (e.name !== 'RangeError') {
    supported = false;
  }
}

function localeCompareBaseNumeric(a, b) {
  return a.localeCompare(b, undefined, { sensitivity: 'base', numeric: true });
}

function localeCompareLowerCase(a, b) {
  return a.toLowerCase().localeCompare(b.toLowerCase());
}

const stringCompare = supported ? localeCompareBaseNumeric : localeCompareLowerCase;
export default stringCompare;
