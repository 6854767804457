<template>
  <layout-main title="Zadania do realizacji">
    <v-container fluid="fluid">
      <v-card>
        <v-row align="center">
          <v-col>
            <v-card-actions class="px-8">
              <v-menu right="right" bottom="bottom" offset-y="offset-y" :nudge-bottom="6" :nudge-left="8">
                <template v-slot:activator="{ on, attrs }">
                  <div class="grey lighten-4 rounded-circle">
                    <v-btn fab="fab" outlined="outlined" small="small" color="grey" v-bind="attrs" v-on="on">
                      <v-icon>{{ queryStatusFilterIcon }}</v-icon>
                    </v-btn>
                  </div>
                </template>
                <v-list dense="dense">
                  <v-list-item @click="setFilter('NEW')">
                    <v-list-item-icon>
                      <v-icon>{{ taskStatusIcon('NEW') }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ taskStatusName('NEW') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="setFilter('IN_PROGRESS')">
                    <v-list-item-icon>
                      <v-icon>{{ taskStatusIcon('IN_PROGRESS') }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ taskStatusName('IN_PROGRESS') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="setFilter(null)">
                    <v-list-item-icon>
                      <v-icon>mdi-menu</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Wszystkie</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-responsive class="mx-4" max-width="260">
                <search-input v-model="search"></search-input>
              </v-responsive>
            </v-card-actions>
          </v-col>
          <v-col cols="auto">
            <v-card-actions class="px-8">
              <v-btn
                rounded="rounded"
                color="primary"
                :outlined="sort === 'due'"
                :depressed="sort === 'createdAt'"
                @click="setSort('createdAt')"
              >
                <v-icon dark="dark">mdi-clock-outline</v-icon><span class="px-2">Najnowsze</span>
              </v-btn>
              <v-btn
                rounded="rounded"
                color="primary"
                :outlined="sort === 'createdAt'"
                :depressed="sort === 'due'"
                @click="setSort('due')"
              >
                <v-icon dark="dark">mdi-camera-timer</v-icon><span class="px-2">Najbliższe</span>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <infinite-loading class="pa-4" ref="infinite" :load="load">
          <template v-slot:default="{ list }">
            <v-list class="rounded overflow-hidden">
              <template v-for="item in list.items">
                <task-list-item :key="item.id" :item="item"></task-list-item>
                <v-divider :key="item.id" class="last-child--hidden"></v-divider>
              </template>
            </v-list>
          </template>
        </infinite-loading>
      </v-card>
    </v-container>
  </layout-main>
</template>

<script>
import { taskStatusIcon, taskStatusName } from '@/v2/components/Task/Mixin';
import LayoutMain from '@/v2/layouts/Main.vue';
import SearchInput from '@/v2/components/Form/SearchInput.vue';
import InfiniteLoading from '@/v2/components/InfiniteLoading.vue';
import TaskListItem from '@/v2/components/Task/ListItem.vue';
import TASK_LIST_QUERY from '@/graphql/task/query/TaskListQuery.gql';

export default {
  components: {
    LayoutMain,
    SearchInput,
    InfiniteLoading,
    TaskListItem,
  },
  data() {
    return {
      search: '',
      filter: null,
      sort: 'due',
    };
  },
  computed: {
    sortDirection() {
      return this.sort === 'due' ? 'ASC_NULL_LAST' : 'DESC';
    },
    queryStatusFilter() {
      if (this.filter) {
        return { field: 'status', type: 'EQ', value: this.filter };
      }
      return { field: 'status', type: 'NEQ', value: 'FINISHED' };
    },
    queryStatusFilterIcon() {
      switch (this.filter) {
        case 'NEW':
          return 'mdi-bell-outline';
        case 'IN_PROGRESS':
          return 'mdi-progress-check';
        default:
          return 'mdi-filter-variant';
      }
    },
  },
  watch: {
    search() {
      this.$refs.infinite.reset();
    },
  },
  methods: {
    taskStatusIcon,
    taskStatusName,
    setFilter(filter) {
      this.filter = filter;
      this.$refs.infinite.reset();
    },
    setSort(sort) {
      this.sort = sort;
      this.$refs.infinite.reset();
    },
    async load(offset) {
      const filters = [this.queryStatusFilter];
      if (this.search !== '') {
        filters.push({ field: 'search', type: 'EQ', value: this.search });
      }
      const { data } = await this.$apollo.query({
        query: TASK_LIST_QUERY,
        variables: {
          query: {
            filters,
            orders: [{ field: this.sort, direction: this.sortDirection }],
          },
          offset,
          limit: 20,
        },
      });
      return data.list;
    },
  },
};
</script>
