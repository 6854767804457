<template>
  <div class="ckeditor-container-wysiwyg-form pb-2 mb-5">
    <VLabel>{{ label }}</VLabel>
    <!-- <ckeditor :editor="editor" @ready="onEditorReady" v-model="model" :config="editorConfig" /> -->
    <ckeditor
      :editor="editor"
      @ready="ready = true"
      :config="editorConfig"
      :value="content"
      @input="$emit('input', $event)"
    />
    <VMessages v-if="shouldValidate" :value="errorBucket" color="error" />
  </div>
</template>

<script>
import { VInput, VMessages, VLabel } from 'vuetify/lib';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import Markdown from '@ckeditor/ckeditor5-markdown-gfm/src/markdown';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';

export default {
  name: 'SimpleWysiwygField',
  components: {
    VMessages,
    VLabel,
  },
  extends: VInput,
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  // watch: {
  //   value(value) {
  //     if (!this.ready) {
  //       this.tmpModel = value;
  //       return;
  //     }
  //     this.model = value;
  //   },
  //   model(value) {
  //     this.$emit('input', value);
  //   },
  // },
  // methods: {
  //   onEditorReady() {
  //     this.ready = true;
  //     this.model = this.tmpModel;
  //     this.tmpModel = null;
  //   },
  // },
  data() {
    return {
      ready: false,
      // model: null,
      // tmpModel: null,
      editor: ClassicEditor,
    };
  },
  computed: {
    content() {
      return (this.ready && this.value) || '';
    },
    editorConfig() {
      return {
        plugins: [Markdown, List, EssentialsPlugin, BoldPlugin, ItalicPlugin, LinkPlugin, ParagraphPlugin],
        placeholder: this.placeholder,
        toolbar: {
          items: ['bold', 'italic', 'numberedList', 'bulletedList'],
        },
      };
    },
  },
};
</script>
<style>
.ckeditor-container-wysiwyg-form .ck-editor__editable {
  min-height: 200px;
  max-height: 200px;
}
</style>
