<template>
  <v-card class="overflow-hidden">
    <v-card-actions class="pa-4">
      <v-row align="center">
        <v-col cols="auto">
          <v-btn class="px-2" outlined="outlined" rounded="rounded" color="secondary" @click="cancel">
            <v-icon>mdi-arrow-left-circle</v-icon>
            <span class="px-2">Powrót</span>
          </v-btn>
        </v-col>
        <template v-if="document">
          <v-col>
            <h4>{{ document.name }}</h4>
          </v-col>
          <v-col cols="auto" v-if="documents.length">
            <v-btn
              class="px-2"
              rounded="rounded"
              small="small"
              color="secondary"
              :outlined="!proceedingDocuments.expand"
              :depressed="proceedingDocuments.expand"
              @click="proceedingDocuments.expand = !proceedingDocuments.expand"
              ><span class="px-2">Dokumenty</span></v-btn
            >
          </v-col>
          <v-col cols="auto">
            <v-btn
              class="px-2"
              depressed="depressed"
              rounded="rounded"
              color="secondary"
              :loading="loading"
              @click="save"
            >
              <v-icon>mdi-check-circle</v-icon>
              <span class="px-2">Zapisz</span>
            </v-btn>
          </v-col>
        </template>
      </v-row>
    </v-card-actions>
    <div class="mx-4 mb-4" v-show="proceedingDocuments.expand">
      <v-list
        class="rounded grey lighten-5"
        v-show="!proceedingDocuments.preview"
        :style="{ maxHeight: '20vh', overflowY: 'scroll' }"
      >
        <template v-for="document in documents">
          <document-list-item
            :key="document.id"
            :document="document"
            @click="proceedingDocuments.preview = document"
          ></document-list-item>
          <v-divider :key="document.id" class="last-child--hidden"></v-divider>
        </template>
      </v-list>
      <div class="px-4 pb-4 rounded grey lighten-4" v-if="proceedingDocuments.preview">
        <div class="py-4">
          <v-row align="center">
            <v-col>
              <div class="text-subtitle-2">{{ proceedingDocuments.preview.name }}</div>
            </v-col>
            <v-col cols="auto">
              <v-btn
                x-small="x-small"
                depressed="depressed"
                fab="fab"
                color="grey lighten-2"
                @click="proceedingDocuments.preview = null"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <pdf-viewer
          :style="{ maxHeight: '30vh', overflowY: 'scroll' }"
          :url="`${proceedingDocuments.preview.file.downloadUrl}?version=${proceedingDocuments.preview.file.checksum}`"
        ></pdf-viewer>
      </div>
    </div>
    <v-divider></v-divider>
    <document-editor-base
      v-if="document"
      ref="editor"
      :document="document"
      :initialData="content"
      @change="content = $event"
    ></document-editor-base>
  </v-card>
</template>

<script>
import { jsPDF } from 'jspdf';
import stringCompare from '@/v2/helpers/stringCompare';
import { documentRoute } from '@/v2/components/Document/Mixin';
import DocumentListItem from '@/v2/components/Document/ListItem.vue';
import PdfViewer from '@/v2/components/Document/PdfViewer.vue';
import DocumentEditorBase from '@/components/Document/Editor/DocumentEditorBase.vue';
import DOCUMENT_QUERY from '@/graphql/document/query/GetOneDocumentQuery.gql';
import SET_INTERNAL_DOCUMENT_DATA from '@/graphql/document/mutation/SetInternalDocumentData.gql';
import PRINT_DOCUMENT_MUTATION from '@/graphql/document/mutation/SavePrintedDocumentMutation.gql';

export default {
  components: {
    DocumentListItem,
    PdfViewer,
    DocumentEditorBase,
  },
  props: {
    proceeding: {
      type: Object,
      required: true,
    },
    docid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      content: '',
      proceedingDocuments: {
        expand: false,
        preview: null,
      },
    };
  },
  apollo: {
    document: {
      query: DOCUMENT_QUERY,
      variables() {
        return {
          documentId: this.docid,
        };
      },
      update(data) {
        const document = data.list.items[0];
        this.content = document.data || '';
        return document;
      },
    },
  },
  computed: {
    documents() {
      const documents = this.proceeding.documents.filter((doc) => doc.file && doc.id !== this.docid);
      documents.sort((a, b) => stringCompare(a.receivedAt, b.receivedAt));
      return documents;
    },
  },
  methods: {
    async save() {
      this.loading = true;
      try {
        await this.editorSave();
        const document = await this.printPdf();
        this.$emit('document-update', document);
      } catch (e) {
        alert(e.message); // eslint-disable-line
      }
      this.loading = false;
    },
    async editorSave() {
      const data = this.$refs.editor.$refs.container.ej2Instances.documentEditor.serialize();
      await this.$apollo.mutate({
        mutation: SET_INTERNAL_DOCUMENT_DATA,
        variables: {
          id: this.document.id,
          data,
        },
        update: () => {
          this.$emit('unsavedChange', false);
        },
      });
    },
    async printPdf() {
      // Default export is a4 paper, portrait, using millimeters for units
      // eslint-disable-next-line
      const doc = new jsPDF({unit: 'in'});
      doc.deletePage(0);
      doc.deletePage(1);
      // Rendering canvas to print
      const { documentHelper } = this.$refs.editor.$refs.container.ej2Instances.documentEditor;
      const parts = [];
      // eslint-ignore-next-line
      for (let i = 0; i < documentHelper.pages.length; i += 1) {
        const page = documentHelper.pages[i];
        const pageWidth = page.boundingRectangle.width / 100;
        const pageHeight = page.boundingRectangle.height / 100;
        doc.addPage([pageWidth, pageHeight], pageWidth < pageHeight ? 'portrait' : 'landscape');

        // 100 units = 133 units of pageHeight
        documentHelper.render.isPrinting = true;
        documentHelper.render.renderWidgets(page, 0, 0, 0, 0);
        const canvasURL = documentHelper.render.pageCanvas.toDataURL();
        parts.push(canvasURL);
        doc.addImage(canvasURL, 'JPEG', 0, 0, doc.getPageWidth(), doc.getPageHeight(), 'alias', 'FAST');
        documentHelper.render.isPrinting = false;
      }

      if (window.debugPdf) {
        doc.save('a4.pdf');
      }

      const file = doc.output('blob');

      const { data } = await this.$apollo.mutate({
        mutation: PRINT_DOCUMENT_MUTATION,
        variables: {
          id: this.document.id,
          file,
        },
      });
      return data.savePrintedDocument;
    },
    cancel() {
      this.$router.push(documentRoute(this.proceeding, this.document));
    },
  },
};
</script>
