<template>
  <v-row>
    <v-col>
      <v-text-field
        outlined="outlined"
        hide-details="hide-details"
        thumb-label="always"
        label="lata"
        type="number"
        min="0"
        max="5"
        step="1"
        v-model="years"
      ></v-text-field>
    </v-col>
    <v-col>
      <v-text-field
        outlined="outlined"
        hide-details="hide-details"
        thumb-label="always"
        label="miesiące"
        type="number"
        min="0"
        max="12"
        step="1"
        v-model="months"
      ></v-text-field>
    </v-col>
    <v-col>
      <v-text-field
        outlined="outlined"
        hide-details="hide-details"
        thumb-label="always"
        label="dni"
        type="number"
        min="0"
        max="31"
        step="1"
        v-model="days"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    value: String,
  },
  data() {
    return {
      days: 0,
      months: 0,
      years: 0,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        const duration = moment.duration(value);
        this.days = duration.get('days');
        this.months = duration.get('months');
        this.years = duration.get('years');
      },
    },
    days() {
      this.emitInput();
    },
    months() {
      this.emitInput();
    },
    years() {
      this.emitInput();
    },
  },
  methods: {
    emitInput() {
      const value = moment
        .duration({
          days: this.days,
          months: this.months,
          years: this.years,
        })
        .toISOString();
      this.$emit('input', value);
    },
  },
};
</script>
