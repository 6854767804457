<template>
  <v-form class="px-4 pt-6" ref="form">
    <v-row v-if="claim || createClaim">
      <v-col cols="12">
        <div class="text-subtitle-1 px-0 text--secondary">Finanse</div>
      </v-col>
      <v-col cols="12">
        <v-text-field
          outlined="outlined"
          label="Konto"
          :rules="form.rules.accountNumber"
          v-model="form.input.accountNumber"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col>
            <vuetify-money
              outlined="outlined"
              persistent-placeholder="persistent-placeholder"
              label="Suma wierzytelności"
              placeholder="0.00"
              :options="{ locale: 'pl-PL', suffix: 'PLN', precision: 2 }"
              :value="form.input.sum.amount / 100"
              @input="form.input.sum.amount = $event * 100"
            ></vuetify-money>
          </v-col>
          <v-col>
            <vuetify-money
              outlined="outlined"
              persistent-placeholder="persistent-placeholder"
              label="Udział w głosowaniu"
              placeholder="0.00"
              :options="{ locale: 'pl-PL', suffix: 'PLN', precision: 2 }"
              :value="form.input.sumForVote.amount / 100"
              @input="form.input.sumForVote.amount = $event * 100"
            ></vuetify-money>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col>
            <vuetify-money
              outlined="outlined"
              persistent-placeholder="persistent-placeholder"
              label="Kat. I"
              placeholder="0.00"
              :options="{ locale: 'pl-PL', suffix: 'PLN', precision: 2 }"
              :value="form.input.sumCatI.amount / 100"
              @input="form.input.sumCatI.amount = $event * 100"
            ></vuetify-money>
          </v-col>
          <v-col>
            <vuetify-money
              outlined="outlined"
              persistent-placeholder="persistent-placeholder"
              label="Kat. II"
              placeholder="0.00"
              :options="{ locale: 'pl-PL', suffix: 'PLN', precision: 2 }"
              :value="form.input.sumCatII.amount / 100"
              @input="form.input.sumCatII.amount = $event * 100"
            ></vuetify-money>
          </v-col>
          <v-col>
            <vuetify-money
              outlined="outlined"
              persistent-placeholder="persistent-placeholder"
              label="Kat. III"
              placeholder="0.00"
              :options="{ locale: 'pl-PL', suffix: 'PLN', precision: 2 }"
              :value="form.input.sumCatIII.amount / 100"
              @input="form.input.sumCatIII.amount = $event * 100"
            ></vuetify-money>
          </v-col>
          <v-col>
            <vuetify-money
              outlined="outlined"
              persistent-placeholder="persistent-placeholder"
              label="Kat. IV"
              placeholder="0.00"
              :options="{ locale: 'pl-PL', suffix: 'PLN', precision: 2 }"
              :value="form.input.sumCatIV.amount / 100"
              @input="form.input.sumCatIV.amount = $event * 100"
            ></vuetify-money>
          </v-col>
          <v-col cols="12">
            <v-card class="pa-4 success lighten-5" flat="flat">
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    class="mt-0"
                    hide-details="hide-details"
                    label="Zabezpieczenie wierzytelności"
                    v-model="form.input.protectionExists"
                  ></v-checkbox>
                  <v-textarea
                    class="pt-4"
                    v-if="form.input.protectionExists"
                    outlined="outlined"
                    auto-grow="auto-grow"
                    no-resize="no-resize"
                    rows="1"
                    label="Informacja o rodzaju zabezpieczenia"
                    :rules="form.rules.protectionExistsDescription"
                    v-model="form.input.protectionExistsDescription"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    class="mt-0"
                    hide-details="hide-details"
                    label="Warunek wierzytelności"
                    v-model="form.input.isConditional"
                  ></v-checkbox>
                  <v-textarea
                    class="pt-4"
                    v-if="form.input.isConditional"
                    outlined="outlined"
                    auto-grow="auto-grow"
                    no-resize="no-resize"
                    rows="1"
                    label="Informacja o warunku"
                    :rules="form.rules.isConditionalDescription"
                    v-model="form.input.isConditionalDescription"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    class="mt-0"
                    hide-details="hide-details"
                    label="Prawo potrącenia"
                    v-model="form.input.rightToDeduct"
                  ></v-checkbox>
                  <v-textarea
                    class="pt-4"
                    v-if="form.input.rightToDeduct"
                    outlined="outlined"
                    auto-grow="auto-grow"
                    no-resize="no-resize"
                    rows="1"
                    label="Informacja o prawie potrącenia"
                    :rules="form.rules.rightToDeductDescription"
                    v-model="form.input.rightToDeductDescription"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col class="pt-8" cols="12">
            <div class="text-subtitle-1 px-0 text--secondary">Informacje</div>
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined="outlined"
              auto-grow="auto-grow"
              no-resize="no-resize"
              rows="1"
              label="Informacja o stanie postępowania"
              v-model="form.input.courtCaseStatus"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined="outlined"
              auto-grow="auto-grow"
              no-resize="no-resize"
              rows="1"
              label="Uzasadnienie uznania wierzytelności"
              v-model="form.input.justification"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined="outlined"
              auto-grow="auto-grow"
              no-resize="no-resize"
              rows="1"
              label="Uzasadnienie zaprzeczenia przez syndyka oświadczeniom wierzyciela"
              v-model="form.input.justificationForDismissal"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined="outlined"
              auto-grow="auto-grow"
              no-resize="no-resize"
              rows="1"
              label="Oświadczenie upadłego z uzasadnieniem"
              v-model="form.input.debtorClaim"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="pb-4 text-center" v-else>
      <v-btn outlined="outlined" large="large" color="secondary" @click="createClaim = true">Dodaj wierzytelność</v-btn>
    </div>
  </v-form>
</template>

<script>
export default {
  props: {
    claim: { type: Object },
  },
  data() {
    return {
      createClaim: false,
      form: {
        input: {
          accountNumber: '',
          sum: {
            amount: 0,
            currency: 'PLN',
          },
          sumForVote: {
            amount: 0,
            currency: 'PLN',
          },
          sumCatI: {
            amount: 0,
            currency: 'PLN',
          },
          sumCatII: {
            amount: 0,
            currency: 'PLN',
          },
          sumCatIII: {
            amount: 0,
            currency: 'PLN',
          },
          sumCatIV: {
            amount: 0,
            currency: 'PLN',
          },
          courtCaseStatus: '',
          protectionExists: false,
          protectionExistsDescription: '',
          isConditional: false,
          isConditionalDescription: '',
          rightToDeduct: false,
          rightToDeductDescription: '',
          justification: '',
          justificationForDismissal: '',
          debtorClaim: '',
        },
        rules: {
          accountNumber: [(v) => !!v || 'Wprowadź numer konta'],
          protectionExistsDescription: [(v) => v.length < 255 || 'Maksymalnie 255 znaków'],
          isConditionalDescription: [(v) => v.length < 255 || 'Maksymalnie 255 znaków'],
          justificationForDismissal: [(v) => v.length < 255 || 'Maksymalnie 255 znaków'],
        },
      },
    };
  },
  created() {
    if (this.claim) {
      this.form.input.accountNumber = this.claim.accountNumber || '';
      this.form.input.sum.amount = this.claim.sum.amount || 0;
      this.form.input.sumForVote.amount = this.claim.sumForVote.amount || 0;
      this.form.input.sumCatI.amount = this.claim.sumCatI.amount || 0;
      this.form.input.sumCatII.amount = this.claim.sumCatII.amount || 0;
      this.form.input.sumCatIII.amount = this.claim.sumCatIII.amount || 0;
      this.form.input.sumCatIV.amount = this.claim.sumCatIV.amount || 0;
      this.form.input.courtCaseStatus = this.claim.courtCaseStatus || '';
      this.form.input.protectionExists = this.claim.protectionExists || false;
      this.form.input.protectionExistsDescription = this.claim.protectionExistsDescription || '';
      this.form.input.isConditional = this.claim.isConditional || false;
      this.form.input.isConditionalDescription = this.claim.isConditionalDescription || '';
      this.form.input.rightToDeduct = this.claim.rightToDeduct || false;
      this.form.input.rightToDeductDescription = this.claim.rightToDeductDescription || '';
      this.form.input.justification = this.claim.justification || '';
      this.form.input.justificationForDismissal = this.claim.justificationForDismissal || '';
      this.form.input.debtorClaim = this.claim.debtorClaim || '';
    }
  },
  watch: {
    'form.input': {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>
